import { useEffect } from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";

import ChatMessage from "./ChatMessage";
import useGetCompanyId from "../../hooks/useGetCompanyId";
import { useScrollToEnd } from "./useScrollToEnd";

const useStyles = makeStyles(() => ({
  container: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column-reverse",
  },
  scroll: {
    display: "flex",
    flexDirection: "column-reverse",
    padding: 10,
  },
}));

export default function ChatFeed({ messages, onScrollEnd, chatFeedRef, isPropertyChat, customHeight = undefined }) {
  const classes = useStyles({});
  const { companyId } = useGetCompanyId();
  const { isAtEnd, setIsAtEnd } = useScrollToEnd(chatFeedRef, true);
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const renderMessages = () => {
    return messages.map((message) => {
      const modifiedMessage = {
        ...message,
        isSender: message.from.id === companyId,
        date: dayjs.unix(message.createdAt ? message.createdAt.seconds : 0).format("DD/MM/YYYY - hh:mm a"),
      };

      return (
        <Box mt={0.5} key={modifiedMessage.id}>
          <ChatMessage message={modifiedMessage} />
        </Box>
      );
    });
  };

  useEffect(() => {
    if (isAtEnd) {
      console.log("getting more messages...");
      onScrollEnd();
    }

    return () => {
      setIsAtEnd(false);
    };
  }, [isAtEnd, setIsAtEnd]);

  return (
    <Box
      className={classes.container}
      height={customHeight ?? (isSm ? (isPropertyChat ? "48vh" : "72vh") : "100%")}
      id="scrollableChatFeed"
      ref={chatFeedRef}
    >
      {Array.isArray(messages) && <>{renderMessages()}</>}
    </Box>
  );
}

import { Box, Typography } from "@material-ui/core";

import useSharedStyles from "../hooks/useSharedStyles";
import TooltipPopover from "./TooltipPopover";

const ShowFullTextOnHover = (props) => {
  const { record, source } = props;
  const { styles } = useSharedStyles();

  return (
    <TooltipPopover message={record[source]}>
      <Box className={styles.truncate3}>
        <Typography variant="body2">{record[source]}</Typography>
      </Box>
    </TooltipPopover>
  );
};

export default ShowFullTextOnHover;

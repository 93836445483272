import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useRedirect } from "react-admin";
import { useEffect } from "react";

const CompanyHome = ({ redirectEnabled = false }) => {
  const redirect = useRedirect();

  useEffect(() => {
    if (redirectEnabled) redirect(`/home`);
  }, []);

  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
      <Typography>Welcome to company dashboard</Typography>
    </Box>
  );
};

CompanyHome.prototype = {
  redirectEnabled: PropTypes.bool,
};

export default CompanyHome;

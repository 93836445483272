import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";

import TooltipPopover from "../../../components/TooltipPopover";
import { ResourcesKeys } from "../../../enums";

const StartChat = (props) => {
  const record = props.record;
  const { push } = useHistory();

  const handleRedirect = (e) => {
    e.stopPropagation();
    push({
      pathname: `/${ResourcesKeys.chatWithSingleClient}/${record.clientId}`,
    });
  };

  return (
    <TooltipPopover message="Start chat">
      <IconButton onClick={handleRedirect}>
        <ChatIcon />
      </IconButton>
    </TooltipPopover>
  );
};

export default StartChat;

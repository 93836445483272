import { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { TextInput } from "react-admin";

export default function TextInputWithCounter({ counter, multiline, ...props }) {
  const inputValue = (() => {
    const isLocalized = props.source.split(".")[1];
    if (!isLocalized) return props.record[props.source];
    else {
      if (props.record[props.source.split(".")[0]])
        return props.record[props.source.split(".")[0]][props.source.split(".")[1]];
      else return "";
    }
  })();

  const [val, setVal] = useState(inputValue);
  delete props.options;

  return (
    <TextInput
      {...props}
      multiline={multiline}
      rows={multiline ? 5 : 1}
      value={val}
      onChange={(e) => setVal(e.target.value)}
      InputProps={{
        endAdornment: counter ? (
          <Box display="flex" alignItems="flex-end" height="100%">
            <Typography variant="overline" style={{ padding: 4 }}>
              {counter - val.length}
            </Typography>
          </Box>
        ) : null,
      }}
      style={{ marginTop: 16 }}
    />
  );
}

import { useEffect, useState } from "react";
import { useGetPermissions } from "react-admin";

import { UserRoles } from "../enums";
import useCurrentUser from "./useCurrentUser";

/**
 * this hook won't work outside react admin 'Admin' component
 * will return 'undefined', so use it inside
 * */
const useIsCompanyAdmin = () => {
  const { user } = useCurrentUser();
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(
    user.permissions && user.permissions.role === UserRoles.propertyAdmin
  );
  const res = useGetPermissions();

  useEffect(() => {
    (async function () {
      const permissions = await res();
      const _isCompanyAdmin = permissions && permissions.role === UserRoles.propertyAdmin;
      if (_isCompanyAdmin !== isCompanyAdmin) setIsCompanyAdmin(_isCompanyAdmin);
    })();
  }, []);

  return { isCompanyAdmin };
};

export default useIsCompanyAdmin;

import { Box, Typography } from "@material-ui/core";
import {
  BooleanInput,
  Edit,
  email,
  FormGroupContextProvider,
  FormWithRedirect,
  minLength,
  PasswordInput,
  required,
  TextInput,
} from "react-admin";

import { FormGroupNames } from "../../enums";
import { isExists, phoneNumber } from "../../utils/validators";
import ImageUploadInput from "../../components/ImageUploadInput";
import CustomToolbar from "../../components/CustomToolbar";
import useDisabledEvents from "../../hooks/useDisabledEvents";
import FormWrapper from "../../components/FormWrapper";
import CustomCard from "../../components/CustomCard";
import useCurrentUser from "../../hooks/useCurrentUser";
import { employeesCollPath } from "../../utils/paths.utils";
import useSharedStyles from "../../hooks/useSharedStyles";
import { redirectTo } from "./utils";
import PhonePrefixCode from "../../components/PhonePrefixCode";

const CustomForm = (props) => {
  const employee = props.record;
  employee.phoneNumber = employee.phoneNumber.replace("+974", "");
  const { styles } = useSharedStyles();
  const { disabledEvents } = useDisabledEvents();
  const { user } = useCurrentUser();
  const path = employeesCollPath(user.companyId);

  return (
    <FormWrapper>
      <FormGroupContextProvider name={FormGroupNames.employeesEdit}>
        <FormWithRedirect
          {...props}
          redirect={redirectTo(props.options.type)}
          render={(formProps) => {
            return (
              <form style={disabledEvents}>
                <CustomCard>
                  <Typography variant="h6">Contact Info</Typography>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>
                      <TextInput variant="outlined" source="displayName" validate={[required()]} />
                    </Box>
                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        source="email"
                        validate={[required(), email(), isExists("email", path, props.record.email)]}
                      />
                    </Box>
                  </Box>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>
                      <PasswordInput variant="outlined" source="password" validate={[minLength(8)]} />
                    </Box>
                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        type="number"
                        source="phoneNumber"
                        validate={[required(), phoneNumber(), isExists("phoneNumber", path, props.record.phoneNumber)]}
                        InputProps={{
                          startAdornment: <PhonePrefixCode />,
                        }}
                      />
                    </Box>
                  </Box>
                </CustomCard>
                <CustomCard>
                  <Typography variant="h6">Media</Typography>
                  <ImageUploadInput source="photo" label="Photo" validate={[required()]} />
                </CustomCard>
                <CustomCard>
                  <Typography variant="h6">Preference</Typography>
                  <BooleanInput source="disabled" />
                </CustomCard>

                <CustomToolbar
                  {...formProps}
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  formName={FormGroupNames.employeesEdit}
                />
              </form>
            );
          }}
        />
      </FormGroupContextProvider>
    </FormWrapper>
  );
};

export default function EmployeesEdit(props) {
  return (
    <Edit
      {...props}
      component="div"
      transform={(values) => {
        values.phoneNumber = `+974${values.phoneNumber}`;

        return values;
      }}
    >
      <CustomForm variant="outlined" sanitizeEmptyValues={false} {...props} />
    </Edit>
  );
}

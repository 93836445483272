import { Box, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useEffect, useState } from "react";
import { from } from "rxjs";
import { map } from "rxjs/operators";

import Loader from "../../../components/Loader";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { employeesDocRef } from "../../../utils/refrences.utils";
import AssignEmployees from "./listActions/AssignEmployees";

const EmployeeAssignees = ({ record }) => {
  const { user } = useCurrentUser();
  const [assignee, setAssignee] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAssignees = () => {
    return from(employeesDocRef(user.companyId, record.parentId).get()).pipe(map((snapshot) => snapshot.data()));
  };

  useEffect(() => {
    setLoading(true);
    getAssignees().subscribe({
      next(data) {
        setAssignee(data);
        setLoading(false);
      },
      error(ex) {
        console.log(ex.message);
        setAssignee(null);
        setLoading(false);
      },
    });
  }, [record.parentId]);

  return (
    <Loader loading={loading} type="linear">
      <AssignEmployees
        selectedIds={[record.id]}
        ClickBtn={() => {
          return (
            <Box display="flex" alignItems="center">
              <AddCircleOutlineIcon color="primary" />
              {assignee ? <Typography>{assignee.displayName}</Typography> : null}
            </Box>
          );
        }}
      />
    </Loader>
  );
};

export default EmployeeAssignees;

import { call, put, takeEvery } from "redux-saga/effects";

import { rsf } from "../../../init/firebase";
import { getDataFromFirebaseCollection } from "../../../utils/funcs";
import { setRoomMessages } from "../../reducers/chat";
import chatActionCreators from "../../actions/chat";
import { messagesDocRef, messagesSubRef } from "../../../utils/refrences.utils";

const messagesQuery = (roomId) => messagesSubRef(roomId).orderBy("createdAt", "desc");

function* getRoomMessages(action) {
  const { last, roomId, type } = action.payload;
  let firstDocSnapshot;
  if (last) {
    firstDocSnapshot = yield messagesDocRef(roomId, last.id).get();
  }

  try {
    const messages = yield call(
      rsf.firestore.getCollection,
      last ? messagesQuery(roomId).startAfter(firstDocSnapshot).limit(20) : messagesQuery(roomId).limit(20)
    );

    const formattedMessages = getDataFromFirebaseCollection(messages.docs);
    yield put(setRoomMessages({ messages: formattedMessages, roomId, type }));
  } catch (ex) {
    console.log(ex.message);
  }
}

export function* watchGetRoomMessages() {
  yield takeEvery(chatActionCreators.getRoomMessages.toString(), getRoomMessages);
}

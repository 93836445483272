import { Route } from "react-router-dom";
import { ListGuesser, Resource } from "react-admin";
import BusinessIcon from "@material-ui/icons/Business";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import AddIcon from "@material-ui/icons/Add";
import GroupIcon from "@material-ui/icons/Group";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AddAlertIcon from "@material-ui/icons/AddAlert";

import { ResourcesKeys } from "../../../enums";
import { PropertiesCompaniesCreate, PropertiesCompaniesEdit, PropertiesCompaniesList } from "../../propertiesCompanies";
import { ClientsList } from "../../clients";
import { PropertiesList, PropertiesShow } from "../../companyProperties";
import { UserGuideCreate, UserGuideEdit, UserGuideList } from "../../userGuide";
import { PackagesCreate, PackagesEdit, PackagesList } from "../../packages";
import RenewPackage from "../../packages/components/RenewPackage";
import SettingsEdit from "../../settings/SettingsEdit";
import AdminProfile from "../../profile/Profile";
import AdminHome from "./Home";
import { AnnouncementsCreate, AnnouncementsList } from "../../Announcements";
import CreateAD from "../../ads/create";
import AdsList from "../../ads/list";

export const adminDashboard = {
  resources: [
    <Resource name={ResourcesKeys.Cities} />,
    <Resource name={ResourcesKeys.Areas} />,
    <Resource
      name={ResourcesKeys.PropertiesCompanies}
      list={PropertiesCompaniesList}
      create={PropertiesCompaniesCreate}
      edit={PropertiesCompaniesEdit}
      options={{
        label: "Companies",
      }}
      icon={BusinessIcon}
    />,
    <Resource
      name={ResourcesKeys.Clients}
      list={ClientsList}
      options={{
        label: "Clients",
      }}
      icon={GroupIcon}
    />,
    <Resource
      name={ResourcesKeys.Properties}
      list={PropertiesList}
      show={PropertiesShow}
      options={{
        label: "Properties",
      }}
      icon={HomeWorkIcon}
    />,
    <Resource
      name={ResourcesKeys.announcements}
      list={AnnouncementsList}
      create={AnnouncementsCreate}
      options={{
        label: "Announcements",
      }}
      icon={AddAlertIcon}
    />,
    <Resource
      name={ResourcesKeys.UserGuide}
      list={UserGuideList}
      edit={UserGuideEdit}
      create={UserGuideCreate}
      options={{
        label: "Userguide",
      }}
      icon={LiveHelpIcon}
    />,
    <Resource
      name={ResourcesKeys.Packages}
      list={PackagesList}
      create={PackagesCreate}
      edit={PackagesEdit}
      options={{
        label: "Packages",
      }}
      icon={BusinessCenterIcon}
    />,
    <Resource
      name={ResourcesKeys.ads}
      list={AdsList}
      create={CreateAD}
      // edit={PackagesEdit}
      options={{
        label: "ADS",
      }}
      icon={AddIcon}
    />,
    // <Resource name="content" />
  ],
  customRoutes: [
    <Route exact path="/company/:companyId/package/:packageId" component={RenewPackage} />,
    <Route exact path={`/${ResourcesKeys.Settings}`} component={SettingsEdit} />,
    <Route exact path={`/${ResourcesKeys.Profile}`} component={AdminProfile} />,
  ],
  home: () => <AdminHome redirectEnabled />,
};

import { memo } from "react";
import { useRecordContext } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import isEqual from "lodash/fp/isEqual";

import defaultImg from "../assets/default.png";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 75,
    height: 75,
    borderRadius: "50%",
    overflow: "hidden",
    boxShadow: "1px 1px 5px",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const ListImage = (props) => {
  const classes = useStyles(props);
  const { source, ...rest } = props;
  const record = useRecordContext(props);

  const src = source.includes(".") ? record[source.split(".")[0]][source.split(".")[1]] : record[source];

  console.log("src", src);

  return (
    <div className={classes.container} {...rest}>
      <img
        className={classes.img}
        src={src || defaultImg}
        alt={record && record.name && record.name.en}
        loading="lazy"
      />
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export default memo(ListImage, areEqual);

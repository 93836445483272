import { createContext, useContext } from "react";

const NotificationContext = createContext({
  getNotificationData: () => {},
  getNotificationRequest: () => {},
  onRedirect: () => {},
});

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

export default NotificationContext;

import NotificationContext from "./utils/notificationContext";
import FullNotificationList from "./FullNotificationList";
import MenuNotificationList from "./MenuNotificationList";

const NotificationsList = ({ type, getNotificationData, getNotificationRequest, onRedirect }) => {
  return (
    <NotificationContext.Provider
      value={{
        getNotificationRequest,
        getNotificationData,
        onRedirect,
      }}
    >
      {type === "full" ? <FullNotificationList /> : type === "dropdown" ? <MenuNotificationList /> : null}
    </NotificationContext.Provider>
  );
};

export default NotificationsList;

import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";

const announcementsResource = {
  [ResourcesKeys.announcements]: {
    [RequestKeys.GET_LIST]: async (queryData) =>
      getFinalList(firestore.collection(ResourcesKeys.announcements), queryData),
  },
};

export default announcementsResource;

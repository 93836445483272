import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";

const clientsResource = {
  [ResourcesKeys.Clients]: {
    [RequestKeys.GET_LIST]: async (queryData) =>
      getFinalList(firestore.collection(ResourcesKeys.Clients), {
        ...queryData,
        limit: queryData.pagination.perPage,
        filters: queryData.filter,
        operators: {
          phoneNumber: "==",
        },
      }),
  },
};

export default clientsResource;

import { useSelector } from "react-redux";

const useCurrentUser = () => {
  // todo - fetch user from firebase auth and check if it changed to update
  const user = useSelector((state) => state.user);

  // console.log( "user" , user );

  return { user };
};

export default useCurrentUser;

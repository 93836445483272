import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ImageInput } from "react-admin";

import realEstate from "../assets/realEstate.svg";

const CustomImageInput = (props) => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (props.record.rawFile && props.record.rawFile instanceof File) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result) {
          setPreview(result);
        }
      };
      fileReader.readAsDataURL(props.record.rawFile);
    }
  }, [props.record]);

  return props.record.rawFile && props.record.rawFile instanceof File ? (
    <img src={preview} alt={preview} title={preview} style={{ maxWidth: isMd ? "200px" : "", maxHeight: "200px" }} />
  ) : (
    <img
      src={props.record.src}
      onError={(e) => {
        e.currentTarget.src = realEstate;
      }}
      alt={props.record.path}
      title={props.record.path}
      style={{ maxWidth: isMd ? "200px" : "", maxHeight: "200px" }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    border: `3px dashed ${theme.palette.grey[300]}`,
    borderRadius: 10,
    padding: theme.spacing(1.5),
    boxSizing: "border-box",

    "& label": {
      padding: theme.spacing(1),
    },

    "& .previews": {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  dropZone: {
    backgroundColor: "transparent",
  },
  removeButton: {
    position: "relative",
    flexBasis: 200,
    margin: theme.spacing(0.5),
    borderRadius: 20,
    overflow: "hidden",

    "& button": {
      position: "absolute",
      right: 5,
      top: 5,
    },

    "& img": {
      height: "100%",
      objectFit: "cover",
      margin: 0,
    },
  },
}));

export default function ImageUploadInput({ source, ...rest }) {
  const classes = useStyles({});
  // const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <ImageInput
      classes={{
        root: classes.root,
        dropZone: classes.dropZone,
        removeButton: classes.removeButton,
      }}
      source={source}
      label="File"
      accept="image/*"
      {...rest}
    >
      <CustomImageInput />
      {/* <ImageField
        source="src"
        title="path"
        onError={(e) => {
          e.currentTarget.src = realEstate;
        }}
        style={{ maxWidth: isMd ? "200px" : "" }}
      /> */}
    </ImageInput>
  );
}

import { useLoading } from "react-admin";

const useDisabledEvents = (check = false) => {
  const loading = useLoading();

  const disabledEvents = loading || check ? { pointerEvents: "none", opacity: 0.5 } : {};
  return { disabledEvents };
};

export default useDisabledEvents;

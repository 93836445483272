import { RequestKeys, ResourcesKeys, UserRoles } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList, prepareQueryFilters } from "../../../utils/funcs";

const supervisors = {
  [RequestKeys.GET_LIST]: async (queryData) =>
    getFinalList(
      firestore
        .collection(`${ResourcesKeys.PropertiesCompanies}/${queryData.user.companyId}/${ResourcesKeys.Employees}`)
        .where("role", "==", UserRoles.propertySupervisor),
      {
        ...queryData,
        limit: queryData.pagination.perPage,
        ...prepareQueryFilters(queryData.filter),
      }
    ),
};

export default supervisors;

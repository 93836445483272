import UnpublishedPropertyButton from "./UnpublishPropertyButton";
import DeletePropertyButton from "./DeleteProperty";
import AssignProperty from "./AssignProperty";
import Spacing from "../../../../components/Spacing";

const PropertiesBulkActions = (props) => {
  const { isSales, isAdmin } = props;

  const renderActions = () => {
    let actions = [<DeletePropertyButton key="DeletePropertyButton" label="Delete" {...props} />];

    if (!isSales) {
      actions = [
        <UnpublishedPropertyButton key="UnpublishedPropertyButton" label="Unpublish" {...props} />,
        ...actions,
      ];
    }

    if (!isSales && !isAdmin) {
      actions = [<AssignProperty key="AssignProperty" {...props} />, ...actions];
    }
    return actions;
  };

  return <Spacing elements={renderActions().length}>{renderActions()}</Spacing>;
};

export default PropertiesBulkActions;

import { call, put, takeEvery } from "redux-saga/effects";

import { rsf } from "../../init/firebase";
import { getAreas } from "../actions/init";
import { ResourcesKeys } from "../../enums";
import { setAreas } from "../reducers/areas";

function* _getAreas() {
  const res = yield call(rsf.firestore.getCollection, ResourcesKeys.Areas);
  const areas = res.docs.reduce((docs, snapshot) => {
    return {
      ...docs,
      [snapshot.id]: snapshot.data(),
    };
  }, {});

  yield put(setAreas(areas));
}

export function* watchGetAreas() {
  yield takeEvery(getAreas.toString(), _getAreas);
}

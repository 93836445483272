import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import ChatMenu from "./ChatMenu";
import chatActionCreators from "../../redux/actions/chat";
import { setCurrentRoom } from "../../redux/reducers/chat";
import useCurrentUser from "../../hooks/useCurrentUser";
import useGetUserRole from "../../hooks/useGetUserRole";
import Chatting from "./Chatting";
import { areEqual } from "../../utils/general";
import { ChatType } from "../../enums";

const Chat = ({ type }) => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { user } = useCurrentUser();
  const { role } = useGetUserRole();

  const currentRoomId = useSelector((state) => state.chat[type].current);
  const rooms = useSelector((state) => state.chat[type].rooms);
  const isLoading = useSelector((state) => state.chat[type].isLoading);
  const currentRoom = rooms.find((room) => room.id === currentRoomId);

  const [chatResponsive, setChatResponsive] = useState(false);

  const {
    location: { state },
  } = useHistory();

  useEffect(() => {
    if (isSm && currentRoomId) setChatResponsive(false);
  }, [currentRoomId, isSm]);

  useEffect(() => {
    if (state) {
      const { userId, clientId } = state;
      if (userId && clientId) {
        const roomId = `${clientId}_${userId}`;
        dispatch(setCurrentRoom({ type, roomId }));
      }
    }
  }, [dispatch, state, type]);

  return (
    <Box mt={2} style={{ boxShadow: "1px 1px 10px #c6c6c6", overflow: "hidden" }} height={isSm ? "90vh" : "80vh"}>
      <Grid container style={{ height: "100%" }}>
        {!isSm ? (
          <>
            <Grid item xs={3} style={{ backgroundColor: "#f6f6f6", height: "100%", width: isSm ? "100%" : "" }}>
              <ChatMenu
                channels={rooms}
                onChannelChange={(roomId) => dispatch(setCurrentRoom({ type, roomId }))}
                onScrollEnd={() => {
                  if (type === ChatType.property)
                    dispatch(
                      chatActionCreators.getPropertyRooms(user.companyId, user.id, role, rooms[rooms.length - 1])
                    );
                  if (type === ChatType.reply)
                    dispatch(chatActionCreators.getReplyRooms(user.companyId, user.id, role, rooms[rooms.length - 1]));
                  if (type === ChatType.company)
                    dispatch(
                      chatActionCreators.getCompanyRooms(user.companyId, user.id, role, rooms[rooms.length - 1])
                    );
                }}
                currentRoomId={currentRoomId}
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={9} style={{ height: "100%", width: isSm ? "100%" : "" }}>
              {currentRoomId ? (
                <Chatting type={type} currentRoom={currentRoom} currentRoomId={currentRoomId} />
              ) : (
                <Box display="flex" height="100%" alignItems="center" justifyContent="center">
                  <Typography>Select Chat to start</Typography>
                </Box>
              )}
            </Grid>
          </>
        ) : (
          <>
            {isSm && !chatResponsive && (
              <Grid item md={4} sm={12} style={{ width: isSm ? "100%" : "" }}>
                <ChatMenu
                  channels={rooms}
                  onChannelChange={(roomId) => {
                    setChatResponsive(true);
                    dispatch(setCurrentRoom({ type, roomId }));
                  }}
                  onScrollEnd={() => {
                    if (type === ChatType.property)
                      dispatch(
                        chatActionCreators.getPropertyRooms(user.companyId, user.id, role, rooms[rooms.length - 1])
                      );
                    if (type === ChatType.reply)
                      dispatch(
                        chatActionCreators.getReplyRooms(user.companyId, user.id, role, rooms[rooms.length - 1])
                      );
                    if (type === ChatType.company)
                      dispatch(
                        chatActionCreators.getCompanyRooms(user.companyId, user.id, role, rooms[rooms.length - 1])
                      );
                  }}
                  currentRoomId={currentRoomId}
                  roomsLoaded={isLoading}
                />
              </Grid>
            )}
            {isSm && chatResponsive && (
              <Grid item md={4} sm={12} style={{ width: isSm ? "100%" : "" }}>
                {currentRoomId ? (
                  <Chatting type={type} currentRoom={currentRoom} currentRoomId={currentRoomId} />
                ) : (
                  <Box display="flex" height="100%" alignItems="center" justifyContent="center">
                    <Typography>Select Chat to start</Typography>
                  </Box>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default memo(Chat, areEqual);

import { Box, useMediaQuery } from "@material-ui/core";

const FormWrapper = ({ children }) => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box p={isMd ? "" : "1em"} width={isMd ? "auto" : "50%"} minWidth={isMd ? "auto" : 600}>
      {children}
    </Box>
  );
};

export default FormWrapper;

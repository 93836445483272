import { useEffect, useState } from "react";
import { useGetPermissions } from "react-admin";

import { UserRoles } from "../enums";
import useCurrentUser from "./useCurrentUser";

/**
 * this hook won't work outside react admin 'Admin' component
 * will return 'undefined', so use it inside
 * */
const useIsAdmin = () => {
  const { user } = useCurrentUser();
  const [isAdmin, setIsAdmin] = useState(user.permissions && user.permissions.role === UserRoles.admin);
  const res = useGetPermissions();

  useEffect(() => {
    (async function () {
      const permissions = await res();
      const _isAdmin = permissions && permissions.role === UserRoles.admin;
      if (_isAdmin !== isAdmin) setIsAdmin(_isAdmin);
    })();
  }, []);

  return { isAdmin };
};

export default useIsAdmin;

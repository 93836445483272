import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Edit, FormGroupContextProvider, FormWithRedirect, minValue, number, NumberInput, required } from "react-admin";

import { FormGroupNames } from "../../enums";
import CustomToolbar from "../../components/CustomToolbar";
import useDisabledEvents from "../../hooks/useDisabledEvents";
import { usePackagesLocalizations } from "./utils";
import FormWrapper from "../../components/FormWrapper";
import useSharedStyles from "../../hooks/useSharedStyles";

const CustomForm = (props) => {
  const { styles } = useSharedStyles();
  const { disabledEvents } = useDisabledEvents();
  const localizedInputs = usePackagesLocalizations(props);

  return (
    <FormGroupContextProvider name={FormGroupNames.packages}>
      <FormWithRedirect
        {...props}
        style={disabledEvents}
        redirect="/packages"
        render={(formProps) => (
          <form>
            <FormWrapper>
              <Box>
                <Typography variant="h6">Quota</Typography>
                <Box className={styles.row}>
                  <Box className={styles.rowElement}>
                    <NumberInput variant="outlined" source="total" validate={[required(), number(), minValue(0)]} />
                  </Box>
                  <Box className={styles.rowElement}>
                    <NumberInput variant="outlined" source="published" validate={[required(), number(), minValue(0)]} />
                  </Box>
                  <Box className={styles.rowElement}>
                    <NumberInput variant="outlined" source="featured" validate={[required(), number(), minValue(0)]} />
                  </Box>
                  <Box className={styles.rowElement}>
                    <NumberInput variant="outlined" source="boosted" validate={[required(), number(), minValue(0)]} />
                  </Box>
                  <Box className={styles.rowElement}>
                    <NumberInput variant="outlined" source="days" validate={[required(), number(), minValue(0)]} />
                  </Box>
                </Box>
              </Box>

              <Box>
                <Typography variant="h6">Package Name</Typography>
                <Box className={styles.row}>{localizedInputs}</Box>
              </Box>

              <CustomToolbar
                {...formProps}
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                formName={FormGroupNames.packages}
              />
            </FormWrapper>
          </form>
        )}
      />
    </FormGroupContextProvider>
  );
};

const PackageEdit = (props) => {
  return (
    <Edit {...props}>
      <CustomForm variant="outlined" {...props} />
    </Edit>
  );
};

export default PackageEdit;

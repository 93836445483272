import { useNotify, useRefresh, useUnselectAll } from "react-admin";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";

import { firestore } from "../../../../init/firebase";
import { applyTimestamps } from "../../../../utils/funcs";
import useIsAdmin from "../../../../hooks/useIsAdmin";
import ConfirmationPopover from "../../../../components/ConfirmationPopover";

const UnpublishedPropertyButton = ({ selectedIds, label }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const { isAdmin } = useIsAdmin();

  const unpublishedProperty = async () => {
    try {
      const batch = firestore.batch();
      Array.isArray(selectedIds) &&
        selectedIds.forEach((id) => {
          batch.update(
            firestore.collection("properties").doc(id),
            applyTimestamps({
              data: {
                published: false,
              },
              isUpdate: true,
            })
          );
        });
      await batch.commit();
      notify("Properties have been unpublished", "success");
      unselectAll(isAdmin ? "companyProperties" : "properties");
      refresh();
    } catch (ex) {
      console.log(ex.message);
      notify("Error on unpublish properties", "error");
      refresh();
    }
  };

  return (
    <ConfirmationPopover
      onConfirm={unpublishedProperty}
      message="Are you sure you want to unpublish properties?"
      buttonText="Unpublish"
      buttonColor="default"
      buttonVariant="text"
      StartIcon={DomainDisabledIcon}
    />
  );
};

export default UnpublishedPropertyButton;

import { Box, ClickAwayListener, Grow, IconButton, Paper, Popper, useMediaQuery } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import DropDownMenuNotificationsList from "./DropDownMenuNotificationsList";
import theme from "../../../theme";

const MenuNotificationList = () => {
  const notificationCounter = useSelector((state) => state.notifications.notificationCounter);

  // console.log( "notificationCounter", notificationCounter );

  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current && !open) anchorRef.current?.focus();
    prevOpen.current = open;
  }, [open]);

  return (
    <Box position={isMd ? "static" : "relative"} mr="auto">
      <IconButton aria-controls="fade-menu" aria-haspopup="true" onClick={handleToggle}>
        <Box position="relative" height={24}>
          <NotificationsIcon style={{ color: "#fff" }} />

          {!!notificationCounter && (
            <div
              style={{
                backgroundColor: "#ff6363",
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: "50%",
                width: 14,
                height: 14,
                position: "absolute",
                top: -2,
                left: -4,
                color: "white",
                fontSize: 11,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {notificationCounter > 9 ? "+9" : notificationCounter}
            </div>
          )}
        </Box>
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ position: "absolute", top: 50, left: isMd ? 0 : -150 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <DropDownMenuNotificationsList handleClose={handleClose} />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default MenuNotificationList;

import clsx from "clsx";
import React from "react";
import { useNotify, useRefresh } from "react-admin";
import { ReactComponent as BoostedIcon } from "../../../../assets/rocket.svg";

import ConfirmationPopover from "../../../../components/ConfirmationPopover";
import CustomPopover from "../../../../components/CustomPopover";
import useIsSales from "../../../../hooks/useIsSales";
import { applyTimestamps } from "../../../../utils/funcs";
import { propertyDocRef } from "../../../../utils/refrences.utils";
import { useSharedActionsStyles } from "../../utils";

const BoostUnBoostPropertyIcon = ({ record }) => {
  const { styles } = useSharedActionsStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const { isSales } = useIsSales();

  const data = applyTimestamps({
    data: {
      boosted: !record.boosted,
    },
    isUpdate: true,
  });

  if (record.publishedAt) delete data.publishedAt;

  const publishProperty = async (propertyId) => {
    try {
      if (record.published || record.featured) throw new Error("Either property is published or featured");
      await propertyDocRef(propertyId).update(data);
      notify(`Property has been ${record.boosted ? "unboosted" : "boosted"}`, "success");
    } catch (ex) {
      console.log(ex.message);
      notify(ex.message, "error");
    } finally {
      refresh();
    }
  };

  const FeatureStatusIcon = ({ boosted, unboosted }) => (
    <CustomPopover message={record.boosted ? boosted : unboosted}>
      <BoostedIcon
        className={clsx({
          [styles.icon]: true,
          [styles.active]: record.boosted,
        })}
      />
    </CustomPopover>
  );

  return isSales ? (
    <FeatureStatusIcon boosted="Boosted" unboosted="Unboosted" />
  ) : (
    <ConfirmationPopover
      onConfirm={() => publishProperty(record.id)}
      message={`Are you sure you want to ${record.boosted ? "unboost" : "boost"} property?`}
      Icon={() => <FeatureStatusIcon boosted="Boosted, Click to unboost" unboosted="Unboosted, Click to boost" />}
    />
  );
};

export default BoostUnBoostPropertyIcon;

import { Box, Button, TextField, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNotify } from "react-admin";

import { ResourcesKeys } from "../../enums";
import firebaseApp, { firestore } from "../../init/firebase";
import { applyTimestamps } from "../../utils/funcs";
import useCurrentUser from "../../hooks/useCurrentUser";
import useIsCompany from "../../hooks/useIsCompany";
import useDisabledEvents from "../../hooks/useDisabledEvents";
import { propertiesXmlSubRef } from "../../utils/refrences.utils";

const PropertiesXML = () => {
  const notify = useNotify("");
  const { user } = useCurrentUser();
  const { isCompany } = useIsCompany();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [xmlLink, setXMLLink] = useState("");
  const [error, setError] = useState(true);
  const [uploadXMLLoading, setUploadXMLLoading] = useState();
  const [disableSyncing, setDisableSyncing] = useState(true);
  const { disabledEvents } = useDisabledEvents(uploadXMLLoading || disableSyncing);

  const updateXMLDocument = () => {
    setUploadXMLLoading(true);

    const sfDocRef = firestore
      .collection(ResourcesKeys.PropertiesCompanies)
      .doc(isCompany ? user.id : user.companyId)
      .collection(ResourcesKeys.propertiesXml)
      .doc(isCompany ? user.id : user.companyId);

    firestore
      .runTransaction((transaction) => {
        return transaction.get(sfDocRef).then((snapshot) => {
          if (snapshot.exists) {
            const isStatusPendingOrSyncing =
              snapshot.data().status === "pending" || snapshot.data().status === "syncing";
            if (isStatusPendingOrSyncing) {
              notify("There is already xml file syncing", "error");
              throw new Error("There is already xml file syncing");
            } else {
              transaction.update(
                sfDocRef,
                applyTimestamps({
                  data: {
                    url: xmlLink,
                    status: "pending",
                    total: 0,
                  },
                  isUpdate: true,
                })
              );
            }
          }
        });
      })
      .then(() => {
        setXMLLink("");
        notify("XML has been uploaded successfully", "success");
        setUploadXMLLoading(false);
      })
      .catch((ex) => {
        setXMLLink("");
        setUploadXMLLoading(false);
        console.log(ex.message);
      });

    // const snapshots = await propertiesXmlSubRef(user.id).where("status", "in", ["syncing", "pending"]).limit(1).get();
    // if (snapshots.empty) {
    //   firestore
    //     .collection(
    //       `/${ResourcesKeys.PropertiesCompanies}/${isCompany ? user.id : user.companyId}/${ResourcesKeys.propertiesXml}`
    //     )
    //     .doc(isCompany ? user.id : user.companyId)
    //     .update(
    //       applyTimestamps({
    //         data: {
    //           url: xmlLink,
    //           status: "pending",
    //           total: 0,
    //         },
    //         isUpdate: true,
    //       })
    //     )
    //     .then(() => {
    //       setXMLLink("");
    //       notify("XML has been uploaded successfully", "success");
    //       setUploadXMLLoading(false);
    //     })
    //     .catch((ex) => {
    //       console.log(ex.message);
    //       notify("Error on upload XML", "error");
    //       setUploadXMLLoading(false);
    //     });
    // } else {
    //   notify("There is already xml file syncing", "error");
    //   setUploadXMLLoading(false);
    // }
  };

  // Validate xml
  useEffect(() => {
    const linkRegex = /^(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gm;
    setError(!linkRegex.test(xmlLink));
  }, [xmlLink]);

  // listen to xml collection, check is there a file with syncing status
  useEffect(() => {
    let unsub;
    if (user) {
      (function () {
        unsub = propertiesXmlSubRef(user.id)
          .where("status", "in", ["syncing", "pending"])
          .limit(1)
          .onSnapshot((snapshots) => {
            if (snapshots.size) {
              setDisableSyncing(true);
            } else {
              setDisableSyncing(false);
            }
          });
      })();
    }

    return () => {
      if (unsub) unsub();
    };
  }, [user]);

  return (
    <>
      <Box
        display="flex"
        alignItems="baseline"
        maxWidth={isSm ? 290 : 550}
        ml={isSm ? 2 : 0}
        mb={2}
        style={disabledEvents}
      >
        <TextField
          value={xmlLink}
          variant="outlined"
          size="small"
          placeholder="XML link"
          style={{ width: "100%" }}
          onChange={(e) => setXMLLink(e.target.value)}
          type="url"
        />
        <Button variant="contained" color="primary" disabled={error} onClick={updateXMLDocument}>
          Sync
        </Button>
      </Box>
    </>
  );
};

export default PropertiesXML;

import curry from "lodash/fp/curry";
import dayjs from "dayjs";

import { setResourceNotifications, updateList } from "../redux/reducers/list";
import { landlordsQuery, propertiesReqQuery, reviewsQuery, seenByQuery } from "./resourceQueies.utils";
import messageAudioFile from "../assets/message.mp3";

export const syncPropertiesRequestsQuery = (date, limit = 1) => {
  return propertiesReqQuery({
    filters: { createdAt: date },
    operators: { createdAt: ">=" },
    sort: { field: "createdAt", order: "desc" },
    limit,
  });
};

export const syncCompanyReviewsQuery = (date, userId, limit = 1) => {
  return reviewsQuery(userId)({
    filters: { createdAt: date },
    operators: { createdAt: ">=" },
    sort: { field: "createdAt", order: "desc" },
    limit,
  });
};

export const syncTenantsQuery = (date, userId, limit = 1) => {
  return landlordsQuery({
    filters: {
      companyId: userId,
      createdAt: date,
    },
    operators: {
      createdAt: ">=",
    },
    sort: {
      field: "createdAt",
      order: "desc",
    },
    limit,
  });
};

export const listenToResourceChanges = curry((dispatchFn, resourceKey, query, args) => {
  return query(args, (snapshot) => {
    let count = 0;
    let requiresRefresh = false;
    snapshot.docChanges().forEach(function (change) {
      if (change.type === "added") {
        requiresRefresh = true;
        count += 1;
      }
      if (change.type === "removed") requiresRefresh = true;
    });
    dispatchFn(updateList({ resourceKey, count, requiresRefresh }));
  });
});

export const subscribeToNotifications = curry((dispatchFn, resourceKey, query, condition) => {
  const updateStore = (resourceKey, hasNotifications) => setResourceNotifications({ resourceKey, hasNotifications });

  return query.onSnapshot({
    next(snapshot) {
      if (condition(snapshot.docs)) {
        dispatchFn(updateStore(resourceKey, true));
        // add notificatio sound here...

        // const notificationSound = new Audio(messageAudioFile);

        // notificationSound.play().catch((ex) => {
        //   console.log(ex.message);
        // });
      } else dispatchFn(updateStore(resourceKey, false));
    },
    error(ex) {
      console.log(ex.message);
    },
  });
});

export const subscribeToSeenRequests = (dispatchFn, action, userId) => {
  return seenByQuery({
    filters: {
      companyId: userId,
    },
  }).onSnapshot({
    next(snapshot) {
      const seenRequestsIds = snapshot.docs.map((doc) => {
        return doc.id.slice(0, doc.id.lastIndexOf("_"));
      });
      dispatchFn(action(seenRequestsIds));
    },
    error(ex) {
      console.log(ex.message);
    },
  });
};

export const hasNotification = (docs, date) => {
  if (!docs[0]) return false;

  const doc = docs[0].data();
  return dayjs(doc.createdAt.toDate()).isAfter(date);
};

import { Box } from "@material-ui/core";

const Terms = () => {
  return (
    <Box p={5}>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
          textTransform: "lowercase",
        }}
      >
        <span style={{ fontSize: "16px" }}>PROPERTY FILTER TERMS AND CONDITIONS OF USE</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
          textTransform: "lowercase",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          IMPORTANT: BY DOWNLOADING PROPERTY FILTER YOU ARE AGREEING TO BE BOUND BY THE FOLLOWING TERMS:
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
          textTransform: "lowercase",
        }}
      >
        <span style={{ fontSize: "16px" }}>A.PROPERTY FILTER SOFTWARE LICENSE AGREEMENT</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
          textTransform: "lowercase",
        }}
      >
        <span style={{ fontSize: "16px" }}>B.PROPERTY FILTER PRE-AUTHORIZED DEBIT AND CREDIT SUPPLEMENTAL TERMS</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
          textTransform: "lowercase",
        }}
      >
        <span style={{ fontSize: "16px" }}>C.NOTICES FROM PROPERTY FILTER</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
          textTransform: "lowercase",
        }}
      >
        <span style={{ fontSize: "16px" }}>D.PROPERTY FILTER PRIVACY POLICY</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
          textTransform: "lowercase",
        }}
      >
        <span style={{ fontSize: "16px" }}>PROPERTY FILTER SOFTWARE LICENSE AGREEMENT Single Use License</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
          textTransform: "lowercase",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          PLEASE READ THIS SOFTWARE LICENSE AGREEMENT (“LICENSE”) CAREFULLY BEFORE USING PROPERTY FILTER OR DOWNLOADING
          THE SOFTWARE UPDATE ACCOMPANYING THIS LICENSE. BY USING PROPERTY FILTER OR DOWNLOADING A SOFTWARE UPDATE, AS
          APPLICABLE, YOU ARE AGREEING TO BE BOUND BY THE TERMS OF THIS LICENSE. IF YOU DO NOT AGREE TO THE TERMS OF
          THIS LICENSE, DO NOT USE PROPERTY FILTER OR DOWNLOAD THE SOFTWARE UPDATE.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>1. GENERAL.</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (a) This is part of the PROPERTY FILTER Application Program which is owned and operated by MORE CREATIVE. In
          order to use this Application (the "Application") and application program (the "Program"), you must first
          agree to the following terms and conditions (the "Terms and Conditions"). Please review the Terms and
          Conditions carefully.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (b) PROPERTY FILTER, at its discretion, may make available future Software Updates. The Software Updates, if
          any, may not necessarily include all existing software features or new features that PROPERTY FILTER releases
          for newer versions. The terms of this License will govern any Software Updates provided by PROPERTY FILTER
          that replace and/or supplement the original Software product unless such Software Update is accompanied by a
          separate license in which case the terms of that license will govern.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>2. PERMITTED LICENSE USES AND RESTRICTIONS.</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (a) Subject to the terms and conditions of this License, you are granted a limited non-exclusive license to
          use the Software on a single PROPERTY FILTER registered profile. You may not, and you agree not to or enable
          others to, copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, decrypt,
          modify, or create derivative works of the Software or any services provided by the Software or any part
          thereof.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (b) PROPERTY FILTER will retain all right, title, and interest in and to the Application and the Program
          worldwide including, without limitation, ownership of all copyrights, trademarks, patents, look and feel,
          trade secret rights, and any other intellectual property rights recognized under any applicable law. PROPERTY
          FILTER reserves the right to make such modifications to the Program from time to time as it deems advisable in
          order to enhance the functionalities or appearance of the Program at its discretion.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (c) You agree to use the App and the Services (as defined in Section 5 below) in compliance with all
          applicable laws, including local laws of the country or region in which you reside or in which you download or
          use the Software and Services. Features of the Software and the Services may not be available in all languages
          or regions, some features may vary by region, and some may be restricted or unavailable from your service
          provider.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (d) &nbsp;Use of the App requires a unique user name and password combination, known as a PROPERTY FILTER ID.
          A PROPERTY FILTER ID is also required to access app updates and certain features of the Software and Services.
          In addition, you acknowledge that many features, built-in apps, and Services of the Software transmit data and
          could impact charges to your data plan and that you are responsible for any such charges.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (e) If you choose to allow automatic app updates, your Device will periodically check with PROPERTY FILTER for
          updates to the App on your device and, if one is available, the update will automatically download and install
          onto your device. You can turn off the automatic app updates altogether at any time by going to Settings, and
          under Automatic Downloads, turn off Updates.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (f) If you have received access to the App and Software through a free trial demo or promotion, you agree that
          PROPERTY FILTER maintains the right to revoke, extend, or shorten your trial period at its sole discretion
          without the need to notify users prior to the alteration. PROPERTY FILTER reserves the right, in its absolute
          discretion, to determine your eligibility for a trial for some trials; we’ll require you to provide your
          payment details to start the Trial. AT THE END OF SUCH TRIALS, WE MAY AUTOMATICALLY START TO CHARGE YOU FOR
          THE APPLICABLE PAID SUBSCRIPTION ON THE FIRST DAY FOLLOWING THE END OF THE TRIAL, ON A RECURRING MONTHLY
          BASIS. BY PROVIDING YOUR PAYMENT DETAILS IN CONJUNCTION WITH THE TRIAL, YOU AGREE TO THIS CHARGE USING SUCH
          PAYMENT DETAILS. IF YOU DO NOT WANT THIS CHARGE, YOU MUST CANCEL THE APPLICABLE PAID SUBSCRIPTION THROUGH YOUR
          PROPERTY FILTER USER ID.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          3. &nbsp; &nbsp;TRANSFER. You may not rent, lease, lend, sell, redistribute, or sublicense the Software.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          4. &nbsp; &nbsp;CONSENT TO USE OF DATA When you use the App certain unique identifiers for your profile is
          sent to PROPERTY FILTER in order to facilitate the various communication features of the Software. PROPERTY
          FILTER may hold your information for a limited period of time. At all times your information will be treated
          in accordance with PROPERTY FILTER Privacy Policy, which can be viewed below.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>5. &nbsp; &nbsp;SERVICES AND THIRD-PARTY MATERIALS.</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (a) You agree to use the Services at your sole risk and that PROPERTY FILTER, its affiliates, agents,
          principals, or licensors shall have no liability to you. You agree to defend, indemnify, and hold harmless
          PROPERTY FILTER and its employees, contractors, officers, agents, and managers from all liabilities, claims,
          and expenses, including attorney's fees that arise from your use of the Program. PROPERTY FILTER reserves the
          right, at its own expense, to assume the exclusive defense and control of any matter for which you are
          obligated to indemnify PROPERTY FILTER. If PROPERTY FILTER chooses to assume the defense of any matter that is
          subject to indemnification by you, you will cooperate with PROPERTY FILTER, at its expense, in any respect
          reasonably requested by PROPERTY FILTER.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (b) Certain Services may display, include or make available content, data, information, applications, or
          materials from third parties (“Third Party Materials”) or provide links to certain third-party websites. By
          using the Services, you acknowledge and agree that PROPERTY FILTER is not responsible for examining or
          evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency,
          quality or any other aspect of such Third Party Materials or web sites. PROPERTY FILTER, its officers,
          affiliates, and subsidiaries do not warrant or endorse and do not assume and will not have any liability or
          responsibility to you or any other person for any third-party Services, Third Party Materials or web sites, or
          for any other materials, products, or services of third parties. Third-Party Materials and links to other
          websites are provided solely as a convenience to you.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (c) To the extent that you upload any content through the use of the Services, you represent that you own all
          rights in, or have authorization or are otherwise legally permitted to upload such content and that such
          content does not violate any terms of service applicable to the Services. You agree that the Services contain
          proprietary content, information, and material that are owned by PROPERTY FILTER, the site owner and/or their
          licensors, and is protected by applicable intellectual property and other laws, including but not limited to
          copyright. You agree that you will not use such proprietary content, information, or materials other than for
          permitted use of the Services or in any manner that is inconsistent with the terms of this License or that
          infringes any intellectual property rights of a third party or PROPERTY FILTER. No portion of the Services may
          be reproduced in any form or by any means. You agree not to modify, rent, lease, loan, sell, distribute, or
          create derivative works based on the Services, in any manner, and you shall not exploit the Services in any
          unauthorized way whatsoever, including but not limited to, using the Services to transmit any computer
          viruses, worms, trojan horses or other malware, or by trespass or burdening network capacity. You further
          agree not to use the Services in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or
          violate the rights of any other party, and that PROPERTY FILTER is not in any way responsible for any such use
          by you, nor for any harassing, threatening, defamatory, offensive, infringing or illegal messages or
          transmissions that you may receive as a result of using any of the Services.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>6. PROHIBITED CONTENT.</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (a) You agree that you will not upload, post, or otherwise distribute or publish through the Program any text,
          communications, data, or other information ("Content") (i) that is unlawful, threatening, abusive, degrading,
          libelous, defamatory, harassing, tortious, racially, ethnically or otherwise objectionable, (ii) that is
          sexually explicit or indecent (including, without limitation, graphic or indecent sexual language of a
          threatening or harassing nature directed at any individual or group); (iii) that constitutes or encourages
          conduct that would give rise to civil liability or violate law; (iv) that violates, plagiarizes or infringes
          the rights of third parties including, without limitation, copyright rights, trademarks, rights of privacy or
          publicity or any other proprietary right; (v) that contains a virus or other harmful component designed to
          interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications
          equipment, or that is designed to obtain unauthorized access to any information; (vi) for which you do not
          have all necessary rights and licenses to transmit under any law or under any contractual or fiduciary
          relationship; or (vii) that constitutes or contains false or misleading indications of origin or statements of
          fact. You also agree that you will not harvest or collect information about any users of the Program or use
          such information for the purpose of transmitting or facilitating transmission of the unsolicited bulk
          electronic e-mail or other communications.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (b) PROPERTY FILTER generally does not, and will not be obligated in any way to, pre-screen, monitor, or edit
          any Content posted by users of the Program. However, PROPERTY FILTER reserves the right to remove any Content
          that, in its sole discretion, does not comply with these Terms and Conditions or is otherwise harmful,
          objectionable, or inaccurate. However, PROPERTY FILTER will in no event be liable for any failure or delay in
          removing any such Content.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          7. TERMINATION This License is effective until terminated. Your rights under this License will terminate
          automatically or otherwise cease to be effective without notice from PROPERTY FILTER if you fail to comply
          with any term(s) of this License. Upon the termination of this License, you shall cease all use of the
          Software. Sections 4, 5, 6, 7, 8, 9, and 12 of this License shall survive any such termination.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>8. DISCLAIMER OF WARRANTIES.</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.1 YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, USE OF THE SOFTWARE
          AND ANY SERVICES PERFORMED BY OR ACCESSED THROUGH THE SOFTWARE IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK
          AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT IS WITH YOU.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.2 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SOFTWARE AND SERVICES ARE PROVIDED “AS IS” AND “AS
          AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND PROPERTY FILTER AND PROPERTY FILTER’S
          LICENSORS (COLLECTIVELY REFERRED TO AS “PROPERTY FILTER” FOR THE PURPOSES OF SECTIONS 7 AND 8) HEREBY DISCLAIM
          ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE SOFTWARE AND SERVICES, EITHER EXPRESS, IMPLIED OR STATUTORY,
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY
          QUALITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, QUIET ENJOYMENT, AND NON-INFRINGEMENT OF THIRD PARTY
          RIGHTS.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.3 PROPERTY FILTER DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE SOFTWARE AND SERVICES,
          THAT THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR PROVIDED BY, THE SOFTWARE WILL MEET YOUR
          REQUIREMENTS, THAT THE OPERATION OF THE SOFTWARE AND SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY
          SERVICE WILL CONTINUE TO BE MADE AVAILABLE, THAT DEFECTS IN THE SOFTWARE OR SERVICES WILL BE CORRECTED, OR
          THAT THE SOFTWARE WILL BE COMPATIBLE OR WORK WITH ANY THIRD-PARTY SOFTWARE, APPLICATIONS OR THIRD PARTY
          SERVICES. INSTALLATION OF THIS SOFTWARE MAY AFFECT THE AVAILABILITY AND USABILITY OF THIRD-PARTY SOFTWARE,
          APPLICATIONS, OR THIRD-PARTY SERVICES, AS WELL AS PROPERTY FILTER PRODUCTS AND SERVICES.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.4 YOU FURTHER ACKNOWLEDGE THAT THE SOFTWARE AND SERVICES ARE NOT INTENDED OR SUITABLE FOR USE IN SITUATIONS
          OR ENVIRONMENTS WHERE THE FAILURE OR TIME DELAYS OF, OR ERRORS OR INACCURACIES IN, THE CONTENT, DATA, OR
          INFORMATION PROVIDED BY THE SOFTWARE OR SERVICES COULD LEAD TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR
          ENVIRONMENTAL DAMAGE, INCLUDING WITHOUT LIMITATION THE OPERATION OF NUCLEAR FACILITIES, AIRCRAFT NAVIGATION OR
          COMMUNICATION SYSTEMS, AIR TRAFFIC CONTROL, LIFE SUPPORT OR WEAPONS SYSTEMS.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.5 NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY PROPERTY FILTER OR AN PROPERTY FILTER AUTHORIZED
          REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE SOFTWARE OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE
          COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
          IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND
          LIMITATIONS MAY NOT APPLY TO YOU.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          9. &nbsp; &nbsp;LIMITATION OF LIABILITY TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT SHALL
          PROPERTY FILTER, ITS AFFILIATES, AGENTS OR PRINCIPALS BE LIABLE FOR PERSONA INJURY, OR ANY INCIDENTAL,
          SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
          PROFITS, CORRUPTION OR LOSS OF DATA, FAILURE TO TRANSMIT OR RECEIVE ANY DATA (INCLUDING WITHOUT LIMITATION
          COURSE INSTRUCTIONS, ASSIGNMENTS AND MATERIALS), BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR
          LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE SOFTWARE AND SERVICES OR ANY THIRD-PARTY
          SOFTWARE OR APPLICATIONS IN CONJUNCTION WITH THE SOFTWARE OR SERVICES, HOWEVER, CAUSED, REGARDLESS OF THE
          THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF PROPERTY FILTER HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
          PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. In no
          event shall PROPERTY FILTER total liability to you for all damages (other than as may be required by
          applicable law in cases involving personal injury) exceed the amount of ( &nbsp; &nbsp;). The foregoing
          limitations will apply even if the above-stated remedy fails of its essential purpose.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          10. &nbsp;DIGITAL CERTIFICATES. The Software contains functionality that allows it to accept digital
          certificates either issued from PROPERTY FILTER or from third parties. YOU ARE SOLELY RESPONSIBLE FOR DECIDING
          WHETHER OR NOT TO RELY ON A CERTIFICATE WHETHER ISSUED BY PROPERTY FILTER OR A THIRD PARTY. YOUR USE OF
          DIGITAL CERTIFICATES IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, PROPERTY FILTER
          MAKES NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, AS TO MERCHANTABILITY OR FITNESS FOR ANY
          PARTICULAR PURPOSE, ACCURACY, SECURITY, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS WITH RESPECT TO DIGITAL
          CERTIFICATES.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          11. &nbsp;CONTROLLING LAW AND SEVERABILITY. This License will be governed by and construed in accordance with
          the laws of QATAR, excluding its conflict of law principles. If for any reason a court of competent
          jurisdiction finds any provision, or portion thereof, to be unenforceable, the remainder of this License shall
          continue in full force and effect.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          12. &nbsp;COMPLETE AGREEMENT; GOVERNING LANGUAGE. This License constitutes the entire agreement between you
          and PROPERTY FILTER relating to the Software and supersedes all prior or contemporaneous understandings
          regarding such subject matter. No amendment to or modification of this License will be binding unless in
          writing and signed by PROPERTY FILTER. Any translation of this License is done for local requirements and in
          the event of a dispute between the ARABIC and any non-ARABIC versions, the ARABIC version of this License
          shall govern, to the extent not prohibited by local law in your jurisdiction.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>PROPERTY FILTER PRE-AUTHORIZED DEBIT AND CREDIT SUPPLEMENTAL TERMS</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          This PROPERTY FILTER Pre-Authorized Debit Terms and Conditions (the “Supplemental Terms”) supplement the
          Software License Agreement (the “License”); both the terms of the License and these Supplemental Terms govern
          your use of the PROPERTY FILTER Pay feature. Capitalized terms used in these Supplemental Terms have the
          meanings set forth in the License.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>1- &nbsp; &nbsp;OVERVIEW AND USE RESTRICTIONS</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          PROPERTY FILTER Pay allows you to store virtual representations of credit and debit cards, and or banking
          information to enable wireless payments, which are supported by the PROPERTY FILTER Pay feature (“Supported
          Payments”) and use supported Partners to make contactless payments within the app. Features may vary by
          region, issuer, and merchant.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          In order to facilitate the contactless payment feature within the app you must provide your choice of valid
          payment method whether by card or bank account and provide PROPERTY FILTER a one-time acknowledgment and
          authorization in the following form:
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          I (we) hereby authorize PROPERTY FILTER and or its processing partners to initiate debit and/or credit entries
          to my (our) account, at the depository financial institution named, hereinafter called DEPOSITORY, and to
          debit and/or credit the same such account.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          I (we) include my (our) authorization to PROPERTY FILTER and their processing Partner to reverse or debit any
          credit entries made in error to my (our) account. I (we) acknowledge that the financial transactions to my
          (our) account must comply with the provision of Qatar law.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          PROPERTY FILTER Pay is intended for your personal and corporate use and you may only provide your own and or
          legally authorized information above in connection with your User ID. If you are provisioning a supported
          corporate card or accounts, or if you are a connected corporate user provisioning on behalf of your client you
          represent that you are doing so with the authorization of your client or colleague as may be applicable and
          you are authorized to bind your client/colleague these terms of use and all transactions effected by use of
          this feature.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          You agree not to use PROPERTY FILTER Pay for illegal or fraudulent purposes, or any other purposes which are
          prohibited by the License and these Supplemental Terms. You further agree to use PROPERTY FILTER Pay in
          accordance with applicable law and regulation. You agree not to interfere with or disrupt the PROPERTY FILTER
          Pay service (including accessing the service through any automated means), or any servers or networks
          connected to the service, or any policies, requirements, or regulations of networks connected to the Service
          (including any unauthorized access to, use or monitoring of data or traffic thereon).
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>2- &nbsp; &nbsp;PROPERTY FILTER RELATIONSHIP WITH YOU</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          You agree that PROPERTY FILTER is not a party to your cardholder, bank, or merchant agreements, nor is
          PROPERTY FILTER responsible for the content, accuracy, or unavailability of any payment cards, bank
          information, stored value cards, commerce activities, transactions, or purchases while using PROPERTY FILTER
          Pay functionality, nor is PROPERTY FILTER in any way involved in the issuance of credit or assessing
          eligibility for credit, or the accrual or redemption of rewards under a merchant’s rewards program. For all
          disputes or questions about payment cards, bank accounts, stored value cards, or associated commerce activity,
          please contact your issuer, bank, or the applicable merchant.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>3- &nbsp; &nbsp;PRIVACY</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          PROPERTY FILTER Pay requires some information from your device in order to offer the full experience. You can
          find more information on the data collected, used, or shared as part of your use of PROPERTY FILTER Pay by
          reading about PROPERTY FILTER Privacy Policy below.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>4- &nbsp; &nbsp;SECURITY; LOST OR DISABLED DEVICES.</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          PROPERTY FILTER Pay and Pre-Authorized Debit transactions stores virtual representations of your Supported
          Payment, and or bank account information as may be applicable and should be protected as you would protect
          your physical credit and debit cards. Providing your device passcode to a third party or allowing a third
          party to add their fingerprint to use Touch ID may result in their ability to make payments using PROPERTY
          FILTER Pay on your device. You are solely responsible for maintaining the security of your device and of your
          passcode. You agree that PROPERTY FILTER does not have any responsibility if you lose or share access to your
          device. You agree that PROPERTY FILTER does not have any responsibility if you make unauthorized modifications
          to (such as by way of a “jailbreak”).
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          If you report or PROPERTY FILTER suspects fraudulent or abusive activity, you agree to cooperate with PROPERTY
          FILTER in any investigation and to use any fraud prevention measures we prescribe.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>5- &nbsp; &nbsp;LIMITATION OF LIABILITY&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          IN ADDITION TO THE DISCLAIMERS OF WARRANTIES AND LIMITATION OF LIABILITY SET FORTH IN THE LICENSE, PROPERTY
          FILTER DOES NOT ASSUME ANY LIABILITY FOR PURCHASES, PAYMENTS, TRANSACTIONS, OR OTHER COMMERCE ACTIVITY MADE
          USING THE PROPERTY FILTER PAY FEATURE, AND YOU AGREE TO LOOK SOLELY TO AGREEMENTS YOU MAY HAVE WITH YOUR
          ISSUING BANK, PAYMENT NETWORK, OR MERCHANT TO RESOLVE ANY QUESTIONS OR DISPUTES RELATING TO YOUR SUPPORTED
          CARDS, VIRTUAL SUPPORTED CARDS AND ASSOCIATED COMMERCE ACTIVITY.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>NOTICES FROM PROPERTY FILTER</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          1. &nbsp; &nbsp;If PROPERTY FILTER needs to contact you about your product or account, you consent to receive
          the notices by email. You agree that any such notices that we send you electronically will satisfy any legal
          communication requirements.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          2. &nbsp; &nbsp;You understand and agree to PROPERTY FILTER authorizing targeted advertisements with credible
          third parties and in an effort to provide better service and enjoyment of the App and related functions. You
          understand and agree that PROPERTY FILTER may send you notices on the App and Software from time to time that
          is in line with business and product development at its sole discretion.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          If you have any questions regarding these Terms and Conditions or the Program, you may reach PROPERTY FILTER
          at ( &nbsp; &nbsp; ).
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>PROPERTY FILTER PRIVACY POLICY</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>PRIVACY POLICY&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          At PROPERTY FILTER (PROPERTY FILTER operating as “PROPERTY FILTER”) the protection of your personal
          information has always been a top priority. PROPERTY FILTER respects your privacy as a user. It is our policy
          that all information that you, as the Registered User, provide will be securely maintained and is for the
          single purpose of providing you the best browsing experience in our application. To better protect your
          privacy, we provide this notice explaining our online information practices and the choices you can make about
          the way your information is collected and used. This notice will be easy to find on the home page and any
          pages where we require information for use of the application.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>1. ACCOUNTABILITY&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          PROPERTY FILTER is responsible for personal information under its control.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          All PROPERTY FILTER employees and third-party contractors are responsible for day-to-day compliance.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Our Software and App may contain links to other websites. We are not responsible for the privacy practices of
          other websites. We encourage our users to be aware when they leave our application to read the privacy
          statements of each and every website that collects personally identifiable information. This Privacy Policy
          applies solely to information collected by MORE PROPERTY. Please review the TERMS AND CONDITIONS for more
          information.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>2. IDENTIFYING PURPOSES</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Unless the purpose is self-evident due to the nature of the transaction in question, PROPERTY FILTER will
          identify the purposes for which personal information is collected at or before the time the information is
          collected. The proposed purposes will be described in a reasonably understandable manner.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>3. CONSENT</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          We will collect, use or disclose your personal information only with your knowledge and consent, except where
          required or permitted by law.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          PROPERTY FILTER will not make your consent a requirement to the supply of a product or a service other than
          required to be able to supply the product or service.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Consent can be express or, in some circumstances, implied, and given in writing, by using or not using a
          check-off box, electronically, orally (in person or by telephone), or by your conduct, such as the use of a
          product or service.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          In determining the type of consent to obtain, PROPERTY FILTER will consider all relevant factors, including
          the sensitivity of the information and your reasonable expectations.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          You may withdraw your consent at any time, on reasonable notice, subject to legal or contractual restrictions.
          PROPERTY FILTER will inform you of the implications of doing so.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>4. LIMITING COLLECTION&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          The collection of personal information by PROPERTY FILTER will be limited to what is necessary for the
          purposes which it identifies. We will collect personal information by fair and lawful means.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>5. LIMITING USE, DISCLOSURE, AND RETENTION&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Personal information will not be used or disclosed for purposes other than those for which it was collected,
          except with your consent or as required or permitted by law. It will be retained only as long as necessary for
          these purposes or as required by law.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          We may disclose your personal information to: (i) parties in the context of a sale, financing or
          reorganization or potential sale, financing or reorganization of our business, provided that they agree to be
          bound by this Policy with respect to your personal information, (ii) parties to whom we have agreed to provide
          anonymized and/or aggregated data, though we will remove personal identifiers before we do so, and (iii)
          parties with whom we have contracted to process data, including parties who are located in countries or
          jurisdictions other than the one in which you reside (when this occurs, your personal information becomes
          subject to the laws of those jurisdictions).
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>6. ACCURACY&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          We will keep your personal information as accurate, complete, and up-to-date as necessary for the purposes for
          which it is to be used.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>7. SAFEGUARDS&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          PROPERTY FILTER will protect personal information by security safeguards appropriate to the sensitivity of the
          information, including through the use of the following measures: physical (e.g., locked filing cabinets,
          restricted access, appropriate disposal of personal information), organizational (e.g., security clearances,
          access only on a "need to know" basis), technological (e.g., passwords, encryption) and training of employees.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>8. OPENNESS</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Information about our policies and practices relating to the management of personal information will be made
          readily available to individuals.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>9. INDIVIDUAL ACCESS</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Upon request, PROPERTY FILTER will inform you of the existence, use, and disclosure of personal information
          relating to you, and give you access to that information. You have the right to challenge the accuracy and
          completeness of your information and have it amended as appropriate.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          However, in certain circumstances permitted by law, this information will not be disclosed to you. Some
          examples of these circumstances are information that contains references to other individuals that cannot be
          disclosed for legal, security or commercial proprietary reasons, or that is subject to solicitor-client or
          litigation privilege.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>10. CONTACTING US AND/OR CHALLENGING COMPLIANCE</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          For anything to do with this Policy, including questions or comments, or to challenge our compliance with this
          Policy, please contact us as follows: …………………………………..
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          PROPERTY FILTER will inform individuals who make inquiries or lodge complaints about its inquiry or complaint
          procedures, as applicable. If a complaint is found to be justified, we will take appropriate measures,
          including, if necessary, amending our policies and practices.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Of course, this Policy is subject to any other applicable privacy laws and PROPERTY FILTER right to change it
          at any time.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>THE INFORMATION WE COLLECT:</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          This notice applies to all information collected or submitted on the PROPERTY FILTER Application. We collect
          both personally identifiable information and anonymous information from Registered Users. The types of
          information collected include:
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>● &nbsp;Name</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>● &nbsp;Address</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>● &nbsp;Email Address</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>● &nbsp;Phone Number</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>● &nbsp;User Id</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>● &nbsp;Password</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>● &nbsp;Gender</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>● &nbsp;Birthday</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>● &nbsp;Photo</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          We may use cookies to provide Registered Users customized and personalized services. Cookies are small amounts
          of data that are sent to a Registered User's browser from a web server and stored on the Registered User's
          hard drive. This information does not specifically identify the user but identifies the user's computer. The
          cookies used, will not collect any personal information like name, address, or telephone number.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>THE WAY WE USE INFORMATION</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          We use the information of Registered Users for purposes of profiling and analysis. These elements allow us to
          provide better and improved products and services, and new products and services that may interest Registered
          Users.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Registered User personal information is neither sold nor rented to third parties without the express
          permission of the individual Registered User. We do retain the right to share aggregated demographic and/or
          statistical information with partners, advertisers, and sponsors.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          We use non-identifying and aggregate information to improve your experience on the website and to share with
          advertisers or other interested parties. For example, we may share with an advertiser that X number of persons
          visited a particular area of the application.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          We will not share your information with any other company or organization, except in non-identifying
          aggregated form.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>HOW YOU CAN ACCESS OR CORRECT YOUR INFORMATION?</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          You can access all your personally identifiable information that we collect online. To protect your privacy
          and security, we will also take reasonable steps to verify your identity before granting access or making
          corrections. You can correct factual errors in your personally identifiable information by sending us a
          request that credibly shows the error. For more information on the procedures email us at:&nbsp;
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>OUR COMMITMENT TO CHILDREN’S PRIVACY&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Protecting the privacy of the very young is especially important. For that reason, we never collect or
          maintain information at our application from those we actually know are under 13, and no part of our
          application is structured to attract anyone under 13.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>OUR COMMITMENT TO DATA SECURITY</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put
          in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information
          we collect online.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>LIMITATIONS ON OUR LIABILITY&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          Although we are committed to taking all reasonable steps to properly implement our Privacy Policy, our
          liability for breaches of security associated with any information transmitted through this site is limited.
          For a statement of these limitations, please refer to Paragraph 2 of our User Terms and Conditions. In
          addition, we are not responsible for the security of any information that you may choose to transmit through
          this application to any advertiser or to any linked site. Any information so transmitted is not covered by our
          Privacy Policy. Finally, we reserve the right to disclose any information submitted to this application to the
          extent we are required to do so by law or if we believe in good faith that disclosure of such information is
          advisable (i) to identify, contact, or bring legal action against any party violating, or threatening to
          violate, our User Terms and Conditions or damaging, or threatening to damage, the rights or property of any
          third party, or (ii) to protect the physical safety of any party.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          By clicking this box, you agree that you have read and accepted the above terms and conditions. You have the
          authority to grant PROPERTY FILTER and its affiliates the authorization to process payments from your account.
        </span>
      </p>

      <Box mt={2}>
        The "Property" application collect data after registering on our website and/or the "Property" application and
        we have the right to use it for the purposes of conducting market research studies and/or scientific research.
        Nevertheless, ensuring compliance with confidentiality and non-disclosure terms and conditions. The "Property"
        application use the information we collect from existing services to help us develop new ones. The "Property"
        application cannot control the actions of other users with whom you share your information. We cannot, and do
        not, control the information you share with other users using an Application, the Services or the Site
        (including via Forums) or how such other users will use or share such information. We are not responsible for
        third party circumvention of our security measures. The "Property" application use data for analytic reasons and
        statistics to better-understand how our services are used. For instance, we analyze data about your use of the
        Application to do things like improve our product design. Correspondence: The "Property" application uses the
        information we collect, such as your email address, to initiate a direct interactive communication with you.
        Managing, reviewing, and updating your information: When you're signed in, you can always review and update your
        information by visiting the services you use. Cancellation of membership & Unsubscribing: The management of
        "Property" application has the right to send notifications, advertisements and/or promotional campaigns on
        e-mail, SMS, mobile applications and/or social media notifications. Hence, the user may at his sole discretion
        stop the notifications and/or unsubscribe. Moreover, the application management also has the right to cancel
        your membership and/or subscription, in the event that you adopt any policy that may be interpreted as misuse,
        including, but not limited to: frequently repeating the deed of ordering and cancelling, and/or failure to pay
        upon receipt, and/or repeating ordering unwanted products. Cookies: "Property" application uses cookies as a
        defining file for the first party drivers (A cookie is a small file that can be placed on your device that
        allows us to recognize you, and enables you to access our website/application.) We may also use third party’s
        cookies Facebook Integration: Users can log in to our application using their Facebook accounts. We are
        collecting some data like (email, display name, and profile picture) from the user's Facebook account in order
        to complete the user profile. Users may request deletion of that data. Accordingly, you can request modification
        or deletion of your information by contacting us at info@property.com.
      </Box>
    </Box>
  );
};

export default Terms;

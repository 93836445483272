const FormGroupNames = {
  properties: "PropertiesEditForm",
  packages: "packagesEditForm",
  companies: "companiesEditForm",
  userGuide: "userGuideEditForm",
  employeesCreate: "employeesCreate",
  employeesEdit: "employeesEdit",
  announcementsCreate: "announcementsCreate",
};

export default FormGroupNames;

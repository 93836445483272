import { RequestKeys, ResourcesKeys } from "../../../enums";
import { getFinalList } from "../../../utils/funcs";
import { firestore } from "../../firebase";

const companyReviewsResource = {
  [ResourcesKeys.CompanyReviews]: {
    [RequestKeys.GET_LIST]: async (queryData) => {
      return getFinalList(
        firestore
          .collection(ResourcesKeys.PropertiesCompanies)
          .doc(queryData.user && queryData.user.companyId)
          .collection(ResourcesKeys.Reviews),
        {
          ...queryData,
          filters: {
            client: queryData.filter.clientId,
          },
          limit: queryData.pagination.perPage,
        }
      );
    },
    [RequestKeys.GET_ONE]: async (_, queryData) => {
      const companyId = queryData.id.split("_")[1];
      const order = await firestore
        .collection(ResourcesKeys.PropertiesCompanies)
        .doc(companyId)
        .collection(ResourcesKeys.Reviews)
        .doc(queryData.id)
        .get();

      const formattedOrder = {
        ...order.data(),
        id: order.id,
      };
      return {
        data: formattedOrder,
      };
    },
  },
};

export default companyReviewsResource;

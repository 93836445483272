import { memo } from "react";
import { Box, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { AppBar } from "react-admin";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { areEqual } from "../../utils/general";
import useIsCompany from "../../hooks/useIsCompany";
import DropdownNotificationList from "../../pages/NotificationsListInitialization/DropdownNotificationList";
import useIsCompanyAdmin from "../../hooks/useIsCompanyAdmin";
import useIsSales from "../../hooks/useIsSales";
import useIsSupervisor from "../../hooks/useIsSupervisor";
import MyUserMenu from "./UserMenu";
import RedirectBack from "../RedirectBack";

const useStyles = makeStyles({
  appBar: {
    display: "flex",
    padding: "8px 0",
  },
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 16,
    flex: 1,
  },
  logo: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    width: 150,
  },
});

const MyAppBar = (props) => {
  const classes = useStyles();
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { isCompany } = useIsCompany();
  const { isCompanyAdmin } = useIsCompanyAdmin();
  const { isSales } = useIsSales();
  const { isSupervisor } = useIsSupervisor();

  return (
    <AppBar {...props} className={classes.appBar} userMenu={<MyUserMenu />} color="primary">
      <RedirectBack />

      {!isMd && (
        <>
          <Typography color="inherit" className={classes.title} id="react-admin-title" />
          <Box className={classes.logo}>
            <Logo />
          </Box>
        </>
      )}
      {(isCompany || isCompanyAdmin || isSales || isSupervisor) && <DropdownNotificationList />}
    </AppBar>
  );
};

export default memo(MyAppBar, areEqual);

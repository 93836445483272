import { useEffect, useState } from "react";
import { useGetPermissions } from "react-admin";

import { UserRoles } from "../enums";
import useCurrentUser from "./useCurrentUser";

/**
 * this hook won't work outside react admin 'Admin' component
 * will return 'undefined', so use it inside
 * */
const useIsSupervisor = () => {
  const { user } = useCurrentUser();
  const [isSupervisor, setIsSupervisor] = useState(
    user.permissions && user.permissions.role === UserRoles.propertySupervisor
  );
  const res = useGetPermissions();

  useEffect(() => {
    (async function () {
      const permissions = await res();
      const _isSupervisor = permissions && permissions.role === UserRoles.propertySupervisor;
      if (_isSupervisor !== isSupervisor) setIsSupervisor(_isSupervisor);
    })();
  }, []);

  return { isSupervisor };
};

export default useIsSupervisor;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const listSlice = createSlice({
  name: "@@@List",
  initialState,
  reducers: {
    updateList(state, action) {
      const { requiresRefresh, resourceKey } = action.payload;
      if (requiresRefresh)
        return {
          ...state,
          [resourceKey]: {
            ...(state[resourceKey] || {}),
            requiresRefresh,
          },
        };
      return state;
    },
    resetResourceRefreshRequirement(state, action) {
      const { resourceKey } = action.payload;
      return {
        ...state,
        [resourceKey]: {
          ...(state[resourceKey] || {}),
          requiresRefresh: false,
        },
      };
    },
    setResourceNotifications(state, action) {
      const { hasNotifications, resourceKey } = action.payload;
      return {
        ...state,
        [resourceKey]: {
          ...(state[resourceKey] || {}),
          hasNotifications,
        },
      };
    },
  },
});

export const { updateList, resetResourceRefreshRequirement, setResourceNotifications } = listSlice.actions;
export default listSlice.reducer;

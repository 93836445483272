import { Field } from "react-final-form";
import { Box, IconButton, TextField, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import firebase from "firebase/compat/app";
import React, { useEffect, useRef, useState } from "react";
import {
  BooleanInput,
  Edit,
  email,
  FormGroupContextProvider,
  FormWithRedirect,
  minLength,
  PasswordInput,
  required,
  TextInput,
} from "react-admin";
import { useHistory } from "react-router-dom";
import _includes from "lodash/includes";

import CustomCard from "../../components/CustomCard";
import CustomToolbar from "../../components/CustomToolbar";
import FormWrapper from "../../components/FormWrapper";
import ImageUploadInput from "../../components/ImageUploadInput";
import PhonePrefixCode from "../../components/PhonePrefixCode";
import { FormGroupNames, ResourcesKeys } from "../../enums";
import useDisabledEvents from "../../hooks/useDisabledEvents";
import useSharedStyles from "../../hooks/useSharedStyles";
import { isExists, phoneNumber } from "../../utils/validators";
import { usePropertiesCompaniesLocalizations } from "./utils";
import CustomSelect from "../../components/CustomSelect";
import LocationPicker from "../../components/LocationPicker";
import appConfig from "../../init/config";
import { areEqual } from "../../utils/general";
import useGetEnumsAsChoices from "../../hooks/useGetEnumsAsChoices";

const CustomPropertiesCompaniesSimpleForm = (props) => {
  const badgesEnums = useGetEnumsAsChoices({ enumEntry: "companiesBadges" });

  const company = props.record;
  const { badgesRef, locationRef } = props;
  company.phoneNumber = company.phoneNumber.replace("+974", "");
  company.whatsappNumber = company?.whatsappNumber?.replace("+974", "");
  const [selectedBadges, setSelectedBadges] = useState(company.badges ?? []);
  const { styles } = useSharedStyles();

  const [enteredLocationUrl, setEnteredLocationUrl] = useState("");
  const [location, setLocation] = useState(null);
  const [locationLoading, setLocationLoading] = useState(false);
  const [badgesList, setBadgesList] = useState(badgesEnums);
  const { disabledEvents } = useDisabledEvents(locationLoading);

  const localizedInputs = usePropertiesCompaniesLocalizations(props);
  const history = useHistory();

  const handleLocationUrl = async (e) => {
    if (e.target.value.trim()) {
      try {
        setLocationLoading(true);
        const response = await fetch(`${appConfig.cloudFunctionsExternalUrl}/getLatLng?url=${e.target.value}`);
        const data = await response.json();
        setEnteredLocationUrl(data);
        setLocationLoading(false);
      } catch (ex) {
        console.log(ex.message);
        setLocationLoading(false);
      }
    }
  };

  const handleSelectBadges = (_selectedBadges) => {
    if (_includes(_selectedBadges, "hotel")) {
      setSelectedBadges(["hotel"]);
      badgesRef.current = ["hotel"];
    } else {
      setSelectedBadges(_selectedBadges);
      badgesRef.current = _selectedBadges;
    }
  };

  useEffect(() => {
    badgesRef.current = company.badges ?? [];
    locationRef.current = company.location;
    if (!company.badges || (company.badges && !_includes(company.badges, "hotel"))) {
      const restBadges = badgesEnums.filter((badge) => badge.id !== "hotel");
      setBadgesList(restBadges);
    }
  }, []);

  return (
    <FormWrapper>
      <FormGroupContextProvider name={FormGroupNames.companies}>
        <FormWithRedirect
          {...props}
          style={disabledEvents}
          redirect={`/${ResourcesKeys.PropertiesCompanies}`}
          render={(formProps) => {
            return (
              <form style={disabledEvents}>
                <CustomCard>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6">Current Package</Typography>
                    <IconButton
                      onClick={() => history.push(`/company/${props.record.id}/package/${props.record.packageId}`)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <TextField source="package.name.en" />
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Details</Typography>
                  <Box className={styles.row}>{[localizedInputs[0], localizedInputs[1]]}</Box>
                  <Box className={styles.row}>{[localizedInputs[2], localizedInputs[3]]}</Box>
                  <Box className={styles.row}>
                    <CustomSelect
                      disabled={_includes(company.badges, "hotel")}
                      multiple={true}
                      handleSelectItem={(_selectedBadges) => {
                        handleSelectBadges(_selectedBadges);
                      }}
                      selectedItem={selectedBadges}
                      labelTitle="Badges"
                      listItems={badgesList}
                    />
                  </Box>
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Contact Info</Typography>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        source="email"
                        validate={[
                          required(),
                          email(),
                          isExists("email", ResourcesKeys.PropertiesCompanies, props.record.email),
                        ]}
                      />
                    </Box>
                    <Box className={styles.rowElement}>
                      <PasswordInput variant="outlined" source="password" validate={[minLength(8)]} />
                    </Box>
                  </Box>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        source="phoneNumber"
                        type="number"
                        validate={[
                          required(),
                          phoneNumber(),
                          isExists("phoneNumber", ResourcesKeys.PropertiesCompanies),
                        ]}
                        InputProps={{
                          startAdornment: <PhonePrefixCode />,
                        }}
                      />
                    </Box>

                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        source="whatsappNumber"
                        type="number"
                        validate={[
                          required(),
                          phoneNumber("whatsapp"),
                          isExists("whatsappNumber", ResourcesKeys.PropertiesCompanies, company.whatsappNumber),
                        ]}
                        InputProps={{
                          startAdornment: <PhonePrefixCode />,
                        }}
                      />
                    </Box>
                  </Box>
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Location</Typography>
                  <Box className={styles.row} flexDirection="column">
                    <Box mr={2} mb={1}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={handleLocationUrl}
                        label="Location url"
                      />
                    </Box>
                    <Box className={styles.rowElement}>
                      <Field name="_geoPoint">
                        {(props) => {
                          return (
                            <LocationPicker
                              onLocationChange={(data) => {
                                if (!areEqual(data, location)) {
                                  setLocation(data);
                                  setEnteredLocationUrl(data);
                                  locationRef.current = new firebase.firestore.GeoPoint(data.lat, data.lng);
                                  props.input.onChange(data);
                                }
                              }}
                              defaultValue={""}
                              value={
                                enteredLocationUrl &&
                                (!isNaN(+enteredLocationUrl.lat) || !isNaN(+enteredLocationUrl.lng))
                                  ? { latitude: +enteredLocationUrl.lat, longitude: +enteredLocationUrl.lng }
                                  : company.location
                              }
                            />
                          );
                        }}
                      </Field>
                    </Box>
                  </Box>
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Media</Typography>

                  <ImageUploadInput source="logo" label="Logo" validate={[required()]} />
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Preference</Typography>
                  <BooleanInput source="disabled" defaultValue={false} />
                </CustomCard>

                <CustomToolbar
                  {...formProps}
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  formName={FormGroupNames.companies}
                />
              </form>
            );
          }}
        />
      </FormGroupContextProvider>
    </FormWrapper>
  );
};

export default function PropertiesCompaniesEdit(props) {
  const badgesRef = useRef([]);
  const locationRef = useRef();

  return (
    <Edit
      {...props}
      component="div"
      transform={(values) => {
        values.location = locationRef.current;
        values.phoneNumber = `+974${values.phoneNumber}`;
        values.whatsappNumber = `+974${values.whatsappNumber}`;
        values.badges = !badgesRef.current.length ? [] : badgesRef.current;
        delete values._geoPoint;

        delete values.packageId;
        return values;
      }}
    >
      <CustomPropertiesCompaniesSimpleForm
        variant="outlined"
        {...props}
        badgesRef={badgesRef}
        locationRef={locationRef}
      />
    </Edit>
  );
}

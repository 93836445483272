import { Box, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Datagrid, List } from "react-admin";

import TablePagination from "../../components/TablePagination";
import TimestampField from "../../components/TimestampField";
import useCurrentUser from "../../hooks/useCurrentUser";
import useDisabledEvents from "../../hooks/useDisabledEvents";
import useGetCompanyId from "../../hooks/useGetCompanyId";
import useIsAdmin from "../../hooks/useIsAdmin";
import useIsCompany from "../../hooks/useIsCompany";
import useIsCompanyAdmin from "../../hooks/useIsCompanyAdmin";
import useIsSales from "../../hooks/useIsSales";
import useIsSupervisor from "../../hooks/useIsSupervisor";
import PackageCalculations from "../packages/components/PackageCalculations";
import PropertiesXML from "../PropertiesXML/PropertiesXML";
import PropertiesBulkActions from "./components/actions";
import AdminPropertiesFilters from "./components/filters/AdminPropertiesFilters";
import CompanyPropertiesFilters from "./components/filters/CompanyPropertiesFilters";
import PropertyAddress from "./components/PropertyAddress";
import PropertyAssignees from "./components/PropertyAssignees";
import PropertyIdentifier from "./components/PropertyIdentifier";
import PropertyLayouts from "./components/PropertyLayouts";
import PropertyPrice from "./components/PropertyPrice";
import PropertyStatus from "./components/PropertyStatus";
import PropertyThumbnails from "./components/PropertyThumbnails";
import PropertyType from "./components/PropertyType";
import ViewStats from "./components/ViewStats";
import Loader from "../../components/Loader";
import { propertiesXmlSubRef } from "../../utils/refrences.utils";

const PropertiesList = (props) => {
  const { isAdmin } = useIsAdmin();
  const { isCompany } = useIsCompany();
  const { isCompanyAdmin } = useIsCompanyAdmin();
  const { isSupervisor } = useIsSupervisor();
  const { isSales } = useIsSales();
  const { companyId } = useGetCompanyId();
  const { user } = useCurrentUser();

  const [syncingMessage, setSyncingMessage] = useState("");
  const [hasXMLSyncing, setHasXMLSyncing] = useState(true);
  const [loading, setLoading] = useState(true);
  const { disabledEvents } = useDisabledEvents(hasXMLSyncing);

  let queryData;
  if (isCompany || isCompanyAdmin) {
    queryData = {
      filters: {
        companyId,
        deleted: false,
      },
    };
  }
  if (isSales || isSupervisor) {
    queryData = {
      filters: {
        assignees: user.id,
        deleted: false,
      },
      operators: {
        assignees: "array-contains",
      },
    };
  }

  useEffect(() => {
    let unsub;
    if (user) {
      (async function () {
        setLoading(true);
        unsub = propertiesXmlSubRef(user.id)
          .where("status", "in", ["syncing", "pending"])
          .limit(1)
          .onSnapshot((snapshots) => {
            if (snapshots.size) {
              const doc = snapshots.docs[0].data();
              setHasXMLSyncing(true);
              setSyncingMessage(`Syncing ${doc.total ?? 0} properties... `);
            } else {
              setHasXMLSyncing(false);
              setLoading(false);
              setSyncingMessage("");
            }

            setLoading(false);
          });
      })();
    }

    return () => {
      if (unsub) unsub();
    };
  }, [user]);

  return (
    <Loader loading={loading}>
      <PackageCalculations size="small" divider />
      {(isCompany || isCompanyAdmin) && (
        <>
          <PropertiesXML />
        </>
      )}
      {syncingMessage && hasXMLSyncing && (
        <Typography
          style={{
            textAlign: "center",
            fontSize: 32,
            color: "#b60125",
          }}
        >
          {syncingMessage}
        </Typography>
      )}

      <Box style={disabledEvents}>
        <List
          {...props}
          sort={{ field: "createdAt", order: "desc" }}
          pagination={<TablePagination />}
          filters={isAdmin ? <AdminPropertiesFilters /> : <CompanyPropertiesFilters />}
          bulkActionButtons={
            <PropertiesBulkActions
              isCompany={isCompany}
              isCompanyAdmin={isCompanyAdmin}
              isSales={isSales}
              isSupervisor={isSupervisor}
              isAdmin={isAdmin}
            />
          }
          filter={queryData}
          exporter={false}
        >
          <Datagrid rowClick={isAdmin ? "show" : "edit"}>
            <PropertyIdentifier label="Reference Id" />
            <PropertyType label="Type" sortable={false} />
            <PropertyThumbnails label="" sortable={false} />
            <PropertyAddress label="Address" sortable={false} />
            <PropertyLayouts label="Layouts" sortable={false} />
            <PropertyPrice label="Price" sortable={false} />
            <PropertyStatus label="Status" sortable={false} />

            {!isSales && !isAdmin && <PropertyAssignees label="Assignees" sortable={false} />}
            {(isCompany || isCompanyAdmin) && <ViewStats label="Statistics" />}
            <TimestampField source="createdAt" label="Created At" />
          </Datagrid>
        </List>
      </Box>
    </Loader>
  );
};

export default PropertiesList;

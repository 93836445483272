import { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { doc, GeoPoint, updateDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";

import useOneDocumentHandler from "../../../new/utils/firebase/useOneDocumentHandler";
import useCurrentUser from "../../../hooks/useCurrentUser";
import ResourceKeys from "../../../enums/resourceKeys";
import CustomCard from "../../../components/CustomCard";
import Loader from "../../../components/Loader";
import { useFormUpdate } from "../../../new/utils/firebase/firebaseForm";
import { firestore } from "../../../init/firebase";
import {
  CustomPhoneNumber,
  CustomTextField,
  FormSubmitBtn,
  MapLocation,
  MyImagesUploader,
} from "../../../new/components";
import { isExists } from "../../../utils/validators";
import { companyDocRef } from "../../../utils/refrences.utils";

const CompanyProfileNew = () => {
  const { user } = useCurrentUser();

  const {
    record: company,
    fetchDocument: fetchCompany,
    isFetching,
  } = useOneDocumentHandler(`${ResourceKeys.PropertiesCompanies}/${user.id}`, { addId: false, addMeta: false });

  const { fireOperationFn, submitError, submitLoading } = useFormUpdate(
    ResourceKeys.PropertiesCompanies,
    async (finalData) => {
      await updateDoc(doc(firestore, ResourceKeys.PropertiesCompanies, user.id), finalData);
    }
  );

  const profileForm = useForm({
    mode: "onBlur",
    criteriaMode: "firstError",
    values: company ?? {
      about: {
        en: "",
        ar: "",
      },
    },
  });
  const { control, handleSubmit, formState, setValue, getValues } = profileForm;

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  return (
    <Loader loading={isFetching}>
      {company ? (
        <form
          onSubmit={handleSubmit(async (data) => {
            if (data && !data.password) delete data.password;
            await fireOperationFn(data);
          })}
        >
          <Typography variant="h6">Profile</Typography>
          <CustomCard width="50%">
            <Box style={{ display: "flex", marginBottom: 16, marginLeft: -8, marginRight: -8 }}>
              <CustomTextField
                controllerProps={{
                  control,
                  name: "about.en",
                  rules: {
                    required: "Required",
                  },
                }}
                textFieldProps={{
                  label: "About (en)",
                  style: { margin: "0px 8px 0px 8px" },
                  multiple: true,
                  rows: 5,
                  disabled: submitLoading,
                }}
              />
              <CustomTextField
                controllerProps={{
                  control,
                  name: "about.ar",
                  rules: {
                    required: "Required",
                  },
                }}
                textFieldProps={{
                  label: "About (ar)",
                  style: { margin: "0px 8px 0px 8px" },
                  multiple: true,
                  rows: 5,
                  disabled: submitLoading,
                }}
              />
            </Box>

            <Box style={{ marginTop: 2, marginBottom: 16 }}>
              <MyImagesUploader
                controllerProps={{
                  name: "logo",
                  control,
                }}
                defaultValue={company.logo}
                disabled={submitLoading}
              />
            </Box>

            <Box style={{ marginBottom: 16 }}>
              <CustomTextField
                controllerProps={{
                  control,
                  name: "password",
                }}
                textFieldProps={{
                  label: "Password",
                  disabled: submitLoading,
                }}
              />
            </Box>

            <Box style={{ display: "flex", marginBottom: 16, marginLeft: -8, marginRight: -8 }}>
              <CustomPhoneNumber
                controllerProps={{
                  control,
                  name: "phoneNumber",
                  rules: {
                    required: "Required",
                  },
                }}
                textFieldProps={{
                  label: "Phone",
                  style: { margin: "0px 8px 0px 8px" },
                  disabled: submitLoading,
                }}
                form={profileForm}
              />
              <CustomPhoneNumber
                controllerProps={{
                  control,
                  name: "whatsappNumber",
                  rules: {
                    required: "Required",
                    // async validate(phone) {
                    //   await isExists("whatsappNumber", ResourceKeys.CompanyProperties, company.whatsappNumber)(phone);
                    // },
                  },
                }}
                textFieldProps={{
                  label: "Whatsapp",
                  style: { margin: "0px 8px 0px 8px" },
                  disabled: submitLoading,
                }}
                form={profileForm}
              />
            </Box>
          </CustomCard>

          <Typography variant="h6">Location</Typography>
          <CustomCard width="50%">
            <Box height={600}>
              <MapLocation
                enableUrl
                defaultLocation={{
                  lat: getValues("location")?.latitude,
                  lng: getValues("location")?.longitude,
                }}
                onLocationChange={(location) => {
                  const geoPoint = new GeoPoint(location.lat, location.lng);
                  setValue("location", geoPoint);
                }}
                disabled={submitLoading}
              />
            </Box>
          </CustomCard>

          <FormSubmitBtn title="Update" disabled={!formState.isValid} loading={submitLoading} />
        </form>
      ) : null}
    </Loader>
  );
};

export default CompanyProfileNew;

import { useEffect, useState } from "react";
import { useGetPermissions } from "react-admin";

import { UserRoles } from "../enums";
import useCurrentUser from "./useCurrentUser";
import { setTimeStampForCurrrentCompany } from "../pages/dashboards/company/utils";
import { useSelector } from "react-redux";
import { setReloadFlag } from "../redux/reducers/user";

/**
 * this hook won't work outside react admin 'Admin' component
 * will return 'undefined', so use it inside
 * */
const useIsCompany = () => {
  const { user } = useCurrentUser();
  const [isCompany, setIsCompany] = useState(user.permissions && user.permissions.role === UserRoles.propertyCompany);
  const res = useGetPermissions();

  useEffect(() => {
    (async function () {
      const permissions = await res();
      const _isCompany = permissions && permissions.role === UserRoles.propertyCompany;
      if (_isCompany !== isCompany) setIsCompany(_isCompany);
    })();
  }, []);

  return { isCompany };
};

export default useIsCompany;

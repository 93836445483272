import { BooleanInput, Filter, ReferenceInput, SelectInput, TextInput, NumberInput } from "react-admin";

import AreasFilter from "./AreasFilter";
// import LocationFilter from "./LocationFilter";

const CompanyPropertiesFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput label="City" source="cityId" reference="cities" variant="outlined">
        <SelectInput optionText="name.en" />
      </ReferenceInput>
      <AreasFilter label="Area" source="areaId" reference="areas" optionText="name.en" variant="outlined" />
      <TextInput label="Reference ID" source="identifier" variant="outlined" size="small" />
      <NumberInput label="Min Price" source="priceFrom" variant="outlined" size="small" />
      <NumberInput label="Max Price" source="priceTo" variant="outlined" size="small" />
      <BooleanInput label="Published" source="published" defaultValue={false} />
      <BooleanInput label="Featured" source="featured" defaultValue={false} />
      <BooleanInput label="Boosted" source="boosted" defaultValue={false} />
      {/* todo - move city and area filters to one location filter */}
      {/*<LocationFilter label="Location" source="location" />*/}
    </Filter>
  );
};

export default CompanyPropertiesFilters;

import { Box, Button, Chip, ListItem, ListItemAvatar, Menu, MenuItem } from "@material-ui/core";
import PlusOneIcon from "@material-ui/icons/PlusOne";
import { useEffect, useState } from "react";
import { useNotify, useRefresh, useUnselectAll } from "react-admin";
import { from, of } from "rxjs";
import { catchError, concatMap, filter, groupBy, map, mergeMap, reduce, switchMap, toArray } from "rxjs/operators";

import CustomSearch from "../../../../components/CustomSearch";
import Loader from "../../../../components/Loader";
import { ResourcesKeys, UserRoles } from "../../../../enums";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import useGetCompanyId from "../../../../hooks/useGetCompanyId";
import useIsCompany from "../../../../hooks/useIsCompany";
import useIsCompanyAdmin from "../../../../hooks/useIsCompanyAdmin";
import useIsSupervisor from "../../../../hooks/useIsSupervisor";
import { firestore } from "../../../../init/firebase";
import { applyTimestamps } from "../../../../utils/funcs";
import { employeesDocRef, employeesSubRef, propertyDocRef } from "../../../../utils/refrences.utils";

const AssignProperty = ({ selectedIds, variant = "text", color = "primary", ClickBtn = null }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const { user } = useCurrentUser();
  const { companyId } = useGetCompanyId();
  const { isSupervisor } = useIsSupervisor();
  const { isCompanyAdmin } = useIsCompanyAdmin();
  const { isCompany } = useIsCompany();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const getEmployees = (query, value) => {
    query =
      isCompany || isCompanyAdmin
        ? query.where("role", "in", [UserRoles.propertySupervisor, UserRoles.propertySales])
        : isSupervisor
        ? query.where("parentId", "==", user.id).where("role", "==", UserRoles.propertySales)
        : query;

    return from(query.limit(10).get()).pipe(
      switchMap((snapshot) => from(snapshot.docs)),
      map((empSnapshot) => {
        return {
          ...empSnapshot.data(),
          id: empSnapshot.id,
        };
      }),
      concatMap((emp) => {
        if (emp.parentId) {
          return from(employeesDocRef(companyId, emp.parentId).get()).pipe(
            map((snapshot) => {
              return {
                id: snapshot.id,
                ...snapshot.data(),
                employees: [emp],
              };
            }),
            // filter for downshift list
            filter((employee) => (value ? employee.displayName.startsWith(value) : employee)),
            catchError((ex) => {
              console.log(ex.message);
            })
          );
        } else {
          return of({ ...emp, employees: [] });
        }
      }),
      groupBy((emp) => emp.id),
      mergeMap((group$) =>
        group$.pipe(
          reduce((acc, cur) => ({ ...acc, ...{ ...cur, employees: [...acc.employees, ...cur.employees] } }), {
            employees: [],
          })
        )
      ),
      toArray(),
      catchError((ex) => {
        console.log(ex.message);
        return of([]);
      })
    );
  };

  const assignProperties = async (employee) => {
    handleClose();

    try {
      const batch = firestore.batch();
      selectedIds.forEach((id) => {
        const assignees = [employee.id];

        if (employee.parentId) assignees.push(employee.parentId);
        batch.update(
          propertyDocRef(id),
          applyTimestamps({
            data: {
              assignees,
            },
            isUpdate: true,
          })
        );
      });

      await batch.commit();

      notify("Properties assigned to employee", "success");
      unselectAll(ResourcesKeys.CompanyProperties);
      refresh();
    } catch (ex) {
      console.log(ex.message);
      notify("Error on assign properties, make sure to unpublish properties first", "error");
    }
  };

  useEffect(() => {
    setLoading(true);
    getEmployees(employeesSubRef(companyId)).subscribe({
      next(result) {
        setEmployees(result);
        setLoading(false);
      },
    });
  }, [user.id]);

  const renderMenuItems = (parent, getItemProps) => {
    return (
      <>
        <ListItem button style={{ paddingLeft: 8 }} onClick={() => assignProperties(parent)}>
          <Box display="flex" alignItems="center">
            {parent.displayName}
            <Chip
              style={{ cursor: "pointer" }}
              label={parent.role === UserRoles.propertySupervisor ? "Supervisor" : "Sales"}
              size="small"
            />
          </Box>
        </ListItem>
        {parent.employees.map((employee, index) => (
          <ListItem
            button
            {...getItemProps({
              key: employee.displayName,
              index,
              item: employee,
            })}
          >
            <Box display="flex" alignItems="center">
              {employee.displayName}
              <Chip
                style={{ cursor: "pointer" }}
                label={employee.role === UserRoles.propertySupervisor ? "Supervisor" : "Sales"}
                size="small"
              />
            </Box>
          </ListItem>
        ))}
      </>
    );
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Box onClick={handleClick} aria-controls="properties-employees-menu" aria-haspopup="true">
        {ClickBtn ? (
          <ClickBtn />
        ) : (
          <Button variant={variant} color={color} startIcon={<PlusOneIcon />}>
            Assign
          </Button>
        )}
      </Box>
      <Menu id="properties-employees-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <Loader loading={loading} size={24}>
          <CustomSearch
            query={employeesSubRef(companyId)}
            source="displayName"
            initial={employees}
            getResults={getEmployees}
            renderMenuItems={renderMenuItems}
            alwaysOpened
            onSelect={assignProperties}
          />
        </Loader>
      </Menu>
    </Box>
  );
};
export default AssignProperty;

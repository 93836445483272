import realEstate from "../../../assets/realEstate.svg";
import appConfig from "../../../init/config";

const PropertyThumbnails = ({ record: { images, id } }) => {
  function changePathIntoOptimized(img) {
    if (img && !img.src.includes("amazonaws")) {
      const baseUrl = "https://storage.googleapis.com";
      const objectBucket = appConfig.storageBucket;

      const indexOfLastSlash = img?.src.toLowerCase().lastIndexOf("images%2f");
      const imageName = img?.src.slice(indexOfLastSlash + 9);

      const completePath = `${baseUrl}/${objectBucket}/properties/${id}/images/optimized/${imageName}`;

      console.log("completePath 2", completePath);

      return completePath;
    }

    return img.src;
  }

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {images.length ? (
        <div
          style={{
            boxShadow: "1px 1px 5px",
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            objectFit: "cover",
            overflow: "hidden",
          }}
        >
          <img
            src={changePathIntoOptimized(images[0])}
            onError={(e) => {
              e.currentTarget.src = realEstate;
            }}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt={changePathIntoOptimized(images[0])}
          />
        </div>
      ) : (
        <div>No images</div>
      )}
    </div>
  );
};

export default PropertyThumbnails;

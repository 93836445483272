import React from "react";
import { Title } from "react-admin";

import Chat from "./index";
import { ChatType } from "../../enums";
import usePrepareChat from "../../hooks/useChatPrepared";

const CompanyChat = () => {
  usePrepareChat(ChatType.company);

  return (
    <>
      <Title title="Company Chat" />
      <Chat type={ChatType.company} />
    </>
  );
};

export default CompanyChat;

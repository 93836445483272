import { put, takeEvery } from "redux-saga/effects";

import { setRoomMessages } from "../../reducers/chat";
import chatActionCreators from "../../actions/chat";

function* addMessage(action) {
  const { roomId, message, type } = action.payload;

  yield put(
    setRoomMessages({
      roomId,
      messages: [message],
      type,
    })
  );
}

export function* watchAddMessage() {
  yield takeEvery(chatActionCreators.addMessage.toString(), addMessage);
}

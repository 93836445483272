import { Box, Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  typeStyle: {
    margin: 4,
    "& > span": {
      textTransform: "capitalize",
    },
  },
}));

const AnnouncementsType = ({ record }) => {
  const classes = useStyles();

  return (
    <Box display="flex">
      {record.types.map((type) => (
        <Chip className={classes.typeStyle} label={type} />
      ))}
    </Box>
  );
};

export default AnnouncementsType;

export function getAgentDevice() {
  const userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  return {
    isSafari: ios && safari,
    isIosWebView: ios && !safari,
    isAndroidWebView: !ios && userAgent.includes("wv"),
    isWeb: !ios && !userAgent.includes("wv"),
  };
}

const useCheckAgentType = () => {
  return getAgentDevice();
};

export default useCheckAgentType;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  seen: [],
};

const seenRequestsSlice = createSlice({
  name: "@@@SeenRequests",
  initialState,
  reducers: {
    updateSeenRequests(state, action) {
      state.seen = action.payload;
    },
  },
});

export const { updateSeenRequests } = seenRequestsSlice.actions;
export default seenRequestsSlice.reducer;

import { Typography, useMediaQuery } from "@material-ui/core";

const ErrorMessage = (props) => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Typography variant="body2" color="error">
      {props.error}
    </Typography>
  );
};

export default ErrorMessage;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const enumsSlice = createSlice({
  name: "@__enums",
  initialState,
  reducers: {
    setEnums(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setEnums } = enumsSlice.actions;
export default enumsSlice.reducer;

import { companyDocRef } from "../utils/refrences.utils";
import { roomsQuery } from "../utils/resourceQueies.utils";
import { UserRoles } from "../enums";

export const getChatRoomsQuery = (type, companyId, userId, role, sync = false) => {
  const isCompany = () => role === UserRoles.propertyCompany;
  const isCompanyAdmin = () => role === UserRoles.propertyAdmin;
  const isEmployee = () => role === UserRoles.propertySupervisor || role === UserRoles.propertySales;

  const f = sync
    ? {
        "lastMessage.seen": false,
        "lastMessage.to": companyDocRef(companyId),
      }
    : {};

  let filters = {
    type,
    ...f,
  };

  if (isCompany() || isCompanyAdmin()) {
    filters = {
      filters: {
        ...filters,
        company: companyDocRef(companyId),
      },
    };
  } else if (isEmployee()) {
    filters = {
      filters: {
        ...filters,
        assignees: userId,
      },
      operators: {
        assignees: "array-contains",
      },
    };
  }

  return roomsQuery({
    ...filters,
    sort: {
      field: "lastMessage.createdAt",
      order: "desc",
    },
  });
};

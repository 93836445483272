import { Box, Typography } from "@material-ui/core";

const NotificationImage = ({ color, char }) => {
  return (
    <Box
      style={{
        backgroundColor: color,
      }}
      width={40}
      height={40}
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h6" color="textSecondary">
        {char}
      </Typography>
    </Box>
  );
};

export default NotificationImage;

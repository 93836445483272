import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useCheckAgentType, { getAgentDevice } from "../../hooks/useCheckAgentType";
import { updateUserData } from "../../redux/reducers/user";
import { prepareUserToken } from "../../hooks/useGetUserTokensDoc";
import { applyTimestamps } from "../../utils/funcs";

export const useGetWebViewFcmToken = () => {
  const dispatch = useDispatch();
  const { isAndroidWebView, isIosWebView } = useCheckAgentType();

  useEffect(() => {
    if (isAndroidWebView || isIosWebView) {
      const searchParams = new URLSearchParams(window.location.search);
      dispatch(updateUserData({ webviewFcmToken: searchParams.get("fcmToken") }));
    }
  }, [isAndroidWebView, isIosWebView]);
};

export const resetWebViewUserToken = async (user) => {
  const { isAndroidWebView, isIosWebView } = getAgentDevice();
  if (isAndroidWebView || isIosWebView) {
    if (user && user.permissions && user.permissions.role !== "admin") {
      await prepareUserToken(user).set(
        applyTimestamps({
          data: {
            fcmToken: null,
          },
          isCreate: true,
        })
      );
    }
  }
};

import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

import { PackageSizeType } from "../packages.types";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[700],
  },
}));

const PackageTitle = ({ title, size }) => {
  const classes = useStyles({});
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Typography
      className={clsx([
        classes.title,
        {
          [classes.titleSmall]: size === "small",
          [classes.titleLarge]: size === "large",
        },
      ])}
      style={{
        fontSize: size === "small" ? (isSm ? 20 : 28) : isSm ? 22 : 32,
      }}
    >
      {title}
    </Typography>
  );
};

PackageTitle.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(PackageSizeType),
};

export default PackageTitle;

import { Box, Chip } from "@material-ui/core";
import useGetEnumsAsValue from "../../../hooks/useGetEnumsAsValue";

const CompanyBadges = ({ record }) => {
  const formData = useGetEnumsAsValue("companiesBadges");

  return formData && record.badges
    ? record.badges.map((badge) => (
        <Box m={0.5}>
          <Chip label={formData(badge)} />
        </Box>
      ))
    : "N/A";
};

export default CompanyBadges;

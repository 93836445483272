// import { Sort } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { useListContext } from "ra-core";
import { RefreshIconButton } from "ra-ui-materialui";

function ClearSortButton() {
  const { setSort } = useListContext();

  const handleClearSort = () => {
    // Clear the sort by setting the sort field and order to null or empty values
    // You can modify this logic based on your specific implementation
    // For example, if you're using query parameters to handle sorting, you may need to update the query params accordingly
    setSort(null);
  };

  return (
    <Button
      id="resetBtn"
      style={{
        backgroundColor: "#c4c4c4",
        color: "black",
        marginRight: "15px",
        padding: "0px 10px",
        textTransform: "unset",
        display: "none",
      }}
      onClick={handleClearSort}
    >
      <RefreshIconButton style={{ padding: "5px" }} />
      Reset Sort
    </Button>
  );
}

export default ClearSortButton;

import { Box, TextField, Typography } from "@material-ui/core";
import firebase from "firebase/compat/app";
import { isEmpty } from "lodash";
import _includes from "lodash/includes";
import _map from "lodash/map";
import { useRef } from "react";
import { useEffect } from "react";
import { memo, useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  FormGroupContextProvider,
  FormWithRedirect,
  maxValue,
  minValue,
  NumberInput,
  required,
  SelectInput,
} from "react-admin";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";

import CustomCard from "../../components/CustomCard";
import CustomSelect from "../../components/CustomSelect";
import CustomToolbar from "../../components/CustomToolbar";
import FormWrapper from "../../components/FormWrapper";
import ImageUploadInput from "../../components/ImageUploadInput";
import Loader from "../../components/Loader";
import { FormGroupNames, ResourcesKeys } from "../../enums";
import useDisabledEvents from "../../hooks/useDisabledEvents";
import useIsCompany from "../../hooks/useIsCompany";
import useIsCompanyAdmin from "../../hooks/useIsCompanyAdmin";
import useIsSales from "../../hooks/useIsSales";
import useIsSupervisor from "../../hooks/useIsSupervisor";
import useSharedStyles from "../../hooks/useSharedStyles";
import { areEqual } from "../../utils/general";
import { isNumber, validateImages } from "../../utils/validators";
import PackageCalculations from "../packages/components/PackageCalculations";
import AreasSelectInput from "./components/AreasSelectInput";
import CitiesSelectInput from "./components/CitiesSelectInput";
import LocationPicker from "../../components/LocationPicker";

import {
  fetchCompanyData,
  isBoosted,
  isFeatured,
  isPublished,
  useGetPropertyCity,
  useGetRequiredPropertiesEnums,
  usePropertyLocalization,
} from "./utils";
import appConfig from "../../init/config";
import useCurrentUser from "../../hooks/useCurrentUser";
import useGetEnumsAsChoices from "../../hooks/useGetEnumsAsChoices";
import { propertiesXmlSubRef } from "../../utils/refrences.utils";

function validatePropertyCreation(formValues, location, isPublished) {
  const errors = {};
  if (!formValues.areaId) errors.cityId = "Area must be specified";
  if (!location || !location.lat || !location.lng) errors.areaId = "Location must be specified";

  if (isPublished && formValues.published)
    errors.published = "Can't update published property, please unpublish it first";
  return errors;
}

const CustomForm = (props) => {
  const { user } = useCurrentUser();
  const { record, rentalFrequencyRef, purchasingOptionRef, typeRef, bedroomsRef, categoryRef } = props;

  const propertiesRentalFrequenciesEnums = useGetEnumsAsChoices({
    enumEntry: "propertiesRentalFrequencies",
    sortMe: (a, b) => (a[1].sortIndex > b[1].sortIndex ? 1 : -1),
  });
  const categoriesEnums = useGetEnumsAsChoices({ enumEntry: "propertiesCategories" });

  const { styles } = useSharedStyles();
  const settingProperties = useSelector((state) => state.settings.properties);
  const { formData, isFormDataLoading } = useGetRequiredPropertiesEnums();
  const [chosenCity, setChosenCity] = useState();
  const [chosenArea, setChosenArea] = useState();
  const { areas } = useGetPropertyCity(chosenCity);
  const chosenCityData =
    formData && Array.isArray(formData.cities) && formData.cities.find((city) => city.id === chosenCity);
  const chosenAreaData = Array.isArray(areas) && areas.find((area) => area.id === chosenArea);
  const [location, setLocation] = useState();
  const localizedInputs = usePropertyLocalization(props);
  const { isCompany, isCompanyAdmin, isSales } = props;
  const [selectedRentalFrequency, setSelectRentalFrequency] = useState("");
  const [selectedPurchasingOption, setSelectPurchasingOption] = useState("");
  const [enteredLocationUrl, setEnteredLocationUrl] = useState("");
  const [locationLoading, setLocationLoading] = useState(false);
  const [companyData, setCompanyData] = useState("");
  const [selectedType, setSelectType] = useState("");
  const [bedrooms, setBedRooms] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [hasXMLSyncing, setHasXMLSyncing] = useState(true);
  const { disabledEvents } = useDisabledEvents(locationLoading || hasXMLSyncing);

  const handleLocationUrl = async (e) => {
    if (e.target.value.trim()) {
      try {
        setLocationLoading(true);
        const response = await fetch(`${appConfig.cloudFunctionsExternalUrl}/getLatLng?url=${e.target.value}`);
        const data = await response.json();
        setEnteredLocationUrl(data);
        setLocationLoading(false);
      } catch (ex) {
        console.log(ex.message);
        setLocationLoading(false);
      }
    }
  };
  const canViewPackageDetails = () => isCompany || isCompanyAdmin;

  const handleChangeBedRooms = (e) => {
    const regex = /^[0-9]+$/;
    const value = +e.target.value;
    if (regex.test(value)) {
      setBedRooms(e.target.value);
      bedroomsRef.current = e.target.value;
    }
  };

  const handleSelectPurchasingOption = (_selectedPurchasingOption) => {
    setSelectPurchasingOption(_selectedPurchasingOption);
    purchasingOptionRef.current = _selectedPurchasingOption;
  };

  useEffect(() => {
    if (record.purchasingOption === "rent") {
      setSelectRentalFrequency(record.rentalFrequency);
      rentalFrequencyRef.current = record.rentalFrequency;
    }

    setSelectPurchasingOption(record.purchasingOption);
    purchasingOptionRef.current = record.purchasingOption;

    setSelectType(record.type);
    typeRef.current = record.type;

    setBedRooms(record.bathrooms);
    bedroomsRef.current = record.bathrooms;

    setSelectedCategory(record.category);
    categoryRef.current = record.category;
  }, []);

  useEffect(() => {
    if (companyData) {
      if (_includes(companyData.badges, "hotel")) {
        setCategoriesList([{ id: "hotel", name: "Hotel" }]);
        setSelectedCategory("hotel");
        categoryRef.current = "hotel";
      } else {
        setCategoriesList(categoriesEnums.filter((category) => category.id !== "hotel"));
      }
    }
  }, [companyData]);

  useEffect(() => {
    (async function () {
      if (user && user.id) {
        const data = await fetchCompanyData(user);
        const isHotelBadge = data.badges[0] === "hotel";
        if (isHotelBadge) {
          setSelectPurchasingOption("rent");
          purchasingOptionRef.current = "rent";
        }
        setCompanyData(data);
      }
    })();
  }, [user]);

  useEffect(() => {
    let unsub;
    if (user) {
      (async function () {
        unsub = propertiesXmlSubRef(user.id)
          .where("status", "in", ["syncing", "pending"])
          .limit(1)
          .onSnapshot((snapshots) => {
            if (snapshots.size) {
              setHasXMLSyncing(true);
            } else {
              setHasXMLSyncing(false);
            }
          });
      })();
    }

    return () => {
      if (unsub) unsub();
    };
  }, [user]);

  useEffect(() => {
    if (record.purchasingOption === "rent") {
      setSelectRentalFrequency(record.rentalFrequency);
      rentalFrequencyRef.current = record.rentalFrequency;
    }

    setSelectPurchasingOption(record.purchasingOption);
    purchasingOptionRef.current = record.purchasingOption;

    setSelectType(record.type);
    typeRef.current = record.type;

    setBedRooms(record.bedrooms);
    bedroomsRef.current = record.bedrooms;

    setSelectedCategory(record.category);
    categoryRef.current = record.category;
  }, []);

  useEffect(() => {
    if (companyData) {
      if (_includes(companyData.badges, "hotel")) {
        setCategoriesList([{ id: "hotel", name: "Hotel" }]);
        setSelectedCategory("hotel");
        categoryRef.current = "hotel";
      } else {
        setCategoriesList(categoriesEnums.filter((category) => category.id !== "hotel"));
      }
    }
  }, [companyData]);

  return (
    <Loader loading={isFormDataLoading || !companyData} height="25vh" size={30}>
      <FormGroupContextProvider name={FormGroupNames.properties}>
        <FormWithRedirect
          {...props}
          validate={(formValues) => validatePropertyCreation(formValues, location)}
          redirect={`/${ResourcesKeys.CompanyProperties}`}
          render={(formProps) => {
            return (
              <form style={disabledEvents}>
                <FormWrapper>
                  {canViewPackageDetails() && (
                    <CustomCard>
                      <PackageCalculations size="small" />
                    </CustomCard>
                  )}
                  <CustomCard>
                    <Typography variant="h6">Reference Id</Typography>

                    <Typography color="textSecondary">{record.identifier}</Typography>
                  </CustomCard>

                  <CustomCard>
                    <Typography variant="h6">Type</Typography>
                    <Box className={styles.row}>
                      <CustomSelect
                        disabled={_includes(companyData.badges, "hotel")}
                        handleSelectItem={(_selectedCategory) => {
                          setSelectedCategory(_selectedCategory);
                          categoryRef.current = _selectedCategory;
                        }}
                        selectedItem={selectedCategory}
                        labelTitle="Category"
                        listItems={categoriesList}
                      />
                      <CustomSelect
                        handleSelectItem={(_selectedType) => {
                          setSelectType(_selectedType);
                          typeRef.current = _selectedType;
                          if (selectedType) {
                            if (selectedType === "singleRoom") {
                              setBedRooms(1);
                              bedroomsRef.current = 1;
                            } else if (selectedType === "doubleRoom") {
                              setBedRooms(2);
                              bedroomsRef.current = 2;
                            } else if (selectedType === "tripleRoom") {
                              setBedRooms(3);
                              bedroomsRef.current = 3;
                            } else if (selectedType === "meetingRoom") {
                              setBedRooms(0);
                              bedroomsRef.current = 0;
                            } else {
                              setBedRooms("");
                              bedroomsRef.current = "";
                            }
                          }
                        }}
                        required={true}
                        labelTitle="Type"
                        selectedItem={selectedType}
                        disabled={!selectedCategory}
                        listItems={
                          formData.propertiesTypes &&
                          _map(formData.propertiesTypes, (value, key) => ({
                            id: key,
                            ...value,
                          }))
                            .filter((type) => _includes(type.categories, selectedCategory))
                            .map((type) => ({
                              id: type.id,
                              name: type.label.en,
                            }))
                        }
                      />
                    </Box>
                    <Box className={styles.row}>
                      <CustomSelect
                        handleSelectItem={(_selectedPurchasingOption) => {
                          handleSelectPurchasingOption(_selectedPurchasingOption);
                        }}
                        required={true}
                        labelTitle="Purchasing Option"
                        selectedItem={companyData.badges[0] === "hotel" ? "rent" : selectedPurchasingOption}
                        disabled={companyData.badges[0] === "hotel"}
                        listItems={
                          isEmpty(formData.propertiesPurchasingOptions)
                            ? []
                            : _map(formData.propertiesPurchasingOptions, (value, key) => {
                                return {
                                  id: key,
                                  name: value.label ? value.label.en : "",
                                };
                              })
                        }
                      />
                      <CustomSelect
                        disabled={selectedPurchasingOption !== "rent"}
                        handleSelectItem={(_selectedRentalFrequency) => {
                          setSelectRentalFrequency(selectedPurchasingOption === "rent" ? _selectedRentalFrequency : "");
                          rentalFrequencyRef.current =
                            selectedPurchasingOption === "rent" ? _selectedRentalFrequency : "";
                        }}
                        selectedItem={selectedPurchasingOption === "rent" ? selectedRentalFrequency : ""}
                        required={selectedPurchasingOption === "rent"}
                        labelTitle="Rental Frequency"
                        listItems={propertiesRentalFrequenciesEnums}
                      />
                    </Box>
                  </CustomCard>

                  <CustomCard>
                    <Typography variant="h6">Specifications</Typography>
                    <Box className={styles.row}>
                      <Box className={styles.rowElement}>
                        <NumberInput
                          variant="outlined"
                          source="price"
                          validate={[required(), minValue(0), isNumber("price")]}
                        />
                      </Box>
                      <Box className={styles.rowElement}>
                        <NumberInput
                          variant="outlined"
                          source="space"
                          validate={[
                            required(),
                            minValue(`${settingProperties.minSpace}`),
                            maxValue(`${settingProperties.maxSpace}`),
                          ]}
                        />
                      </Box>
                    </Box>
                    <Box className={styles.row}>
                      <Box className={styles.rowElement}>
                        <NumberInput
                          variant="outlined"
                          source="parking"
                          validate={[required(), minValue(0), maxValue(20)]}
                        />
                      </Box>
                      <Box className={styles.rowElement}>
                        <NumberInput
                          variant="outlined"
                          source="bathrooms"
                          validate={[required(), minValue(0), maxValue(20)]}
                        />
                      </Box>
                    </Box>
                    <Box className={styles.row}>
                      <Box className={styles.rowElement}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Bedrooms"
                          value={
                            selectedType
                              ? selectedType === "singleRoom"
                                ? 1
                                : selectedType === "doubleRoom"
                                ? 2
                                : selectedType === "tripleRoom"
                                ? 3
                                : selectedType === "meetingRoom"
                                ? 0
                                : bedrooms
                              : bedrooms
                          }
                          disabled={
                            !selectedType ||
                            ["singleRoom", "doubleRoom", "tripleRoom", "meetingRoom"].includes(selectedType)
                          }
                          onChange={handleChangeBedRooms}
                        />
                      </Box>
                      <Box className={styles.rowElement}>
                        <SelectInput
                          variant="outlined"
                          source="furnishing"
                          validate={[required()]}
                          defaultValue={companyData.badges[0] === "hotel" ? "furnished" : ""}
                          disabled={companyData.badges[0] === "hotel"}
                          choices={
                            formData.propertiesFurnishings &&
                            _map(formData.propertiesFurnishings, (value, key) => {
                              return {
                                id: key,
                                name: value.label ? value.label.en : "",
                              };
                            })
                          }
                        />
                      </Box>
                    </Box>
                    <BooleanInput variant="outlined" source="petsAllowed" />
                  </CustomCard>

                  <CustomCard>
                    <Typography variant="h6">Amenities</Typography>
                    <AutocompleteArrayInput
                      variant="outlined"
                      source="amenities"
                      choices={_map(formData.amenities, (value, key) => {
                        return {
                          id: key,
                          name: value.label ? value.label.en : "",
                        };
                      })}
                      validate={[required()]}
                    />
                  </CustomCard>

                  <CustomCard>
                    <Typography variant="h6">Description</Typography>
                    <Box className={styles.row}>{[localizedInputs[0], localizedInputs[1]]}</Box>
                  </CustomCard>

                  <CustomCard>
                    <Typography variant="h6">Location</Typography>
                    <Box className={styles.row}>
                      <Box className={styles.rowElement}>
                        <CitiesSelectInput
                          label="City"
                          cities={formData.cities}
                          onChange={(cityId) => setChosenCity(cityId)}
                          source="cityId"
                          validate={[required()]}
                        />
                      </Box>
                      <Box className={styles.rowElement}>
                        <FormDataConsumer>
                          {({ formData: values }) => (
                            <AreasSelectInput
                              resource={ResourcesKeys.CompanyProperties}
                              source="areaId"
                              label="Area"
                              disabled={!values.cityId || !Array.isArray(areas)}
                              validate={[required()]}
                              onChange={(areaId) => setChosenArea(areaId)}
                              areas={areas}
                            />
                          )}
                        </FormDataConsumer>
                      </Box>
                    </Box>
                    <Box className={styles.row} flexDirection="column">
                      <Box mr={2} mb={1}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          onChange={handleLocationUrl}
                          label="Location url"
                        />
                      </Box>
                      <Box className={styles.rowElement}>
                        <Field name="_geoPoint">
                          {(props) => {
                            return (
                              <LocationPicker
                                onLocationChange={(data) => {
                                  if (!areEqual(data, location)) {
                                    setLocation(data);
                                    setEnteredLocationUrl(data);
                                    props.input.onChange(data);
                                  }
                                }}
                                defaultValue={
                                  chosenCityData && chosenAreaData
                                    ? `${chosenCityData.name.en}, ${chosenAreaData.name.en}`
                                    : ""
                                }
                                value={
                                  enteredLocationUrl &&
                                  (!isNaN(+enteredLocationUrl.lat) || !isNaN(+enteredLocationUrl.lng))
                                    ? { latitude: +enteredLocationUrl.lat, longitude: +enteredLocationUrl.lng }
                                    : record.geoPoint
                                }
                              />
                            );
                          }}
                        </Field>
                      </Box>
                    </Box>
                    <Box>
                      <Box className={styles.row}>{[localizedInputs[2], localizedInputs[3]]}</Box>
                    </Box>
                  </CustomCard>

                  <CustomCard>
                    <Typography variant="h6">Media</Typography>
                    <ImageUploadInput
                      source="images"
                      validate={[required(), validateImages()]}
                      label="Images"
                      multiple
                    />
                  </CustomCard>

                  <CustomCard>
                    <Typography variant="h6">Preferences</Typography>
                    <Box className={styles.row}>
                      {!isSales && (
                        <Box className={styles.rowElement}>
                          <BooleanInput source="published" validate={[isPublished(record.published)]} />
                        </Box>
                      )}
                      <Box className={styles.rowElement}>
                        <BooleanInput source="featured" validate={[isBoosted()]} />
                      </Box>
                      <Box className={styles.rowElement}>
                        <BooleanInput source="boosted" validate={[isFeatured()]} />
                      </Box>
                    </Box>
                  </CustomCard>
                </FormWrapper>

                <CustomToolbar
                  {...formProps}
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  formName={FormGroupNames.properties}
                  errors={{
                    publishedForSales: record.published && isSales ? "Property is published, you can't update it" : "",
                    rentalFrequency:
                      selectedPurchasingOption === "rent" && !selectedRentalFrequency
                        ? "Rental Frequency required"
                        : "",
                    bedrooms: bedrooms || bedrooms === 0 ? "" : "Bed rooms is required",
                    // price: record.price > 0 ? "":"Price is required"
                  }}
                />
              </form>
            );
          }}
        />
      </FormGroupContextProvider>
    </Loader>
  );
};

const EditProperty = (props) => {
  const user = useSelector((state) => state.user);
  const { isCompany } = useIsCompany();
  const { isCompanyAdmin } = useIsCompanyAdmin();
  const { isSupervisor } = useIsSupervisor();
  const { isSales } = useIsSales();
  const rentalFrequencyRef = useRef();
  const purchasingOptionRef = useRef();
  const typeRef = useRef();
  const bedroomsRef = useRef();
  const categoryRef = useRef();

  return (
    <Edit
      {...props}
      transform={(values) => {
        values.purchasingOption = purchasingOptionRef.current;
        const point = values._geoPoint;
        if (point) values.geoPoint = new firebase.firestore.GeoPoint(point.lat, point.lng);
        values.companyId = user.companyId;
        if (values.published && !values.publishedAt)
          values.publishedAt = firebase.firestore.FieldValue.serverTimestamp();
        else delete values.publishedAt;
        if (purchasingOptionRef.current === "rent") values.rentalFrequency = rentalFrequencyRef.current;
        else delete values.rentalFrequency;
        delete values._geoPoint;
        values.type = typeRef.current;
        values.bedrooms = +bedroomsRef.current;
        values.category = categoryRef.current;
        return values;
      }}
      component="div"
    >
      <CustomForm
        variant="outlined"
        rentalFrequencyRef={rentalFrequencyRef}
        purchasingOptionRef={purchasingOptionRef}
        typeRef={typeRef}
        bedroomsRef={bedroomsRef}
        isCompany={isCompany}
        isCompanyAdmin={isCompanyAdmin}
        categoryRef={categoryRef}
        isSales={isSales}
        isSupervisor={isSupervisor}
        {...props}
      />
    </Edit>
  );
};

export default memo(EditProperty, areEqual);

import { ResourcesKeys } from "../../../enums";
import { injectMetadataToSnapshotData } from "../../../utils/firebase.utils";
import { notificationData } from "./data";
import { NotificationType } from "./types";

export const getNotificationData = (notification) => {
  return notificationData()[notification.type][notification.event];
};

export const generateProperPath = (Id, type) => {
  if (type === NotificationType.REQUEST) return `${ResourcesKeys.PropertiesRequests}/${Id}/show`;
  else if (type === NotificationType.REQUESTAUTOREPLY) return `${ResourcesKeys.PropertiesRequests}/${Id}/show`;
  else if (type === NotificationType.REVIEW) return `${ResourcesKeys.CompanyReviews}`;
  else if (type === NotificationType.LANDLORD) return `${ResourcesKeys.Tenants}`;
  return `/home`;
};

export const prepareNotificationsList = async (notificationSnapshots) => {
  const modifiedNotifications = [];
  for (const notificationSnapshot of notificationSnapshots) {
    const notification = injectMetadataToSnapshotData(notificationSnapshot);
    const documentRef = notification.ref;

    try {
      const requestSnapshot = await documentRef.get();
      if (requestSnapshot.exists) {
        modifiedNotifications.push({
          ...notification,
          meta: {
            request: injectMetadataToSnapshotData(requestSnapshot),
            collectionName: documentRef.parent.path,
          },
        });
      }
    } catch (ex) {
      console.log(ex.message);
    }
  }
  return modifiedNotifications;
};

export const redirectOnClick = (notification, redirect) => {
  return redirect(generateProperPath(notification.meta.request.documentRef.id, notification.type));
};

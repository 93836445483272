import { Box } from "@material-ui/core";

import BoostUnBoostPropertyIcon from "./actions/BoostUnBoostPropertyIcon";
import FeatureUnFeaturePropertyIcon from "./actions/FeatureUnFeaturePropertyIcon";
import PublishUnPublishProperty from "./actions/PublishUnPublishPropertyIcon";

const PropertyStatus = ({ record }) => {
  return (
    <Box display="flex" alignItems="center">
      <PublishUnPublishProperty record={record} />
      <FeatureUnFeaturePropertyIcon record={record} />
      <BoostUnBoostPropertyIcon record={record} />
    </Box>
  );
};

export default PropertyStatus;

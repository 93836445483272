import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  text: {
    paddingTop: 5,
  },
});

const ErrorMessage = (props) => {
  const { text } = props;
  const classes = useStyles(props);

  return (
    <Typography variant="body2" color="error" className={classes.text}>
      {text}
    </Typography>
  );
};

export default ErrorMessage;

import { IconButton } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase/compat/app";
import React from "react";
import { useNotify, useRefresh, useUnselectAll } from "react-admin";

import ConfirmationPopover from "../../../../components/ConfirmationPopover";
import CustomPopover from "../../../../components/CustomPopover";
import { ResourcesKeys } from "../../../../enums";
import useIsCompany from "../../../../hooks/useIsCompany";
import useIsSales from "../../../../hooks/useIsSales";
import { applyTimestamps } from "../../../../utils/funcs";
import { propertyDocRef } from "../../../../utils/refrences.utils";
import { useSharedActionsStyles } from "../../utils";

const PublishUnPublishPropertyIcon = ({ record }) => {
  const { styles } = useSharedActionsStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const isDisabled =
    !record.amenities.length ||
    !(record.bathrooms >= 0) ||
    !(record.bedrooms >= 0) ||
    !record.category ||
    !record.type ||
    !(record.price >= 0) ||
    !(record.space >= 0) ||
    !(record.parking >= 0) ||
    !record.images.length ||
    !record.description.ar ||
    !record.description.en ||
    !record.furnishing ||
    record.images.length > 8 ||
    (record.purchasingOption && record.purchasingOption === "rent" && !record.rentalFrequency);

  const { isCompany } = useIsCompany();
  const { isSales } = useIsSales();

  const data = applyTimestamps({
    data: {
      published: !record.published,
      publishedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    isUpdate: true,
  });

  if (record.publishedAt) delete data.publishedAt;

  const publishProperty = async (propertyId) => {
    try {
      await propertyDocRef(propertyId).update(data);
      notify(`Property has been ${record.published ? "unpublished" : "published"}`, "success");
      unselectAll(isCompany ? ResourcesKeys.CompanyProperties : ResourcesKeys.Properties);
    } catch (ex) {
      console.log(ex.message);
      notify(ex.message, "error");
    } finally {
      refresh();
    }
  };

  const PublishStatusIcon = ({ publishMessage, unpublishMessage }) => (
    <CustomPopover message={record.published ? publishMessage : unpublishMessage}>
      {record.published ? <CheckIcon className={styles.active} /> : <CloseIcon className={styles.inActive} />}
    </CustomPopover>
  );

  return !isDisabled ? (
    isSales ? (
      <PublishStatusIcon publishMessage="Publish" unpublishMessage="Unpublished" />
    ) : (
      <ConfirmationPopover
        onConfirm={() => publishProperty(record.id)}
        message={`Are you sure you want to ${record.published ? "unpublish" : "publish"} property?`}
        Icon={() => (
          <PublishStatusIcon
            publishMessage="Published, Click to unpublish"
            unpublishMessage="Unpublished, Click to publish"
          />
        )}
      />
    )
  ) : null;
};

export default PublishUnPublishPropertyIcon;

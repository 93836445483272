import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import _forEach from "lodash/forEach";
import _includes from "lodash/includes";
import ReduxSagaFirebase from "redux-saga-firebase";
import { FirebaseAuthProvider, FirebaseDataProvider } from "react-admin-firebase";

import { requestOverrides, resourceNameOverrides } from "./resources";
import { PaginationDirection, RequestKeys, ResourcesKeys } from "../enums";
import { applyTimestamps } from "../utils/funcs";
import store from "../redux/createStore";
import { setPaginationDirection } from "../redux/reducers/pagination";
import appConfig from "./config";
import { resetWebViewUserToken } from "./FCM/fcm.utils";

const firebaseApp = firebase.initializeApp(appConfig);
export const rsf = new ReduxSagaFirebase(firebaseApp);
export const firestore = firebase.firestore();
export const storageRef = firebase.storage().ref();

const options = {
  app: firebaseApp,
  disableMeta: true,
  dontAddIdFieldToDoc: true,
  logging: true,
  firestoreCostsLogger: true,
};

export const dataProvider = FirebaseDataProvider({}, options);
const firebaseAuthProvider = FirebaseAuthProvider({}, options);
// console.log( "firebaseAuthProvider", firebaseAuthProvider.checkAuth );

export const authProvider = {
  ...firebaseAuthProvider,

  checkError: (error) => {
    if (error.status === 403) {
      error.redirectTo = "/";
      return Promise.resolve(error);
    }
    return firebaseAuthProvider.checkError(error);
  },
  logout: async () => {
    if (sessionStorage.getItem("crrIntervalId")) {
      clearInterval(sessionStorage.getItem("crrIntervalId"));
      sessionStorage.removeItem("crrIntervalId");
    }

    if (sessionStorage.getItem("started")) {
      sessionStorage.removeItem("started");
    }

    try {
      const data = store.getState();
      await resetWebViewUserToken(data.user);
      await firebaseApp.auth().signOut();
    } catch (ex) {
      console.log(ex);
    }
  },
};

const total = Number.MAX_SAFE_INTEGER;
export const skipLists = [ResourcesKeys.Properties];

function getPaginationData(data, resource, page, perPge, skip) {
  if (!data || !resource || !page || page === 1) return {};

  const resourceData = data.admin.resources[resource];
  const paginationDirection = data.pagination[resource] && data.pagination[resource].direction;
  const displayedIds = resourceData.list.ids;
  const cachedData = resourceData.data;

  let record;
  if (paginationDirection === PaginationDirection.next) {
    const lastRecord = cachedData[displayedIds[displayedIds.length - 1]];
    record = lastRecord && lastRecord;
  }
  if (paginationDirection === PaginationDirection.prev) {
    const firstRecord = cachedData[displayedIds[0]];
    record = firstRecord && firstRecord;
  }
  if (paginationDirection === PaginationDirection.current) {
    /*
     * Note
     * we used the difference between displayed length and the 'perPage' count here
     * to solve the case of adding new record when being in the last page
     * because adding a new record inject the new created ID to the current displayed IDs
     *
     * also 'skip' argument is just a mark to go to the first page after creating a new record
     * */
    const diff = displayedIds.length - perPge;
    const firstRecord = cachedData[displayedIds[diff === 0 && !skip ? diff : -1]];
    record = firstRecord && firstRecord;
  }
  return {
    record,
    paginationDirection,
  };
}

async function getList(resource, queryData) {
  const data = store.getState();
  const { record, paginationDirection } = getPaginationData(
    data,
    resource,
    queryData.pagination.page,
    queryData.pagination.perPage,
    skipLists.includes(resource)
  );

  if (!record && queryData.pagination.page > 1)
    return {
      data: [],
      total,
    };

  const res = await requestOverrides[resource][RequestKeys.GET_LIST]({
    ...queryData,
    paginationDirection,
    record,
    user: data.user,
  });

  store.dispatch(
    setPaginationDirection({
      resource,
      paginationDirection: PaginationDirection.current,
    })
  );

  return res;
}

function dataProviderProxy(requestKey, resourceKey, queryData) {
  const reduxStore = store.getState();
  const { GET_LIST, ...requestKeysToOverride } = RequestKeys;

  if (requestKey === RequestKeys.CREATE) {
    queryData.data = applyTimestamps({
      data: queryData.data,
      isCreate: true,
    });
  }
  if (_includes([RequestKeys.UPDATE, RequestKeys.UPDATE_MANY], requestKey)) {
    queryData.data = applyTimestamps({
      data: queryData.data,
      isUpdate: true,
    });
  }

  const validResourceKey = resourceNameOverrides[resourceKey] || resourceKey;

  let promiseToReturn;
  if (requestKey === RequestKeys.GET_LIST) {
    promiseToReturn = getList(resourceKey, queryData);
  } else {
    if (requestOverrides[resourceKey] && requestOverrides[resourceKey][requestKey]) {
      promiseToReturn = requestOverrides[resourceKey][requestKey](validResourceKey, queryData, reduxStore);
    } else {
      promiseToReturn = dataProvider[requestKey](validResourceKey, queryData);
    }
  }
  return promiseToReturn;
}

export const providerWithOverrides = (() => {
  const resultingProvider = {};
  _forEach(RequestKeys, (requestKey) => {
    resultingProvider[requestKey] = (resourceKey, queryData) => dataProviderProxy(requestKey, resourceKey, queryData);
  });
  return resultingProvider;
})();

export default firebaseApp;

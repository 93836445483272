import firebase from "firebase/compat/app";

import { applyTimestamps } from "../../../utils/funcs";
import { notificationsCollectionRef } from "../../../utils/refrences.utils";
import { updateNotificationSeenBy } from "../reducer";

export const normalizeText = (text) => {
  return text.replace(/([A-Z])/g, " $1");
  // uppercase the first character
  // .replace(/^./, function(str){ return str.toUpperCase(); })
};

export const prepareNotificationsForRedux = (notifications, userId) => {
  // console.log("hamada" , notifications);

  let counter = 0;

  return notifications.reduce(
    (acc, el) => {
      if (!el.seenBy.includes(userId)) {
        // console.log("Hnaaaaaa", el , userId);
        counter++;
      }

      return {
        listIds: [...acc.listIds, el.id],
        listMap: {
          ...acc.listMap,
          [el.id]: el,
        },
        notificationCounter: counter,
      };
    },
    {
      listIds: [],
      listMap: {},
    }
  );
};

export const updateNotificationInRedux = (notificationId, userId, dispatch) => {
  dispatch(
    updateNotificationSeenBy({
      userId,
      notificationId,
    })
  );
};

export const updateNotification = (notification, userId, dispatch) => {
  notification.ref
    .update(
      applyTimestamps({
        data: {
          seenBy: firebase.firestore.FieldValue.arrayUnion(userId),
        },
        isUpdate: true,
      })
    )
    .then(() => {
      updateNotificationInRedux(notification.id, userId, dispatch);
    })
    .catch((ex) => {
      console.log(ex.message);
    });
};

// on load more
export const getNotificationsListQuery = (userId, limit = 5, snapshot) => {
  let query = notificationsCollectionRef
    .where("receivers", "array-contains", userId)
    .limit(limit)
    .orderBy("createdAt", "desc");

  if (snapshot) query = query.startAfter(snapshot);

  return query;
};

import { Link, Typography, useTheme } from "@material-ui/core";

const CustomArrayField = ({ source, record, link }) => {
  const theme = useTheme();

  return (
    <>
      {record[source].map((str, i) => (
        <>
          {link ? (
            <Link href={str} target="_blank" style={{ color: theme.palette.action.main }}>
              {`View Attachment (${i + 1})${i + 1 === record[source].length ? "" : " - "}`}
            </Link>
          ) : (
            <Typography variant="subtitle2">- {str}</Typography>
          )}
        </>
      ))}
    </>
  );
};

export default CustomArrayField;

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { adminReducer, USER_LOGOUT } from "react-admin";

import history from "../init/history";
import rootReducer from "./reducers";
import saga from "./sagas";
import { logout } from "./reducers/user";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  admin: adminReducer,
  router: connectRouter(history),
  ...rootReducer,
});

const store = configureStore({
  reducer: (state, action) => {
    return reducer(action.type === logout.toString() ? undefined : state, action);
  },
  middleware: [sagaMiddleware, routerMiddleware(history)],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(saga);

export default store;

import { call, put, takeEvery } from "redux-saga/effects";

import { rsf } from "../../init/firebase";
import { setRoles } from "../reducers/roles";
import { getEmployeeRoles } from "../actions/init";
import { ResourcesKeys } from "../../enums";

function* getRoles() {
  const res = yield call(rsf.firestore.getDocument, `${ResourcesKeys.Enums}/${ResourcesKeys.Roles}`);
  yield put(setRoles(res.data()));
}

export function* watchGetRoles() {
  yield takeEvery(getEmployeeRoles.toString(), getRoles);
}

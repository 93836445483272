import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { Box, FormHelperText, IconButton, Typography, useTheme } from "@material-ui/core";
import { Close, Photo } from "@material-ui/icons";

export const MyImagesUploader = ({
  form,
  controllerProps,
  defaultValue,
  disabled = false,
  multiple = false,
  accept = "image/*",
}) => {
  const { palette } = useTheme();

  /**
   * this state is used to control the input field dirty status
   * for create mode it will be false at first and when user select first image will be set to true.
   * if the default value is present, it will be true from first time (update mode)
   * */
  const [customIsDirty, setCustomIsDirty] = useState(false);

  const {
    field,
    fieldState: { error },
  } = useController({
    ...controllerProps,
    rules: {
      ...controllerProps.rules,
      validate(data) {
        const isValid =
          !!data ||
          (Array.isArray(data) && data.length > 0) ||
          (typeof data === "object" && (data instanceof File || Object.keys(data).length > 0));

        return !isValid ? "Required Images" : undefined;
      },
    },
  });
  const [files, setFiles] = useState(defaultValue ?? []);

  const handleRemoveImage = (index) => {
    setFiles((prevState) => prevState.filter((_, i) => i !== index));
  };

  useEffect(() => {
    /**
     * only change file value if in edit mode which will be true by default
     * or give the user a try to upload his first file to be true
     * */
    if (customIsDirty)
      if (files.length === 0) {
        /**
         * call field.onChange() with related value to make it available in the form state
         * */
        field.onChange(undefined);
      } else {
        field.onChange(multiple ? files : files[0]);
      }
  }, [files.length, customIsDirty]);

  useEffect(() => {
    if (defaultValue) {
      setFiles(multiple ? defaultValue : [defaultValue]);
      setCustomIsDirty(true);
    }
  }, [defaultValue, multiple]);

  return (
    <>
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "2px dashed",
          borderColor: error ? palette.error.main : palette.grey["600"],
          borderRadius: 2,
          pointerEvents: disabled ? "none" : "initial",
          opacity: disabled ? 0.75 : 1,
        }}
      >
        {files?.length > 0 && (
          <Box
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
              m: -1,
            }}
          >
            {files.map((file, index) => {
              const url = file instanceof File ? URL.createObjectURL(file) : file.src;
              return (
                <Box
                  key={url}
                  style={{
                    width: 100,
                    height: 100,
                    backgroundColor: palette.background.default,
                    m: 1,
                    position: "relative",
                  }}
                >
                  <IconButton
                    size="small"
                    style={{
                      position: "absolute",
                      top: 3,
                      right: 3,
                    }}
                    onClick={() => handleRemoveImage(index)}
                  >
                    <Close />
                  </IconButton>
                  <img
                    src={url}
                    alt="image"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        )}

        <Box style={{ width: "100%", height: 75 }}>
          <label
            htmlFor="my-image-uploader"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              cursor: !multiple && files.length === 1 ? "not-allowed" : "pointer",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Photo />
            <Typography>Upload</Typography>
          </label>
          <input
            type="file"
            multiple={multiple}
            id="my-image-uploader"
            accept={accept}
            style={{ display: "none" }}
            disabled={!multiple && files.length === 1}
            name={field.name}
            ref={field.ref}
            onBlur={field.onBlur}
            value={""}
            onChange={(e) => {
              const selectedFiles = Array.from(e.target.files);
              /**
               * control the uploaded files by managing local state
               * we will call the field.onChange() later
               * */
              setFiles((prevState) => [...prevState, ...selectedFiles]);
              if (!customIsDirty) setCustomIsDirty(true);
            }}
          />
        </Box>
      </Box>
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </>
  );
};

import { ListItem, ListItemText } from "@material-ui/core";
import { useListContext } from "react-admin";
import { from, of } from "rxjs";
import { catchError, map, switchMap, toArray } from "rxjs/operators";

import CustomSearch from "../../../../components/CustomSearch";
import { companiesRef } from "../../../../utils/refrences.utils";

const SearchCompanies = ({ source }) => {
  const context = useListContext();

  const handleGetCompanies = (query) => {
    return from(query.limit(10).get()).pipe(
      switchMap((snapshot) => from(snapshot.docs)),
      map((docSnapshot) => ({
        ...docSnapshot.data(),
        id: docSnapshot.id,
      })),
      toArray(),
      catchError((ex) => {
        console.log(ex.message);
        return of([]);
      })
    );
  };

  const handleSelectCompany = (company) => {
    context.setFilters({ ...context.filterValues, [source]: company.id }, context.displayedFilters);
  };

  const renderMenuItems = (company, index, getItemProps) => {
    return (
      <ListItem
        {...getItemProps({
          key: company.name.en,
          item: company,
          index,
        })}
        button
      >
        <ListItemText>{company.name.en}</ListItemText>
      </ListItem>
    );
  };

  return (
    <>
      <CustomSearch
        query={companiesRef}
        source="name.en"
        label="company name"
        initial={[]}
        getResults={handleGetCompanies}
        renderMenuItems={renderMenuItems}
        onSelect={handleSelectCompany}
        listPosition="absolute"
      />
    </>
  );
};

export default SearchCompanies;

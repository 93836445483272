import { call, put, takeLatest } from "redux-saga/effects";
import curry from "lodash/fp/curry";

import { rsf } from "../../../init/firebase";
import chatActionCreators from "../../actions/chat";
import { isLoading, setRooms, syncRooms } from "../../reducers/chat";
import { ChatType } from "../../../enums";
import { modifiedPreparedRooms } from "../../../utils/chat";
import { getChatRoomsQuery } from "../../../queries/chatRooms";
import { roomDocRef } from "../../../utils/refrences.utils";

const finalQuery = curry((isLast, lastDocSnapshot, query) => {
  let newQuery;
  if (isLast && lastDocSnapshot) {
    newQuery = query.startAfter(lastDocSnapshot).limit(20);
  } else {
    newQuery = query.limit(20);
  }
  return newQuery;
});

function* getReplyRooms(action) {
  const { companyId, userId, role, last } = action.payload;

  let lastDocSnapshot;
  if (last) {
    lastDocSnapshot = yield roomDocRef(last.id).get();
  }

  const finalQueryWithLast = finalQuery(last)(lastDocSnapshot);
  const replyQuery = finalQueryWithLast(getChatRoomsQuery(ChatType.reply, companyId, userId, role));

  try {
    yield put(isLoading({ type: ChatType.reply, loading: true }));
    const replySnapshot = yield call(rsf.firestore.getCollection, replyQuery);
    const preparedRooms = yield modifiedPreparedRooms(replySnapshot.docs);
    yield put(
      last
        ? syncRooms({ rooms: preparedRooms, chatType: ChatType.reply })
        : setRooms({ rooms: preparedRooms, chatType: ChatType.reply })
    );
    yield put(isLoading({ type: ChatType.reply, loading: false }));
  } catch (ex) {
    console.log(ex.message);
    yield put(isLoading({ type: ChatType.reply, loading: false }));
  }
}

export function* watchGetReplyRooms() {
  yield takeLatest(chatActionCreators.getReplyRooms.toString(), getReplyRooms);
}

import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { uniq, isEmpty } from "lodash/fp";
import { Grid, Modal, Typography, Chip, useMediaQuery } from "@material-ui/core";
import { matchersy$ } from "./ComparisonModal.new";

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "75%",
    maxWidth: 850,
    height: "75vh",
    maxHeight: 750,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    overflow: "auto",
  },
  chip: {
    borderRadius: "5px",
    marginTop: "15px",
    fontSize: "15px",
    color: theme.palette.background.paper,
    backgroundColor: "rgba(0,142,13,0.8)",
    display: "block",
    font: "18px",
    lineHeight: "32px",
    textAlign: "center",
    maxWidth: "100%",
    overflowX: "auto",
  },
  notMatch: {
    backgroundColor: theme.palette.error.main,
  },
  heading: {
    marginTop: "16px",
    marginBottom: "16px",
    fontSize: "14px",
    height: "32px",
  },
  modelTitle: {
    paddingBottom: theme.spacing(4),
  },
}));

export default function ComparisonModal({ isOpen, handleCancel, property, request, areas, city }) {
  const classes = useStyles();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [nameOfAreas, setNameOfAreas] = useState([]);
  const [modalResults, setModalResults] = useState({});

  const gitAreasNames = () => {
    const areaNames = areas.map((area) => area.name.en);
    setNameOfAreas(uniq(areaNames));
  };

  useEffect(() => {
    gitAreasNames();
  }, [areas]);

  useEffect(() => {
    if (property && request)
      matchersy$(["rentalFrequency", "furnishing", "price", "space", "bedrooms", "bathrooms", "amenities"], {
        request,
        property,
        rangeFields: ["price", "space"],
        result: {},
        helpers: {
          formatName(name) {
            const formatedName = name.replace(/([A-Z])/g, " $1");
            return `${formatedName.slice(0, 1).toUpperCase()}${formatedName.slice(1)}`;
          },
        },
      }).subscribe((result) => {
        console.log({ result });
        setModalResults(result);
      });
  }, [property, request]);

  const body = property && request && !isEmpty(modalResults) && (
    <div style={modalStyle} className={classes.paper}>
      <Typography
        variant={isSm ? "h5" : "h4"}
        className={classes.modelTitle}
        style={{ marginLeft: isSm ? "-24px" : "" }}
      >
        Request Comparison
      </Typography>
      <Grid container spacing={8} justify="space-between" style={{ flexWrap: "nowrap" }}>
        <Grid item xs={4} style={{ maxWidth: isSm ? "75%" : "33.333333%", padding: isSm ? "8px 12px" : "32px" }}>
          <Typography variant="h6">Key</Typography>
          <Typography className={classes.heading}>Category</Typography>
          <Typography className={classes.heading}>Type</Typography>
          <Typography className={classes.heading}>City</Typography>
          <Typography className={classes.heading}>Area</Typography>
          <Typography className={classes.heading}>Purchasing Option</Typography>
          <Typography className={classes.heading}>Price</Typography>
          <Typography className={classes.heading}>Rental Frequency</Typography>
          <Typography className={classes.heading}>Space</Typography>
          <Typography className={classes.heading}>Amenities</Typography>
          <Typography className={classes.heading}>Bathrooms</Typography>
          <Typography className={classes.heading}>Bedrooms</Typography>
          <Typography className={classes.heading}>Furnishing</Typography>
        </Grid>

        <Grid
          item
          xs={4}
          style={{ maxWidth: isSm ? "75%" : "33.333333%", padding: isSm ? "8px" : "32px", backgroundColor: "#0000" }}
        >
          <Typography variant="h6">Request</Typography>
          <Chip label={request.category} className={classes.chip} />
          <Chip label={request.type} className={classes.chip} />
          <Chip label={city && city.name.en} className={classes.chip} />
          <Chip label={nameOfAreas.join(",")} className={classes.chip} />
          <Chip label={request.purchasingOption} className={classes.chip} />
          <Chip
            label={request.price.join(" - ")}
            className={`${classes.chip} ${modalResults.price.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={request && request.rentalFrequency ? request.rentalFrequency : "Any"}
            className={`${classes.chip} ${modalResults.rentalFrequency.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={request.space.join(" - ")}
            className={`${classes.chip} ${modalResults.space.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={request && request.amenities ? request.amenities.join(" - ") : "Any"}
            className={`${classes.chip} ${modalResults.amenities.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={request.bathrooms ? request.bathrooms.join(" - ") : "Any"}
            className={`${classes.chip} ${modalResults.bathrooms.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={request.bedrooms ? request.bedrooms.join(" - ") : "Any"}
            className={`${classes.chip} ${modalResults.bedrooms.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={request && request.furnishing.length > 0 ? request.furnishing.join(" - ") : "Any"}
            className={`${classes.chip} ${modalResults.furnishing.matching ? "" : classes.notMatch}`}
          />
        </Grid>

        <Grid
          item
          xs={4}
          style={{ maxWidth: isSm ? "75%" : "33.333333%", padding: isSm ? "8px" : "32px", backgroundColor: "#0000" }}
        >
          <Typography variant="h6">Property</Typography>
          <Chip label={property.category} className={classes.chip} />
          <Chip label={property.type} className={classes.chip} />
          <Chip label={city && city.name.en} className={classes.chip} />
          <Chip label={property.area} className={classes.chip} />
          <Chip label={property.purchasingOption} className={classes.chip} />
          <Chip
            label={property.price}
            className={`${classes.chip} ${modalResults.price.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={property.rentalFrequency}
            className={`${classes.chip} ${modalResults.rentalFrequency.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={property.space}
            className={`${classes.chip} ${modalResults.space.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={property.amenities.join(",")}
            className={`${classes.chip} ${modalResults.amenities.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={property.bathrooms}
            className={`${classes.chip} ${modalResults.bathrooms.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={property.bedrooms}
            className={`${classes.chip} ${modalResults.bedrooms.matching ? "" : classes.notMatch}`}
          />
          <Chip
            label={property.furnishing}
            className={`${classes.chip} ${modalResults.furnishing.matching ? "" : classes.notMatch}`}
          />
        </Grid>
      </Grid>
    </div>
  );

  return isEmpty(modalResults) ? null : (
    <>
      <Modal open={isOpen} onClose={handleCancel}>
        {body}
      </Modal>
    </>
  );
}

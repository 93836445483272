import { Box, Card, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Title } from "react-admin";
import { useParams } from "react-router-dom";

import Loader from "../../components/Loader";
import { ResourcesKeys } from "../../enums";
import { firestore } from "../../init/firebase";
import { repliesDocRef } from "../../utils/refrences.utils";

const useStyles = makeStyles((theme) => ({
  panel: {
    minWidth: 300,
  },
  title: {
    fontSize: 20,
    marginBottom: theme.spacing(2),
  },
  field: {
    color: theme.palette.grey[50],
    padding: theme.spacing(1),
    borderRadius: 5,
  },
  match: {
    backgroundColor: "rgba(0,142,13,0.35)",
  },
  notMatch: {
    backgroundColor: theme.palette.error.main,
  },
}));

const ComparisonRowField = ({ right, value, match, isTitle, isHeader }) => {
  const classes = useStyles({});
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      mx={isSm ? 1 : 3}
      my={0.25}
      flex={1}
      minWidth={isHeader & isSm ? "auto" : 200}
      textAlign={isSm ? "" : right ? "right" : ""}
    >
      <Typography
        style={{ overflowWrap: "break-word" }}
        className={clsx({
          [classes.title]: isTitle,
          [classes.field]: !isTitle && !isHeader,
          [classes.match]: match && !isTitle && !isHeader,
          [classes.notMatch]: !match && !isTitle && !isHeader,
        })}
      >
        {value}
      </Typography>
    </Box>
  );
};

const ComparisonRow = ({ field, current, sent, isTitle }) => {
  const isMatch = current === sent;
  return (
    <Box display="flex" justifyContent="center">
      <ComparisonRowField value={field} right match={isMatch} isTitle={isTitle} isHeader />
      <ComparisonRowField value={current} match={isMatch} isTitle={isTitle} />
      <ComparisonRowField value={sent} match={isMatch} isTitle={isTitle} />
    </Box>
  );
};

const ReplyPreview = () => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles({});
  const { replyId } = useParams();
  const [replyProperty, setReplyProperty] = useState(null);
  const [currentProperty, setCurrentProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [requestId, propertyId] = replyId.split("_");

  const getReplyProperty = async (replyId) => {
    try {
      setLoading(true);
      const snapshot = await repliesDocRef(requestId, replyId).get();
      setError(null);
      return snapshot.data().property;
    } catch (ex) {
      console.log(ex.message);
      setError("Couldn't fetch the reply");
    } finally {
      setLoading(false);
    }
  };

  const getCurrentProperty = async (propertyId) => {
    try {
      setLoading(true);
      const snapshot = await firestore.collection(ResourcesKeys.Properties).doc(propertyId).get();
      setError(null);
      return snapshot.data();
    } catch (ex) {
      console.log(ex.message);
      setError("Property might not be exist");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async function () {
      setReplyProperty(await getReplyProperty(replyId));
      setCurrentProperty(await getCurrentProperty(propertyId));
    })();
  }, []);

  return (
    <Card style={{ padding: isSm ? "16px 0px" : "32px" }}>
      <Title title="Reply Property View" />
      <Loader loading={loading && !error}>
        {!loading && !error ? (
          <Box display="flex">
            {currentProperty && replyProperty && (
              <div className={classes.panel} style={{ padding: isSm ? "10px" : "16px" }}>
                <ComparisonRow field="" current="Current Property" sent="Property Sent to Reply" isTitle />
                <ComparisonRow field="Address" current={currentProperty.address.en} sent={replyProperty.address.en} />
                <ComparisonRow
                  field="Address Details"
                  current={currentProperty.addressDetails.en}
                  sent={replyProperty.addressDetails.en}
                />
                <ComparisonRow
                  field="Amenities"
                  current={currentProperty.amenities.join(", ")}
                  sent={replyProperty.amenities.join(", ")}
                />
                <ComparisonRow field="Bathrooms" current={currentProperty.bathrooms} sent={replyProperty.bathrooms} />
                <ComparisonRow field="Bedrooms" current={currentProperty.bedrooms} sent={replyProperty.bedrooms} />
                <ComparisonRow field="Category" current={currentProperty.category} sent={replyProperty.category} />
                <ComparisonRow
                  field="Company"
                  current={currentProperty.companyName.en}
                  sent={replyProperty.companyName.en}
                />
                <ComparisonRow
                  field="Description"
                  current={currentProperty.description.en}
                  sent={replyProperty.description.en}
                />
                <ComparisonRow
                  field="Featured"
                  current={currentProperty.featured ? "Yes" : "No"}
                  sent={replyProperty.featured ? "Yes" : "No"}
                />
                <ComparisonRow
                  field="Boosted"
                  current={currentProperty.boosted ? "Yes" : "No"}
                  sent={replyProperty.boosted ? "Yes" : "No"}
                />
                <ComparisonRow
                  field="Published"
                  current={currentProperty.published ? "Yes" : "No"}
                  sent={replyProperty.published ? "Yes" : "No"}
                />
                <ComparisonRow
                  field="Pets Allowed"
                  current={currentProperty.petsAllowed ? "Yes" : "No"}
                  sent={replyProperty.petsAllowed ? "Yes" : "No"}
                />
                <ComparisonRow field="Price" current={currentProperty.price} sent={replyProperty.price} />
                <ComparisonRow
                  field="Purchasing Option"
                  current={currentProperty.purchasingOption}
                  sent={replyProperty.purchasingOption}
                />
                <ComparisonRow field="Space" current={currentProperty.space} sent={replyProperty.space} />
                <ComparisonRow field="Type" current={currentProperty.type} sent={replyProperty.type} />
                <ComparisonRow
                  field="Furnishing"
                  current={currentProperty.furnishing}
                  sent={replyProperty.furnishing}
                />
              </div>
            )}
          </Box>
        ) : (
          <Alert severity="warning">{error}</Alert>
        )}
      </Loader>
    </Card>
  );
};

export default ReplyPreview;

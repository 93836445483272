const Locals = [
  {
    key: "en",
    label: {
      en: "English",
      ar: "الإنجليزية",
    },
    default: true,
  },
  {
    key: "ar",
    label: {
      en: "Arabic",
      ar: "العربية",
    },
  },
];

export default Locals;

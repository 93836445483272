import { useSelector } from "react-redux";
import { Datagrid, List } from "react-admin";

import JoinArrayField from "../../components/JoinArrayField";
import TextFieldWithAny from "../../components/TextFieldWithAny";
import TablePagination from "../../components/TablePagination";
import Bullet from "../../components/Bullet";
import useRealTimeRefresh from "../../hooks/useRealTimeRefresh";
import { Statistics, ResourcesKeys } from "../../enums";
import TimestampField from "../../components/TimestampField";
import useNotifications from "../../hooks/useNotifications";
import useSharedStyles from "../../hooks/useSharedStyles";
import ClientName from "./components/ClientName";
import ClientEmail from "./components/ClientEmail";
import ClientImage from "./components/ClientImage";
import StartChat from "./components/StartChat";

const PropertiesRequestsList = (props) => {
  const { styles } = useSharedStyles();
  const seenRequests = useSelector((state) => state.seenRequests.seen);
  useRealTimeRefresh();
  useNotifications(Statistics.requestsLastSeenAt, ResourcesKeys.PropertiesRequests);

  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "desc" }}
      pagination={<TablePagination />}
      cellClassName={styles.truncate3}
      bulkActionButtons={false}
      exporter={false}
      actions={null}
    >
      <Datagrid rowClick="show">
        <Bullet sortable={false} requests={seenRequests} cellClassName={styles.truncate3} />
        <TextFieldWithAny source="id" sortable={false} label="Identifier" cellClassName={styles.truncate3} />
        <ClientName source="clientId" label="Client Name" sortable={false} cellClassName={styles.truncate3} />
        <ClientEmail source="clientId" label="Client Email" sortable={false} cellClassName={styles.truncate3} />
        <ClientImage source="clientId" label="Client Photo" sortable={false} />
        <TextFieldWithAny source="type" sortable={false} cellClassName={styles.truncate3} />
        <TextFieldWithAny source="category" sortable={false} cellClassName={styles.truncate3} />
        <JoinArrayField source="price" sortable={false} cellClassName={styles.truncate3} />
        <TextFieldWithAny source="purchasingOption" sortable={false} cellClassName={styles.truncate3} />
        <JoinArrayField source="bedrooms" sortable={false} cellClassName={styles.truncate3} />
        <JoinArrayField source="bathrooms" sortable={false} cellClassName={styles.truncate3} />
        <JoinArrayField source="space" sortable={false} cellClassName={styles.truncate3} />
        <JoinArrayField source="amenities" sortable={false} cellClassName={styles.truncate3} />
        <TimestampField source="createdAt" cellClassName={styles.truncate3} />
        <StartChat label="Start chat" />
      </Datagrid>
    </List>
  );
};

export default PropertiesRequestsList;

import { BooleanField, Datagrid, EmailField, Filter, List, TextField, TextInput } from "react-admin";

import TablePagination from "../../components/TablePagination";
import TimestampField from "../../components/TimestampField";
import DisableClientButton from "./components/DisableClientButton";
import EnableClientButton from "./components/EnableClientButton";
import ListImage from "../../components/ListImage";
import useSharedStyles from "../../hooks/useSharedStyles";
import { useMediaQuery } from "@material-ui/core";

const ClientsBulkActionButtons = (props) => (
  <>
    <DisableClientButton label="Disable" {...props} />
    <EnableClientButton label="Enable" {...props} />
  </>
);

const ClientsFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Phone" source="phoneNumber" />
  </Filter>
);

const ClientsList = (props) => {
  const { styles } = useSharedStyles();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List
      {...props}
      bulkActionButtons={<ClientsBulkActionButtons />}
      filters={<ClientsFilters />}
      sort={{ field: "createdAt", order: "desc" }}
      pagination={<TablePagination />}
      exporter={false}
    >
      <Datagrid style={{ tableLayout: isSm ? "" : "fixed" }}>
        <TextField source="id" cellClassName={styles.truncate1} sortable={false} />
        <TextField source="displayName" cellClassName={styles.truncate1} sortable={false} />
        <ListImage source="photoUrl" label="Image" sortable={false} />
        <EmailField source="email" cellClassName={styles.truncate1} sortable={false} />
        <TextField source="phoneNumber" cellClassName={styles.truncate1} sortable={false} />
        <BooleanField source="emailVerified" sortable={false} />
        <BooleanField source="disabled" sortable={false} />
        <TimestampField source="createdAt" label="Created At" cellClassName={styles.truncate1} />
      </Datagrid>
    </List>
  );
};

export default ClientsList;

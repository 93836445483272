import { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useRedirect } from "react-admin";
import PropTypes from "prop-types";

import { ResourcesKeys } from "../../../enums";

const AdminHome = ({ redirectEnabled = false }) => {
  const redirect = useRedirect();

  useEffect(() => {
    if (redirectEnabled) redirect(`/${ResourcesKeys.PropertiesCompanies}`);
  }, []);

  return (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
      <Typography>Welcome to admin dashboard</Typography>
    </Box>
  );
};

AdminHome.prototype = {
  redirectEnabled: PropTypes.bool,
};

export default AdminHome;

import { useEffect } from "react";
import { useInput, useTranslate } from "ra-core";

import CustomSelectInput from "../../../components/CustomSelectInput";

const CitiesSelectInput = ({ label, onChange: onCityChange, cities, ...props }) => {
  const translate = useTranslate();
  const {
    input: { name, value, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  useEffect(() => {
    onCityChange(value);
  }, [value]);

  return (
    Array.isArray(cities) && (
      <CustomSelectInput
        isRequired={isRequired}
        name={name}
        error={error}
        value={value}
        translate={translate}
        options={cities}
        label={label}
        touched={touched}
        onChange={onChange}
        {...rest}
      />
    )
  );
};

export default CitiesSelectInput;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useCurrentUser from "../../../hooks/useCurrentUser";
import useNotificationListListener from "../../../Notifications/NotificationList/utils/UseNotificationListListener";
import { initialNotificationsFetch } from "../../../Notifications/NotificationList/reducer";
import { companyDocRef } from "../../../utils/refrences.utils";
import { prepareNotificationsList } from "./notificationRequest";
import useGetToken from "./useGetToken";

const useNotificationsRequest = (canReceiveNotification) => {
  const [notificationCounter, setNotificationCounter] = useState(0);

  const { user } = useCurrentUser();
  const dispatch = useDispatch();

  useGetToken(canReceiveNotification);
  useNotificationListListener({
    canGetNotifications: canReceiveNotification,
    prepareNotifications: prepareNotificationsList,
    notificationCounter,
    playSound: true,
  });
  // todo [requests notifications] - implement custom hook to show red bullet for the current side drawer tab

  useEffect(() => {
    dispatch(initialNotificationsFetch(user.id));
  }, []);

  useEffect(() => {
    companyDocRef(user.id).onSnapshot((snapshot) => {
      if (snapshot.exists) {
        const agent = snapshot.data();
        if (agent) setNotificationCounter(agent.totalNotificationsNumber);
      }
    });
  }, []);
};

export default useNotificationsRequest;

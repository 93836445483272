import { Box, Card, Typography } from "@material-ui/core";
import { Show } from "react-admin";
import ShowItem from "../../components/ShowItem";

const getMapsLink = (lat, long) => `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;

const LocationField = ({ value, title }) => {
  return (
    <Box display="flex" flexDirection="column" mb={3}>
      <Typography variant="body1" color="primary">
        {title}
      </Typography>
      <div onClick={(e) => e.stopPropagation()}>
        <a
          href={getMapsLink(value._lat, value._long)}
          target="_blank"
          rel="noreferrer"
          style={{
            wordBreak: "break-all",
          }}
        >
          Location
        </a>
      </div>
    </Box>
  );
};

const PropertiesFields = ({ record }) => {
  return (
    <Box p={3}>
      <ShowItem title="Address" value={record.address.en} />
      <ShowItem title="Purchasing Option" value={record.purchasingOption} />
      <ShowItem title="Type" value={record.type} />
      <ShowItem title="Price" value={record.price} />
      <ShowItem title="Description (en)" value={record.description.en} />
      <ShowItem title="Description (ar)" value={record.description.ar} />
      <LocationField title="Location" value={record.geoPoint} />
      <ShowItem title="Category" value={record.category} />
      <ShowItem title="City" value={record.cityName.en} />
      <ShowItem title="Areas" value={record.areaName.en} />
      <ShowItem title="Bedrooms" value={record.bedrooms} />
      <ShowItem title="Bathrooms" value={record.bathrooms} />
      <ShowItem title="Space" value={record.space} />
      <ShowItem title="Parking" value={record.parking} />
      <ShowItem title="Furnishing" value={record.furnishing} />
      <ShowItem title="Rental Frequency" value={record.rentalFrequency} />
      <ShowItem title="Amenities" value={record.amenities} isList />
    </Box>
  );
};

const PropertiesShow = (props) => {
  return (
    <section>
      <Card>
        <Box p={2}>
          <Typography>Properties Preview</Typography>
        </Box>
        <Show {...props}>
          <PropertiesFields />
        </Show>
      </Card>
    </section>
  );
};

export default PropertiesShow;

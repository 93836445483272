import { useCallback, useMemo, useState } from "react";
import { deleteDoc, getDoc, updateDoc } from "firebase/firestore";

import { applyTimestamps } from "../../../utils/funcs";
import firebase from "../../../init/firebase";

function useOneDocumentHandler(path, { initialValue = null, addId = true, addMeta = true } = {}) {
  const [record, setRecord] = useState(initialValue);

  const [isFetching, setIsFetching] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [error, setError] = useState(null);

  const documentRef = useMemo(() => firebase.firestore().doc(path), [path]);

  const fetchDocument = useCallback(
    async ({ onSuccess, onError } = {}) => {
      try {
        setIsFetching(true);
        const snapshot = await getDoc(documentRef);
        if (snapshot.exists()) {
          setRecord({
            ...snapshot.data(),
            ...(addId ? { id: snapshot.id } : {}),
            ...(addMeta
              ? {
                  __meta: {
                    ref: snapshot.ref,
                  },
                }
              : {}),
          });
        } else {
          setRecord(null);
        }
        setIsFetching(false);
        setError(null);
        onSuccess?.();
      } catch (ex) {
        console.log(ex.message);
        setIsFetching(false);
        setError("Something went wrong");
        onError?.(ex);
      }
    },
    [documentRef]
  );

  const updateDocument = useCallback(
    async (data, { updateLocal = false, onSuccess, onError } = {}) => {
      try {
        setIsUpdating(true);
        await updateDoc(documentRef, applyTimestamps({ data, isUpdating: true }));
        if (updateLocal) {
          setRecord((prevState) => ({ ...prevState, ...data }));
        }
        setIsUpdating(false);
        onSuccess?.();
      } catch (ex) {
        console.log(ex.message);
        setIsUpdating(false);
        setError("Something went wrong");
        onError?.(ex);
      }
    },
    [documentRef]
  );

  const deleteDocument = useCallback(
    async ({ onSuccess, onError } = {}) => {
      try {
        setIsDeleting(true);
        await deleteDoc(documentRef);
        setIsDeleting(false);
        onSuccess?.();
      } catch (ex) {
        console.log(ex.message);
        setIsDeleting(false);
        setError("Something went wrong");
        onError?.(ex);
      }
    },
    [documentRef]
  );

  return {
    record,
    setRecord,
    isFetching,
    isUpdating,
    isDeleting,
    error,
    fetchDocument,
    updateDocument,
    deleteDocument,
  };
}

export default useOneDocumentHandler;

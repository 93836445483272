import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";

import { clientDocRef } from "../../../utils/refrences.utils";

const ClientEmail = (props) => {
  const [clientEmail, setClientEmail] = useState(null);

  useEffect(() => {
    (async function () {
      const snapshot = await clientDocRef(props.record.clientId).get();
      setClientEmail(snapshot.data().email);
    })();
  }, []);

  return clientEmail ? <Typography variant="body2">{clientEmail}</Typography> : null;
};

export default ClientEmail;

import { useState, useRef } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import {
  BooleanInput,
  Create,
  email,
  minLength,
  PasswordInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  FormGroupContextProvider,
  FormWithRedirect,
} from "react-admin";
import firebase from "firebase/compat/app";
import _map from "lodash/map";
import { isEmpty } from "lodash";
import _includes from "lodash/includes";
import { Field } from "react-final-form";

import { isExists, phoneNumber } from "../../utils/validators";
import ImageUploadInput from "../../components/ImageUploadInput";
import CustomToolbar from "../../components/CustomToolbar";
import useDisabledEvents from "../../hooks/useDisabledEvents";
import { usePropertiesCompaniesLocalizations } from "./utils";
import { FormGroupNames, ResourcesKeys } from "../../enums";
import FormWrapper from "../../components/FormWrapper";
import CustomCard from "../../components/CustomCard";
import useSharedStyles from "../../hooks/useSharedStyles";
import PhonePrefixCode from "../../components/PhonePrefixCode";
import { useGetRequiredPropertiesEnums } from "../companyProperties/utils";
import CustomSelect from "../../components/CustomSelect";
import LocationPicker from "../../components/LocationPicker";
import appConfig from "../../init/config";
import { areEqual } from "../../utils/general";

const CustomForm = (props) => {
  const { badgesRef, locationRef } = props;

  const { styles } = useSharedStyles();
  const localizedInputs = usePropertiesCompaniesLocalizations(props);
  const { formData } = useGetRequiredPropertiesEnums();

  const [selectedBadges, setSelectedBadges] = useState([]);
  const [location, setLocation] = useState(null);
  const [locationLoading, setLocationLoading] = useState(false);
  const [enteredLocationUrl, setEnteredLocationUrl] = useState("");

  const { disabledEvents } = useDisabledEvents(locationLoading);

  const handleSelectBadges = (_selectedBadges) => {
    if (_includes(_selectedBadges, "hotel")) {
      setSelectedBadges(["hotel"]);
      badgesRef.current = ["hotel"];
    } else {
      setSelectedBadges(_selectedBadges);
      badgesRef.current = _selectedBadges;
    }
  };

  const handleLocationUrl = async (e) => {
    if (e.target.value.trim()) {
      try {
        setLocationLoading(true);
        const response = await fetch(`${appConfig.cloudFunctionsExternalUrl}/getLatLng?url=${e.target.value}`);
        const data = await response.json();
        console.log("data url", data);
        setEnteredLocationUrl(data);
        setLocationLoading(false);
      } catch (ex) {
        console.log(ex.message);
        setLocationLoading(false);
      }
    }
  };

  return (
    <FormWrapper>
      <FormGroupContextProvider name={FormGroupNames.companies}>
        <FormWithRedirect
          {...props}
          style={disabledEvents}
          redirect={`/${ResourcesKeys.PropertiesCompanies}`}
          render={(formProps) => {
            console.log("formProps", formProps);

            return (
              <form style={disabledEvents}>
                <CustomCard>
                  <Typography variant="h6">Package</Typography>

                  <ReferenceInput
                    variant="outlined"
                    source="packageId"
                    reference="packages"
                    limit="20"
                    validate={[required()]}
                  >
                    <SelectInput optionText="name.en" />
                  </ReferenceInput>
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Details</Typography>
                  <Box className={styles.row}>{[localizedInputs[0], localizedInputs[1]]}</Box>
                  <Box className={styles.row}>{[localizedInputs[2], localizedInputs[3]]}</Box>
                  <Box className={styles.row}>
                    <CustomSelect
                      multiple={true}
                      handleSelectItem={(_selectedBadges) => {
                        handleSelectBadges(_selectedBadges);
                      }}
                      selectedItem={selectedBadges}
                      labelTitle="Badges"
                      listItems={
                        isEmpty(formData.companiesBadges)
                          ? []
                          : _map(formData.companiesBadges, (value, key) => ({
                              id: key,
                              name: value.label?.en,
                            }))
                      }
                    />
                  </Box>
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Contact Info</Typography>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        source="email"
                        validate={[required(), email(), isExists("email", ResourcesKeys.PropertiesCompanies)]}
                      />
                    </Box>
                    <Box className={styles.rowElement}>
                      <PasswordInput variant="outlined" source="password" validate={[required(), minLength(8)]} />
                    </Box>
                  </Box>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        source="phoneNumber"
                        type="number"
                        validate={[
                          required(),
                          phoneNumber(),
                          isExists("phoneNumber", ResourcesKeys.PropertiesCompanies),
                        ]}
                        InputProps={{
                          startAdornment: <PhonePrefixCode />,
                        }}
                      />
                    </Box>

                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        source="whatsappNumber"
                        type="number"
                        validate={[
                          required(),
                          phoneNumber(),
                          isExists("whatsappNumber", ResourcesKeys.PropertiesCompanies),
                        ]}
                        InputProps={{
                          startAdornment: <PhonePrefixCode />,
                        }}
                      />
                    </Box>
                  </Box>
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Location</Typography>{" "}
                  <Box className={styles.row} flexDirection="column">
                    <Box mr={2} mb={1}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={handleLocationUrl}
                        label="Location url"
                      />
                    </Box>
                    <Box className={styles.rowElement}>
                      <Field name="_geoPoint">
                        {(props) => {
                          return (
                            <LocationPicker
                              onLocationChange={(data) => {
                                if (!areEqual(data, location)) {
                                  setLocation(data);
                                  setEnteredLocationUrl(data);
                                  locationRef.current = new firebase.firestore.GeoPoint(data.lat, data.lng);
                                  props.input.onChange(data);
                                }
                              }}
                              defaultValue="Doha, Ain Khaled"
                              value={
                                enteredLocationUrl &&
                                (!isNaN(+enteredLocationUrl.lat) || !isNaN(+enteredLocationUrl.lng))
                                  ? { latitude: +enteredLocationUrl.lat, longitude: +enteredLocationUrl.lng }
                                  : null
                              }
                            />
                          );
                        }}
                      </Field>
                    </Box>
                  </Box>
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Media</Typography>

                  <ImageUploadInput source="logo" label="Logo" validate={[required()]} />
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Preference</Typography>
                  <BooleanInput source="disabled" defaultValue={false} />
                </CustomCard>

                <CustomToolbar
                  {...formProps}
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  formName={FormGroupNames.companies}
                  errors={{
                    geoPoint: location ? "" : "Location is required",
                  }}
                />
              </form>
            );
          }}
        />
      </FormGroupContextProvider>
    </FormWrapper>
  );
};

export default function PropertiesCompaniesCreate(props) {
  const badgesRef = useRef([]);
  const locationRef = useRef();

  return (
    <Create
      {...props}
      component="div"
      transform={(values) => {
        values.location = locationRef.current;
        values.phoneNumber = `+974${values.phoneNumber}`;
        values.whatsappNumber = `+974${values.whatsappNumber}`;
        // todo - revisit this
        values.renewPackage = false;
        values.badges = !badgesRef.current.length ? [] : badgesRef.current;
        delete values._geoPoint;
        return values;
      }}
    >
      <CustomForm variant="outlined" {...props} badgesRef={badgesRef} locationRef={locationRef} />
    </Create>
  );
}

const getValue = (v, length = 20) => {
  const result = Array.isArray(v) ? v.join(" - ") : v;
  return result ? (result.length > 20 ? result.slice(0, length) + "..." : result) : "";
};

const JoinArrayField = ({ source, record }) => {
  return <span>{record[source] ? getValue(record[source]) : "Any"}</span>;
};

export default JoinArrayField;

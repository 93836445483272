import clsx from "clsx";
import React from "react";
import { useNotify, useRefresh } from "react-admin";

import { ReactComponent as Featured } from "../../../../assets/featured.svg";
import ConfirmationPopover from "../../../../components/ConfirmationPopover";
import CustomPopover from "../../../../components/CustomPopover";
import useIsSales from "../../../../hooks/useIsSales";
import { applyTimestamps } from "../../../../utils/funcs";
import { propertyDocRef } from "../../../../utils/refrences.utils";
import { useSharedActionsStyles } from "../../utils";

const FeatureUnFeaturePropertyIcon = ({ record }) => {
  const { styles } = useSharedActionsStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const { isSales } = useIsSales();

  const data = applyTimestamps({
    data: {
      featured: !record.featured,
    },
    isUpdate: true,
  });

  if (record.publishedAt) delete data.publishedAt;

  const publishProperty = async (propertyId) => {
    try {
      if (record.published || record.boosted) throw new Error("Either property is published or boosted");
      await propertyDocRef(propertyId).update(data);
      notify(`Property has been ${record.featured ? "unfeatured" : "featured"}`, "success");
    } catch (ex) {
      console.log(ex.message);
      notify(ex.message, "error");
    } finally {
      refresh();
    }
  };

  const FeatureStatusIcon = ({ featrured, unfeatrured }) => (
    <CustomPopover message={record.featured ? featrured : unfeatrured}>
      <Featured
        className={clsx({
          [styles.icon]: true,
          [styles.active]: record.featured,
        })}
      />
    </CustomPopover>
  );

  return isSales ? (
    <FeatureStatusIcon featrured="Featured" unfeatrured="Unfeatured" />
  ) : (
    <ConfirmationPopover
      onConfirm={() => publishProperty(record.id)}
      message={`Are you sure you want to ${record.featured ? "unfeature" : "feature"} property?`}
      Icon={() => (
        <FeatureStatusIcon featrured="Featured, Click to unfeature" unfeatrured="Unfeatured, Click to feature" />
      )}
    />
  );
};

export default FeatureUnFeaturePropertyIcon;

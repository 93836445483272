import { call, put, takeEvery } from "redux-saga/effects";

import { rsf } from "../../../init/firebase";
import { applyTimestamps } from "../../../utils/funcs";
import { setIsSending, setRoomMessages } from "../../reducers/chat";
import chatActionCreators from "../../actions/chat";
import { clientDocRef, companyDocRef, messagesSubRef } from "../../../utils/refrences.utils";

function* sendMessage(action) {
  const { roomId, fromId, toId, text, attachment, type } = action.payload;
  const message = yield call(
    rsf.firestore.addDocument,
    messagesSubRef(roomId),
    applyTimestamps({
      data: {
        from: companyDocRef(fromId),
        to: clientDocRef(toId),
        text: text || null,
        seen: false,
        attachment: attachment || null,
        arrangeVisit: false,
      },
      isCreate: true,
    })
  );
  const createdMessage = yield message.get();
  const createdMessageData = {
    id: createdMessage.id,
    ...createdMessage.data(),
  };
  yield put(
    setRoomMessages({
      roomId,
      messages: [createdMessageData],
      type,
    })
  );

  yield put(setIsSending({ type, sending: false }));
}

export function* watchSendMessage() {
  yield takeEvery(chatActionCreators.sendMessage.toString(), sendMessage);
}

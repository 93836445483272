import {
  Box,
  Button,
  makeStyles,
  TextField,
  useMediaQuery,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { useEffect } from "react";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { propertyDocRef } from "../../../utils/refrences.utils";

import ErrorMessage from "../../Charts/ErrorMessage";
import Loader from "../../Charts/Loader";
import { constructBigDataQueryUrl } from "../../Charts/utils";
import realEstate from "../../../assets/realEstate.svg";
import useGetEnumsAsValue from "../../../hooks/useGetEnumsAsValue";
import HotelIcon from "@material-ui/icons/Hotel";
import BathtubIcon from "@material-ui/icons/Bathtub";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import StatsExport from "./StatsExport";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const useStyles = makeStyles(() => ({
  textField: {
    "& > div": {
      width: "300px",
    },
  },
}));

const events = {
  views: "propertyView",
  visits: "propertyVisit",
  shares: "propertyShare",
  whats: "propertyWhatsapp",
  chat: "propertyChat",
  calls: "propertyCalls",
};

const PropertyStats = () => {
  const classes = useStyles();
  const { propertyId } = useParams();
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const category = useGetEnumsAsValue("propertiesCategories");
  const propertyType = useGetEnumsAsValue("propertiesTypes");
  const purchasingOption = useGetEnumsAsValue("propertiesPurchasingOptions");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uniqueData, setUniqueData] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [disabled, setDisabled] = useState(true);
  const [propData, setPropData] = useState(null);
  const [propDataLoading, setPropDataLoading] = useState(true);
  const [value, setValue] = useState("total");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleChangeDate = (e, type) => {
    setDate((prevState) => {
      return { ...prevState, [type]: e.target.value.replaceAll("-", "") };
    });
  };

  const getUrlByEvent = (eventName) => {
    return constructBigDataQueryUrl({
      idValue: propertyId,
      idKey: "propertyId",
      eventName,
      date,
    });
  };

  const fetchPropertyData = async () => {
    try {
      const snapshot = await propertyDocRef(propertyId).get();
      setPropData(snapshot.data());
      console.log({ snapshot: snapshot.data() });
      setPropDataLoading(false);
    } catch (ex) {
      console.log(ex.message);
      setPropDataLoading(false);
    }
  };

  const fetchPropertyEvents = async () => {
    try {
      const responseViews = await fetch(getUrlByEvent(events.views));
      const dataViews = await responseViews.json();

      const responseVisits = await fetch(getUrlByEvent(events.visits));
      const dataVisits = await responseVisits.json();

      const responseCalls = await fetch(getUrlByEvent(events.calls));
      const dataCalls = await responseCalls.json();

      const responseWhatsapp = await fetch(getUrlByEvent(events.whats));
      const dataWhatsapp = await responseWhatsapp.json();

      const responseChat = await fetch(getUrlByEvent(events.chat));
      const dataChat = await responseChat.json();

      const responseShares = await fetch(getUrlByEvent(events.shares));
      const dataShares = await responseShares.json();

      setUniqueData([
        dataViews.unique,
        dataVisits.unique,
        dataChat.unique,
        dataWhatsapp.unique,
        dataShares.unique,
        dataCalls.unique,
      ]);
      setTotalData([
        dataViews.total,
        dataVisits.total,
        dataChat.total,
        dataWhatsapp.total,
        dataShares.total,
        dataCalls.total,
      ]);
      setLoading(false);
    } catch (ex) {
      console.log(ex);
      setError("Error on fetching data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPropertyEvents();
    fetchPropertyData();
  }, []);

  useEffect(() => {
    setDisabled(!date.startDate || !date.endDate || date.startDate > date.endDate);
  }, [date]);

  return loading || propDataLoading ? (
    <Loader width="100%" height="100%" />
  ) : error ? (
    <Box mt={isMd ? 4 : 0}>
      <ErrorMessage error={error} />
    </Box>
  ) : (
    <>
      <Box
        mt={isMd ? 4 : 0}
        style={{
          display: isMd ? "block" : "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        mb={2}
        ml={1}
      >
        <Box
          mb={isMd ? 2 : 0}
          display="flex"
          alignItems="center"
          flexWrap={isMd ? "wrap" : ""}
          style={{
            backgroundColor: "#cccccc80",
            padding: "12px 16px",
          }}
        >
          <Box width={75} height={75} mr={1}>
            <img
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              src={propData.images[0].src}
              alt="property"
              onError={(e) => {
                e.currentTarget.src = realEstate;
              }}
            />
          </Box>
          <Typography style={{ marginRight: 16 }}>{propData.identifier}</Typography>
          <Typography style={{ marginRight: 16 }}>
            {category(propData.category)}, {propertyType(propData.type)}, {purchasingOption(propData.purchasingOption)}
          </Typography>
          <Typography style={{ marginRight: 16 }}>
            {propData.addressDetails.en}, {propData.cityName.en}, {propData.areaName.en}
          </Typography>
          <Box
            display={isMd ? "flex" : "block"}
            alignItems="center"
            justifyContent="space-between"
            style={{ marginRight: 16 }}
          >
            <Box display={"flex"} alignItems="center">
              <Box>
                <span className={classes.counter}>{propData.bedrooms}</span>
                <HotelIcon className={classes.icon} />
                <span className={classes.dot}>.</span>
              </Box>
              <Box>
                <span className={classes.counter}>{propData.bathrooms}</span>
                <BathtubIcon className={classes.icon} />
                <span className={classes.dot}>.</span>
              </Box>
              <Box>
                <span className={classes.counter}>{propData.parking}</span>
                <DirectionsCarIcon className={classes.icon} />
              </Box>
            </Box>
            <Box className={classes.space}>{propData?.space} Sqm</Box>
          </Box>
          <Typography className={classes.linkText}>
            {propData.price} {propData.purchasingOption !== "buy" ? `/ ${propData.rentalFrequency}` : ""}
          </Typography>
        </Box>
        <Box mt={isMd ? 4 : 0} style={{ display: isMd ? "block" : "flex", alignItems: "center" }}>
          <TextField
            type="date"
            variant="outlined"
            size="small"
            label="Start date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleChangeDate(e, "startDate")}
            style={{ marginBottom: 16, display: isMd ? "block" : "" }}
            className={isMd ? classes.textField : ""}
          />
          <TextField
            type="date"
            variant="outlined"
            size="small"
            label="End date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleChangeDate(e, "endDate")}
            style={{ marginBottom: 16, display: isMd ? "block" : "" }}
            className={isMd ? classes.textField : ""}
          />
          <Button variant="contained" color="primary" onClick={fetchPropertyEvents} disabled={disabled}>
            Filter
          </Button>
        </Box>
      </Box>

      <Box ml={1} style={{ display: "flex", alignItems: "center" }}>
        <RadioGroup
          aria-label="type"
          name="type"
          value={value}
          onChange={handleChange}
          style={{ flexDirection: "row" }}
        >
          <FormControlLabel value="total" control={<Radio />} label="Total" />
          <FormControlLabel value="uniqe" control={<Radio />} label="Uniqe" />
        </RadioGroup>
        <Box mx={2}>
          <StatsExport
            data={{
              views: [totalData[0], uniqueData[0]],
              visits: [totalData[1], uniqueData[1]],
              chat: [totalData[2], uniqueData[2]],
              whats: [totalData[3], uniqueData[3]],
              shares: [totalData[4], uniqueData[4]],
              calls: [totalData[5], uniqueData[5]],
            }}
          />
        </Box>
      </Box>

      <Bar
        style={{ maxHeight: "80vh" }}
        options={options}
        data={{
          labels: ["Views", "Visit", "Chat", "Whatsapp", "Shares", "Calls"],
          datasets: [
            {
              data: value === "total" ? totalData : uniqueData,
              backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
              borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
              borderWidth: 1,
            },
          ],
        }}
      />
    </>
  );
};

export default PropertyStats;

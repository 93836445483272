import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";

const areasResource = {
  [ResourcesKeys.Areas]: {
    [RequestKeys.GET_LIST]: async (queryData) => getFinalList(firestore.collection(ResourcesKeys.Areas), queryData),
  },
};

export default areasResource;

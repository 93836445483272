import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useCurrentUser from "../../../hooks/useCurrentUser";
import { notificationsCollectionRef } from "../../../utils/refrences.utils";
import { setHasNotification, syncNotification } from "../reducer";
import usePlayNotificationSound from "./usePlayNotificationSound";

const useNotificationListListener = ({
  canGetNotifications = false,
  prepareNotifications,
  notificationCounter = 0,
  playSound = false,
}) => {
  const dispatch = useDispatch();
  const { user } = useCurrentUser();

  const playNotification = usePlayNotificationSound();

  useEffect(() => {
    // set notification bullet
    dispatch(setHasNotification(notificationCounter));
  }, [notificationCounter]);

  useEffect(() => {
    let unsubscribeToListener;

    if (canGetNotifications && user.id) {
      unsubscribeToListener = notificationsCollectionRef
        .where("receivers", "array-contains", user.id)
        .where("type", "in", ["REQUEST", "LANDLORD", "REVIEW", "REQUESTAUTOREPLY"])
        .orderBy("createdAt", "desc")
        .limit(1)
        .onSnapshot({
          next: async (snapshot) => {
            if (!snapshot.empty) {
              for await (const change of snapshot.docChanges()) {
                if (change.type === "added") {
                  try {
                    const modifiedNotification = (await prepareNotifications(snapshot.docs))[0];
                    dispatch(
                      syncNotification({
                        notification: modifiedNotification,
                        error: null,
                      })
                    );

                    if (playSound) playNotification();
                  } catch (ex) {
                    console.log(ex.message);
                  }
                }
              }
            }
          },
          error(ex) {
            console.log(ex.message);
            dispatch(
              syncNotification({
                notification: null,
                error: ex.message,
              })
            );
          },
        });
    }

    return () => {
      if (unsubscribeToListener) unsubscribeToListener();
    };
  }, [canGetNotifications, user.id, dispatch, prepareNotifications]);
};

export default useNotificationListListener;

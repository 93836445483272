import { Box, Button, IconButton, makeStyles, Popover, Typography } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles({
  popover: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: 15,

    "& button": {
      marginTop: 10,
    },
  },
  active: {
    fill: "#1B2C24",
  },
  inActive: {
    fill: "#a1a1a1",
  },
});

const ConfirmationPopover = ({
  onConfirm,
  message,
  buttonText = null,
  buttonColor = "primary",
  buttonVariant = "contained",
  buttonStyle = {},
  confirmationText = "Confirm",
  confirmationColor = "primary",
  confirmationVariant = "contained",
  Icon = null,
  StartIcon = null,
}) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <div className={classes.popover}>
          <Typography>{message}</Typography>
          <Button
            color={confirmationColor}
            variant={confirmationVariant}
            size="small"
            onClick={(e) => {
              onConfirm();
              handleClose(e);
            }}
          >
            {confirmationText}
          </Button>
        </div>
      </Popover>

      <Box display="inline-block" onClick={handleClick}>
        {Icon ? (
          <IconButton>
            <Icon />
          </IconButton>
        ) : (
          <Button
            style={buttonStyle}
            color={buttonColor}
            variant={buttonVariant}
            size="small"
            startIcon={StartIcon ? <StartIcon /> : null}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </>
  );
};

export default ConfirmationPopover;

import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";

const reviewsResource = {
  [ResourcesKeys.Reviews]: {
    [RequestKeys.GET_LIST]: async (queryData) => {
      const clientId = queryData.filter.clientId;
      let clientRef, shouldFilterByClient;
      if (clientId) {
        shouldFilterByClient = true;
        const client = await firestore.collection(ResourcesKeys.Clients).doc(clientId).get();
        clientRef = client.exists && client.ref;
      }

      return getFinalList(firestore.collectionGroup(ResourcesKeys.Reviews), {
        filters: {
          client: queryData.filter.clientId,
        },
        fieldOverrides: {
          client: "client.displayName",
        },
        fieldValueOverrides: {
          client: shouldFilterByClient && clientRef,
        },
        limit: queryData.pagination.perPage,
      });
    },
  },
};

export default reviewsResource;

import { useState } from "react";

import { executeFormAction } from "./executeFormAction";

/**
 * this custom hook is used to update or create a document
 * `path` is the relevant firestore collection path ( will be used for storage to generate the uploaded files' path )
 * `operationFn` will be returned from this hook with the final data to send to firestore ( use it when submitting you form )
 * `type` whether create or update
 * */
export function useFormBaseHandler(path, operationFn, type, onUpdateDocument = () => {}) {
  const [submitError, setSubmitError] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  return {
    fireOperationFn: async (data) => {
      setSubmitLoading(true);

      try {
        await executeFormAction(path, data, operationFn, type);
        setSubmitLoading(false);
        setSubmitError(null);
        onUpdateDocument?.();
      } catch (ex) {
        console.log({ ex });
        setSubmitLoading(false);
        setSubmitError("Error on Submitting form");
      }
    },
    submitError,
    submitLoading,
  };
}

import { useEffect, useState } from "react";

import { ResourcesKeys, UserRoles } from "../enums";
import { firestore } from "../init/firebase";
import useCurrentUser from "./useCurrentUser";

export const prepareUserToken = (user) => {
  return user.permissions.role === UserRoles.propertyCompany
    ? firestore.collection(`${user.permissions.ref}/${ResourcesKeys.PropertiesCompaniesTokens}`).doc(ResourcesKeys.fcm)
    : firestore.collection(`${user.permissions.ref}/${ResourcesKeys.EmployeesTokens}`).doc(ResourcesKeys.fcm);
};

const useGetUserTokensDoc = () => {
  const { user } = useCurrentUser();
  const [tokensCollection, setTokensCollection] = useState(null);

  useEffect(() => {
    if (user.id && user.permissions.role !== "admin") {
      setTokensCollection(prepareUserToken(user));
    }
  }, [user]);

  return tokensCollection;
};

export default useGetUserTokensDoc;

const RequestKeys = {
  GET_LIST: "getList",
  GET_MANY: "getMany",
  GET_ONE: "getOne",
  CREATE: "create",
  UPDATE: "update",
  UPDATE_MANY: "updateMany",
  DELETE: "delete",
  DELETE_MANY: "deleteMany",
  GET_MANY_REFERENCE: "getManyReference",
};

export default RequestKeys;

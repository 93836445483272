import { Box, Typography } from "@material-ui/core";
import { get } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { from, of } from "rxjs";
import { catchError, concatMap, map, toArray } from "rxjs/operators";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import Loader from "../../../components/Loader";
import { employeesDocRef } from "../../../utils/refrences.utils";
import AssignProperty from "./actions/AssignProperty";

const PropertyAssignees = (props) => {
  const { record } = props;
  const [assignees, setAssignees] = useState([]);
  const [loading, setLoading] = useState(false);
  const roles = useSelector((state) => state.roles);

  const getAssignees = () => {
    return from(record.assignees).pipe(
      concatMap((employeeId) => {
        return from(employeesDocRef(record.companyId, employeeId).get()).pipe(map((snapshot) => snapshot.data()));
      }),
      toArray(),
      catchError((ex) => {
        console.log(ex.message);
        return of([]);
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    getAssignees().subscribe({
      next(data) {
        setAssignees(data);
        setLoading(false);
      },
    });
  }, [record.assignees]);

  return (
    <Loader loading={loading} type="linear">
      <AssignProperty
        selectedIds={[record.id]}
        variant="contained"
        ClickBtn={() => {
          return (
            <Box display="flex" alignItems="center" height={60}>
              <AddCircleOutlineIcon color="primary" />
              {assignees.length > 0 ? (
                <Box display="flex" flexDirection="column" justifyContent="center" mx={1}>
                  {assignees.map((assignee) => (
                    <Box display="flex" alignItems="center">
                      <Typography style={{ whiteSpace: "nowrap" }}>{assignee?.displayName}</Typography>
                      <Typography variant="caption" color="textSecondary" style={{ paddingLeft: 2 }}>
                        ({get([assignee?.role, "label", "en"])(roles)})
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Box>
          );
        }}
      />
    </Loader>
  );
};

export default PropertyAssignees;

import firebase from "firebase/compat/app";
import { cloneDeep } from "lodash/fp";

import { storageRef } from "../init/firebase";
import { fullQuery } from "./query.utils";

const total = Number.MAX_SAFE_INTEGER;

export function getDataFromFirebaseCollection(firebaseCollection, formatFn) {
  if (Array.isArray(firebaseCollection))
    return firebaseCollection
      .filter((doc) => doc)
      .map((doc) => {
        const proto = { snapshot: doc };
        const res = {
          ...doc.data(),
          id: doc.id,
          ...(formatFn ? formatFn(doc, doc.data()) : {}),
        };
        return Object.setPrototypeOf(res, proto);
      });
}

export function getUserPermissions(user) {
  return new Promise((resolve, reject) =>
    user
      .getIdTokenResult()
      .then((data) => resolve(data.claims))
      .catch((e) => reject(e))
  );
}

export function applyTimestamps(queryData) {
  const { data, isCreate, isUpdate, isLastSeen } = queryData || {};
  if (isCreate) {
    return {
      ...data,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }
  if (isUpdate) {
    return {
      ...data,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }
  if (isLastSeen) {
    return {
      ...data,
      lastSeen: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }
  return data;
}

export async function uploadImage(imageFile, blob) {
  const path = `chat/${new Date().getTime().toString()}`;
  const imageRef = storageRef.child(path);
  try {
    await imageRef.put(imageFile);
  } catch (e) {
    return;
  }
  const url = await imageRef.getDownloadURL();
  return {
    path,
    src: url,
  };
}

/**
 * @param filter {Record<string, any>} filter will contain some props that should be go outside that object
 * like `filters`, `operators` and we should move the rest props inside `filters` property
 * */
export const prepareQueryFilters = (filter) => {
  const clonedFilter = cloneDeep(filter);
  delete clonedFilter.filters;
  delete clonedFilter.operators;
  return {
    filters: {
      ...clonedFilter,
      ...filter.filters,
    },
    operators: filter.operators,
  };
};

export const getFinalList = async (query, queryData = {}) => {
  try {
    console.log(queryData);
    console.log(fullQuery(query)(queryData));
    const snapshot = await fullQuery(query)(queryData).get();
    const formattedProperties = getDataFromFirebaseCollection(snapshot.docs);
    return {
      data: formattedProperties,
      total,
    };
  } catch (ex) {
    console.log(`[${query.path}], ${ex.message}`);
    return {
      data: [],
      total,
    };
  }
};

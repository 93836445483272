import baseEmployee from "./base";
import sales from "./sales";
import supervisors from "./supervisors";
import admins from "./admins";
import { ResourcesKeys } from "../../../enums";

const employeesResource = {
  [ResourcesKeys.Admins]: {
    ...baseEmployee,
    ...admins,
  },
  [ResourcesKeys.Sales]: {
    ...baseEmployee,
    ...sales,
  },
  [ResourcesKeys.Supervisors]: {
    ...baseEmployee,
    ...supervisors,
  },
};

export default employeesResource;

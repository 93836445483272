import { useEffect, useState } from "react";

import { clientDocRef } from "../../../utils/refrences.utils";
import defaultImg from "../../../assets/default.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 75,
    height: 75,
    borderRadius: "50%",
    overflow: "hidden",
    boxShadow: "1px 1px 5px",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const ClientImage = (props) => {
  const [clientData, setClientData] = useState(null);
  const classes = useStyles(props);

  useEffect(() => {
    (async function () {
      const snapshot = await clientDocRef(props.record.clientId).get();
      setClientData(snapshot.data());
    })();
  }, []);

  return clientData ? (
    <div className={classes.container}>
      <img className={classes.img} src={clientData.photoUrl ?? defaultImg} alt={clientData.displayName} />
    </div>
  ) : null;
};

export default ClientImage;

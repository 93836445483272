import { call, put, takeEvery } from "redux-saga/effects";

import { rsf } from "../../init/firebase";
import { getCities } from "../actions/init";
import { ResourcesKeys } from "../../enums";
import { setCities } from "../reducers/cities";

function* _getCities() {
  const res = yield call(rsf.firestore.getCollection, ResourcesKeys.Cities);
  const cities = res.docs.reduce((docs, snapshot) => {
    return {
      ...docs,
      [snapshot.id]: snapshot.data(),
    };
  }, {});

  yield put(setCities(cities));
}

export function* watchGetCities() {
  yield takeEvery(getCities.toString(), _getCities);
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const paginationSlice = createSlice({
  name: "@@@Pagination",
  initialState,
  reducers: {
    setPaginationDirection(state, action) {
      return {
        ...state,
        [action.payload.resource]: {
          direction: action.payload.paginationDirection,
        },
      };
    },
  },
});

export const { setPaginationDirection } = paginationSlice.actions;
export default paginationSlice.reducer;

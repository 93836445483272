import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Admin } from "react-admin";

import { authProvider, providerWithOverrides } from "../init/firebase";
import Layout from "../components/Layout";
import theme from "../theme";
import LoginPage from "../components/Login";
import { UserRoles } from "../enums";
import { adminDashboard } from "./dashboards/admin";
import { companyDashboard } from "./dashboards/company";
import usePrepareInitialData from "../hooks/usePrepareInitialData";
import { supervisorDashboard } from "./dashboards/supervisor";
import { salesDashboard } from "./dashboards/sales";

const prepareData = (role, id) => {
  let customRoutes;
  let resources;
  let home;

  switch (role) {
    case UserRoles.admin:
      customRoutes = adminDashboard.customRoutes;
      resources = adminDashboard.resources;
      home = adminDashboard.home;
      break;
    case UserRoles.propertyCompany:
      // case UserRoles.propertyAdmin:
      // when the current user is a company setTimeStamp
      customRoutes = companyDashboard.customRoutes;
      resources = companyDashboard.resources;
      home = companyDashboard.home;

      // setTimeStampForCurrrentCompany( id );

      break;
    case UserRoles.propertySupervisor:
      customRoutes = supervisorDashboard.customRoutes;
      resources = supervisorDashboard.resources;
      home = supervisorDashboard.home;
      break;
    case UserRoles.propertySales:
      customRoutes = salesDashboard.customRoutes;
      resources = salesDashboard.resources;
      home = salesDashboard.home;
      break;
    case UserRoles.unknown:
    default:
      customRoutes = [];
      resources = [];
      home = null;
  }
  return {
    customRoutes,
    resources,
    home,
  };
};

export default function AdminController({ history }) {
  const [role, setRole] = useState(UserRoles.unknown);
  const user = useSelector((state) => state.user);

  usePrepareInitialData(role);

  useEffect(() => {
    setRole(user.permissions ? user.permissions.role : UserRoles.unknown);
  }, [user.id]);

  const { customRoutes, home } = prepareData(role, user.id);

  return (
    <Admin
      dataProvider={providerWithOverrides}
      authProvider={authProvider}
      history={history}
      layout={Layout}
      theme={theme}
      loginPage={LoginPage}
      customRoutes={customRoutes}
      dashboard={home}
    >
      {(permissions) => {
        const role = permissions.role;
        return prepareData(role, user.id).resources;
      }}
    </Admin>
  );
}

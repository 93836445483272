import { Box, Typography } from "@material-ui/core";
import {
  BooleanInput,
  Create,
  email,
  FormGroupContextProvider,
  FormWithRedirect,
  minLength,
  PasswordInput,
  required,
  TextInput,
} from "react-admin";

import { FormGroupNames, Statistics, UserRoles } from "../../enums";
import { isExists, phoneNumber } from "../../utils/validators";
import ImageUploadInput from "../../components/ImageUploadInput";
import CustomToolbar from "../../components/CustomToolbar";
import useDisabledEvents from "../../hooks/useDisabledEvents";
import FormWrapper from "../../components/FormWrapper";
import CustomCard from "../../components/CustomCard";
import useCurrentUser from "../../hooks/useCurrentUser";
import { serverTimestamp } from "../../utils/firebase.utils";
import { employeesCollPath } from "../../utils/paths.utils";
import useSharedStyles from "../../hooks/useSharedStyles";
import { redirectTo } from "./utils";
import PhonePrefixCode from "../../components/PhonePrefixCode";

const CustomForm = (props) => {
  const { styles } = useSharedStyles();
  const { disabledEvents } = useDisabledEvents();
  const { user } = useCurrentUser();
  const path = employeesCollPath(user.companyId);

  return (
    <FormWrapper>
      <FormGroupContextProvider name={FormGroupNames.employeesCreate}>
        <FormWithRedirect
          {...props}
          redirect={redirectTo(props.options.type)}
          render={(formProps) => {
            return (
              <form style={disabledEvents}>
                <CustomCard>
                  <Typography variant="h6">Contact Info</Typography>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>
                      <TextInput variant="outlined" source="displayName" validate={[required()]} />
                    </Box>
                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        source="email"
                        validate={[required(), email(), isExists("email", path)]}
                      />
                    </Box>
                  </Box>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>
                      <PasswordInput variant="outlined" source="password" validate={[required(), minLength(8)]} />
                    </Box>
                    <Box className={styles.rowElement}>
                      <TextInput
                        variant="outlined"
                        type="number"
                        source="phoneNumber"
                        validate={[required(), phoneNumber(), isExists("phoneNumber", path)]}
                        InputProps={{
                          startAdornment: <PhonePrefixCode />,
                        }}
                      />
                    </Box>
                  </Box>
                </CustomCard>
                <CustomCard>
                  <Typography variant="h6">Media</Typography>

                  <ImageUploadInput source="photo" label="Photo" validate={[required()]} />
                </CustomCard>
                <CustomCard>
                  <Typography variant="h6">Preference</Typography>
                  <BooleanInput source="disabled" defaultValue={false} />
                </CustomCard>

                <CustomToolbar
                  {...formProps}
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  formName={FormGroupNames.employeesCreate}
                />
              </form>
            );
          }}
        />
      </FormGroupContextProvider>
    </FormWrapper>
  );
};

export default function EmployeesCreate(props) {
  const prepareStatistics = (role) => {
    switch (role) {
      case UserRoles.propertyAdmin:
        return {
          [Statistics.requestsLastSeenAt]: serverTimestamp(),
          [Statistics.reviewsLastSeenAt]: serverTimestamp(),
          [Statistics.landlordsLastSeenAt]: serverTimestamp(),
        };
      case UserRoles.propertySupervisor:
      case UserRoles.propertySales:
        return {
          [Statistics.requestsLastSeenAt]: serverTimestamp(),
          [Statistics.reviewsLastSeenAt]: null,
          [Statistics.landlordsLastSeenAt]: null,
        };
      default:
        return {
          [Statistics.requestsLastSeenAt]: null,
          [Statistics.reviewsLastSeenAt]: null,
          [Statistics.landlordsLastSeenAt]: null,
        };
    }
  };

  return (
    <Create
      {...props}
      component="div"
      transform={(values) => {
        values.parentId = null;
        if (props.options.type === UserRoles.propertyAdmin) values.role = UserRoles.propertyAdmin;
        if (props.options.type === UserRoles.propertySupervisor) values.role = UserRoles.propertySupervisor;
        if (props.options.type === UserRoles.propertySales) values.role = UserRoles.propertySales;
        values.statistics = prepareStatistics(values.role);
        values.phoneNumber = `+974${values.phoneNumber}`;

        return values;
      }}
    >
      <CustomForm variant="outlined" {...props} />
    </Create>
  );
}

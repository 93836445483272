import { Button, useRefresh, useNotify, useUnselectAll } from "react-admin";
import CheckIcon from "@material-ui/icons/Check";
import { useState } from "react";
import { firestore } from "../../../init/firebase";
import { applyTimestamps } from "../../../utils/funcs";

const EnablePropertyCompanyButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [loading, setLoading] = useState();
  const disableProperty = async () => {
    try {
      setLoading(true);
      const batch = firestore.batch();
      Array.isArray(selectedIds) &&
        selectedIds.forEach((id) => {
          batch.update(
            firestore.collection("propertiesCompanies").doc(id),
            applyTimestamps({
              data: {
                disabled: false,
              },
              isUpdate: true,
            })
          );
        });
      await batch.commit();
      notify("Companies have been enabled", "success");
      unselectAll("propertiesCompanies");
      refresh();
      setLoading(false);
    } catch (ex) {
      console.log(ex.message);
      notify("Error on enabling companies", "error");
      refresh();
      setLoading(false);
    }
  };

  return (
    <Button label="Enable" disabled={loading} onClick={disableProperty}>
      <CheckIcon />
    </Button>
  );
};

export default EnablePropertyCompanyButton;

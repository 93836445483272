import { useMediaQuery } from "@material-ui/core";
import { BooleanField, CreateButton, Datagrid, List, NumberField, SimpleList, TextField } from "react-admin";
import ListImage from "../../components/ListImage";
import TimestampField from "../../components/TimestampField";
import useSharedStyles from "../../hooks/useSharedStyles";

const CustomFieldToDisplaySec = ({ record }) => {
  return <span>{record.period} second(s).</span>;
};

export default function AdsList(props) {
  const mdScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { styles } = useSharedStyles();

  return (
    <>
      <List actions={<CreateButton />} {...props} sort={{ field: "createdAt", order: "DESC" }}>
        {mdScreen ? (
          <>
            <SimpleList
              primaryText={(r) => (
                <h4>
                  {" "}
                  {r.type ? r.type : "URL"} <span style={{ marginLeft: "15px" }}> {r.id} </span>{" "}
                </h4>
              )}
              secondaryText={(r) => (
                <img style={{ width: "60px", height: "60px", borderRadius: "50%" }} src={r.image.src} alt="logo" />
              )}
              tertiaryText={(r) => <h6>Period: {r.period} seconds</h6>}
            />
          </>
        ) : (
          <Datagrid>
            <ListImage source="image.src" sortable={false} label="Image" />

            <TextField label="Type" sortable={false} source="type" />
            <TextField label="Content ID" sortable={false} source="contentId" />
            {/* <NumberField sortable={false} source="period" label="Period" /> */}
            <CustomFieldToDisplaySec sortable={false} source="period" label="Period" />

            <TimestampField source="createdAt" sortable={true} label="Created At" />
            <TimestampField source="expiryDate" sortable={true} label="Expiry Date" />
            <BooleanField sortable={false} source="expired" label="Expired" />
          </Datagrid>
        )}
      </List>
    </>
  );
}

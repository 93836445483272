import { memo, useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Box, CircularProgress, TextField } from "@material-ui/core";

import appConfig from "../../../init/config";

const DOHA_CENTER_COORDINATES = {
  lat: 25.279222,
  lng: 51.233224,
};

const QATAR_BOUNDS = {
  south: 24.4711180214628,
  west: 50.72110005967814,
  north: 26.21710000349892,
  east: 51.71440006361076,
};

export const MapLocation = memo(
  ({ onLocationChange, defaultLocation = null, readOnly = false, enableUrl = false, disabled = false }) => {
    const [map, setMap] = useState(null);
    const [markerLocation, setMarkerLocation] = useState(null);
    const [loadingUrl, setLoadingUrl] = useState(false);
    const locationUrlTimerRef = useRef(undefined);

    const { isLoaded } = useJsApiLoader({
      id: "property_filter_map",
      googleMapsApiKey: appConfig.googleMapsApiKey,
    });

    const onLoad = useCallback(function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      map.fitBounds(bounds);
      setMap(map);
    }, []);

    const onUnmount = useCallback(function callback() {
      setMap(null);
    }, []);

    const onDragEnd = (mapMouseEvent) => {
      const lat = mapMouseEvent.latLng.lat();
      const lng = mapMouseEvent.latLng.lng();
      setMarkerLocation({ lat, lng });
      onLocationChange({ lat, lng });
    };

    const onLocationUrlChange = (e) => {
      if (e.target.value.trim()) {
        if (locationUrlTimerRef.current) clearTimeout(locationUrlTimerRef.current);
        locationUrlTimerRef.current = setTimeout(async () => {
          try {
            setLoadingUrl(true);
            const response = await fetch(`${appConfig.cloudFunctionsExternalUrl}/getLatLng?url=${e.target.value}`);
            const data = await response.json();
            setMarkerLocation(data);
            onLocationChange(data);
            setLoadingUrl(false);
          } catch (ex) {
            console.log(ex.message);
            setLoadingUrl(false);
          }
        }, 500);
      }
    };

    useEffect(() => {
      if (defaultLocation && defaultLocation.lat && defaultLocation.lng)
        setMarkerLocation({
          lat: defaultLocation.lat,
          lng: defaultLocation.lng,
        });
    }, [defaultLocation]);

    useEffect(() => {
      return () => {
        if (locationUrlTimerRef.current) clearTimeout(locationUrlTimerRef.current);
      };
    }, []);

    return isLoaded ? (
      <Box
        style={{
          height: "inherit",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {enableUrl ? (
          <TextField
            onChange={onLocationUrlChange}
            label="Location URL"
            disabled={disabled}
            InputProps={{
              endAdornment: loadingUrl ? <CircularProgress size={20} thickness={6} /> : null,
            }}
            style={{ margin: "8px 0px 16px 0px" }}
            variant="outlined"
            fullWidth
            size="small"
          />
        ) : null}

        <GoogleMap
          mapContainerStyle={{
            flex: 1,
            height: "100%",
            width: "100%",
            opacity: disabled ? 0.5 : 1,
          }}
          center={DOHA_CENTER_COORDINATES}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            restriction: {
              latLngBounds: QATAR_BOUNDS,
            },
          }}
        >
          <Marker
            position={markerLocation ?? DOHA_CENTER_COORDINATES}
            draggable={!disabled && !readOnly}
            onDragEnd={onDragEnd}
          />
        </GoogleMap>
      </Box>
    ) : (
      <></>
    );
  }
);

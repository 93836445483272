import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../init/firebase";
import { applyTimestamps } from "../../../utils/funcs";
import { getInterval } from "../../propertiesCompanies/utils";

export async function setTimeStampForCurrrentCompany(id) {
  // console.log("id" , id);

  const timeFreq = await getInterval().then((time) => time);

  updateLastSeen(id);

  const intervalId = setInterval(() => {
    if (sessionStorage.getItem("started") != null) {
      // console.log("user resend in interval");
      updateLastSeen(id);
    } else {
      return;
    }
  }, timeFreq * 1000 * 60);

  sessionStorage.setItem("crrIntervalId", JSON.stringify(intervalId));
}

async function updateLastSeen(id) {
  try {
    const d = new Date();

    const docRef = doc(firestore, "propertiesCompanies", id);

    const xx = await getDoc(docRef);

    // console.log( "xx" , xx.data() );
    const letMeTest = applyTimestamps({
      data: xx.data(),

      isLastSeen: true,
    });
    // console.log("letMeTest", letMeTest);

    const updatedDoc = await updateDoc(docRef, letMeTest);

    // console.log("updatedDoc", updatedDoc);
  } catch (e) {
    console.log("Error in sending timestamp to current user login", e);
  }
}

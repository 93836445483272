import { useEffect, useState } from "react";
import { useGetPermissions } from "react-admin";

import { UserRoles } from "../enums";
import useCurrentUser from "./useCurrentUser";

/**
 * this hook won't work outside react admin 'Admin' component
 * will return 'undefined', so use it inside
 * */
const useCurrentRole = () => {
  const { user } = useCurrentUser();
  const [role, setRole] = useState(user.permissions && user.permissions.role);
  const res = useGetPermissions();

  useEffect(() => {
    (async function () {
      const permissions = await res();
      const role = permissions && permissions.role;
      setRole(role || UserRoles.unknown);
    })();
  }, []);

  return { role };
};

export default useCurrentRole;

import { Box, IconButton, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendIcon from "@material-ui/icons/Send";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import ImageUploading from "react-images-uploading";

import Loader from "../../components/Loader";
import { uploadImage } from "../../utils/funcs";
import { stringHasContent } from "../../utils/validators";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  input: {
    "&.MuiFormControl-root": {
      "& .MuiInputBase-input, & .MuiInputAdornment-root": {
        zIndex: 1,
      },
      "& fieldset": {
        border: "1px solid #E9E9E9",
        background: "#f6f6f6",
        borderRadius: "30px",
        color: "#323232",
      },
    },
  },
}));

export default function ChatInput({ onSend, loading, onAttach, onLoading, disabled, propertyError, propertyLoading }) {
  const classes = useStyles({});
  const [text, setText] = useState("");

  const onChange = async (imageList) => {
    onLoading();
    const res = await uploadImage(imageList[0].file, imageList[0].dataUrl);
    if (res) onAttach(res);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (text && !stringHasContent()(text)) {
      setText("");
      onSend(text);
    }
  };

  const handleSendMessageOnEnter = (e) => {
    if (e.which === 13 && !e.shiftKey) handleSendMessage(e);
  };

  return (
    <Box className={classes.root}>
      {propertyLoading ? null : propertyError && !loading ? (
        <Alert severity="warning">{propertyError}</Alert>
      ) : (
        <ImageUploading onChange={onChange} dataURLKey="dataUrl">
          {({ onImageUpload }) => (
            <TextField
              className={classes.input}
              multiline
              rowsMax={5}
              value={text}
              onChange={(e) => {
                if (e.target.value.length < 1001) setText(e.target.value);
              }}
              onKeyPress={handleSendMessageOnEnter}
              variant="outlined"
              disabled={disabled}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!loading && (
                      <IconButton edge="end" disabled={loading || disabled} onClick={onImageUpload}>
                        <AttachFileIcon />
                      </IconButton>
                    )}
                    <IconButton edge="end" type="submit" onClick={handleSendMessage} disabled={loading || disabled}>
                      <Loader loading={loading} size={30}>
                        <SendIcon />
                      </Loader>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={text.length === 1000 && "Can't exceed 1000 letter"}
              FormHelperTextProps={{
                style: {
                  color: "red",
                },
              }}
            />
          )}
        </ImageUploading>
      )}
    </Box>
  );
}

import { Box, CircularProgress } from "@material-ui/core";

const Loader = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress size={20} color={"primary"} />
    </Box>
  );
};

export default Loader;

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { difference, forEach, inRange } from "lodash/fp";

const propTypeCompare = {
  arrayWithNumber: 1,
  arrayWithString: 2,
  rangeWithNumber: 3,
  stringWithString: 4,
  arrayWithArray: 5,
};

const defaultProps = {
  bedrooms: propTypeCompare.arrayWithNumber,
  bathrooms: propTypeCompare.arrayWithNumber,
  furnishing: propTypeCompare.arrayWithString,
  price: propTypeCompare.rangeWithNumber,
  space: propTypeCompare.rangeWithNumber,
  rentalFrequency: propTypeCompare.stringWithString,
  amenities: propTypeCompare.arrayWithArray,
};

export const getComparableProps = (request) => {
  const comparableProps = [];

  forEach((key) => {
    if (request[key] && key in defaultProps) {
      if (Array.isArray(request[key]) && request[key].length === 0) return;
      comparableProps.push(key);
    }
  })(Object.keys(request));

  return comparableProps;
};

export const getMatchPercent = (property, request) => {
  if (property) {
    const props = getComparableProps(request);
    let total = 50;
    const elPercent = 50 / props.length;

    props.forEach((prop) => {
      if (request[prop]) {
        if (defaultProps[prop] === propTypeCompare.stringWithString) {
          if (property[prop]?.toLowerCase() === request[prop]?.toLowerCase()) {
            total += elPercent;
          }
        }
        if (defaultProps[prop] === propTypeCompare.rangeWithNumber) {
          if (inRange(request[prop][0], request[prop][1])(property[prop])) {
            total += elPercent;
          }
        }
        if (
          defaultProps[prop] === propTypeCompare.arrayWithString ||
          defaultProps[prop] === propTypeCompare.arrayWithNumber
        ) {
          if (request[prop].includes(property[prop])) {
            total += elPercent;
          }
        }
        if (defaultProps[prop] === propTypeCompare.arrayWithArray) {
          if (difference(property[prop])(request[prop]).length === 0) {
            total += elPercent;
          }
        }
      }
    });
    return {
      ...property,
      matching: Math.round(total).toFixed(2),
    };
  }
};

export const getTableCols = () => [
  {
    field: "matching",
    headerName: "Matching",
    width: 120,
    disableColumnMenu: true,
    renderCell: (props) => {
      return <p>{props.value}%</p>;
    },
  },
  {
    field: "sent",
    sortable: false,
    headerName: "Sent",
    width: 100,
    disableColumnMenu: true,
    renderCell: (props) => {
      return (
        <span style={{ lineHeight: "3px" }}>
          {props.value ? <DoneIcon color="primary" /> : <CloseIcon color="error" />}
        </span>
      );
    },
  },
  {
    field: "sentBy",
    sortable: false,
    headerName: "Sent By",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "category",
    sortable: false,
    headerName: "Category",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "type",
    sortable: false,
    headerName: "Type",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "purchasingOption",
    sortable: false,
    headerName: "Purchasing O",
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: "rentalFrequency",
    sortable: false,
    headerName: "RF",
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: "city",
    sortable: false,
    headerName: "City",
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: "area",
    sortable: false,
    headerName: "Area",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "price",
    sortable: false,
    headerName: "Price",
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: "space",
    sortable: false,
    headerName: "Space",
    width: 90,
    disableColumnMenu: true,
  },
  {
    field: "bedrooms",
    sortable: false,
    headerName: "Bedrooms",
    width: 90,
    disableColumnMenu: true,
  },
  {
    field: "bathrooms",
    sortable: false,
    headerName: "Bathrooms",
    width: 90,
    disableColumnMenu: true,
  },
  {
    field: "replyType",
    sortable: false,
    headerName: "Reply Type",
    width: 150,
    disableColumnMenu: true,
    renderCell: (props) => {
      return (
        <span style={{ lineHeight: "3px" }}>
          {props.value === "MANUALREPLY" ? "Manual Reply" : props.value ? "Auto Reply" : ""}
        </span>
      );
    },
  },
];

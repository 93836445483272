import { useMediaQuery, useTheme } from "@material-ui/core";
import { Layout as RALayout } from "react-admin";

import Menu from "./Menu";
import AppBar from "./AppBar";

const Layout = (props) => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const theme = useTheme();
  return (
    <RALayout
      {...props}
      style={{
        marginTop: isSm ? 0 : theme.spacing(3),
      }}
      menu={Menu}
      appBar={AppBar}
    />
  );
};

export default Layout;

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { assocPath, clone } from "ramda";

import { deleteFiles } from "./deleteFiles";

export const fileUploader = async ({ fileUploaderData: { uploads, originalData, path }, storage }) => {
  const uploadedRefs = {};
  // let clonedOriginalData = clone(originalData);
  let clonedOriginalData = originalData;

  for (const upload of uploads) {
    const fileRef = ref(storage, `${path}/${upload.fileData.path}`);
    const splitPath = upload.fieldPath.split(".").map((segment) => (isNaN(+segment) ? segment : +segment));

    try {
      const result = await uploadBytes(fileRef, upload.fileData.rawFile);
      const downloadUrl = await getDownloadURL(result.ref);

      // replace uploaded file with its metadata according to it's path
      clonedOriginalData = assocPath(
        splitPath,
        {
          src: downloadUrl,
          path: result.metadata.fullPath,
        },
        clonedOriginalData
      );

      uploadedRefs[upload.fieldPath] = result.ref;
    } catch (ex) {
      console.log(ex.message);
      clonedOriginalData = originalData;
      await deleteFiles({ fileRefs: uploadedRefs });
      throw new Error(ex);
    }
  }

  return {
    dataWithUploadedFiles: clonedOriginalData,
    uploadedRefs,
  };
};

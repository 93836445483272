import { useEffect, useState } from "react";

import useCurrentUser from "../../../hooks/useCurrentUser";
import { applyTimestamps } from "../../../utils/funcs";
import useCheckAgentType from "../../../hooks/useCheckAgentType";
import useGetUserTokensDoc from "../../../hooks/useGetUserTokensDoc";

const useGetToken = (canReceiveNotification) => {
  const [fcmToken, setFcmToken] = useState(null);

  const { user } = useCurrentUser();
  const { isWeb } = useCheckAgentType();
  const userTokensDoc = useGetUserTokensDoc();

  useEffect(() => {
    if (user.webviewFcmToken) {
      setFcmToken(user.webviewFcmToken);
    } else {
      // todo - enable browser getting fcm token if needed
      // if (isWeb) {
      //   console.log("Am a browser and am gonna get my own fcm token");
      //   getToken(setFcmToken);
      // }
    }
  }, [isWeb, user]);

  useEffect(() => {
    if (canReceiveNotification && user.id && fcmToken && userTokensDoc) {
      userTokensDoc
        .set(
          applyTimestamps({
            data: {
              fcmToken,
            },
            isCreate: true,
          })
        )
        .catch((ex) => {
          console.log(ex.message);
        });
    }
  }, [canReceiveNotification, user.id, fcmToken, userTokensDoc]);
};

export default useGetToken;

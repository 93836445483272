import { maxLength, minLength, required } from "react-admin";

import useLocalizedRAInputs from "../../../hooks/useLocalizedRAInputs";
import { Locals } from "../../../enums";
import { stringHasContent } from "../../../utils/validators";

export const useUserGuideLocalizations = (props) => {
  return useLocalizedRAInputs([
    {
      key: "title",
      label: "Title",
      validate: {
        [Locals[0].key]: [required(), stringHasContent(), minLength(3), maxLength(60)],
        [Locals[1].key]: [required(), stringHasContent(), minLength(3), maxLength(60)],
      },
      ...props,
    },
    {
      key: "description",
      label: "Description",
      validate: {
        [Locals[0].key]: [required(), stringHasContent(), minLength(3), maxLength(250)],
        [Locals[1].key]: [required(), stringHasContent(), minLength(3), maxLength(250)],
      },
      multiline: true,
      counter: 250,
      ...props,
    },
  ]);
};

import { Datagrid, List, TextField } from "react-admin";

import ShowFullTextOnHover from "../../components/ShowFullTextOnHover";
import TablePagination from "../../components/TablePagination";
import AnnouncementsType from "./components/AnnouncementsType";
import TimestampField from "../../components/TimestampField";

const AnnouncementsList = (props) => {
  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "desc" }}
      pagination={<TablePagination />}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid>
        <TextField source="title" sortable={false} />
        <ShowFullTextOnHover source="message" label="Message" sortable={false} />
        <AnnouncementsType source="types" label="Type" sortable={false} />
        <TimestampField source="createdAt" label="Created At" />
      </Datagrid>
    </List>
  );
};

export default AnnouncementsList;

import { call, put, takeEvery } from "redux-saga/effects";
import { ResourcesKeys } from "../../enums";

import { firestore, rsf } from "../../init/firebase";
import { getEnumsAction } from "../actions/init";
import { setEnums } from "../reducers/enums";

function* getEnums() {
  try {
    const snapshot = yield call(rsf.firestore.getCollection, firestore.collection(ResourcesKeys.Enums));
    const enums = snapshot.docs.reduce((all, doc) => {
      return {
        ...all,
        [doc.id]: doc.data(),
      };
    }, {});
    yield put(setEnums(enums));
  } catch (ex) {
    console.log(ex.message);
  }
}

export default function* watchGetEnums() {
  yield takeEvery(getEnumsAction.toString(), getEnums);
}

import { useEffect } from "react";
import { Box, Button, Card, Toolbar, Typography, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Show } from "react-admin";
import { from } from "rxjs";
import { catchError } from "rxjs/operators";

import { applyTimestamps } from "../../utils/funcs";
import useCurrentUser from "../../hooks/useCurrentUser";
import { requestsSeenByDocRef, requestsSeenBySubRef } from "../../utils/refrences.utils";
import RequestFields from "./components/RequestFields";

const RequestPreview = (props) => {
  const router = useHistory();
  const { user } = useCurrentUser();
  const requestId = props.id;
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const markRequestAsSeen = (reqId, userId) => {
    const seenByDocId = `${reqId}_${userId}`;
    const seenByRef = requestsSeenBySubRef(requestId);
    const seenByDocRef = requestsSeenByDocRef(requestId, seenByDocId);

    return from(seenByDocRef.get()).pipe(
      catchError((ex) => {
        /**
         * here we assume that any permission denied represents that the document is not found
         * so we can move on and create a new one ( based on backend modeling )
         * */
        console.log(ex.message);
        const data = applyTimestamps({
          data: {
            companyId: user.id,
          },
          isCreate: true,
        });
        return from(seenByRef.doc(seenByDocId).set(data)).pipe(
          catchError((ex) => {
            console.log(ex.message);
          })
        );
      })
    );
  };

  useEffect(() => {
    markRequestAsSeen(requestId, user.id).subscribe();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  return (
    <section>
      <Box display="flex" justifyContent="flex-end" mt={isSm ? "24px" : ""}>
        <Toolbar>
          <Button
            variant="contained"
            color="primary"
            disabled={!props.id}
            onClick={() => router.push(`/propertiesRequests/${props.id}/matches`)}
          >
            Show Matches
          </Button>
        </Toolbar>
      </Box>
      <Card>
        <Box p={2}>
          <Typography>Request Preview</Typography>
        </Box>
        <Show {...props}>
          <RequestFields />
        </Show>
      </Card>
    </section>
  );
};

export default RequestPreview;

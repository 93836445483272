import { Route } from "react-router-dom";
import { Resource } from "react-admin";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import StoreIcon from "@material-ui/icons/Store";
import RateReviewIcon from "@material-ui/icons/RateReview";
import PeopleIcon from "@material-ui/icons/People";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import HomeIcon from "@material-ui/icons/Home";

import { PropertiesCreate, PropertiesEdit, PropertiesList } from "../../companyProperties";
import { PropertiesRequestsList, RequestMatches, RequestPreview } from "../../propertiesRequests";
import TenantsList from "../../tenants/List";
import { CompanyReviewsEdit, CompanyReviewsList } from "../../companyReviews";
import { EmployeeList, EmployeesCreate, EmployeesEdit } from "../../employees";
import { ResourcesKeys, UserRoles } from "../../../enums";
import PropertyChat from "../../chat/PropertyChat";
import CompanyChat from "../../chat/CompanyChat";
import ReplyChat from "../../chat/ReplyChat";
import ReplyPreview from "../../propertiesRequests/ReplyPreview";
import CompanyHome from "./Home";
import PropertiesXML from "../../PropertiesXML/PropertiesXML";
import FullNotificationList from "../../NotificationsListInitialization/FullNotificationList";
import Charts from "../../Charts";
import PropertyStats from "../../companyProperties/components/PropertyStats";
import ChatWithSingleUser from "../../chat/ChatWithSingleClient";
import CompanyProfileNew from "../../propertiesCompanies/components/CompanyProfile.new";

export const companyDashboard = {
  resources: [
    <Resource
      name={"home"}
      list={Charts}
      options={{
        label: "Leads Statistics",
      }}
      icon={HomeIcon}
    />,
    <Resource name={ResourcesKeys.Clients} />,
    <Resource name={ResourcesKeys.Cities} />,
    <Resource name={ResourcesKeys.Areas} />,
    <Resource name={ResourcesKeys.PropertiesCompanies} />,

    <Resource
      name={ResourcesKeys.CompanyProperties}
      list={PropertiesList}
      create={PropertiesCreate}
      edit={PropertiesEdit}
      options={{
        label: "Properties",
      }}
      icon={HomeWorkIcon}
    />,
    <Resource
      name={ResourcesKeys.PropertiesRequests}
      list={PropertiesRequestsList}
      show={RequestPreview}
      options={{
        label: "Requests",
      }}
      icon={AssignmentLateIcon}
    />,
    <Resource
      name={ResourcesKeys.Tenants}
      list={TenantsList}
      options={{
        label: "Tenants",
      }}
      icon={StoreIcon}
    />,
    <Resource
      name={ResourcesKeys.CompanyReviews}
      list={CompanyReviewsList}
      edit={CompanyReviewsEdit}
      options={{
        label: "Reviews",
      }}
      icon={RateReviewIcon}
    />,
    <Resource
      name={ResourcesKeys.Admins}
      list={EmployeeList}
      create={EmployeesCreate}
      edit={EmployeesEdit}
      options={{
        label: "Admins",
        type: UserRoles.propertyAdmin,
      }}
      icon={PeopleIcon}
    />,
    <Resource
      name={ResourcesKeys.Supervisors}
      list={EmployeeList}
      create={EmployeesCreate}
      edit={EmployeesEdit}
      options={{
        label: "Supervisors",
        type: UserRoles.propertySupervisor,
      }}
      icon={SupervisorAccountIcon}
    />,
    <Resource
      name={ResourcesKeys.Sales}
      list={EmployeeList}
      create={EmployeesCreate}
      edit={EmployeesEdit}
      options={{
        label: "Sales",
        type: UserRoles.propertySales,
      }}
      icon={PeopleAltIcon}
    />,
  ],
  customRoutes: [
    <Route exact path={`/${ResourcesKeys.PropertiesRequests}/:requestId/matches`} component={RequestMatches} />,
    <Route exact path={`/${ResourcesKeys.Reply}/:replyId`} component={ReplyPreview} />,
    <Route exact path="/propertyChat" component={PropertyChat} />,
    <Route exact path="/companyChat" component={CompanyChat} />,
    <Route exact path="/replyChat" component={ReplyChat} />,
    <Route exact path="/propertiesXML" component={PropertiesXML} />,
    <Route exact path="/notifications" component={FullNotificationList} />,
    <Route exact path={`/${ResourcesKeys.CompanyProperties}/:propertyId/statistics`} component={PropertyStats} />,
    <Route exact path={`/${ResourcesKeys.Profile}`} component={CompanyProfileNew} />,

    <Route exact path={`/${ResourcesKeys.chatWithSingleClient}/:clientId`} component={ChatWithSingleUser} />,
  ],
  home: () => <CompanyHome redirectEnabled />,
  // home: CompanyHome,
};

import { memo, useCallback } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { SaveButton, Toolbar, useFormGroup, useTranslate } from "react-admin";
import { isEmpty, uniq } from "lodash/fp";

import ErrorMessage from "./ErrorMessage";
import { areEqual, upperCaseFirstLetter } from "../utils/general";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
    backgroundColor: "transparent",
    "@media (max-width: 767px)": {
      position: "static",
    },
  },
}));

const isRAI18Text = (text) => text.startsWith("ra.");

const handleRAI18Error = (error, translate) => {
  const { field, message } = error;
  const splitField = field.split(".");
  if (splitField.length > 0) {
    return `${splitField[0]} ${translate(message)}`;
  }
  return `${field} ${translate(message)}`;
};

const handleObjectError = (error, translate) => {
  const { field, message, args } = error;
  const translatedMessage = translate(message);
  const result = /%{.*}/.exec(translatedMessage);
  if (!isEmpty(result)) {
    const fieldToReplace = result[0].slice(2, -1);
    return `${field} ${translatedMessage.replace(/%{.*}/, args[fieldToReplace])}`;
  }
};

const CustomToolbar = (props) => {
  const { invalid, pristine, isCreate, formName } = props;
  const classes = useStyles(props);
  const translate = useTranslate();
  const state = useFormGroup(formName);

  const getErrorMessages = useCallback((errors) => {
    return !isEmpty(errors)
      ? uniq(
          Object.keys(errors).map((key) => {
            const error = errors[key];
            return typeof error === "object"
              ? upperCaseFirstLetter(handleObjectError({ ...error, field: key }, translate))
              : typeof error === "string" && isRAI18Text(error)
              ? upperCaseFirstLetter(handleRAI18Error({ field: key, message: error }, translate))
              : upperCaseFirstLetter(error.toString());
          })
        )
      : [];
  }, []);

  const validateErrors = () => {
    if (props.errors) {
      const checkisValid = (value) => {
        return !value.length;
      };
      const isValid = Object.values(props.errors).every(checkisValid);
      return isValid;
    }
    return true;
  };

  const isInvalid = () => invalid || !validateErrors(props.errors);

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton disabled={isInvalid()} onClick={props.handleSubmit} />

      {isInvalid() && !pristine ? (
        <Box>
          {isCreate ? (
            <ErrorMessage text="Check all required fields marked with *" />
          ) : (
            getErrorMessages({ ...state.errors, ...props.errors }).map((message) => (
              <ErrorMessage key={message} text={message} />
            ))
          )}
        </Box>
      ) : null}
    </Toolbar>
  );
};

export default memo(CustomToolbar, areEqual);

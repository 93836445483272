import TextInputWithCounter from "../components/TextInputWithCounter";
import { Locals } from "../enums";

export default function useLocalizedRAInputs(fields) {
  if (!Array.isArray(fields)) return null;

  const resultingElements = [];
  fields.forEach(({ key, label, validate, multiline, counter, ...props }) => {
    Locals.forEach((locale) => {
      resultingElements.push(
        <TextInputWithCounter
          {...props}
          key={`${key}.${locale.key}`}
          source={`${key}.${locale.key}`}
          label={`${label} (${locale.key})`}
          validate={validate[locale.key]}
          counter={multiline ? counter : 0}
          multiline={multiline}
        />
      );
    });
  });
  return resultingElements;
}

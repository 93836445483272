import { useEffect, useRef } from "react";

const useDidUpdate = (callback, deps = []) => {
  const isInitialMount = useRef(false);

  useEffect(() => {
    if (!isInitialMount.current) {
      isInitialMount.current = true;
    } else {
      callback();
    }
  }, deps);
};

export default useDidUpdate;

import { useSelector } from "react-redux";

const useGetEnumsAsValue = (enumEntry) => {
  const enums = useSelector((state) => state.enums);
  return (id, lang = "en") => {
    if (!id) return undefined;

    return enums[enumEntry][id.trim()]?.label[lang];
  };
};

export default useGetEnumsAsValue;

import { difference, inRange, max } from "lodash/fp";
import { from, of } from "rxjs";
import { map, takeLast, catchError, reduce } from "rxjs/operators";

/**
 * @param data {{
 *    request: Record<string, value>,
 *    property: Record<string, value>,
 *    rangeFields: string[],
 *    result: Record<string, vallue>,
 *    helpers: Record<string, Func>
 * }}
 */
const compareTwoStrings = (data) => {
  const { request, property, field } = data;
  return {
    matching: property[field].toLowerCase() === request[field].toLowerCase(),
    name: data.helpers?.formatName?.(field) ?? field,
  };
};

const compareTwoArrays = (data) => {
  const { request, property, field } = data;
  return {
    matching: difference(request[field])(property[field]).length === 0,
    name: data.helpers?.formatName?.(field) ?? field,
  };
};

const compareArrayWithString = (data) => {
  const { request, property, field } = data;
  return {
    matching: !request[field].length || request[field].includes(property[field]) || property[field] === "any",
    name: data.helpers?.formatName?.(field) ?? field,
  };
};

const compareArrayWithNumber = (data) => {
  const { request, property, field, rangeFields } = data;
  return rangeFields.includes(field)
    ? {
        matching: inRange(request[field][0], request[field][1])(property[field]),
        name: data.helpers?.formatName?.(field) ?? field,
      }
    : {
        matching: max(request[field]) < property[field] || request[field]?.includes?.(property[field]),
        name: data.helpers?.formatName?.(field) ?? field,
      };
};

const matchOptionalValues = (data) => {
  const { field } = data;
  return {
    matching: true,
    name: data.helpers?.formatName?.(field) ?? field,
  };
};

const extractResult = (data) => data.result;
const getMatchingData = (data, compareFunc) => compareFunc?.(data);

const getProperComparisonFunc = (field, request, property) => {
  return (!request[field] && property[field]) ||
    (request[field] && !property[field]) ||
    (!request[field] && !property[field])
    ? matchOptionalValues
    : typeof request[field] === "string" && typeof property[field] === "string"
    ? compareTwoStrings
    : Array.isArray(request[field]) && (Array.isArray(property[field]) || property[field] === "any")
    ? compareTwoArrays
    : Array.isArray(request[field]) && typeof property[field] === "string"
    ? compareArrayWithString
    : Array.isArray(request[field]) && typeof property[field] === "number"
    ? compareArrayWithNumber
    : undefined;
};

export const matchersy$ = (fields, data) => {
  return from(fields).pipe(
    reduce((acc, field) => {
      return {
        ...acc,
        result: {
          ...acc.result,
          [field]: getMatchingData({ ...data, field }, getProperComparisonFunc(field, data.request, data.property)),
        },
      };
    }, data),
    takeLast(),
    map(extractResult),
    catchError((error) => {
      console.log(error);
      return of({});
    })
  );
};

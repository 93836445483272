const ResourcesKeys = {
  Clients: "clients",
  Properties: "properties",
  PropertiesCompanies: "propertiesCompanies",
  PropertiesRequests: "propertiesRequests",
  CompanyProperties: "companyProperties",
  Cities: "cities",
  Areas: "areas",
  Packages: "packages",
  Reviews: "reviews",
  CompanyReviews: "companyReviews",
  Landlords: "landlords",
  Tenants: "tenants",
  Settings: "settings",
  UserGuide: "userGuides",
  Profile: "profile",
  PropertyChat: "propertyChat",
  CompanyChat: "companyChat",
  ReplyChat: "replyChat",
  Statistics: "statistics",
  CompanyStatistics: "companiesStatistics",
  SeenBy: "propertiesRequestsSeenBy",
  Reply: "propertiesRequestsReplies",
  Rooms: "rooms",
  Message: "messages",
  Enums: "enums",
  Employees: "employees",
  Sales: "sales",
  Supervisors: "supervisors",
  Admins: "admins",
  Login: "login",
  Roles: "roles",
  propertiesXml: "propertiesXml",
  notifications: "notifications",
  announcements: "announcements",
  fcm: "fcm",
  propertiesCompaniesTokens: "propertiesCompaniesTokens",
  employeesTokens: "employeesTokens",
  PropertiesCompaniesTokens: "propertiesCompaniesTokens",
  EmployeesTokens: "employeesTokens",
  createChat: "createChat",
  chatWithSingleClient: "chatWithSingleClient",
  blockedCompanies: "blockedCompanies",
  ads: "ads",
};

export default ResourcesKeys;

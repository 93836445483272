import { deleteFiles, fileUploader, prepareDataUploads } from "../fileUploader";
import { applyTimestamps } from "../../../../../utils/funcs";
import { storageRef } from "../../../../../init/firebase";

/**
 * `path` is the relevant firestore collection path ( will be used for storage to generate the uploaded files' path )
 * `data` the data you want to upload
 * `operationFn` will be returned from this hook with the final data to send to firestore ( use it when submitting you form )
 * `type` whether create or update
 * */
export async function executeFormAction(path, data, operationFn, type) {
  /**
   * already uploaded files
   * */
  let finalUploadedRefs = {};

  try {
    const uploads = prepareDataUploads(data);

    const { dataWithUploadedFiles, uploadedRefs } = await fileUploader({
      fileUploaderData: {
        uploads,
        originalData: data,
        path,
      },
      storage: storageRef,
    });
    finalUploadedRefs = uploadedRefs;

    const finalData = applyTimestamps({
      data: dataWithUploadedFiles,
      ...(type === "create" ? { isCreate: true } : {}),
      ...(type === "update" ? { isUpdate: true } : {}),
    });

    console.log({
      finalData,
      uploadedRefs,
    });

    if (dataWithUploadedFiles) {
      await operationFn(finalData);

      /**
       * delete old files if user replaced its value with new one
       * */
      // todo - later handle this...
      // await deleteFiles({
      //   fileRefs: getPreviousFileRefs({
      //     uploadedRefs,
      //     previousData: data,
      //     storage,
      //   }),
      // });
    }
  } catch (ex) {
    console.log(ex.message);
    /**
     * if there is an error, all uploaded files will be deleted
     * */
    await deleteFiles({ fileRefs: finalUploadedRefs });

    /**
     * throw an error to catch it when using this function
     * */
    throw new Error(ex.message);
  }
}

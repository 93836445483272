import { Box, Chip, Typography } from "@material-ui/core";

import Loader from "./Loader";

const ShowItem = ({ title, value, loading, isRange, isList }) => {
  const getValue = (v) => {
    if (!v) {
      return <Typography>Any</Typography>;
    } else if (isRange) {
      return (
        <Typography>
          {v[0]} - {v[1]}
        </Typography>
      );
    } else if (isList) {
      return v.map((el) => <Chip label={el} style={{ margin: "2px" }} />);
    }
    return v;
  };

  return (
    <Box display="flex" flexDirection="column" mb={3}>
      <Typography variant="body1" color="primary">
        {title}
      </Typography>
      <Typography color="textSecondary">
        <Loader loading={loading} size={10}>
          {getValue(value)}
        </Loader>
      </Typography>
    </Box>
  );
};

export default ShowItem;

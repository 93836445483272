import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";

const sharedStyles = (theme) => ({
  chatBubble: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 20,
    margin: "1px auto",
    maxWidth: 450,
    padding: "8px 14px",
    width: "-webkit-fit-content",
  },
});

const useStyles = makeStyles((theme) => ({
  message: {
    color: theme.palette.grey[200],
    fontSize: 16,
    fontWeight: "300",
    margin: 0,
    wordBreak: "break-all",
    whiteSpace: "break-spaces",
  },
  senderBubble: {
    ...sharedStyles(theme).chatBubble,
    float: "right",
  },
  recipientBubble: {
    ...sharedStyles(theme).chatBubble,
    backgroundColor: theme.palette.grey[200],
    float: "left",
  },
  imgContainer: {
    width: 200,
    maxHeight: 150,
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  attachment: {
    ...sharedStyles(theme).chatBubble,
    backgroundColor: "transparent",
    padding: 0,
    overflow: "hidden",
  },
  arrangeVisit: {
    backgroundColor: theme.palette.error.main,

    "& p": {
      color: `${theme.palette.grey[200]} !important`,
    },
  },
}));

export default function ChatMessage({ message }) {
  const classes = useStyles();
  if (
    !message ||
    (!message.text && !message.attachment) ||
    !message.date ||
    (!message.isSender && message.isSender !== false)
  )
    return null;

  return (
    <Box>
      <Tooltip title={message.date} placement={message.isSender ? "left" : "right"}>
        <Box
          className={clsx({
            [classes.senderBubble]: message.isSender,
            [classes.recipientBubble]: !message.isSender,
            [classes.attachment]: message.attachment,
            [classes.arrangeVisit]: message.arrangeVisit,
          })}
        >
          {message.attachment ? (
            <a href={message.attachment.src} target="_blank" rel="noreferrer">
              <div className={classes.imgContainer}>
                <img src={message.attachment.src} className={classes.img} alt="Image attachment" />
              </div>
            </a>
          ) : (
            <Typography className={classes.message} style={{ color: !message.isSender && "#535353" }}>
              {message.text}
            </Typography>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
}

import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";

const propertiesRequestsResource = {
  [ResourcesKeys.PropertiesRequests]: {
    [RequestKeys.GET_LIST]: async (queryData) => {
      let filters = {
        ...queryData.filter,
        createdAt: queryData.user.createdAt,
        companyId: [queryData.user.companyId, ""],
      };

      if (queryData.user.badges?.includes("hotel")) {
        filters = {
          ...filters,
          target: "hotel",
        };
      }

      return getFinalList(firestore.collection(ResourcesKeys.PropertiesRequests), {
        ...queryData,
        limit: queryData.pagination.perPage,
        filters,
        // filters: {
        //   ...queryData.filter,
        //   createdAt: queryData.user.createdAt,
        //   companyId: [queryData.user.companyId, ""],
        //   target: queryData.user.badges.includes("hotel") ? "hotel" : "property",
        // },
        operators: {
          createdAt: ">=",
          companyId: "in",
        },
      });
    },
  },
};

export default propertiesRequestsResource;

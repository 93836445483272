import { Box, Divider, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { isEmpty } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useCurrentUser from "../../../hooks/useCurrentUser";
import useIsCompany from "../../../hooks/useIsCompany";
import useIsCompanyAdmin from "../../../hooks/useIsCompanyAdmin";
import { companyDocRef } from "../../../utils/refrences.utils";
import { PackageSizeType } from "../packages.types";
import PackageCounter from "./PackageCounter";
import PackageDate from "./PackageDate";
import PackageTitle from "./PackageTitle";

const useStyles = makeStyles((theme) => ({
  containerLeft: {
    marginBottom: theme.spacing(4),
  },
  containerRight: {
    marginBottom: theme.spacing(1),
  },
}));

const PackageCalculations = ({ size = "large", divider = false }) => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles({});
  const params = useParams();
  const [packageData, setPackageData] = useState({});
  const { user } = useCurrentUser();
  const { isCompany } = useIsCompany();
  const { isCompanyAdmin } = useIsCompanyAdmin();

  const canViewPackage = () => isCompany || isCompanyAdmin;

  const getCurrentPackage = useCallback(
    (companyId) => {
      return companyDocRef(companyId).onSnapshot({
        next(snapshot) {
          if (snapshot.exists) {
            const { package: newPackage, consumedPackage } = snapshot.data();
            setPackageData({
              package: newPackage,
              consumed: consumedPackage,
            });
          }
        },
        error(ex) {
          console.log("[Get one package error]", ex.message);
        },
      });
    },
    [user.id]
  );

  useEffect(() => {
    // get companyId and packageId based on current user.
    const companyId = canViewPackage() ? user.companyId : params.companyId;
    const packageId = canViewPackage() ? user.packageId : params.packageId;

    if (packageId && companyId) {
      const unsubscribe = getCurrentPackage(companyId);

      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [isCompany]);

  return (
    !isEmpty(packageData) && (
      <section
        className={clsx({
          [classes.containerLeft]: size === "large",
          [classes.containerRight]: size === "small",
        })}
        style={{
          marginTop: isSm ? 28 : "",
        }}
      >
        <Box display={isSm ? "block" : "flex"} pl={isSm ? 2 : ""} alignItems="center">
          <PackageTitle title="Current Package" size={size} />
          <Box display="flex" alignItems="center">
            <Typography
              className={clsx([
                {
                  [classes.nameLarge]: size === "large",
                  [classes.nameSmall]: size === "small",
                },
              ])}
              style={{
                fontSize: size === "small" ? (isSm ? 14 : 18) : isSm ? 18 : 22,
                marginLeft: isSm ? "" : "8px",
                color: "#616161",
              }}
            >
              {packageData.package.name.en} - &nbsp;
            </Typography>
            {isSm && (
              <>
                {size === "large" && <PackageDate date={packageData.package.expiryDate.toDate?.()} size={size} />}
                {size === "small" && divider && <Divider style={{ marginTop: 8 }} />}
                {size === "small" && <PackageDate date={packageData.package.expiryDate.toDate?.()} size={size} />}
              </>
            )}
          </Box>
          {size === "small" && !isSm && <PackageDate date={packageData.package.expiryDate.toDate?.()} size={size} />}
        </Box>
        <Box display={"flex"} pl={isSm ? 2 : ""} alignItems="center">
          <PackageCounter
            count={packageData.consumed.featured}
            of={packageData.package.featured}
            name="Featured"
            size={size}
          />
          <PackageCounter
            count={packageData.consumed.boosted}
            of={packageData.package.boosted}
            name="Boosted"
            size={size}
          />
          <PackageCounter
            count={packageData.consumed.published}
            of={packageData.package.published}
            name="Published"
            size={size}
          />
          <PackageCounter count={packageData.consumed.total} of={packageData.package.total} name="Total" size={size} />
        </Box>
        {!isSm && (
          <>
            {size === "large" && (
              <PackageDate date={packageData.package.expiryDate.toDate().toLocaleDateString("en-GB")} size={size} />
            )}
            {size === "small" && divider && <Divider style={{ marginTop: 8 }} />}
          </>
        )}
      </section>
    )
  );
};

PackageCalculations.propTypes = {
  size: PropTypes.oneOf(PackageSizeType),
  divider: PropTypes.bool,
};

export default PackageCalculations;

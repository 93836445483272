import { maxLength, minLength, required } from "react-admin";

import useLocalizedRAInputs from "../../../hooks/useLocalizedRAInputs";
import { Locals } from "../../../enums";
import { stringHasContent } from "../../../utils/validators";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../init/firebase";

export const usePropertiesCompaniesLocalizations = (props) => {
  return useLocalizedRAInputs([
    {
      key: "name",
      label: "Name",
      validate: {
        [Locals[0].key]: [required(), stringHasContent(), minLength(3), maxLength(30)],
        [Locals[1].key]: [required(), stringHasContent(), minLength(3), maxLength(30)],
      },
      ...props,
    },
    {
      key: "about",
      label: "About",
      multiline: true,
      variant: "outlined",
      counter: 250,
      validate: {
        [Locals[0].key]: [required(), stringHasContent(), minLength(3), maxLength(250)],
        [Locals[1].key]: [required(), stringHasContent(), minLength(3), maxLength(250)],
      },
      ...props,
    },
  ]);
};

export const useProfileAboutLocalizations = (props) => {
  return useLocalizedRAInputs([
    {
      key: "about",
      label: "About",
      multiline: true,
      variant: "outlined",
      counter: 250,
      validate: {
        [Locals[0].key]: [required(), stringHasContent(), minLength(3), maxLength(250)],
        [Locals[1].key]: [required(), stringHasContent(), minLength(3), maxLength(250)],
      },
      ...props,
    },
    {
      key: "name",
      label: "Name",
      multiline: true,
      variant: "outlined",
      counter: 250,
      validate: {
        [Locals[0].key]: [required(), stringHasContent(), minLength(2), maxLength(250)],
        [Locals[1].key]: [required(), stringHasContent(), minLength(2), maxLength(250)],
      },
      ...props,
    },
  ]);
};

///////////////////////////////  Mahmoud  ////////////////////////////////

export async function getInterval() {
  const collRef = collection(firestore, "settings");

  const docRef = doc(collRef, "properties");

  const myDoc = await getDoc(docRef);

  // console.log( "TeeeeeestDoc" , myDoc );

  // time in minutes to bing with backEnd

  // console.log( "fre" , myDoc.data().companyActivityFrequency );

  return myDoc.data().companyActivityFrequency;
}

import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";

const packagesResource = {
  [ResourcesKeys.Packages]: {
    [RequestKeys.GET_LIST]: async (queryData) =>
      getFinalList(firestore.collection(ResourcesKeys.Packages), {
        ...queryData,
        limit: queryData.pagination.perPage,
        filters: {
          deleted: false,
        },
      }),
  },
};

export default packagesResource;

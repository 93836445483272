import { Box, Card, Divider, useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { useHistory, useParams } from "react-router-dom";
import { from } from "rxjs";
import { catchError, map, switchMap, toArray } from "rxjs/operators";

import Loader from "../../../components/Loader";
import { firestore } from "../../../init/firebase";
import { applyTimestamps } from "../../../utils/funcs";
import { companyDocRef, packagesRef } from "../../../utils/refrences.utils";
import PackageCalculations from "./PackageCalculations";
import PackageCard from "./PackageCard";
import PackageTitle from "./PackageTitle";

const RenewPackage = () => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [packages, setPackages] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [loadingRenew, setLoadingRenew] = useState(false);
  const { companyId, packageId } = useParams();
  const notify = useNotify();
  const history = useHistory();

  const getPackages = () => {
    return from(packagesRef.where("deleted", "==", false).limit(20).get()).pipe(
      switchMap((snapshot) => from(snapshot.docs)),
      map((packageSnapshot) => ({
        ...packageSnapshot.data(),
        id: packageSnapshot.id,
      })),
      toArray(),
      catchError((ex) => {
        console.log(ex.message);
        return [];
      })
    );
  };

  const handleRenew = async (selectedPackageId) => {
    setLoadingRenew(true);
    const batch = firestore.batch();
    batch.update(
      companyDocRef(companyId),
      applyTimestamps({
        data: {
          packageId: selectedPackageId,
          renewPackage: selectedPackageId === packageId,
        },
        isUpdate: true,
      })
    );
    try {
      await batch.commit();
      // update current route with the new package id
      if (companyId && packageId) history.replace(`/company/${companyId}/package/${selectedPackageId}`);
      notify("Package updated");
    } catch (ex) {
      notify("This package is less than current consumed numbers, please choose a higher one", "error");
    } finally {
      setLoadingRenew(false);
    }
  };

  useEffect(() => {
    setLoadingPackages(true);
    const packagesRequest = getPackages().subscribe({
      next(data) {
        setPackages(data);
        setLoadingPackages(false);
      },
    });

    return () => {
      packagesRequest.unsubscribe();
    };
  }, []);

  return (
    <Card>
      <Box p={isSm ? 1 : 4} style={loadingRenew ? { pointerEvents: "none", opacity: 0.5 } : {}}>
        <PackageCalculations />
        <Divider />
        <Box marginTop={4}>
          <PackageTitle title="Available Packages" size="large" />
          <Loader loading={loadingPackages}>
            <Box display="flex" flexWrap="wrap" justifyContent={isSm ? "center" : ""}>
              {packages.map((pack) => (
                <PackageCard cPackage={pack} handleRenew={handleRenew} />
              ))}
            </Box>
          </Loader>
        </Box>
      </Box>
    </Card>
  );
};

export default RenewPackage;

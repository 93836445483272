import { Datagrid, List, ReferenceField, TextField } from "react-admin";

import TimestampField from "../../components/TimestampField";
import TablePagination from "../../components/TablePagination";
import useRealTimeRefresh from "../../hooks/useRealTimeRefresh";
import { ResourcesKeys, Statistics } from "../../enums";
import useNotifications from "../../hooks/useNotifications";
import useSharedStyles from "../../hooks/useSharedStyles";
import { useMediaQuery } from "@material-ui/core";

export default function CompanyReviewsList(props) {
  const { styles } = useSharedStyles();
  useRealTimeRefresh();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useNotifications(Statistics.reviewsLastSeenAt, ResourcesKeys.CompanyReviews);

  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "desc" }}
      // filters={<ReviewsFilters />}
      pagination={<TablePagination />}
      bulkActionButtons={false}
      exporter={false}
      actions={null}
    >
      <Datagrid style={{ tableLayout: isSm ? "" : "fixed" }} rowClick="edit">
        <TextField source="text" label="Text" cellClassName={styles.truncate3} sortable={false} />
        <TextField source="rating" cellClassName={styles.truncate3} sortable={false} />
        <ReferenceField
          source="client.id"
          label="Client"
          reference={ResourcesKeys.Clients}
          sortable={false}
          link={false}
        >
          <TextField source="displayName" />
        </ReferenceField>
        <TextField source="reply.text" label="Reply" cellClassName={styles.truncate3} sortable={false} />
        <ReferenceField
          source="reply.employee.id"
          label="Reply By"
          reference={ResourcesKeys.PropertiesCompanies}
          sortable={false}
          link={false}
        >
          <TextField source="name.en" />
        </ReferenceField>
        <TimestampField source="createdAt" label="Created At" cellClassName={styles.truncate3} />
      </Datagrid>
    </List>
  );
}

import { useMediaQuery } from "@material-ui/core";
import { memo, useEffect, useRef, useState } from "react";
import { Autocomplete, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";

import useDidUpdate from "../hooks/useDidUpdate";
import appConfig from "../init/config";
import { areEqual } from "../utils/general";

const containerStyle = {
  height: "600px",
};

const DOHA_CENTER_COORDINATES = {
  lat: 25.279222,
  lng: 51.233224,
};

const QATAR_BOUNDS = {
  south: 24.4711180214628,
  west: 50.72110005967814,
  north: 26.21710000349892,
  east: 51.71440006361076,
};

const libraries = ["places"];
const apiKey = appConfig.googleMapsApiKey;
Geocode.setApiKey(apiKey);

const getLatLngFromAddress = async (GeoCode, address) => {
  const res = await GeoCode.fromAddress(address);
  return res.results[0].geometry.location;
};

function LocationPicker({ onLocationChange, defaultValue, value }) {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const autoCompleteEl = useRef(null);
  const markerEl = useRef(null);
  const [markerLocation, setMarkerLocation] = useState(null);
  const [defaultMarkerLocation, setDefaultMarkerLocation] = useState(null);
  const [center, setCenter] = useState(DOHA_CENTER_COORDINATES);
  const [zoom, setZoom] = useState(10);

  useEffect(() => {
    if (value) {
      const point = {
        lat: value.latitude,
        lng: value.longitude,
      };
      setCenter(point);
      setMarkerLocation(point);
      setDefaultMarkerLocation(null);
    }
  }, [value]);

  useDidUpdate(() => {
    onLocationChange(markerLocation || defaultMarkerLocation);
  }, [markerLocation, defaultMarkerLocation]);

  useEffect(() => {
    (async function () {
      try {
        if (defaultValue) {
          const { lat, lng } = await getLatLngFromAddress(Geocode, defaultValue);
          setCenter({
            lat,
            lng,
          });
          setDefaultMarkerLocation({
            lat,
            lng,
          });
        }
      } catch (ex) {
        console.log(ex.message);
      }
    })();
  }, [defaultValue]);

  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={{
          minZoom: 9,
          restriction: {
            latLngBounds: QATAR_BOUNDS,
          },
        }}
      >
        {!isMd && (
          <Autocomplete
            fields={["geometry.location"]}
            onPlaceChanged={() => {
              const place = autoCompleteEl.current.getPlace();
              const { geometry } = place;
              const lat = geometry.location.lat();
              const lng = geometry.location.lng();
              setCenter({
                lat,
                lng,
              });
              setMarkerLocation({
                lat,
                lng,
              });
              setDefaultMarkerLocation(null);
              setZoom(11);
            }}
            onLoad={(el) => (autoCompleteEl.current = el)}
            options={{
              componentRestrictions: { country: "qa" },
            }}
          >
            <input
              type="text"
              placeholder="Search your location"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `280px`,
                height: `39px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-90px",
                marginTop: "10px",
              }}
            />
          </Autocomplete>
        )}
        <Marker
          position={markerLocation || defaultMarkerLocation}
          draggable
          onLoad={(el) => (markerEl.current = el)}
          onDragEnd={(mapMouseEvent) => {
            const lat = mapMouseEvent.latLng.lat();
            const lng = mapMouseEvent.latLng.lng();
            setMarkerLocation({ lat, lng });
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
}

export default memo(LocationPicker, areEqual);

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { ResourcesKeys, UserRoles } from "../enums";
import {
  hasNotification,
  subscribeToNotifications,
  subscribeToSeenRequests,
  syncCompanyReviewsQuery,
  syncPropertiesRequestsQuery,
  syncTenantsQuery,
} from "../utils/base";
import useChatListener from "./useChatListener";
import { updateSeenRequests } from "../redux/reducers/seenRequests";
import { getAreas, getCities, getEmployeeRoles, getEnumsAction, getSettings } from "../redux/actions/init";
import useCurrentUser from "./useCurrentUser";
import useNotificationsRequest from "../pages/NotificationsListInitialization/utils";
import { collection, doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { firestore } from "../init/firebase";
import { applyTimestamps } from "../utils/funcs";
import { setTimeStampForCurrrentCompany } from "../pages/dashboards/company/utils";

const usePrepareInitialData = (role) => {
  const dispatch = useDispatch();
  const isAdmin = role === UserRoles.admin;
  const canViewChat = () => role !== UserRoles.admin;
  const isCompanyOrPropertyAdmin = () => role === UserRoles.propertyCompany || role === UserRoles.propertyAdmin;
  const isSuperVisorOrSales = () => role === UserRoles.propertySupervisor || role === UserRoles.propertySales;

  const isCompany = role === UserRoles.propertyCompany;

  const { user } = useCurrentUser();
  const userId = user && user.id;

  //  Notifications list will be shown for every account [propertyCompany, propertyAdmin, propertySupervisor, propertySales]
  //  except admin
  useNotificationsRequest(role !== UserRoles.admin);
  useChatListener(canViewChat());

  useEffect(() => {
    let unsubscribeToSeenRequests;
    const syncNotifications = subscribeToNotifications(dispatch);

    if (isCompany) {
      // setInterval(() => {
      //   console.log("all cache cleared");
      //   caches.keys().then(cacheNames => {
      //     cacheNames.forEach(cacheName => {
      //       caches.delete(cacheName);
      //     });
      //   });
      // }, 1000 * 30 );
      // when the user is company => get the field called boolFlag from settings collection

      // setFlag(true);
      sessionStorage.setItem("started", true);
      setTimeStampForCurrrentCompany(user.id);

      //                        sending the settings from db  and company data
      if (user.id) {
        getSettingsVersion().then((data) => checkIfWillShow(data, user));
      }
    }

    if (isCompanyOrPropertyAdmin() || isSuperVisorOrSales()) {
      const requestsDate = user.requestsLastSeenAt || user.createdAt;
      const syncRequestsNotifications = syncNotifications(ResourcesKeys.PropertiesRequests);

      syncRequestsNotifications(syncPropertiesRequestsQuery(requestsDate), (docs) => {
        const badge = user.badges?.includes["hotel"] ? "hotel" : "property";
        return hasNotification(docs, requestsDate) && badge === docs[0].data().target;
      });
      unsubscribeToSeenRequests = subscribeToSeenRequests(dispatch, updateSeenRequests, userId);
    }

    if (isCompanyOrPropertyAdmin()) {
      const reviewsDate = user.reviewsLastSeenAt || user.createdAt;
      const landlordsDate = user.landlordsLastSeenAt || user.createdAt;

      const syncReviewsNotifications = syncNotifications(ResourcesKeys.CompanyReviews);
      const syncTenantsNotifications = syncNotifications(ResourcesKeys.Tenants);

      syncReviewsNotifications(syncCompanyReviewsQuery(reviewsDate, userId), (docs) =>
        hasNotification(docs, reviewsDate)
      );
      syncTenantsNotifications(syncTenantsQuery(landlordsDate, userId), (docs) => hasNotification(docs, landlordsDate));
    }

    if (userId && !isAdmin) {
      dispatch(getEmployeeRoles());
    }

    if (userId) {
      dispatch(getSettings());
      dispatch(getCities());
      dispatch(getAreas());
      dispatch(getEnumsAction());
    }

    return () => {
      if (unsubscribeToSeenRequests) unsubscribeToSeenRequests();
    };
  }, [role, user.id]);
};

function checkIfWillShow(settingsData, companyData) {
  // if (companyData.dashboardVersion !== settingsData.dashboardVersion) {
  //   showPopUp(settingsData, companyData.id);
  // }

  // must listen here => call listener function

  setDocListener(settingsData, companyData);
}

function setDocListener(settingsData, companyData) {
  const docRef = doc(firestore, "settings", "properties"); // Replace with your collection and document ID

  // after listener
  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      const fieldValue1 = data.dashboardVersion; // Replace 'fieldName' with the actual field name you want to listen to
      const fieldValue2 = data.dashboardForceUpdate; // Replace 'fieldName' with the actual field name you want to listen to
      // console.log("Document Updated field value:", fieldValue);

      if (companyData.dashboardVersion != fieldValue1) {
        showPopUp({ dashboardVersion: fieldValue1, dashboardForceUpdate: fieldValue2 }, companyData.id);
      }

      // Update your component state or perform any other action based on the field value
      // Example: setFieldValue(fieldValue);
    } else {
      console.log("Document does not exist");
    }
  });

  return unsubscribe;
}

function showPopUp({ dashboardForceUpdate = false, dashboardVersion }, compId) {
  const layer = document.createElement("div");
  layer.style.cssText =
    "display:flex; align-items:center; padding: 30px 0px ; flex-direction: column ; justify-content: space-between ; position:fixed; top:0; left:0; right:0; bottom:0; background-color: rgba(0, 0, 0, 0.4); z-index:9999999";
  layer.setAttribute("id", "layer");

  const box = document.createElement("div");
  box.style.cssText =
    "background-color: white;padding:50px ; width: 50%; margin:auto; text-align:center; border-radius: 15px; position:relative";

  const h1 = document.createElement("h1");
  h1.innerText = "A new update is available now";
  h1.style.cssText = "text-align: center;color: #56b894; margin-bottom: 20px";

  const btn = document.createElement("button");
  btn.innerText = "Update";
  btn.style.cssText =
    "padding: 15px 20px; width: fit-content; color: #56b894 ; border-radius: 15px; background-color: #1B2C24;cursor: pointer; ";
  btn.setAttribute("id", "clearCacheBtn");

  // const h6 = document.createElement('h6');
  // h6.innerText = "x";

  // <i class="fa-solid fa-circle-xmark"></i>

  // const update = document.createElement("h6");
  // update.innerText = "Update";
  // update.style.cssText =
  //   "width: fit-content; color: #56b894 ; cursor: pointer; font-size: 30px ; margin:0";
  // update.setAttribute("id", "clearCacheBtn");

  // const closeLayer = document.createElement("h6");
  // closeLayer.innerText = "Close";
  // closeLayer.style.cssText =
  //   "font-size: 30px ;color: red; width: fit-content; cursor: pointer;  margin:0; margin-right: 20px;";
  // closeLayer.setAttribute("id", "closeLayer");

  // const container = document.createElement('div');
  // container.append( dashboardForceUpdate ? "" : closeLayer, update );
  // container.style.cssText = "display:flex; align-items:center; justify-content: flex-end ;"

  const closeLayer = document.createElement("div");
  // closeLayer.innerHTML = "x";
  closeLayer.style.cssText =
    "position: absolute; top:10px;font-size: 30px ;color: white; right: 10px; ; cursor: pointer; background-color: #1B2C24; width: 40px; height: 40px;border-radius: 50% ; display:flex; justify-content:center; align-items:center ";
  closeLayer.setAttribute("id", "closeLayer");

  // const closeImg = document.createElement('img');
  // closeImg.setAttribute('src', './xmark.png');
  // closeImg.setAttribute('alt', 'close layer');
  // closeImg.style.width = "60%";

  closeLayer.innerHTML = `<svg style="color: #56b894;" width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="40" fill="white" fill-opacity="0.01"></rect><path d="M14 14L34 34" stroke="#56b894" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="white"></path><path d="M14 34L34 14" stroke="#56b894" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="white"></path></svg>`;

  // closeLayer.append( closeImg )

  box.append(h1, btn, dashboardForceUpdate ? "" : closeLayer);
  // box.append(h1, container);

  layer.append(box);

  document.getElementById("root").append(layer);

  document.querySelector("#clearCacheBtn").addEventListener("click", async function () {
    // must make clear cache and reload

    await setTheNewDashboardVersionToCrrUser(dashboardVersion, compId);
  });

  document.querySelector("#closeLayer")?.addEventListener("click", function () {
    document.getElementById("layer").style.display = "none";
  });
}

async function getSettingsVersion() {
  const docRef = doc(firestore, "settings", "properties");
  const documentData = await getDoc(docRef).then((docData) => docData.data());

  // add

  return {
    dashboardVersion: documentData.dashboardVersion,
    dashboardForceUpdate: documentData.dashboardForceUpdate,
  };
}

async function setTheNewDashboardVersionToCrrUser(version, compId) {
  const docRef = doc(firestore, "propertiesCompanies", compId);

  console.log("updating", docRef, version);

  const newData = applyTimestamps({
    data: { dashboardVersion: version },
    isUpdate: true,
  });

  await updateDoc(docRef, newData);

  window.location.href += "?nocache=" + Date.now(); // Append a unique query parameter to the URL
  document.getElementById("layer").style.display = "none";
}

export default usePrepareInitialData;

import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  icon: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: "#ff9494",
    position: "relative",
    right: 8,
    bottom: 0,
    display: "inline-block",
    fontSize: 9,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
  },
});

const SidebarIconWrapper = ({ Icon, showBullet = false, size = "default" }) => {
  const classes = useStyles({});

  return (
    <Box display="flex" position="relative">
      <Icon fontSize={size} />
      {showBullet && <div className={classes.icon} />}
    </Box>
  );
};

SidebarIconWrapper.prototype = {
  Icon: PropTypes.any.isRequired,
  showBullet: PropTypes.bool,
  size: PropTypes.string,
};

export default SidebarIconWrapper;

import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { isEmpty } from "lodash/fp";

import ShowItem from "../../../components/ShowItem";
import useGetEnumsAsValue from "../../../hooks/useGetEnumsAsValue";

const RequestFields = ({ record }) => {
  const cities = useSelector((state) => state.cities);
  const areas = useSelector((state) => state.areas);
  const category = useGetEnumsAsValue("propertiesCategories");
  const type = useGetEnumsAsValue("propertiesTypes");
  const purchasingOption = useGetEnumsAsValue("propertiesPurchasingOptions");

  const getCityName = () => {
    if (isEmpty(cities)) return "";
    return cities[record.cityId].name.en;
  };

  const getAreas = () => {
    if (isEmpty(areas)) return "";
    return record.areas.map((areaId) => areas[areaId].name.en);
  };

  return (
    <Box p={3}>
      <ShowItem title="Type" value={type(record?.type)} />
      <ShowItem title="Category" value={category(record?.category)} />
      <ShowItem title="City" value={getCityName()} />
      <ShowItem title="Areas" value={getAreas()} isList />
      <ShowItem title="Bedrooms" value={record.bedrooms} isList />
      <ShowItem title="Bathrooms" value={record.bathrooms} isList />
      <ShowItem title="Space" value={record.space} isRange />
      <ShowItem title="Price" value={record.price} isRange />
      <ShowItem title="Purchasing Option" value={purchasingOption(record?.purchasingOption)} />
      <ShowItem title="Parking" value={record.parking} />
      <ShowItem title="Furnishing" value={record.furnishing} isList />
      <ShowItem title="Rental Frequency" value={record.rentalFrequency} />
      <ShowItem title="Amenities" value={record.amenities} isList />
    </Box>
  );
};

export default RequestFields;

import { createAction, createSlice } from "@reduxjs/toolkit";
import { uniq } from "ramda";

import { Actions } from "../../../enums/actions.types";

const initialState = {
  listIds: [],
  listMap: {},
  error: null,
  loading: false,
  notificationCounter: 0,
};

export const initialNotificationsFetch = createAction(Actions.fetchNotifications, (payload) => ({
  type: Actions.fetchNotifications,
  payload,
}));

const notificationsSlice = createSlice({
  name: "@__notifications",
  initialState,
  reducers: {
    loadingNotification(state, action) {
      state.loading = action.payload;
    },
    setInitialNotificationsList(state, action) {
      state.listIds = action.payload.listIds;
      state.listMap = action.payload.listMap;
      state.notificationCounter = action.payload.notificationCounter;
      state.error = action.payload.error;
    },
    loadMoreNotifications(state, action) {
      state.listIds = uniq([...state.listIds, ...action.payload.listIds]);
      state.listMap = {
        ...action.payload.listMap,
        ...state.listMap,
      };
      state.error = action.payload.error;
    },
    syncNotification(state, action) {
      const { notification, error } = action.payload;
      if (notification) {
        state.listIds = uniq([notification.id, ...state.listIds]);
        state.listMap[notification.id] = notification;
        state.error = error;
      }
    },
    setHasNotification(state, action) {
      state.notificationCounter = action.payload;
    },
    updateNotificationSeenBy(state, action) {
      const { notificationId, userId } = action.payload;
      state.listMap[notificationId].seenBy.push(userId);
      state.notificationCounter--;
    },
  },
});

export const {
  loadingNotification,
  setInitialNotificationsList,
  loadMoreNotifications,
  syncNotification,
  setHasNotification,
  updateNotificationSeenBy,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;

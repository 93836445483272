import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const areasSlice = createSlice({
  name: "@@@Areas",
  initialState,
  reducers: {
    setAreas(_, action) {
      return action.payload;
    },
  },
});

export const { setAreas } = areasSlice.actions;
export default areasSlice.reducer;

import { curry, isNil } from "lodash/fp";

import { Statistics } from "../enums";
import { companyDocRef, employeesDocRef, statisticsDocRef } from "./refrences.utils";

const defaultStatistics = {
  requestsLastSeenAt: null,
  reviewsLastSeenAt: null,
  landlordsLastSeenAt: null,
  packageId: null,
};

/**
 * @param statistics {Record<string, any>}
 * @param key {string}
 * */
export const getDateOrNull = (statistics, key) => {
  if (!isNil(statistics) && !isNil(statistics[key])) return statistics[key].toDate();
  return null;
};

export const getCompanyInfo = async (companyId) => {
  const companyRef = companyDocRef(companyId);
  const companyStatisticsRef = statisticsDocRef(companyId);

  try {
    const companySnapshot = await companyRef.get();
    const statisticsSnapshot = await companyStatisticsRef.get();

    return {
      requestsLastSeenAt: getDateOrNull(statisticsSnapshot.data(), Statistics.requestsLastSeenAt),
      reviewsLastSeenAt: getDateOrNull(statisticsSnapshot.data(), Statistics.reviewsLastSeenAt),
      landlordsLastSeenAt: getDateOrNull(statisticsSnapshot.data(), Statistics.landlordsLastSeenAt),
      packageId: companySnapshot.data().packageId,
      badges: companySnapshot.data().badges,
      dashboardVersion: companySnapshot.data().dashboardVersion,
    };
  } catch (ex) {
    console.log(ex.message);
    return defaultStatistics;
  }
};

export const getEmployeeInfo = curry(async (companyId, employeeId) => {
  const companyRef = companyDocRef(companyId);
  const employeeRef = employeesDocRef(companyId, employeeId);

  try {
    const companySnapshot = await companyRef.get();
    const employeeSnapshot = await employeeRef.get();

    return {
      requestsLastSeenAt: getDateOrNull(employeeSnapshot.data().statistics, Statistics.requestsLastSeenAt),
      reviewsLastSeenAt: getDateOrNull(employeeSnapshot.data().statistics, Statistics.reviewsLastSeenAt),
      landlordsLastSeenAt: getDateOrNull(employeeSnapshot.data().statistics, Statistics.landlordsLastSeenAt),
      packageId: companySnapshot.data().packageId,
      badges: companySnapshot.data().badges,
    };
  } catch (ex) {
    console.log(ex.message);
    return defaultStatistics;
  }
});

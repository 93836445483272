import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";
import firebase from "firebase/compat/app";

const citiesResource = {
  [ResourcesKeys.Cities]: {
    [RequestKeys.GET_LIST]: async (queryData) =>
      getFinalList(firestore.collection(ResourcesKeys.Cities), {
        ...queryData,
        limit: queryData.pagination.perPage,
        filters: queryData.filter,
      }),
    [RequestKeys.GET_MANY]: async (resource, queryData) =>
      getFinalList(firestore.collection(ResourcesKeys.Cities), {
        ...queryData,
        filters: {
          ...queryData.filter,
          [firebase.firestore.FieldPath.documentId()]: queryData.ids,
        },
        operators: {
          [firebase.firestore.FieldPath.documentId()]: "in",
        },
      }),
  },
};

export default citiesResource;

import { call, put, takeEvery } from "redux-saga/effects";

import { getNotificationsListQuery, prepareNotificationsForRedux } from "../../../Notifications/NotificationList/utils";
import { rsf } from "../../../init/firebase";
import {
  initialNotificationsFetch,
  setInitialNotificationsList,
} from "../../../Notifications/NotificationList/reducer";
import { prepareNotificationsList } from "../utils/notificationRequest";

function* getNotificationsList(data) {
  const userId = data.payload;

  try {
    const snapshot = yield call(rsf.firestore.getCollection, getNotificationsListQuery(userId));

    const modifiedNotifications = yield prepareNotificationsList(snapshot.docs);
    const { listIds, listMap, notificationCounter } = prepareNotificationsForRedux(modifiedNotifications, userId);
    console.log("notificationCounter", notificationCounter);

    yield put(setInitialNotificationsList({ listIds, listMap, error: null, notificationCounter: notificationCounter }));
  } catch (ex) {
    yield put(setInitialNotificationsList({ listIds: [], listMap: {}, error: ex.message }));
    console.log(ex.message);
    // console.log("ListIDssss Error");
  }
}

export default function* watchGetNotificationsList() {
  yield takeEvery(initialNotificationsFetch.toString(), getNotificationsList);
}

import isEqual from "lodash/fp/isEqual";

export const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export const upperCaseFirstLetter = (text) => {
  if (text) {
    const lowerCasedText = text.toLowerCase();
    return lowerCasedText[0].toUpperCase() + lowerCasedText.slice(1);
  }
};

export const truncateText = (str, maxLen = 30, separator = " ") => {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
};

import dayjs from "dayjs";
import validUrl from "valid-url";
import { curry, isString } from "lodash/fp";

import { firestore } from "../init/firebase";
import { upperCaseFirstLetter } from "./general";

export function phoneNumber(field = "phone") {
  return (phone) => {
    const qa = /^\d{8}$/;
    const isValid = phone ? qa.test(phone) : true;
    return isValid ? undefined : `Invalid ${field} number`;
  };
}

export function stringHasContent() {
  return (str) => (str ? (str.trim() ? undefined : "Cannot be empty") : undefined);
}

export function matches(propertyToMatchName) {
  return (val, formValues) => (val === formValues[propertyToMatchName] ? undefined : "Passwords do not match");
}

export function beforeToday() {
  return (date) => {
    const today = new Date();
    const yesterday = today.setDate(today.getDate() - 1);
    if (new Date(date) > yesterday) return "Date must be in the past";
  };
}

export function todayOrAfter() {
  return (date) => {
    const today = dayjs().startOf("day");
    if (dayjs(date).isBefore(today)) return "Date can't be in the past";
  };
}

export function url() {
  return (str) => {
    if (!validUrl.isWebUri(str)) {
      return "Value must be a valid URL";
    }
  };
}

export function digits() {
  return (val) => {
    if (!isString(val)) return "Wrong input";
    if (!val.match(/^\d+$/)) return "Can contain only digits";
  };
}

export function stringMin(min) {
  return (val) => {
    const isNumber = digits()(val);
    if (isNumber) return isNumber;
    const number = Number(val);
    if (number < min) return `Must be smaller than ${min}`;
  };
}

export function int() {
  return (num) => (Number.isInteger(Number(num)) ? undefined : "Can't have decimal places");
}

export function isNumber(message) {
  return (num) => (Number(num) ? undefined : `Invaild ${message}`);
}

export const isNotExceeded = (params) => {
  return (val, record) => {
    const max = record.total ? record.total : params;
    return val <= max ? undefined : `Shouldn't exceed the total (${max})`;
  };
};

/**
 * @param type{'featured' | 'boosted'}
 * */
export const checkPackageNumber = (type) => {
  return (val, record) => {
    const total = type === "featured" ? val + record.boosted : val + record.featured;
    return record.published >= total
      ? undefined
      : `Boosted + Featured should be less than (Published ${record.published})`;
  };
};

export function validateMaxPrice(criteria) {
  return (value) => {
    const pOption = criteria.pOption;
    let isMaxValid;
    let maxMessage;
    pOption
      ? pOption === "CS" || pOption === "RS"
        ? (isMaxValid = value <= criteria.maxBuy)
        : (isMaxValid = value <= criteria.maxRent)
      : (isMaxValid = value <= criteria.maxBuy);

    maxMessage = !isMaxValid
      ? pOption === "CS" || pOption === "RS" || pOption === undefined
        ? `Must be ${criteria.maxBuy} or less`
        : `Must be ${criteria.maxRent} or less`
      : undefined;

    return maxMessage;
  };
}
export function validateMinPrice(criteria) {
  return (value) => {
    const pOption = criteria.pOption;
    let isMinValid;
    let minMessage;
    pOption
      ? pOption === "CS" || pOption === "RS"
        ? (isMinValid = value >= criteria.minBuy)
        : (isMinValid = value >= criteria.minRent)
      : (isMinValid = value >= criteria.minBuy);

    minMessage = !isMinValid
      ? pOption === "CS" || pOption === "RS" || pOption === undefined
        ? `Must be at least ${criteria.minBuy}`
        : `Must be at least ${criteria.minRent}`
      : undefined;
    return minMessage;
  };
}

export const validateImages = (numberOfImages = 30, maxSize = 10000000) => {
  return (list) => {
    const isMaxSize = list.some((img) => (img && img.rawFile ? img.rawFile.size > maxSize : false));
    return list.length > numberOfImages
      ? `Maximum number of images is ${numberOfImages}`
      : isMaxSize
      ? `Please select images with size less than or equal ${maxSize / 1000000}MB`
      : undefined;
  };
};

export const validateMinUtilities = (min, key) => {
  return (value, record, a) => {
    if (record.type && record.type === "land") {
      return value > 0 ? `${key} should be 0 with type 'Land'` : undefined;
    }
    return value < min ? `${key} should be greater than ${min}` : undefined;
  };
};

export const validateRequired = (message) => (value) => value ? undefined : message;

export const isExists = curry((key, path, currentValue = null) => {
  return async (value) => {
    if (currentValue !== value) {
      try {
        const snapshot = await firestore.collection(path).where(key, "==", value).limit(1).get();

        return snapshot.empty ? undefined : `${upperCaseFirstLetter(key)} already exists, pick up another`;
      } catch (ex) {
        console.log(ex.message);
        return ex.message;
      }
    }
    return undefined;
  };
});

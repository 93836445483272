import { put, takeEvery } from "redux-saga/effects";

import chatActionCreators from "../../actions/chat";
import { updateRooms } from "../../reducers/chat";
import prepareRooms from "../../../utils/chat";

function* updateSyncedRooms(action) {
  const { rooms, type, userId } = action.payload;
  try {
    const preparedRooms = yield prepareRooms(rooms, userId);
    if (preparedRooms.length > 0) {
      yield put(updateRooms({ preparedRooms, type }));
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export function* watchUpdateSyncedRooms() {
  yield takeEvery(chatActionCreators.updateSyncedRooms.toString(), updateSyncedRooms);
}

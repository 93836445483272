import React from "react";
import { Title } from "react-admin";

import Chat from "./index";
import { ChatType } from "../../enums";
import useChatPrepared from "../../hooks/useChatPrepared";

const PropertyChat = () => {
  useChatPrepared(ChatType.property);

  return (
    <>
      <Title title="Property Chat" />
      <Chat type={ChatType.property} />
    </>
  );
};

export default PropertyChat;

import { Button, CircularProgress } from "@material-ui/core";

export const FormSubmitBtn = ({ title, disabled, loading }) => {
  return (
    <Button
      startIcon={loading ? <CircularProgress color="secondary" size={14} /> : null}
      type="submit"
      variant="contained"
      color="primary"
      disabled={disabled || loading}
    >
      {title}
    </Button>
  );
};

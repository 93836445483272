import { useSelector } from "react-redux";

const useGetEnumsAsChoices = (props) => {
  const { enumEntry, sortMe = ([id1], [id2]) => (id1 < id2 ? -1 : 1), filterMe = () => true } = props;

  const enums = useSelector((state) => state.enums);

  if (enums[enumEntry]) {
    return Object.entries(enums[enumEntry])
      .filter(filterMe)
      .sort(sortMe)
      .map(([id, value]) => {
        return {
          id,
          name: value.label ? value.label.en : value.en,
        };
      });
  }
  return [{ id: "", name: "" }];
};

export default useGetEnumsAsChoices;

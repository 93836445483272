import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const TimestampField = ({ source, record = {}, cellClassName }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return (
    <div className={cellClassName}>
      {dayjs
        .tz(dayjs.unix(source ? record[source].seconds : record.seconds), dayjs.tz.guess())
        .format("DD/MM/YYYY - hh:mm a")}
    </div>
  );
};

export default TimestampField;

import { call, put, takeEvery } from "redux-saga/effects";

import { rsf } from "../../init/firebase";
import { getSettings } from "../actions/init";
import { ResourcesKeys } from "../../enums";
import { setSettings } from "../reducers/settings";

function* _getSettings() {
  const snapshot = yield call(rsf.firestore.getDocument, `${ResourcesKeys.Settings}/${ResourcesKeys.Properties}`);

  yield put(setSettings(snapshot.data()));
}

export function* watchGetSettings() {
  yield takeEvery(getSettings.toString(), _getSettings);
}

import { MenuItemLink, UserMenu } from "react-admin";
import { AccountBoxOutlined } from "@material-ui/icons";

import useIsCompany from "../../hooks/useIsCompany";
import { ResourcesKeys } from "../../enums";

const MyUserMenu = (props) => {
  const { isCompany } = useIsCompany();

  return (
    <UserMenu {...props}>
      {isCompany && (
        <>
          <MenuItemLink to={`/${ResourcesKeys.Profile}`} leftIcon={<AccountBoxOutlined />} primaryText={"Profile"} />
        </>
      )}
    </UserMenu>
  );
};

export default MyUserMenu;

import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

import { PackageSizeType } from "../packages.types";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  date: {
    color: theme.palette.grey[700],
  },
  dateLarge: {
    fontSize: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  dateSmall: {
    fontSize: theme.spacing(1.5),
  },
}));

const PackageDate = ({ date, size }) => {
  const classes = useStyles({});
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Typography
      className={clsx([
        classes.date,
        // {
        //   [classes.dateLarge]: size === "large",
        //   [classes.dateSmall]: size === "small",
        // },
      ])}
      style={{
        fontSize: size === "small" ? (isSm ? 14 : 18) : isSm ? 18 : 22,
        marginTop: size === "large" ? (isSm ? 0 : "32px") : 0,
        // fontWeight: "bold",
        color: "#b60125",
      }}
    >
      Expiration Date: {dayjs(date).format("DD MMMM, YYYY")}
    </Typography>
  );
};

PackageDate.propTypes = {
  size: PropTypes.oneOf(PackageSizeType),
  date: PropTypes.string,
};

export default PackageDate;

import { Box, Button, MenuItem, TextField, Toolbar } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { useListContext, useLoading } from "react-admin";
import { useDispatch } from "react-redux";

import { PaginationDirection } from "../enums";
import useDisabledEvents from "../hooks/useDisabledEvents";
import useIsEmptyList from "../hooks/useIsEmptyList";
import { setPaginationDirection } from "../redux/reducers/pagination";

export default function TablePagination(props) {
  const { page, perPage, setPerPage, setPage, resource } = useListContext();
  const loading = useLoading();
  const dispatch = useDispatch();
  const { disabledStyles } = useIsEmptyList(props);

  return (
    <Toolbar style={disabledStyles}>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <TextField select value={perPage} onChange={(e) => setPerPage(e.target.value)}>
          {[1, 2, 5, 10, 20].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Button
          color="primary"
          key="prev"
          disabled={loading}
          onClick={() => {
            dispatch(
              setPaginationDirection({
                resource,
                paginationDirection: PaginationDirection.prev,
              })
            );
            setPage(page - 1);
          }}
        >
          <ChevronLeft />
        </Button>
        <Button
          color="primary"
          key="next"
          onClick={() => {
            dispatch(
              setPaginationDirection({
                resource,
                paginationDirection: PaginationDirection.next,
              })
            );
            setPage(page + 1);
          }}
          disabled={loading}
        >
          <ChevronRight />
        </Button>
      </Box>
    </Toolbar>
  );
}

import { ResourcesKeys, UserRoles } from "../../../enums";

export const redirectTo = (type) => {
  return type === UserRoles.propertyAdmin
    ? `/${ResourcesKeys.Admins}`
    : type === UserRoles.propertySupervisor
    ? `/${ResourcesKeys.Supervisors}`
    : type === UserRoles.propertySales
    ? `/${ResourcesKeys.Sales}`
    : `/${ResourcesKeys.CompanyProperties}`;
};

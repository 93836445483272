import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const rolesSlice = createSlice({
  name: "@@@Roles",
  initialState,
  reducers: {
    setRoles(_, action) {
      return action.payload;
    },
  },
});

export const { setRoles } = rolesSlice.actions;
export default rolesSlice.reducer;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import firebase from "firebase/compat/app";

import { updateUserData } from "../redux/reducers/user";
import { setResourceNotifications } from "../redux/reducers/list";
import { employeesDocRef, statisticsDocRef } from "../utils/refrences.utils";
import { UserRoles } from "../enums";
import useCurrentRole from "./useCurrentRole";
import useCurrentUser from "./useCurrentUser";
import { updateDocuments } from "../utils/query.utils";

const getUpdatedData = async (ref) => {
  try {
    const snapshot = await ref.get();
    return snapshot.data();
  } catch (ex) {
    console.log(ex.message);
    return undefined;
  }
};

const updateCompanyStatistics = async (ref, key) => {
  await updateDocuments(ref, {
    [key]: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

const updateEmployeeStatistics = async (ref, key) => {
  const firestoreData = await getUpdatedData(ref);
  if (firestoreData) {
    const data = {
      statistics: {
        ...firestoreData.statistics,
        [key]: firebase.firestore.FieldValue.serverTimestamp(),
      },
    };
    await updateDocuments(ref, data);
  }
};

const getCompanyUpdatedDate = async (ref, key) => {
  const firestoreData = await getUpdatedData(ref);
  if (firestoreData) {
    return firestoreData[key];
  }
};

const getEmployeeUpdatedDate = async (ref, key) => {
  const firestoreData = await getUpdatedData(ref);
  if (firestoreData) {
    return firestoreData.statistics[key];
  }
};

const updateCompanyState = async (ref, key, dispatch) => {
  const date = await getCompanyUpdatedDate(ref, key);
  if (date) dispatch(updateUserData({ [key]: date.toDate() }));
};

const updateEmployeeState = async (ref, key, dispatch) => {
  const date = await getEmployeeUpdatedDate(ref, key);
  if (date) dispatch(updateUserData({ [key]: date.toDate() }));
};

const updateHasNotification = (resource, dispatch) => {
  dispatch(
    setResourceNotifications({
      resourceKey: [resource],
      hasNotifications: false,
    })
  );
};

const useNotifications = (key, resource) => {
  const dispatch = useDispatch();
  const { user } = useCurrentUser();
  const companyStatisticsRef = statisticsDocRef(user.companyId);
  const employeesStatisticsRef = employeesDocRef(user.companyId, user.id);
  const { role } = useCurrentRole();

  useEffect(() => {
    if (role !== UserRoles.unknown) {
      let updateSt, updateState;

      if (role === UserRoles.propertyCompany) {
        updateSt = () => updateCompanyStatistics(companyStatisticsRef, key);
        updateState = () => updateCompanyState(companyStatisticsRef, key, dispatch);
      }

      if (
        role === UserRoles.propertySales ||
        role === UserRoles.propertySupervisor ||
        role === UserRoles.propertyAdmin
      ) {
        updateSt = () => updateEmployeeStatistics(employeesStatisticsRef, key);
        updateState = () => updateEmployeeState(employeesStatisticsRef, key, dispatch);
      }

      (async function () {
        await updateSt();
        setTimeout(async () => {
          await updateState();
          updateHasNotification(resource, dispatch);
        }, 0);
      })();
    }
  }, [role]);
};

export default useNotifications;

import { call, put, takeEvery } from "redux-saga/effects";

import { rsf } from "../../../init/firebase";
import { applyTimestamps } from "../../../utils/funcs";
import chatActionCreators from "../../actions/chat";
import { companyDocRef, employeesDocRef, roomDocRef } from "../../../utils/refrences.utils";
import { UserRoles } from "../../../enums";
import { updateRoomSeen } from "../../reducers/chat";

function* markRoomAsSeen(action) {
  const { roomId, companyId, userId, role, type } = action.payload;

  const seenBy = role === UserRoles.propertyCompany ? companyDocRef(companyId) : employeesDocRef(companyId, userId);

  try {
    yield call(
      rsf.firestore.updateDocument,
      roomDocRef(roomId),
      applyTimestamps({
        data: {
          seenBy,
        },
        isUpdate: true,
      })
    );
    yield put(updateRoomSeen({ roomId, type }));
  } catch (ex) {
    console.log(ex.message);
  }
}

export function* watchMarkRoomAsSeen() {
  yield takeEvery(chatActionCreators.markRoomAsSeen.toString(), markRoomAsSeen);
}

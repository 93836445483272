import { useSelector } from "react-redux";

import AdminController from "../../pages";
import Loader from "../Loader";

export default function Home({ history }) {
  const user = useSelector((state) => state.user);

  return (
    <Loader loading={user.loading} height="100vh" size={40}>
      <AdminController history={history} />
    </Loader>
  );
}

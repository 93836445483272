import { Box, List, TextField } from "@material-ui/core";
import Downshift from "downshift";
import { useEffect, useState } from "react";
import { path } from "ramda";

import Loader from "./Loader";

const CustomSearch = ({
  query,
  source,
  label,
  initial,
  getResults,
  renderMenuItems,
  onSelect = undefined,
  alwaysOpened = false,
  listPosition = "initial",
}) => {
  const [results, setResults] = useState(initial);
  const [loading, setLoading] = useState(false);

  const searchQuery = (value) => query.orderBy(source).startAt(value).endAt(`${value}\uf8ff`);

  useEffect(() => {
    setResults(initial);
  }, [initial.length]);

  const handleSelect = (selection) => {
    if (onSelect) onSelect(selection);
  };

  return (
    <Downshift
      onInputValueChange={(value) => {
        setLoading(true);
        getResults(searchQuery(value), value).subscribe({
          next(data) {
            setResults(data);
            setLoading(false);
          },
        });
      }}
      onChange={handleSelect}
      itemToString={(item) => (item ? path(source.split("."))(item) : "")}
    >
      {({ getRootProps, getInputProps, getMenuProps, getItemProps, isOpen }) => (
        <Box width={250} position="relative" onClick={(e) => e.stopPropagation()}>
          <Box {...getRootProps({}, { suppressRefError: true })} p={1}>
            <TextField fullWidth {...getInputProps()} variant="outlined" size="small" placeholder={`Search ${label}`} />
          </Box>

          {alwaysOpened || isOpen ? (
            <Box
              position={listPosition}
              top={60}
              width="100%"
              zIndex={5}
              boxShadow={alwaysOpened ? "none" : "2px 2px 5px"}
              style={{ background: "white" }}
            >
              <Loader loading={loading}>
                <List disablePadding {...getMenuProps()}>
                  {results.map((result) => renderMenuItems(result, getItemProps))}
                </List>
              </Loader>
            </Box>
          ) : null}
        </Box>
      )}
    </Downshift>
  );
};

export default CustomSearch;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  properties: {},
};

const settingsSlice = createSlice({
  name: "@@@Settings",
  initialState,
  reducers: {
    setSettings(state, action) {
      return {
        ...state,
        properties: action.payload,
      };
    },
  },
});

export const { setSettings } = settingsSlice.actions;
export default settingsSlice.reducer;

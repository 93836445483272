import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: "200px",
    margin: "8px 0",
    "& label": {
      zIndex: 1,
      padding: theme.spacing(0, 0.5),
      transform: "translate(10px, 12px) scale(1)",
      pointerEvents: "none",
    },
  },
  filledInput: {
    transform: "translate(8px, -5.5px) scale(0.75) !important",
  },
}));

const CustomSelect = ({
  handleSelectItem,
  selectedItem,
  labelTitle,
  multiple = false,
  required = false,
  disabled = false,
  listItems,
  labelBG = "#fafafa",
  width = "96%",
}) => {
  const classes = useStyles({});
  const [checkError, setCheckError] = useState(false);
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleSelect = (e) => {
    handleSelectItem(e.target.value, e);
  };

  let items = listItems.map((entry) => (
    <MenuItem key={entry.id} value={entry.id}>
      {entry.name}
    </MenuItem>
  ));

  const checkFocusInput = () => {
    setCheckError(true);
  };

  return (
    <FormControl
      required={required}
      error={checkError && required && !selectedItem?.length}
      className={classes.root}
      size="small"
      variant="outlined"
    >
      <InputLabel
        style={{ backgroundColor: labelBG }}
        className={selectedItem?.length ? classes.filledInput : ""}
        id={labelTitle}
      >
        {labelTitle}
      </InputLabel>
      <Select
        labelId={labelTitle}
        onChange={handleSelect}
        value={selectedItem}
        multiple={multiple}
        fullWidth
        style={{ width: isMd ? "100%" : width }}
        onBlur={checkFocusInput}
        disabled={disabled}
      >
        {items}
      </Select>
      {checkError && required && !selectedItem?.length && <FormHelperText>Required</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelect;

import { TextField } from "react-admin";
import { Locals } from "../enums";

export default function useLocalizedRAFields(fields) {
  if (!Array.isArray(fields)) return null;
  const resultingElements = [];
  fields.forEach((field) => {
    Locals.forEach((locale) =>
      resultingElements.push(
        <TextField {...field} source={`${field.key}.${locale.key}`} label={`${field.label} (${locale.key})`} />
      )
    );
  });
  return resultingElements;
}

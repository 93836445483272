import React from "react";
import { Title } from "react-admin";

import Chat from "./index";
import { ChatType } from "../../enums";
import useChatPrepared from "../../hooks/useChatPrepared";

const ReplyChat = () => {
  useChatPrepared(ChatType.reply);

  return (
    <>
      <Title title="Reply Chat" />
      <Chat type={ChatType.reply} />
    </>
  );
};

export default ReplyChat;

import { Parser } from "json2csv";
import { useEffect, useRef, useState } from "react";

const StatsExport = ({ data }) => {
  const [encodedCsvUri, setEncodedCsvUri] = useState(null);
  const ref = useRef();

  useEffect(() => {
    if (data) {
      let transformedObject = {};

      for (const [key, value] of Object.entries(data)) {
        transformedObject[key + " company total"] = value[0].total;
        transformedObject[key + " company unique"] = value[0].unique;
        transformedObject[key + " property total"] = value[1].total;
        transformedObject[key + " property unique"] = value[1].unique;
      }

      console.log(transformedObject);

      try {
        const json2csvParser = new Parser();
        const csv = json2csvParser.parse(transformedObject);

        setEncodedCsvUri(window.encodeURI(`data:text/csv;charset=utf-8,${csv}`));
      } catch (err) {
        console.error(err);
        setEncodedCsvUri(null);
      }
    }
  }, [data]);

  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute("href", encodedCsvUri);
      ref.current.setAttribute("download", "stats.csv");
    }
  }, [encodedCsvUri]);

  return data && <a ref={ref}>Export CSV</a>;
};

export default StatsExport;

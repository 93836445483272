import _orderBy from "lodash/orderBy";

import { getDataFromFirebaseCollection } from "./funcs";

const prepareRooms = async (roomsSnapshots, userId) => {
  const rooms = getDataFromFirebaseCollection(roomsSnapshots);

  const newRooms = [];
  for (const room of rooms) {
    let clientSnapshot;

    try {
      clientSnapshot = await room.client.get();
    } catch (ex) {
      console.log(ex.message);
    }

    newRooms.push({
      ...room,
      client: { id: clientSnapshot.id, ...clientSnapshot.data() },
    });
  }

  return _orderBy(newRooms, ["lastMessage.createdAt"], ["desc"]);
};

export const modifiedPreparedRooms = async (roomsSnapshots) => {
  const rooms = getDataFromFirebaseCollection(roomsSnapshots);

  const newRooms = [];
  const result = await Promise.all(rooms.map((r) => r.client.get()));

  rooms.forEach((r, i) => {
    newRooms.push({
      ...r,
      client: { id: result[i].id, ...result[i].data() },
    });
  });

  return _orderBy(newRooms, ["lastMessage.createdAt"], ["desc"]);
};

export default prepareRooms;

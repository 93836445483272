import { Box, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import useCurrentUser from "../../../hooks/useCurrentUser";
import { loadingNotification, loadMoreNotifications } from "../reducer";
import CustomCard from "../../../components/CustomCard";
import { getNotificationsListQuery, prepareNotificationsForRedux } from "../utils";
import NotificationItems from "../NotificationsItems";
import { useNotificationContext } from "../utils/notificationContext";

const FullNotificationList = () => {
  const notifications = useSelector((state) => state.notifications);

  console.log("notifications", notifications);

  const dispatch = useDispatch();
  const { user } = useCurrentUser();

  const { getNotificationRequest } = useNotificationContext();

  const onLoadMore = async () => {
    const lastNotificationId = notifications.listIds[notifications.listIds.length - 1];

    try {
      dispatch(loadingNotification(true));
      const sn = await getNotificationsListQuery(user.id, 5, notifications.listMap[lastNotificationId].snapshot).get();

      if (!sn.empty) {
        const modifiedNotifications = await getNotificationRequest(sn.docs);
        const { listIds, listMap } = prepareNotificationsForRedux(modifiedNotifications);

        // dispatch load more action to update redux status
        dispatch(
          loadMoreNotifications({
            listIds,
            listMap,
            error: null,
          })
        );
      }
      dispatch(loadingNotification(false));
    } catch (ex) {
      console.log(ex.message);
      dispatch(
        loadMoreNotifications({
          listIds: [],
          listMap: {},
          error: ex.message,
        })
      );
      dispatch(loadingNotification(false));
    }
  };

  return (
    <CustomCard width="50%" title="Notifications">
      <NotificationItems notifications={notifications} />
      {notifications.listIds.length ? (
        <Box display="flex" justifyContent="center" width="100%">
          <Button disabled={notifications.loading} onClick={onLoadMore}>
            Load More
          </Button>
        </Box>
      ) : null}
    </CustomCard>
  );
};

export default FullNotificationList;

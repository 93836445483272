import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const citiesSlice = createSlice({
  name: "@@@Cities",
  initialState,
  reducers: {
    setCities(_, action) {
      return action.payload;
    },
  },
});

export const { setCities } = citiesSlice.actions;
export default citiesSlice.reducer;

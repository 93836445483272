import { Typography, useTheme } from "@material-ui/core";

import useSharedStyles from "../../../hooks/useSharedStyles";

const PropertyAddress = ({ record }) => {
  const theme = useTheme();
  const { styles } = useSharedStyles();

  return record && record.addressDetails && record.cityName ? (
    <>
      <div className={styles.truncate1}>
        <Typography>{record.addressDetails.en}</Typography>
      </div>
      <Typography variant="subtitle2" style={{ color: theme.palette.grey[500] }}>
        {record.cityName.en}, {record.areaName.en}
      </Typography>
    </>
  ) : null;
};

export default PropertyAddress;

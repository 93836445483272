import { useController } from "react-hook-form";
import { TextField } from "@material-ui/core";

export const CustomTextField = ({ controllerProps, textFieldProps, transformIn, transformOut }) => {
  const {
    field,
    fieldState: { isTouched, error },
  } = useController(controllerProps);

  return (
    <TextField
      {...field}
      onChange={(e) => {
        const value = e.target.value;
        field.onChange(transformOut ? transformOut(value) : textFieldProps.type === "number" ? +value : value);
      }}
      onKeyDown={(evt) => {
        if (textFieldProps.type === "number") {
          ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
        }
      }}
      value={transformIn ? transformIn(field.value) : field.value}
      error={isTouched && error !== undefined}
      helperText={error?.message}
      fullWidth
      size="small"
      variant="outlined"
      {...textFieldProps}
    />
  );
};

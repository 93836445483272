import { normalizeText } from "../../../Notifications/NotificationList/utils";
import { NotificationEvent, NotificationType } from "./types";

export const notificationData = () => ({
  [NotificationType.REQUEST]: {
    [NotificationEvent.CREATED]: {
      title: normalizeText(`New request created`),
      color: "#ccc",
      char: "R",
    },
  },
  [NotificationType.LANDLORD]: {
    [NotificationEvent.CREATED]: {
      title: normalizeText(`New landlord created`),
      color: "#ccc",
      char: "L",
    },
  },
  [NotificationType.REVIEW]: {
    [NotificationEvent.CREATED]: {
      title: normalizeText(`New review created`),
      color: "#ccc",
      char: "R",
    },
  },
  [NotificationType.REQUESTAUTOREPLY]: {
    [NotificationEvent.MATCHED]: {
      title: normalizeText(`Request auto reply`),
      color: "#ccc",
      char: "R",
      body: "Matched properties have been sent successfuly",
    },
    [NotificationEvent.NOTMATCHED]: {
      title: normalizeText(`Request auto reply`),
      color: "#ccc",
      char: "R",
      body: "No matched properties",
    },
  },
});

import firebase from "firebase/compat/app";

export const serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();

export const injectMetadataToSnapshotData = (snapshot) => ({
  ...snapshot.data(),
  id: snapshot.id,
  ref: snapshot.ref,
  snapshot,
});

import { useEffect, useState } from "react";
import { useGetPermissions } from "react-admin";

import { UserRoles } from "../enums";
import useCurrentUser from "./useCurrentUser";

/**
 * this hook won't work outside react admin 'Admin' component
 * will return 'undefined', so use it inside
 * */
const useIsSales = () => {
  const { user } = useCurrentUser();
  const [isSales, setIsSales] = useState(user.permissions && user.permissions.role === UserRoles.propertySales);
  const res = useGetPermissions();

  useEffect(() => {
    (async function () {
      const permissions = await res();
      const _isSales = permissions && permissions.role === UserRoles.propertySales;
      if (_isSales !== isSales) setIsSales(_isSales);
    })();
  }, []);

  return { isSales };
};

export default useIsSales;

import { ResourcesKeys } from "../../enums";
import employeesResource from "./employees";
import companyReviewsResource from "./companyReviews";
import reviewsResource from "./reviews";
import tenantsResource from "./tenants";
import packagesResource from "./packages";
import citiesResource from "./cities";
import propertiesRequestsResource from "./propertiesRequests";
import propertiesCompaniesResource from "./propertiesCompanies";
import clientsResource from "./clients";
import companyPropertiesResource from "./companyProperties";
import propertiesResource from "./properties";
import userGuideResource from "./userGuide";
import areasResource from "./areas";
import announcementsResource from "./announcements";
import adsResource from "./ads";

export const requestOverrides = {
  ...areasResource,
  ...citiesResource,
  ...clientsResource,
  ...companyPropertiesResource,
  ...companyReviewsResource,
  ...employeesResource,
  ...packagesResource,
  ...propertiesResource,
  ...propertiesCompaniesResource,
  ...propertiesRequestsResource,
  ...reviewsResource,
  ...tenantsResource,
  ...userGuideResource,
  ...announcementsResource,
  ...adsResource,
};

export const resourceNameOverrides = {
  companyProperties: ResourcesKeys.Properties,
  admins: ResourcesKeys.Employees,
  supervisors: ResourcesKeys.Employees,
  sales: ResourcesKeys.Employees,
};

const appConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  vapidToken: process.env.REACT_APP_VAPID_TOKEN,
  cloudFunctionsBaseUrl: process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL,
  cloudFunctionsExternalUrl: process.env.REACT_APP_CLOUD_FUNCTIONS_EXTERNAL_URL,
};

export default appConfig;

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useGetUserRole from "./useGetUserRole";
import useCurrentUser from "./useCurrentUser";
import chatActionCreators from "../redux/actions/chat";
import { ChatType } from "../enums";

const useChatPrepared = (type) => {
  const dispatch = useDispatch();
  const { role } = useGetUserRole();
  const { user } = useCurrentUser();

  useEffect(() => {
    // get rooms from here
    if (type === ChatType.company) dispatch(chatActionCreators.getCompanyRooms(user.companyId, user.id, role));
    if (type === ChatType.property) dispatch(chatActionCreators.getPropertyRooms(user.companyId, user.id, role));
    if (type === ChatType.reply) dispatch(chatActionCreators.getReplyRooms(user.companyId, user.id, role));
  }, []);
};

export default useChatPrepared;

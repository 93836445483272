import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useRedirect } from "react-admin";
import { Box, makeStyles, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import useCurrentUser from "../../../hooks/useCurrentUser";
import { truncateText } from "../../../utils/general";
import { updateNotification } from "../utils";
import NotificationImage from "../NotificationType/NotificationImage";
import TimeCounter from "./TimeCounter";
import { useNotificationContext } from "../utils/notificationContext";

dayjs.extend(relativeTime);

const useStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
    borderRadius: 5,
    padding: 16,

    "&:hover": {
      backgroundColor: "#e8e8e8",
    },
  },
}));

const NotificationItem = ({ notification, handleClose }) => {
  const classes = useStyles({});

  const dispatch = useDispatch();
  const redirect = useRedirect();

  const { user } = useCurrentUser();
  const { getNotificationData, onRedirect } = useNotificationContext();

  const handleClickNotification = async (e) => {
    updateNotification(notification, user.id, dispatch);
    handleClose?.(e);

    onRedirect(notification, redirect);
  };

  const notificationData = useMemo(() => getNotificationData(notification), [notification]);

  console.log("notificationData", notificationData, notification);

  return (
    <Box className={classes.root} onClick={handleClickNotification}>
      <Box display="flex" justifyContent="space-between">
        <Box width={"95%"} display="flex">
          <NotificationImage color={notificationData.color} char={notificationData.char} />

          <Box width={"80%"} mx={1}>
            <Typography variant="body2" style={{ textTransform: "capitalize" }}>
              {notificationData.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              #{truncateText(notification.id, 15)}
              {notificationData?.body}
            </Typography>
          </Box>
        </Box>

        <Box display="flex">
          <TimeCounter endDate={notification.createdAt.toDate()} />

          {!notification.seenBy.includes(user.id) && <FiberManualRecordIcon style={{ fontSize: 16 }} color="error" />}
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationItem;

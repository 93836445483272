import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { Box, Link, Typography } from "@material-ui/core";
import _uniqBy from "lodash/uniqBy";
import { useRedirect } from "react-admin";

import {
  blockedCompaniesSubRef,
  clientDocRef,
  companyDocRef,
  messagesSubRef,
  roomDocRef,
} from "../../../utils/refrences.utils";
import useCurrentUser from "../../../hooks/useCurrentUser";
import Loader from "../../../components/Loader";
import { applyTimestamps } from "../../../utils/funcs";
import { ChatType } from "../../../enums";
import ChatFeed from "../ChatFeed";
import ChatInput from "../ChatInput";
import { injectMetadataToSnapshotData } from "../../../utils/firebase.utils";
import CustomCard from "../../../components/CustomCard";

const ChatWithSingleClient = () => {
  const { user: company } = useCurrentUser();
  const { clientId } = useParams();
  const redirect = useRedirect();

  const roomId = `${clientId}_${company.id}`;

  const [loading, setLoading] = useState(false);

  const [clientName, setClientName] = useState(null);
  const [gettingClientLoading, setGettingClientLoading] = useState(true);

  const [room, setRoom] = useState(null);

  const [gettingMessagesLoading, setGettingMessagesLoading] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messages, setMessages] = useState([]);

  const [isBlocked, setIsBlocked] = useState(true);
  const [error, setError] = useState(null);
  const chatFeedRef = useRef();

  const scrollAfterNewMessage = () => {
    const ref = chatFeedRef.current;
    ref.scrollTo(0, ref.scrollHeight);
  };

  const checkIfRoomIsExist = useCallback(async (companyId, clientId) => {
    try {
      setLoading(true);
      setError(null);

      const snapshot = await getDoc(roomDocRef(`${clientId}_${companyId}`));
      setLoading(false);
      if (snapshot.exists()) {
        setRoom({
          ...snapshot.data(),
          id: snapshot.id,
          ref: snapshot.ref,
        });
        return true;
      }
      return false;
    } catch (ex) {
      setLoading(false);
      setError("Couldn't check room existence");
      console.log(ex.message);
      return false;
    }
  }, []);

  const createChatRoomWithInitialMsg = useCallback(
    async (companyId, clientId, clientName) => {
      try {
        setLoading(true);
        setError(null);

        await setDoc(
          roomDocRef(roomId),
          applyTimestamps({
            data: {
              company: companyDocRef(companyId),
              client: clientDocRef(clientId),
              disabled: false,
              type: ChatType.company,
              reply: null,
              property: null,
              lastMessage: applyTimestamps({
                data: {
                  from: companyDocRef(companyId),
                  to: clientDocRef(clientId),
                  text: `Hello ${clientName}`,
                  attachment: null,
                  seen: false,
                },
                isCreate: true,
              }),
            },
            isCreate: true,
          })
        );

        const message = applyTimestamps({
          data: {
            from: companyDocRef(companyId),
            to: clientDocRef(clientId),
            text: `Hello ${clientName}`,
            seen: false,
            attachment: null,
            arrangeVisit: false,
          },
          isCreate: true,
        });

        await setDoc(doc(messagesSubRef(roomId)), message);

        const createdRoomSnap = await getDoc(roomDocRef(`${clientId}_${companyId}`));
        setRoom({
          ...createdRoomSnap.data(),
          id: createdRoomSnap.id,
          ref: createdRoomSnap.ref,
        });

        setLoading(false);
      } catch (ex) {
        console.log(ex.message);
        setLoading(false);
      }
    },
    [roomId]
  );

  const getClientData = useCallback(async (clientId) => {
    try {
      setGettingClientLoading(true);
      setError(null);

      const userSnapshot = await clientDocRef(clientId).get();
      if (userSnapshot.exists) setClientName(userSnapshot.data().displayName);
      setGettingClientLoading(false);
    } catch (ex) {
      setGettingClientLoading(false);
      setError("Error on getting client!");
      console.log(ex.message);
    }
  }, []);

  const getMessages = useCallback(async (roomRef) => {
    try {
      setGettingMessagesLoading(true);

      const messagesSnapshots = await messagesSubRef(roomRef.id).orderBy("createdAt", "desc").limit(20).get();
      if (messagesSnapshots.size) {
        setMessages(messagesSnapshots.docs.map(injectMetadataToSnapshotData));
      }
      setGettingMessagesLoading(false);
    } catch (ex) {
      setGettingMessagesLoading(false);
      console.log(ex.message);
    }
  }, []);

  const lastMessageSnapshot = messages[messages.length - 1]?.snapshot;
  const loadMoreMessages = useCallback(
    async (roomRef) => {
      if (lastMessageSnapshot)
        try {
          const messagesSnapshots = await messagesSubRef(roomRef.id)
            .orderBy("createdAt", "desc")
            .startAfter(lastMessageSnapshot)
            .limit(15)
            .get();
          if (messagesSnapshots.size) {
            setMessages((prevState) => [...prevState, ...messagesSnapshots.docs.map(injectMetadataToSnapshotData)]);
          }
        } catch (ex) {
          console.log(ex.message);
        }
    },
    [lastMessageSnapshot]
  );

  const listenToMessages = useCallback((roomRef) => {
    return messagesSubRef(roomRef.id)
      .orderBy("createdAt", "desc")
      .limit(1)
      .onSnapshot({
        next(snapshots) {
          snapshots.docChanges().forEach((change) => {
            if (change.type === "added") {
              if (snapshots.size) {
                // console.log(snapshots.docs[0].data().text, snapshots.docs[0].data().createdAt);
                const message = injectMetadataToSnapshotData(snapshots.docs[0]);
                setMessages((prevState) => _uniqBy([message, ...prevState], "id"));
                scrollAfterNewMessage();
              }
            }
          });
        },
        error(ex) {
          console.log(ex.message);
        },
      });
  }, []);

  const sendMessage = useCallback(
    async (text, attachment) => {
      try {
        setSendingMessage(true);
        await setDoc(
          doc(messagesSubRef(roomId)),
          applyTimestamps({
            data: {
              from: companyDocRef(company.id),
              to: clientDocRef(clientId),
              text: text ?? null,
              seen: false,
              attachment: attachment ?? null,
              arrangeVisit: false,
            },
            isCreate: true,
          })
        );
        setSendingMessage(false);
      } catch (ex) {
        console.log(ex);
        setSendingMessage(false);
      }
    },
    [roomId, company.id, clientId]
  );

  const markRoomAsSeen = async (roomRef) => {
    try {
      updateDoc(
        doc(roomRef),
        applyTimestamps({
          data: {
            seenBy: companyDocRef(company.id),
          },
          isUpdate: true,
        })
      );
    } catch (ex) {
      console.log(ex);
    }
  };

  const checkBlockedChat = useCallback(() => {
    return blockedCompaniesSubRef(clientId)
      .where("companyId", "==", company.id)
      .onSnapshot({
        next(snapshots) {
          setIsBlocked(!!snapshots.size);
        },
      });
  }, [clientId, company.id]);

  useEffect(() => {
    (async function () {
      await getClientData(clientId);
    })();
  }, [getClientData, clientId]);

  useEffect(() => {
    const unsub = checkBlockedChat();

    return () => {
      unsub();
    };
  }, [checkBlockedChat]);

  useEffect(() => {
    (async function () {
      if (clientName) {
        const isRoomExist = await checkIfRoomIsExist(company.id, clientId);
        if (!isRoomExist) await createChatRoomWithInitialMsg(company.id, clientId, clientName);
      }
    })();
  }, [company.id, clientId, clientName, checkIfRoomIsExist, createChatRoomWithInitialMsg]);

  useEffect(() => {
    let unsub;

    (async function () {
      if (room) {
        await getMessages(room.ref);
        unsub = listenToMessages(room.ref);
      }
    })();

    return () => {
      unsub?.();
    };
  }, [getMessages, listenToMessages, room]);

  return (
    <CustomCard>
      <Box minHeight={100}>
        <Loader loading={gettingClientLoading} height="100%">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid #ccc"
            minHeight={50}
          >
            <Typography variant="h5">{clientName}</Typography>

            <Link
              onClick={() => {
                redirect("/companyChat");
              }}
              style={{ cursor: "pointer" }}
            >
              See All Chats
            </Link>
          </Box>
        </Loader>

        <Loader loading={loading || gettingMessagesLoading} height="100%">
          {room ? (
            <>
              <ChatFeed
                isPropertyChat={!!room.property}
                onScrollEnd={() => {
                  if (messages.length > 0) {
                    loadMoreMessages(room.ref);
                  }
                }}
                messages={messages}
                chatFeedRef={chatFeedRef}
                customHeight="60vh"
              />

              {isBlocked ? (
                <Box display="flex" justifyContent="center">
                  <Typography>You no longer can send a message to this client</Typography>
                </Box>
              ) : (
                <ChatInput
                  loading={false}
                  propertyLoading={false}
                  propertyError={null}
                  disabled={!room}
                  onSend={async (messageText) => {
                    await sendMessage(messageText, null);
                    scrollAfterNewMessage();
                  }}
                  onLoading={() => {}}
                  onAttach={(image) => {
                    sendMessage(null, image);
                  }}
                />
              )}
            </>
          ) : null}
        </Loader>
      </Box>
    </CustomCard>
  );
};

export default ChatWithSingleClient;

import { Typography } from "@material-ui/core";

import { CustomTextField } from "./base";
import { useEffect } from "react";

export const CustomPhoneNumber = ({ controllerProps, textFieldProps, form }) => {
  useEffect(() => {
    console.log(form.getValues(controllerProps.name));

    return () => {
      console.log("hey am unmounted...");
    };
  }, [form.formState]);

  return (
    <CustomTextField
      controllerProps={{
        ...controllerProps,
        rules: {
          ...controllerProps.rules,
          async validate(phone) {
            // in case we sent another validate function from outside
            return controllerProps.rules.validate
              ? await controllerProps.rules.validate(phone)
              : !/^\d{8}$/.test(`${phone}`.replace("+974", ""))
              ? "Must be 8 digits"
              : undefined;
          },
        },
      }}
      textFieldProps={{
        ...textFieldProps,
        InputProps: {
          startAdornment: (
            <Typography color="textSecondary" style={{ margin: "0px 8px 0px 8px" }}>
              +974
            </Typography>
          ),
        },
      }}
      transformIn={(phone) => phone?.replace("+974", "")}
      transformOut={(phone) => `+974${phone}`}
    />
  );
};

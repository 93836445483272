import { Box, makeStyles, useMediaQuery } from "@material-ui/core";
import BathtubIcon from "@material-ui/icons/Bathtub";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import HotelIcon from "@material-ui/icons/Hotel";

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: "middle",
    fill: theme.palette.grey[500],
  },
  counter: {
    display: "inline-block",
    marginRight: 2,
  },
  dot: {
    display: "inline-block",
    marginLeft: 5,
  },
  space: {
    color: theme.palette.grey[500],
    marginTop: 5,
  },
}));

const PropertyLayouts = ({ record, cellClassName }) => {
  const classes = useStyles();
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div className={cellClassName}>
      <Box display={isMd ? "block" : "flex"} alignItems="center">
        <div>
          <span className={classes.counter}>{record.bedrooms}</span>
          <HotelIcon className={classes.icon} />
          <span className={classes.dot}>.</span>
        </div>
        <div>
          <span className={classes.counter}>{record.bathrooms}</span>
          <BathtubIcon className={classes.icon} />
          <span className={classes.dot}>.</span>
        </div>
        <div>
          <span className={classes.counter}>{record.parking}</span>
          <DirectionsCarIcon className={classes.icon} />
        </div>
      </Box>
      <div className={classes.space}>{record?.space} Sqm</div>
    </div>
  );
};

export default PropertyLayouts;

import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  count: {
    color: theme.palette.grey[700],
    fontSize: 18,
    width: "50px",
    textAlign: "right",
  },
  label: {
    color: theme.palette.grey[600],
    margin: "0 5px",
    lineHeight: "27px",
    flex: 1,
  },
}));

const PackageCardItem = ({ label, count }) => {
  const classes = useStyles({});

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="body2" className={classes.count}>
        {count}
      </Typography>
      <Typography variant="body2" className={classes.label}>
        {label}
      </Typography>
    </Box>
  );
};

export default PackageCardItem;

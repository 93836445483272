import useGetEnumsAsValue from "../../../hooks/useGetEnumsAsValue";

const PropertyType = ({ record }) => {
  const category = useGetEnumsAsValue("propertiesCategories");
  const type = useGetEnumsAsValue("propertiesTypes");
  const purchasingOption = useGetEnumsAsValue("propertiesPurchasingOptions");

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <div style={{ whiteSpace: "nowrap" }}>{category(record?.category)}</div>
        <div style={{ whiteSpace: "nowrap" }}>{type(record?.type)}</div>
        <div style={{ whiteSpace: "nowrap" }}>{purchasingOption(record?.purchasingOption)}</div>
      </div>
    </div>
  );
};

export default PropertyType;

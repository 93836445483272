import { useEffect, useState } from "react";
import { Datagrid, List } from "ra-ui-materialui";
import { LinearProgress, Typography } from "@material-ui/core";

import { firestore } from "../../init/firebase";
import TablePagination from "../../components/TablePagination";
import TimestampField from "../../components/TimestampField";
import useRealTimeRefresh from "../../hooks/useRealTimeRefresh";
import { ResourcesKeys, Statistics } from "../../enums";
import CustomArrayField from "./components/CustomArrayField";
import useNotifications from "../../hooks/useNotifications";
import useGetCompanyId from "../../hooks/useGetCompanyId";

const ClientNameField = ({ record = {}, cellClassName }) => {
  const [clientData, setClientData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firestore
      .doc(`${ResourcesKeys.Clients}/${record.snapshot.ref.parent.parent.id}`)
      .get()
      .then((snapshot) => {
        if (snapshot.exists)
          setClientData({
            ...snapshot.data(),
            id: snapshot.id,
          });
        setLoading(false);
      })
      .catch((ex) => {
        console.log(ex.message);
        setLoading(false);
      });
  }, []);

  return (
    <Typography className={cellClassName}>
      {loading && !clientData && <LinearProgress />}
      {clientData && clientData.displayName}
    </Typography>
  );
};

const TenantsList = (props) => {
  const { companyId } = useGetCompanyId();

  useRealTimeRefresh();
  useNotifications(Statistics.landlordsLastSeenAt, ResourcesKeys.Tenants);

  return (
    <List
      {...props}
      pagination={<TablePagination />}
      sort={{
        field: "createdAt",
        order: "desc",
      }}
      filter={{ companyId }}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid style={{ tableLayout: "" }}>
        <ClientNameField source="clientId" sortable={false} />
        <TimestampField source="contractEndDate" sortable={false} />
        <CustomArrayField source="remindDates" />
        <TimestampField source="contractStartDate" sortable={false} />
        <CustomArrayField source="attachments" link />
        <TimestampField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default TenantsList;

import { RequestKeys } from "../../../enums";
import { dataProvider } from "../../firebase";
import { employeesCollPath } from "../../../utils/paths.utils";

const baseEmployee = {
  [RequestKeys.GET_ONE]: async (resource, queryData, reduxStore) => {
    return dataProvider.getOne(employeesCollPath(reduxStore.user.companyId), queryData);
  },
  [RequestKeys.UPDATE]: async (resource, queryData, reduxStore) => {
    // todo - revisit
    delete queryData.data.snapshot;
    return dataProvider.update(employeesCollPath(reduxStore.user.companyId), queryData);
  },
  [RequestKeys.CREATE]: async (resource, queryData, reduxStore) => {
    return dataProvider.create(employeesCollPath(reduxStore.user.companyId), queryData);
  },
};

export default baseEmployee;

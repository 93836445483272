import { collectionQuery, queryWithFilters, queryWithFiltersAndOrderBy } from "./query.utils";
import { landlordsCGRef, propertiesRequestsRef, reviewsSubRef, roomsRef, seenByCGRef } from "./refrences.utils";

// collection queries
export const propertiesReqQuery = collectionQuery(propertiesRequestsRef);
export const roomsQuery = queryWithFiltersAndOrderBy(roomsRef);

// sub collection queries
export const reviewsQuery = (id) => collectionQuery(reviewsSubRef(id));

// collection group query
export const landlordsQuery = collectionQuery(landlordsCGRef);
export const seenByQuery = queryWithFilters(seenByCGRef);

import { Box, CircularProgress, LinearProgress } from "@material-ui/core";
import PropTypes from "prop-types";

const Loader = ({
  loading,
  type = "circular",
  color = "primary",
  size = 20,
  height = "100%",
  position = "initial",
  children,
}) => {
  return loading ? (
    type === "circular" ? (
      <Box display="flex" justifyContent="center" alignItems="center" height={height} width="100%" position={position}>
        <CircularProgress size={size} color={color} />
      </Box>
    ) : (
      <LinearProgress color={color} />
    )
  ) : (
    <>{children}</>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["circular", "linear"]),
  color: PropTypes.oneOf(["primary", "secondary", "inherit"]),
  size: PropTypes.number,
  height: PropTypes.string,
  position: PropTypes.oneOf(["initial", "relative", "absolute"]),
};

export default Loader;

import {
  AutocompleteInput,
  BooleanInput,
  Filter,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";

import AreasFilter from "./AreasFilter";
import SearchCompanies from "./SearchCompanies";

const AdminPropertiesFilters = (props) => {
  return (
    <Filter {...props}>
      {/*<ReferenceInput
        label="Company Name (en)"
        source="companyId"
        reference="propertiesCompanies"
        filterToQuery={(q) => ({ "name.en": q, autoComplete: "name.en" })}
        perPage={10}
        variant="outlined"
      >
        <AutocompleteInput optionText="name.en" />
      </ReferenceInput>*/}
      <SearchCompanies label="Company name" source="companyId" />
      <ReferenceInput label="City" source="cityId" reference="cities" variant="outlined">
        <SelectInput optionText="name.en" />
      </ReferenceInput>
      <AreasFilter label="Area" source="areaId" reference="areas" optionText="name.en" variant="outlined" />
      <TextInput label="Reference ID" source="identifier" variant="outlined" size="small" />
      <NumberInput label="Min Price" source="priceFrom" variant="outlined" size="small" />
      <NumberInput label="Max Price" source="priceTo" variant="outlined" size="small" />
      <BooleanInput label="Published" source="published" defaultValue={false} />
      <BooleanInput label="Featured" source="featured" defaultValue={false} />
      <BooleanInput label="Boosted" source="boosted" defaultValue={false} />
    </Filter>
  );
};

export default AdminPropertiesFilters;

import { Route } from "react-router-dom";
import { Resource } from "react-admin";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import PeopleIcon from "@material-ui/icons/People";

import { PropertiesCreate, PropertiesEdit, PropertiesList } from "../../companyProperties";
import { PropertiesRequestsList, RequestMatches, RequestPreview } from "../../propertiesRequests";
import { EmployeeList } from "../../employees";
import { ResourcesKeys, UserRoles } from "../../../enums";
import PropertyChat from "../../chat/PropertyChat";
import ReplyChat from "../../chat/ReplyChat";
import ReplyPreview from "../../propertiesRequests/ReplyPreview";
import CompanyHome from "./Home";
import FullNotificationList from "../../../pages/NotificationsListInitialization/FullNotificationList";

export const supervisorDashboard = {
  resources: [
    <Resource name={ResourcesKeys.Clients} />,
    <Resource name={ResourcesKeys.Cities} />,
    <Resource name={ResourcesKeys.Areas} />,
    <Resource name={ResourcesKeys.PropertiesCompanies} />,
    <Resource
      name={ResourcesKeys.CompanyProperties}
      list={PropertiesList}
      create={PropertiesCreate}
      edit={PropertiesEdit}
      options={{
        label: "Properties",
      }}
      icon={HomeWorkIcon}
    />,
    <Resource
      name={ResourcesKeys.PropertiesRequests}
      list={PropertiesRequestsList}
      show={RequestPreview}
      options={{
        label: "Requests",
      }}
      icon={AssignmentLateIcon}
    />,
    <Resource
      name={ResourcesKeys.Sales}
      list={EmployeeList}
      options={{
        label: "Sales",
        type: UserRoles.propertySales,
      }}
      icon={PeopleIcon}
    />,
  ],
  customRoutes: [
    <Route exact path={`/${ResourcesKeys.PropertiesRequests}/:requestId/matches`} component={RequestMatches} />,
    <Route exact path="/propertyChat" component={PropertyChat} />,
    <Route exact path="/replyChat" component={ReplyChat} />,
    <Route exact path={`/${ResourcesKeys.Reply}/:replyId`} component={ReplyPreview} />,
    <Route exact path="/notifications" component={FullNotificationList} />,
  ],
  home: () => <CompanyHome redirectEnabled />,
};

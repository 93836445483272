import { Box, IconButton, useMediaQuery } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const RedirectBack = () => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  let history = useHistory();
  const handleRedirect = () => {
    history.goBack();
  };

  return isMd ? (
    <Box order={-1}>
      <IconButton aria-label="delete" onClick={handleRedirect}>
        <ArrowBackIcon style={{ color: "#fff" }} />
      </IconButton>
    </Box>
  ) : null;
};

export default RedirectBack;

import { memo } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { has } from "lodash/fp";

import { areEqual } from "../utils/general";

const CustomSelectInput = (props) => {
  const { isRequired, label, name, touched, error, options, translate, onChange, disabled, ...rest } = props;

  return (
    <TextField
      label={label}
      name={name}
      disabled={disabled}
      error={touched && error}
      required={isRequired}
      onChange={onChange}
      select
      helperText={touched && error && translate(error)}
      variant="outlined"
      size="small"
      fullWidth
      {...rest}
    >
      {Array.isArray(options) &&
        options.map((option) => (
          <MenuItem value={option.id} key={option.id}>
            {option.name.en}
          </MenuItem>
        ))}
    </TextField>
  );
};

CustomSelectInput.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.objectOf((props) => {
      return has(props)("name.en") && has(props)("name.ar") ? null : "Options should contain 'name.en', 'name.ar'";
    })
  ),
  translate: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  rest: PropTypes.object,
};

export default memo(CustomSelectInput, areEqual);

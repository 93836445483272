import { useNotify, useRefresh, useUnselectAll } from "react-admin";
import Delete from "@material-ui/icons/Delete";

import { firestore } from "../../../../init/firebase";
import { sharedTheme } from "../../../../theme";
import { applyTimestamps } from "../../../../utils/funcs";
import ConfirmationPopover from "../../../../components/ConfirmationPopover";
import useIsAdmin from "../../../../hooks/useIsAdmin";
import { propertyDocRef } from "../../../../utils/refrences.utils";

const DeletePropertyButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const { isAdmin } = useIsAdmin();

  const deleteProperty = async () => {
    try {
      const batch = firestore.batch();

      Array.isArray(selectedIds) &&
        selectedIds.forEach((propertyId) => {
          batch.update(
            propertyDocRef(propertyId),
            applyTimestamps({
              data: {
                deleted: true,
              },
              isUpdate: true,
            })
          );
        });
      await batch.commit();

      notify("Properties have been deleted", "success");
      unselectAll(!isAdmin ? "companyProperties" : "properties");
      refresh();
    } catch (e) {
      console.log(e.message);
      notify("Error on deletion, please make sure to unselect published properties or unpublish them first", "error");
      refresh();
    }
  };

  return (
    <ConfirmationPopover
      message="Are you sure you want to delete property?"
      onConfirm={deleteProperty}
      buttonText="Delete"
      buttonStyle={{ backgroundColor: sharedTheme.error.main }}
      StartIcon={() => <Delete color="error" />}
    />
  );
};

export default DeletePropertyButton;

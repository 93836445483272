import { Box, Grid, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { Children } from "react";

const Spacing = ({ spacing = 1, alignItems = "center", children }) => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Grid container spacing={spacing}>
      {Children.map(children, (child) => (
        <Grid item xs={isMd ? 12 : 12 / Children.count(children)}>
          <Box display="flex" alignItems={alignItems} justifyContent="center" height="100%">
            {child}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

Spacing.propTypes = {
  spacing: PropTypes.number,
  alignItems: PropTypes.oneOf(["center", "flex-start", "flex-end"]),
};

export default Spacing;

import appConfig from "../../init/config";

/**
 * @param props {{
 *   idValue: string,
 *   idKey: "companyId" | "propertyId",
 *   eventName: string,
 *   date: {
 *     startDate: string,
 *     endDate: string
 *   }
 * }}
 * */
export const constructBigDataQueryUrl = (props) => {
  const { idValue, idKey, eventName, date } = props;
  return `${appConfig.cloudFunctionsBaseUrl}/bigQueryData?idValue=${idValue}&&idKey=${idKey}&&eventName=${eventName}&&startDate=${date.startDate}&&endDate=${date.endDate}`;
};

import { Box, Button, Chip, FormControlLabel, ListItem, Menu, Radio, RadioGroup, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useEffect, useState } from "react";
import { useNotify, useRefresh, useUnselectAll } from "react-admin";
import { from, of } from "rxjs";
import { catchError, filter, map, switchMap, toArray } from "rxjs/operators";

import CustomSearch from "../../../../components/CustomSearch";
import Loader from "../../../../components/Loader";
import { ResourcesKeys, UserRoles } from "../../../../enums";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import useGetCompanyId from "../../../../hooks/useGetCompanyId";
import { firestore } from "../../../../init/firebase";
import { applyTimestamps } from "../../../../utils/funcs";
import { employeesDocRef, employeesSubRef } from "../../../../utils/refrences.utils";

const useStyles = makeStyles((theme) => ({
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

const AssignEmployees = ({ selectedIds, ClickBtn = null }) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [supervisors, setSupervisors] = useState([]);
  const { companyId } = useGetCompanyId();
  const [value, setValue] = useState(undefined);
  const [helperText, setHelperText] = useState("Select Mode");
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const { user } = useCurrentUser();

  const handleRadioChange = (event) => {
    event.stopPropagation();
    setValue(event.target.value);
    setHelperText("");
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
    setValue(undefined);
  };

  const getEmployees = (query, value) => {
    return from(query.where("role", "==", UserRoles.propertySupervisor).limit(10).get()).pipe(
      switchMap((snapshot) => from(snapshot.docs)),
      map((empSnapshot) => {
        return {
          ...empSnapshot.data(),
          id: empSnapshot.id,
        };
      }),
      filter((employee) => (value ? employee.displayName.startsWith(value) : employee)),
      toArray(),
      catchError((ex) => {
        console.log(ex.message);
        return of([]);
      })
    );
  };

  const assignEmployee = async (supervisor) => {
    handleClose();
    try {
      const batch = firestore.batch();
      Array.isArray(selectedIds) &&
        selectedIds.forEach((id) => {
          batch.update(
            employeesDocRef(user.companyId, id),
            applyTimestamps({
              data: {
                parentId: supervisor.id,
                with: value === "with",
              },
              isUpdate: true,
            })
          );
        });
      if (value) await batch.commit();

      notify("Properties assigned to employee", "success");
      unselectAll(ResourcesKeys.Sales);
      refresh();
    } catch (ex) {
      console.log(ex.message);
      notify("Error on assign properties", "error");
      refresh();
    }
  };

  useEffect(() => {
    setLoading(true);
    getEmployees(employeesSubRef(companyId)).subscribe({
      next(supervisors) {
        setLoading(false);
        setSupervisors(supervisors);
      },
    });
  }, [user.id]);

  const renderMenuItems = (employee, getItemProps) => {
    return (
      <ListItem
        button
        {...getItemProps({
          key: employee.displayName,
          item: employee,
        })}
      >
        <Box display="flex" alignItems="center">
          {employee.displayName}
          <Chip style={{ cursor: "pointer", margin: "0 4px 0 4px" }} label="Supervisor" size="small" />
        </Box>
      </ListItem>
    );
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Box aria-controls="employees-menu" aria-haspopup="true" onClick={handleClick}>
        {ClickBtn ? <ClickBtn /> : <Button startIcon={<PersonAddIcon />}>Assign</Button>}
      </Box>
      <Menu id="employees-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <Box p={2} tabIndex={null}>
          <Typography>Select Properties Mode</Typography>
          <RadioGroup value={value} onChange={handleRadioChange}>
            <Box display="flex" alignItems="center">
              <FormControlLabel value="with" control={<Radio size="small" color="primary" />} label="With" />
              <FormControlLabel value="without" control={<Radio size="small" color="primary" />} label="Without" />
            </Box>
          </RadioGroup>
          {!value && (
            <Typography variant="caption" color="error">
              {helperText}
            </Typography>
          )}
        </Box>

        <Box className={!value ? classes.disabled : ""}>
          <Loader loading={loading} size={24}>
            <CustomSearch
              query={employeesSubRef(companyId)}
              source="displayName"
              label="supervisor"
              initial={supervisors}
              getResults={getEmployees}
              renderMenuItems={renderMenuItems}
              alwaysOpened
              onSelect={assignEmployee}
            />
          </Loader>
        </Box>
      </Menu>
    </Box>
  );
};

export default AssignEmployees;

import { call, put, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { isNil, pick } from "lodash/fp";

import firebase from "../../init/firebase";
import { logout, updateUserData, userLoading } from "../reducers/user";
import { UserRoles } from "../../enums";
import { getCompanyInfo, getEmployeeInfo } from "../../utils/syncUser.utils";

function getAuthChannel() {
  return eventChannel((emit) => {
    return firebase.auth().onAuthStateChanged((user) => emit({ user }));
  });
}

export default function* watchForFirebaseAuth() {
  yield put(userLoading(true));

  // This is where you wait for a callback from firebase
  const channel = yield call(getAuthChannel);

  while (true) {
    const res = yield take(channel);

    if (res.user) {
      const idTokenResult = yield res.user.getIdTokenResult();
      const claims = idTokenResult.claims;

      const companyId = isNil(claims.companyId) ? claims.sub : claims.companyId;
      const employeeId = !isNil(claims.companyId) ? claims.sub : null;

      let statistics;
      switch (claims.role) {
        case UserRoles.propertyCompany:
          statistics = yield getCompanyInfo(companyId);
          break;
        case UserRoles.propertyAdmin:
          statistics = yield getEmployeeInfo(companyId, employeeId);
          break;
        case UserRoles.propertySupervisor:
          statistics = yield getEmployeeInfo(companyId, employeeId);
          break;
        case UserRoles.propertySales:
          statistics = yield getEmployeeInfo(companyId, employeeId);
          break;
        default:
          statistics = {};
      }

      const userData = pick(["email", "displayName", "phoneNumber", "photoUrl"])(res.user);
      const permissions = pick(["name", "role", "ref", "sub", "email_verified", "companyId"])(idTokenResult.claims);

      yield put(
        updateUserData({
          ...userData,
          ...statistics,
          permissions,
          createdAt: res.user.metadata ? new Date(res.user.metadata.creationTime) : null,
          id: res.user.uid,
          companyId,
        })
      );
    } else yield put(logout());
    yield put(userLoading(false));
  }
}

import DeleteIcon from "@material-ui/icons/Delete";
import { useNotify, useRefresh, useUnselectAll } from "react-admin";

import ConfirmationPopover from "../../../components/ConfirmationPopover";
import { ResourcesKeys } from "../../../enums";
import { firestore } from "../../../init/firebase";
import { sharedTheme } from "../../../theme";
import { applyTimestamps } from "../../../utils/funcs";
import { packageDocRef } from "../../../utils/refrences.utils";

const PackageDelete = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const deleteProperty = async () => {
    try {
      const batch = firestore.batch();
      selectedIds.forEach((packageId) => {
        batch.update(
          packageDocRef(packageId),
          applyTimestamps({
            data: {
              deleted: true,
            },
            isUpdate: true,
          })
        );
      });
      await batch.commit();

      notify("Packages have been deleted", "success");
      unselectAll(ResourcesKeys.Packages);
      refresh();
    } catch (e) {
      console.log(e.message);
      notify("Error on deletion", "error");
    }
  };

  return (
    <ConfirmationPopover
      message="Are you sure you want to delete package/s?"
      buttonText="Delete"
      onConfirm={deleteProperty}
      buttonStyle={{ backgroundColor: sharedTheme.error.main }}
      StartIcon={DeleteIcon}
    />
  );
};

export default PackageDelete;

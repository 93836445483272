import RequestKeys from "./requestKeys";
import ResourcesKeys from "./resourceKeys";
import Locals from "./locales";
import ChatType from "./chatType";
import FormGroupNames from "./forms";
import UserRoles from "./user";
import Statistics from "./statistics";

const UserAuthTypes = {
  email: "email",
  phone: "phone",
};

const PaginationDirection = {
  next: "next",
  prev: "prev",
  current: "current",
};

export {
  RequestKeys,
  ResourcesKeys,
  UserAuthTypes,
  PaginationDirection,
  Locals,
  ChatType,
  FormGroupNames,
  UserRoles,
  Statistics,
};

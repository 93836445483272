import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import ConfirmationPopover from "../../../components/ConfirmationPopover";
import PackageCardItem from "./PackageCardItem";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[300]}`,
    width: 150,
    borderRadius: 5,
    margin: 15,
    padding: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  counts: {
    margin: "20px 0",
  },
  popover: {
    padding: 15,

    "& .MuiButton-root": {
      textAlign: "right",
      marginTop: 10,
    },
  },
}));

const PackageCard = ({ cPackage, handleRenew }) => {
  const classes = useStyles({});

  return (
    <Box className={classes.card}>
      <Typography className={classes.title}>{cPackage.name.en}</Typography>

      <div className={classes.counts}>
        <PackageCardItem label="Featured" count={cPackage.featured} />
        <PackageCardItem label="Boosted" count={cPackage.boosted} />
        <PackageCardItem label="Published" count={cPackage.published} />
        <PackageCardItem label="Total" count={cPackage.total} />
        <PackageCardItem label="Days" count={cPackage.days} />
      </div>

      <ConfirmationPopover
        onConfirm={() => handleRenew(cPackage.id)}
        message={`Are you sure you want to select ( ${cPackage.name.en} ) package?`}
        buttonText="Select"
      />
    </Box>
  );
};

export default PackageCard;

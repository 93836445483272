const PropertyChatActionTypes = {
  SYNC_PROPERTY_MESSAGES: "CHAT/syncPropertyMessages",
  SYNC_COMPANY_MESSAGES: "CHAT/syncCompanyMessages",
  SYNC_REPLY_MESSAGES: "CHAT/syncReplyMessages",
  SYNC_PROPERTY_ROOMS: "CHAT/syncPropertyRooms",
  SYNC_COMPANY_ROOMS: "CHAT/syncCompanyRooms",
  SYNC_REPLY_ROOMS: "CHAT/syncReplyRooms",
  START_WATCH_ROOMS: "CHAT/startWatchRooms",
  GET_ALL_ROOMS: "CHAT/getAllRooms",
  GET_ROOM_MESSAGES: "CHAT/getRoomMessages",
  SEND_MESSAGE: "CHAT/sendMessage",
  MARK_ROOM_AS_SEEN: "CHAT/markRoomAsSeen",
  ADD_MESSAGE: "CHAT/addMessage",
  UPDATE_SYNCED_ROOM: "CHAT/updateSyncedRooms",
  GET_PROPERTY_ROOMS: "CHAT/getPropertyRooms",
  GET_COMPANY_ROOMS: "CHAT/getCompanyRooms",
  GET_REPLY_ROOMS: "CHAT/getReplyRooms",
};

export default PropertyChatActionTypes;

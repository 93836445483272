import { Button, Card, CircularProgress, TextField, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { Show, Title } from "react-admin";

import ShowItem from "../../components/ShowItem";
import useCurrentUser from "../../hooks/useCurrentUser";
import useGetCompanyId from "../../hooks/useGetCompanyId";
import { applyTimestamps } from "../../utils/funcs";
import { employeesDocRef, reviewsDocRef } from "../../utils/refrences.utils";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  reply: {
    width: 450,
    marginBottom: theme.spacing(2),
    display: "block",

    "& .MuiInputBase-root": {
      width: "100%",
    },
  },
  send: {
    flex: 0,
  },
}));

const List = ({ record }) => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles({});
  const [reply, setReply] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSameReply, setIsSameReply] = useState(true);
  const { user } = useCurrentUser();
  const { companyId } = useGetCompanyId();

  useEffect(() => {
    if (record.reply) {
      setReply(record.reply.text);
    } else {
      setReply("");
      setIsSameReply(false);
    }
  }, []);

  const handleChangeReply = (e) => {
    const value = e.target.value;
    setReply(value);
    if (record.reply) setIsSameReply(value.trim() === record.reply.text);
  };

  const isValidReply = reply.length > 1;
  const isButtonDisabled = isSameReply || loading || !isValidReply;

  const handleSendReply = async () => {
    setLoading(true);
    const employeeRef = employeesDocRef(companyId, user.id);
    const reviewRef = reviewsDocRef(companyId, record.id);

    try {
      await reviewRef.update(
        applyTimestamps({
          data: {
            reply: {
              text: reply.trim(),
              employee: employeeRef,
            },
          },

          isUpdate: true,
        })
      );
      setIsSameReply(true);
    } catch (ex) {
      console.log(ex.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title title="Add Review" />
      <ShowItem title="Text" value={record.text} />
      <ShowItem title="Rating" value={record.rating} />

      <TextField
        className={classes.reply}
        style={{ width: isSm ? "auto" : 450 }}
        variant="outlined"
        fullWidth
        label="Reply"
        size="small"
        multiline
        rows={3}
        rowsMax={6}
        value={reply}
        onChange={handleChangeReply}
        error={!isValidReply}
        disabled={loading}
        helperText={
          (!isValidReply && "Add a reply with min 2 characters") || (isSameReply && "Same reply, change it to send")
        }
      />
      <Button
        className={classes.send}
        color="primary"
        variant="contained"
        disabled={isButtonDisabled}
        onClick={handleSendReply}
        startIcon={loading ? <CircularProgress size={14} style={{ color: "white" }} /> : null}
      >
        Send
      </Button>
    </>
  );
};

const CompanyReviewsEdit = (props) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.card}>
      <Show component="div" {...props} actions={false}>
        <List />
      </Show>
    </Card>
  );
};

export default CompanyReviewsEdit;

import { useEffect, useState } from "react";
import { Box, FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

import Loader from "./Loader";
import ErrorMessage from "./ErrorMessage";

ChartJS.register(ArcElement, Tooltip, Legend);

const Chart = ({ type, title, data, loading, error }) => {
  const theme = useTheme();
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [dataLength, setDataLength] = useState([[], []]);
  const [value, setValue] = useState("total");

  const handleChange = (e) => {
    setValue(e.target.value);

    const key = e.target.value === "total" ? "total" : "unique";
    setDataLength([data[type][0][key], data[type][1][key]]);
  };

  useEffect(() => {
    if (data[type]) {
      setDataLength([data[type][0].total, data[type][1].total]);
    }
  }, [data]);

  return (
    <Box
      className="myBox"
      style={{
        padding: 16,

        width: "20%",
        boxSizing: "border-box",
        flex: isXs ? 1 : 0,
        // flexBasis: isXs ? "100%" : "30%",

        "& h2": {
          fontSize: 24,
          marginTop: theme.spacing(3),
          textAlign: "center",
        },
      }}
    >
      <Box
        style={{
          background: theme.palette.grey[100],
          boxShadow: `0 3px 4px ${theme.palette.grey[300]}`,
        }}
      >
        {loading ? (
          <Loader />
        ) : error ? (
          <ErrorMessage error={error} />
        ) : (
          <>
            <Doughnut
              data={{
                labels: ["Company", "Properties"],
                datasets: [
                  {
                    data: dataLength,
                    backgroundColor: ["rgba(49, 84, 68, 0.2)", "rgba(248, 231, 28, 0.2)"],
                    borderColor: ["rgba(49, 84, 68, 1)", "rgba(248, 231, 28, 1)"],
                    borderWidth: 1,
                  },
                ],
              }}
            />
            <Typography style={{ textAlign: "center" }} variant="h4">
              {title}
            </Typography>
            <RadioGroup
              aria-label="type"
              name="type"
              value={value}
              onChange={handleChange}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel value="total" control={<Radio />} label="Total" />
              <FormControlLabel value="unique" control={<Radio />} label="Unique" />
            </RadioGroup>
            <Box mt={2}>
              <Typography>Total: {dataLength[0] + dataLength[1]}</Typography>
              <Typography>Company: {dataLength[0]}</Typography>
              <Typography>Properties: {dataLength[1]}</Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Chart;

import { memo } from "react";
import { Box, ListItem, ListItemSecondaryAction, ListItemText, Typography, makeStyles } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import HomeIcon from "@material-ui/icons/Home";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ReplyIcon from "@material-ui/icons/Reply";

import useCurrentUser from "../../hooks/useCurrentUser";
import { areEqual } from "../../utils/general";
import useIsCompany from "../../hooks/useIsCompany";
import { ChatType } from "../../enums";

const useStyles = makeStyles((theme) => ({
  item: {
    padding: "10px 30px",
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  message: {
    maxWidth: 150,
  },
  notification: {
    color: theme.palette.error.main,
  },
  attachment: {
    color: theme.palette.grey[500],
    marginLeft: -4,
  },
}));

const ChatChannel = (props) => {
  const classes = useStyles({});
  const { user } = useCurrentUser();
  const { isCompany } = useIsCompany();
  const { type, client, onClick, lastMessage, currentRoomId, id: roomId } = props;

  const hasNotification = (userId) => !lastMessage.seen && lastMessage.to.id === userId && currentRoomId !== roomId;

  return (
    <ListItem button selected={currentRoomId === roomId} onClick={onClick} className={classes.item}>
      <ListItemText
        disableTypography
        primary={
          <Typography style={{ fontSize: 16 }} color="primary">
            {client.displayName}
          </Typography>
        }
        secondary={
          <Typography variant="body2" color="textSecondary" noWrap className={classes.message}>
            {lastMessage.text ? (
              lastMessage.text
            ) : lastMessage.attachment ? (
              <Box display="flex" alignItems="center" className={classes.attachment}>
                <AttachFileIcon fontSize="small" />
                <Typography style={{ fontSize: 14 }}>Attachment</Typography>
              </Box>
            ) : (
              ""
            )}
          </Typography>
        }
      />
      <ListItemSecondaryAction className={classes.actions}>
        {hasNotification(isCompany ? user.id : user.companyId) && (
          <Box color="red" fontSize={15}>
            <NotificationsIcon fontSize="inherit" className={classes.notification} />
          </Box>
        )}
        <div title="Chat for a specific property">
          {type === ChatType.reply && <ReplyIcon color="primary" fontSize="inherit" />}
          {type === ChatType.property && <HomeIcon color="primary" fontSize="inherit" />}
        </div>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default memo(ChatChannel, areEqual);

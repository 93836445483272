import { Box, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

import { PackageSizeType } from "../packages.types";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    // paddingRight: theme.spacing(8),
  },
  counter: {
    color: theme.palette.grey[700],
    lineHeight: 0.9,
  },
  counterLarge: {
    // fontSize: theme.spacing(6),
  },
  counterSmall: {
    // fontSize: theme.spacing(4),
  },
  of: {
    color: theme.palette.grey[500],
  },
  ofLarge: {
    // fontSize: theme.spacing(2.5),
  },
  ofSmall: {
    // fontSize: theme.spacing(1.85),
  },
  name: {
    color: theme.palette.grey[700],
    margin: 0,
  },
  nameLarge: {
    // fontSize: theme.spacing(3),
  },
  nameSmall: {
    // fontSize: theme.spacing(2),
  },
}));

const PackageCounter = ({ count, of, name, size }) => {
  const classes = useStyles({});
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box className={classes.container} pr={isSm ? 2 : 8}>
      <Box display="flex" alignItems="flex-end">
        <Typography
          style={{
            fontSize: size === "large" ? (isSm ? "24px" : "48px") : isSm ? "30px" : "40px",
          }}
          className={clsx([
            classes.counter,
            {
              [classes.counterLarge]: size === "large",
              [classes.counterSmall]: size === "small",
            },
          ])}
        >
          {count}
        </Typography>
        {of ? (
          <Typography
            style={{
              fontSize: size === "large" ? (isSm ? "20px" : "30px") : isSm ? "14px" : "22px",
            }}
            className={[
              classes.of,
              {
                [classes.ofLarge]: size === "large",
                [classes.ofSmall]: size === "small",
              },
            ]}
          >{`/${of}`}</Typography>
        ) : null}
      </Box>
      <Typography
        style={{
          fontSize: size === "large" ? (isSm ? "20px" : "30px") : isSm ? "14px" : "22px",
        }}
        className={clsx([
          classes.counter,
          {
            [classes.nameLarge]: size === "large",
            [classes.nameSmall]: size === "small",
          },
        ])}
      >
        {name}
      </Typography>
    </Box>
  );
};

PackageCounter.propTypes = {
  count: PropTypes.number,
  of: PropTypes.number,
  name: PropTypes.string,
  size: PropTypes.oneOf(PackageSizeType),
};

export default PackageCounter;

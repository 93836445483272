import { makeStyles } from "@material-ui/core";

const getTruncateStyles = (clamp, maxWidth = 150) => ({
  maxWidth,

  "& > [class^=MuiTypography-root]": {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": clamp,
    "-webkit-box-orient": "vertical",
  },
});

const useStyles = makeStyles((theme) => ({
  truncate1: getTruncateStyles(1),
  truncate2: getTruncateStyles(2),
  truncate3: getTruncateStyles(3),
  imageCell: {
    overflow: "hidden",
  },
  row: {
    display: "flex",
    "@media (max-width: 767px)": {
      display: "block",
    },
  },
  rowElement: {
    flex: 1,
    alignSelf: "flex-start",
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px 0px`,
    width: "100% !important",
  },
}));

const useSharedStyles = () => {
  const styles = useStyles({});

  return {
    styles,
  };
};

export default useSharedStyles;

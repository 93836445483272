import { useEffect } from "react";
import { useInput, useTranslate } from "ra-core";
import { useFormState } from "react-final-form";
import usePrevious from "use-previous";

import CustomSelectInput from "../../../components/CustomSelectInput";

const AreasSelectInput = ({ label, onChange: onAreaChange, areas, disabled, ...props }) => {
  const translate = useTranslate();
  const {
    values: { cityId },
  } = useFormState() || {};
  const previousCityId = usePrevious(cityId);
  const {
    input: { name, onChange, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  useEffect(() => {
    if (previousCityId && previousCityId !== cityId) {
      onAreaChange(null);
      onChange(null);
    }
  }, [cityId]);

  useEffect(() => {
    onAreaChange(value);
  }, [value]);

  return (
    <CustomSelectInput
      isRequired={isRequired}
      name={name}
      value={value}
      error={error}
      translate={translate}
      options={areas}
      label={label}
      touched={touched}
      onChange={onChange}
      disabled={disabled}
      rest={rest}
    />
  );
};

export default AreasSelectInput;

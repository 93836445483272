import { Datagrid, List, NumberField, TextField } from "react-admin";

import TablePagination from "../../components/TablePagination";
import useLocalizedRAFields from "../../hooks/useLocalizedRAFields";
import ListImage from "../../components/ListImage";
import useSharedStyles from "../../hooks/useSharedStyles";
import { useMediaQuery } from "@material-ui/core";

const UserGuideList = (props) => {
  const { styles } = useSharedStyles();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const localizedFields = useLocalizedRAFields([
    {
      key: "title",
      label: "Title",
      cellClassName: styles.truncate3,
      sortable: false,
    },
    {
      key: "description",
      label: "Description",
      cellClassName: styles.truncate3,
      sortable: false,
    },
  ]);

  return (
    <List {...props} sort={{ field: "sortIndex", order: "ASC" }} pagination={<TablePagination />} exporter={false}>
      <Datagrid style={{ tableLayout: isSm ? "" : "fixed" }} rowClick="edit">
        <NumberField source="sortIndex" />
        <TextField source="id" cellClassName={styles.truncate1} sortable={false} />
        {[localizedFields[0], localizedFields[1]]}
        <ListImage source="image.src" label="Image" sortable={false} />
        {[localizedFields[2], localizedFields[3]]}
      </Datagrid>
    </List>
  );
};

export default UserGuideList;

import { Provider } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { createGenerateClassName, StylesProvider, ThemeProvider } from "@material-ui/core";
import { ConnectedRouter } from "connected-react-router";

import store from "./redux/createStore";
import Home from "./components/Home";
import history from "./init/history";
import theme from "./theme";
import TermsEN from "./pages/Terms/TermsEN";
import TermsAR from "./pages/Terms/TermsAR";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
});

console.log("building...");

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StylesProvider generateClassName={generateClassName}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route exact path="/termsAR" component={TermsAR} />,
              <Route exact path="/termsEN" component={TermsEN} />,
              <Route>
                <Home history={history} />
              </Route>
            </Switch>
          </ConnectedRouter>
        </StylesProvider>
      </ThemeProvider>
    </Provider>
  );
}

import { Box, Typography } from "@material-ui/core";

const PhonePrefixCode = () => {
  return (
    <Box pr={1}>
      <Typography variant="body2" color="textSecondary">
        +974
      </Typography>
    </Box>
  );
};

export default PhonePrefixCode;

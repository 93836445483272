import { useSelector } from "react-redux";
import { Typography, useMediaQuery } from "@material-ui/core";
import { BooleanField, Datagrid, EmailField, List, TextField } from "react-admin";

import TablePagination from "../../components/TablePagination";
import ListImage from "../../components/ListImage";
import useSharedStyles from "../../hooks/useSharedStyles";
import TimestampField from "../../components/TimestampField";
import useIsSupervisor from "../../hooks/useIsSupervisor";
import useCurrentUser from "../../hooks/useCurrentUser";
import EmployeesBulkActions from "./components/listActions";
import useIsCompany from "../../hooks/useIsCompany";
import useIsCompanyAdmin from "../../hooks/useIsCompanyAdmin";
import { UserRoles } from "../../enums";
import EmployeeAssignees from "./components/EmployeeAssignees";

const RoleField = (props) => {
  const roles = useSelector((state) => state.roles);
  const roleId = props.record[props.source];

  return <Typography>{roles[roleId].label.en}</Typography>;
};

const EmployeeList = (props) => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { styles } = useSharedStyles();
  const { isCompany } = useIsCompany();
  const { isCompanyAdmin } = useIsCompanyAdmin();
  const { isSupervisor } = useIsSupervisor();
  const { user } = useCurrentUser();

  let filter = {};

  if (isSupervisor) {
    filter = {
      filters: {
        parentId: user.id,
      },
    };
  }

  const canViewBulkActions = () => {
    return (isCompany || isCompanyAdmin) && props.options.type === UserRoles.propertySales;
  };

  const canViewEmployeesAssignees = () =>
    (isCompany || isCompanyAdmin) && props.options.type === UserRoles.propertySales;

  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "desc" }}
      pagination={<TablePagination />}
      bulkActionButtons={
        canViewBulkActions() ? <EmployeesBulkActions isCompany={isCompany} isCompanyAdmin={isCompanyAdmin} /> : false
      }
      filter={filter}
      exporter={false}
    >
      <Datagrid style={{ tableLayout: isSm ? "" : "fixed" }} rowClick={isSupervisor ? "" : "edit"}>
        <TextField source="displayName" label="Name" cellClassName={styles.truncate3} sortable={false} />
        <EmailField source="email" cellClassName={styles.truncate3} sortable={false} />
        <ListImage source="photo.src" sortable={false} label="Photo" />
        <TextField source="phoneNumber" cellClassName={styles.truncate3} sortable={false} />
        <RoleField source="role" sortable={false} />
        <BooleanField source="disabled" sortable={false} />
        {canViewEmployeesAssignees() && <EmployeeAssignees label="Assignee" sortable={false} />}
        <TimestampField source="createdAt" label="Created At" />
      </Datagrid>
    </List>
  );
};

export default EmployeeList;

import { Box, Typography } from "@material-ui/core";

import NotificationItem from "./NotificationItem";

const NotificationItems = ({ handleClose, notifications, limit }) => {
  const listIds = limit ? notifications.listIds.slice(0, limit) : notifications.listIds;

  return (
    <>
      {notifications.error ? (
        <Box p={2}>
          <Typography variant="body2" color="error">
            {notifications.error}
          </Typography>
        </Box>
      ) : (
        <>
          {listIds.length > 0 ? (
            // only limit items to any specific small number and see the rest on view all
            listIds.map((notificationId) => (
              <NotificationItem
                key={notificationId}
                notification={notifications.listMap[notificationId]}
                handleClose={handleClose}
              />
            ))
          ) : (
            <Box p={2}>
              <Typography variant="body2">No notifications yet</Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default NotificationItems;

import { useFormState } from "react-final-form";
import { ReferenceInput, SelectInput } from "react-admin";

const AreasFilter = ({ optionText, ...props }) => {
  const { values } = useFormState();

  return (
    <ReferenceInput
      {...props}
      disabled={!values.cityId}
      filterToQuery={() => ({ cityId: values.cityId })}
      key={values.cityId}
      variant="outlined"
    >
      <SelectInput optionText={optionText} />
    </ReferenceInput>
  );
};

export default AreasFilter;

import paginationReducer from "./pagination";
import userReducer from "./user";
import listsReducer from "./list";
import seenRequestsReducer from "./seenRequests";
import settingsReducer from "./settings";
import chatReducer from "./chat";
import rolesReducer from "./roles";
import citiesReducer from "./cities";
import areasReducer from "./areas";
import enumsReducer from "./enums";
import notificationsReducer from "../../Notifications/NotificationList/reducer";

const rootReducer = {
  pagination: paginationReducer,
  user: userReducer,
  lists: listsReducer,
  requiresRefresh: listsReducer,
  seenRequests: seenRequestsReducer,
  settings: settingsReducer,
  chat: chatReducer,
  roles: rolesReducer,
  cities: citiesReducer,
  areas: areasReducer,
  enums: enumsReducer,
  notifications: notificationsReducer,
};

export default rootReducer;

import firebase from "firebase/compat/app";
import { compose, curry, isNil } from "lodash/fp";
import { forEachObjIndexed } from "ramda";
import { firestore } from "../init/firebase";
import { applyTimestamps } from "./funcs";
import { PaginationDirection } from "../enums";

/**
 * This file contains some useful small functions that can be composed to get valid query.
 * It works fine with collection queries only
 * @param params {{
 *   limit: number
 *   sort: {
 *     field: string,
 *     order: 'asc' | 'desc'
 *   },
 *   filters: Record<string, any>,
 *   operators: Record<string, string> The operation string (e.g "<", "<=", "==", ">", ">="),
 *   fieldOverrides: Record<string, string>,
 *   fieldValueOverrides: Record<string, string>
 * }}
 * @param query {Query}
 * */

// starting...

const addLimit = curry((params, query) => {
  const { limit } = params;
  return query.limit(limit);
});

const addOrderBy = curry((params, query) => {
  const {
    sort: { field, order },
  } = params;

  if (!isNil(field) && field !== "id") {
    const orderBy = order.toLowerCase();
    return query.orderBy(field, orderBy).orderBy(firebase.firestore.FieldPath.documentId(), orderBy);
  }
  return query;
});

const addFilters = curry((params, query) => {
  const { filters, operators, fieldOverrides, fieldValueOverrides } = params;

  let filteredQuery = query;
  if (!isNil(filters)) {
    forEachObjIndexed((value, key) => {
      const k = (fieldOverrides && fieldOverrides[key]) || key;
      const op = (operators && operators[key]) || "==";
      const v = (fieldValueOverrides && fieldValueOverrides[key]) || value;
      if (!isNil(v)) filteredQuery = filteredQuery.where(k, op, v);
    })(filters);
  }
  return filteredQuery;
});

const addPagination = curry((params, query) => {
  const {
    record,
    paginationDirection,
    sort: { field },
    limit,
  } = params;
  if (record && paginationDirection) {
    const paginateBy = record[field];
    if (paginationDirection === PaginationDirection.next) query = query.startAfter(paginateBy);
    if (paginationDirection === PaginationDirection.prev) query = query.endBefore(paginateBy).limitToLast(limit);
    if (paginationDirection === PaginationDirection.current) query = query.startAt(paginateBy);
  }
  return query;
});

const addAutoComplete = curry((params, query) => {
  const { filters } = params;

  if (filters && filters.autoComplete && filters[filters.autoComplete]) {
    query = query
      .orderBy(filters.autoComplete)
      .startAt(filters[filters.autoComplete])
      .endAt(`${filters[filters.autoComplete]}\uf8ff`);
  }

  return query;
});

export const queryWithFilters = curry((query, params) => addFilters(params)(query));

export const queryWithFiltersAndOrderBy = curry((query, params) => {
  const composed = compose(addOrderBy(params), addFilters(params));
  return composed(query);
});

export const collectionQuery = curry((query, params) => {
  const composed = compose(addLimit(params), addOrderBy(params), addFilters(params));
  return composed(query);
});

export const fullQuery = curry((query, params) => {
  const composed = compose(
    addAutoComplete(params),
    addPagination(params),
    addLimit(params),
    addOrderBy(params),
    addFilters(params)
  );
  return composed(query);
});

export const updateDocuments = async (ref, data) => {
  const batch = firestore.batch();
  try {
    batch.update(
      ref,
      applyTimestamps({
        data,
        isUpdate: true,
      })
    );
    await batch.commit();
  } catch (ex) {
    console.log(ex.message);
  }
};

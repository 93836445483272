import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useRecordContext } from "ra-core";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function CustomField({ time }) {
  const recordContext = useRecordContext();

  const [dateFormat, setDateFormat] = useState(null);
  const [dateUI, setDateUI] = useState(null);
  const [color, setColor] = useState("red");

  useEffect(() => {
    if (recordContext) {
      setColor(recordContext.lastSeen ? getDiffBetweenData(recordContext.lastSeen, time) : "#BDBDBD");
      setDateFormat(changeFromTimeStampToDate(recordContext.lastSeen));
    }
  }, [recordContext, time]);

  useEffect(() => {
    if (dateFormat) {
      setDateUI(displayingFormatToUser(dateFormat));
    }
  }, [dateFormat]);

  return (
    <>
      <Box p={0} alignItems="center" justifyContent="center" display="flex">
        <Typography variant="body1" style={{ width: "70%", fontSize: "0.9rem" }} component="span">
          {/* {dateUI ||"Not available" } */}

          {dateUI && recordContext
            ? dayjs.tz(dayjs.unix(recordContext.lastSeen.seconds), dayjs.tz.guess()).format("DD/MM/YYYY - hh:mm a")
            : "Not available"}
        </Typography>

        <div
          style={{ width: "15px", height: "15px", marginLeft: "10px", backgroundColor: color, borderRadius: "50%" }}
        ></div>
      </Box>
    </>
  );
}

// time comes in this format { seconds, nanoseconds }
function getDiffBetweenData(dateOfRecord, timeDiff) {
  if (!dateOfRecord) return;

  const currentDate = new Date();

  const lastSeen = changeFromTimeStampToDate(dateOfRecord);

  const diff = Math.floor((currentDate.getTime() - lastSeen.getTime()) / 1000 / 60);

  if (diff < timeDiff) {
    return "#00A400";
  }
  return "red";
}

function changeFromTimeStampToDate(timeStampFormat) {
  if (!timeStampFormat) return;
  return new Date(timeStampFormat.seconds * 1000 + timeStampFormat.nanoseconds / 1000000);
}

function displayingFormatToUser(dateFormat) {
  const month = dateFormat.getMonth();
  const date = dateFormat.getDate();
  const hours = dateFormat.getHours();
  const minutes = dateFormat.getMinutes();

  return `${month + 1}/${date} at ${hours}:${minutes} `;
}

import { createAction } from "@reduxjs/toolkit";

import PropertyChatActionTypes from "./chat.types";
import { ChatType } from "../../enums";

const syncPropertyRooms = createAction(PropertyChatActionTypes.SYNC_PROPERTY_ROOMS, (companyId, userId, role) => ({
  payload: {
    companyId,
    userId,
    role,
    type: ChatType.property,
  },
}));

const syncCompanyRooms = createAction(PropertyChatActionTypes.SYNC_COMPANY_ROOMS, (companyId, userId, role) => ({
  payload: {
    companyId,
    userId,
    role,
    type: ChatType.company,
  },
}));

const syncReplyRooms = createAction(PropertyChatActionTypes.SYNC_REPLY_ROOMS, (companyId, userId, role) => ({
  payload: {
    companyId,
    userId,
    role,
    type: ChatType.reply,
  },
}));

const syncPropertyMessages = createAction(PropertyChatActionTypes.SYNC_PROPERTY_MESSAGES, (companyId) => ({
  payload: {
    companyId,
    type: ChatType.property,
  },
}));

const syncCompanyMessages = createAction(PropertyChatActionTypes.SYNC_COMPANY_MESSAGES, (companyId) => ({
  payload: {
    companyId,
    type: ChatType.company,
  },
}));

const syncReplyMessages = createAction(PropertyChatActionTypes.SYNC_REPLY_MESSAGES, (companyId) => ({
  payload: {
    companyId,
    type: ChatType.reply,
  },
}));

const getPropertyRooms = createAction(PropertyChatActionTypes.GET_PROPERTY_ROOMS, (companyId, userId, role, last) => ({
  payload: {
    companyId,
    userId,
    role,
    last,
    type: ChatType.property,
  },
}));

const getCompanyRooms = createAction(PropertyChatActionTypes.GET_COMPANY_ROOMS, (companyId, userId, role, last) => ({
  payload: {
    companyId,
    userId,
    role,
    last,
    type: ChatType.company,
  },
}));

const getReplyRooms = createAction(PropertyChatActionTypes.GET_REPLY_ROOMS, (companyId, userId, role, last) => ({
  payload: {
    companyId,
    userId,
    role,
    last,
    type: ChatType.reply,
  },
}));

const getRoomMessages = createAction(PropertyChatActionTypes.GET_ROOM_MESSAGES, (roomId, type, last) => ({
  payload: {
    roomId,
    type,
    last,
  },
}));

const sendMessage = createAction(
  PropertyChatActionTypes.SEND_MESSAGE,
  ({ roomId, fromId, toId, text, type, attachment }) => ({
    payload: { roomId, fromId, toId, text, type, attachment },
  })
);

const markRoomAsSeen = createAction(
  PropertyChatActionTypes.MARK_ROOM_AS_SEEN,
  (roomId, companyId, userId, role, type) => ({
    payload: { roomId, companyId, userId, role, type },
  })
);

const addMessage = createAction(PropertyChatActionTypes.ADD_MESSAGE, (roomId, message, type) => ({
  payload: { roomId, message, type },
}));

const updateSyncedRooms = createAction(PropertyChatActionTypes.UPDATE_SYNCED_ROOM, ({ rooms, type, userId }) => ({
  payload: { rooms, type, userId },
}));

const chatActionCreators = {
  syncPropertyRooms,
  syncCompanyRooms,
  syncReplyRooms,
  syncPropertyMessages,
  syncCompanyMessages,
  syncReplyMessages,
  getRoomMessages,
  sendMessage,
  markRoomAsSeen,
  addMessage,
  updateSyncedRooms,
  getPropertyRooms,
  getCompanyRooms,
  getReplyRooms,
};

export default chatActionCreators;

import { ResourcesKeys } from "../enums";

/**
 * Helpers
 * */
const mergePath = (path1, path2) => `${path1}/${path2}`;

/**
 * Get document path in root collection by passing its id
 * */
export const companyDocPath = (companyId) => mergePath(ResourcesKeys.PropertiesCompanies, companyId);
export const clientDocPath = (clientId) => mergePath(ResourcesKeys.Clients, clientId);
export const requestDocPath = (requestId) => mergePath(ResourcesKeys.PropertiesRequests, requestId);
export const roomDocPath = (roomId) => mergePath(ResourcesKeys.Rooms, roomId);
export const propertyDocPath = (propertyId) => mergePath(ResourcesKeys.Properties, propertyId);
export const packageDocPath = (packageId) => mergePath(ResourcesKeys.Packages, packageId);

/**
 * Pass parent id like 'companyId` to get the sub collection path
 * */
export const reviewCollPath = (companyId) => mergePath(companyDocPath(companyId), ResourcesKeys.Reviews);
export const employeesCollPath = (companyId) => mergePath(companyDocPath(companyId), ResourcesKeys.Employees);
export const statisticsCollPath = (companyId) => mergePath(companyDocPath(companyId), ResourcesKeys.CompanyStatistics);
export const requestsSeenByCollPath = (requestId) => mergePath(requestDocPath(requestId), ResourcesKeys.SeenBy);
export const repliesCollPath = (requestId) => mergePath(requestDocPath(requestId), ResourcesKeys.Reply);
export const messagesCollPath = (roomId) => mergePath(roomDocPath(roomId), ResourcesKeys.Message);
export const xmlsCollPath = (companyId) => mergePath(companyDocPath(companyId), ResourcesKeys.propertiesXml);
export const blockedCompaniesCollPath = (clientId) =>
  mergePath(clientDocPath(clientId), ResourcesKeys.blockedCompanies);

/**
 * Get a document in sub collection by its id and parent id
 * */
export const reviewsDocPath = (companyId, docId) => mergePath(reviewCollPath(companyId), docId);
export const employeeDocPath = (companyId, docId) => mergePath(employeesCollPath(companyId), docId);
export const statisticsDocPath = (companyId, docId) => mergePath(statisticsCollPath(companyId), docId);
export const requestsSeenByDocPath = (requestId, docId) => mergePath(requestsSeenByCollPath(requestId), docId);
export const repliesDocPath = (requestId, docId) => mergePath(repliesCollPath(requestId), docId);
export const messagesDocPath = (roomId, docId) => mergePath(messagesCollPath(roomId), docId);

import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { MenuItemLink } from "react-admin";
import { ExpandLess, ExpandMore, Inbox, QuestionAnswer, Textsms } from "@material-ui/icons";

import { ChatType, ResourcesKeys } from "../../enums";
import ChatIcon from "@material-ui/icons/Chat";
import SidebarIconWrapper from "../SidebarIconWrapper";
import { areEqual } from "../../utils/general";

const hasNotification = (rooms) => {
  return (
    Array.isArray(rooms) &&
    !!rooms.filter((room) => {
      return !room.lastMessage.seen && room.lastMessage.to.id === room.id.split("_")[1];
    }).length
  );
};

const useGetRooms = (type) => {
  return useSelector((state) => state.chat[type].rooms);
};

const CustomizedMenus = ({ onMenuClick, open, canViewCompanyChat }) => {
  const [dropDownOpened, setDropDownOpen] = useState(true);

  const propertyRooms = useGetRooms(ChatType.property);
  const companyRooms = useGetRooms(ChatType.company);
  const replyRooms = useGetRooms(ChatType.reply);

  const propertyHasNotification = hasNotification(propertyRooms);
  const companyHasNotification = hasNotification(companyRooms);
  const replyHasNotification = hasNotification(replyRooms);

  const handleClick = () => {
    setDropDownOpen(!dropDownOpened);
  };

  const allChatsNotification = () => propertyHasNotification || replyHasNotification || companyHasNotification;

  return (
    <List style={{ padding: 0 }}>
      <ListItem button onClick={handleClick}>
        <ListItemIcon style={{ minWidth: 40 }}>
          {open ? (
            <SidebarIconWrapper Icon={Inbox} showBullet={allChatsNotification()} />
          ) : dropDownOpened ? (
            <SidebarIconWrapper Icon={ExpandLess} showBullet={allChatsNotification()} />
          ) : (
            <SidebarIconWrapper Icon={ExpandMore} showBullet={allChatsNotification()} />
          )}
        </ListItemIcon>
        <ListItemText primary="Chats" />
        {dropDownOpened ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={dropDownOpened} timeout="auto" unmountOnExit>
        <List dense disablePadding>
          <MenuItemLink
            to={`/${ResourcesKeys.PropertyChat}`}
            primaryText="Property"
            leftIcon={<SidebarIconWrapper showBullet={propertyHasNotification} Icon={QuestionAnswer} />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          {canViewCompanyChat && (
            <MenuItemLink
              to={`/${ResourcesKeys.CompanyChat}`}
              primaryText="Company"
              leftIcon={<SidebarIconWrapper showBullet={companyHasNotification} Icon={Textsms} />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )}
          <MenuItemLink
            to={`/${ResourcesKeys.ReplyChat}`}
            primaryText="Request"
            leftIcon={<SidebarIconWrapper showBullet={replyHasNotification} Icon={ChatIcon} />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </List>
      </Collapse>
    </List>
  );
};

export default memo(CustomizedMenus, areEqual);

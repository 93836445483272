import AssignEmployees from "./AssignEmployees";
import Spacing from "../../../../components/Spacing";

const EmployeesBulkActions = (props) => {
  const { isCompany, isCompanyAdmin } = props;

  const renderActions = () => {
    let actions = [];

    if (isCompany || isCompanyAdmin) {
      actions = [<AssignEmployees {...props} />, ...actions];
    }
    return actions;
  };

  return <Spacing elements={renderActions().length}>{renderActions()}</Spacing>;
};

export default EmployeesBulkActions;

export const NotificationCollectionName = {
  subscribedGyms: "subscribedGyms",
};

export const NotificationType = {
  REQUEST: "REQUEST",
  LANDLORD: "LANDLORD",
  REVIEW: "REVIEW",
  REQUESTAUTOREPLY: "REQUESTAUTOREPLY",
};

export const NotificationEvent = {
  CREATED: "CREATED",
  MATCHED: "MATCHED",
  NOTMATCHED: "NOTMATCHED",
};

export const NotificationUserType = {
  company: "company",
};

export const INotificationData = {
  id: "",
  path: "",
  type: "",
  event: "",
  collectionName: "",
  userType: "",
};

export const INotificationPayload = {
  data: "",
  fcmMessageId: "",
  from: "",
  priority: "",
};

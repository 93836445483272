import { memo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DefaultIcon from "@material-ui/icons/ViewList";
import { getResources, MenuItemLink } from "react-admin";

import SidebarIconWrapper from "../SidebarIconWrapper";
import useIsAdmin from "../../hooks/useIsAdmin";
import CustomizedMenus from "./ChatMenuIcons";
import { areEqual } from "../../utils/general";
import useIsCompany from "../../hooks/useIsCompany";
import useIsCompanyAdmin from "../../hooks/useIsCompanyAdmin";

const Menu = ({ onMenuClick }) => {
  const resources = useSelector(getResources);
  const { isAdmin } = useIsAdmin();
  const { isCompany } = useIsCompany();
  const { isCompanyAdmin } = useIsCompanyAdmin();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const lists = useSelector((state) => state.lists);

  const canViewChat = () => !isAdmin;
  const canViewCompanyChat = () => isCompany || isCompanyAdmin;

  return (
    <Box>
      {resources.map((resource) => {
        return resource.hasList ? (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={
              resource.icon ? (
                <SidebarIconWrapper
                  Icon={resource.icon}
                  showBullet={lists[resource.name] && lists[resource.name].hasNotifications}
                />
              ) : (
                <DefaultIcon />
              )
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ) : null;
      })}

      {isAdmin && (
        <>
          <MenuItemLink
            to={`/settings`}
            primaryText={"Settings"}
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to={`/profile`}
            primaryText={"Profile"}
            leftIcon={<AccountBoxIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </>
      )}

      {canViewChat() && (
        <CustomizedMenus onMenuClick={onMenuClick} open={open} canViewCompanyChat={canViewCompanyChat()} />
      )}
    </Box>
  );
};

export default memo(Menu, areEqual);

import { createSlice, createAction } from "@reduxjs/toolkit";

export const userInitialState = {
  loading: false,
  // companyFlagToDetectReload: false
};

export const userLoading = createAction("@@@user/loading");

const userSlice = createSlice({
  name: "@@@User",
  initialState: userInitialState,
  reducers: {
    updateUserData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    logout() {
      return userInitialState;
    },
    // setReloadFlag (state, action) {
    //   state.companyFlagToDetectReload = action.payload;
    // }
  },
  extraReducers: {
    [userLoading]: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { updateUserData, logout, setReloadFlag } = userSlice.actions;
export default userSlice.reducer;

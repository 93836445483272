import { Box, Typography } from "@material-ui/core";
import {
  Create,
  FormGroupContextProvider,
  FormWithRedirect,
  minValue,
  number,
  NumberInput,
  required,
} from "react-admin";
import Spacing from "../../components/Spacing";

import useDisabledEvents from "../../hooks/useDisabledEvents";
import CustomToolbar from "../../components/CustomToolbar";
import { usePackagesLocalizations } from "./utils";
import { checkPackageNumber, isNotExceeded } from "../../utils/validators";
import { FormGroupNames } from "../../enums";
import FormWrapper from "../../components/FormWrapper";
import useSharedStyles from "../../hooks/useSharedStyles";
import CustomCard from "../../components/CustomCard";

const CustomForm = (props) => {
  const { styles } = useSharedStyles();
  const { disabledEvents } = useDisabledEvents();
  const localizedInputs = usePackagesLocalizations(props);

  return (
    <FormWrapper>
      <FormGroupContextProvider name={FormGroupNames.packages}>
        <FormWithRedirect
          {...props}
          style={disabledEvents}
          redirect="/packages"
          render={(formProps) => {
            return (
              <form style={disabledEvents}>
                <CustomCard>
                  <Typography variant="h6">Quota</Typography>
                  <Spacing alignItems="flex-start">
                    <NumberInput variant="outlined" source="total" validate={[required(), number(), minValue(1)]} />
                    <NumberInput
                      variant="outlined"
                      source="published"
                      validate={[required(), number(), minValue(0), isNotExceeded(1)]}
                    />
                  </Spacing>

                  <Spacing alignItems="flex-start">
                    <NumberInput
                      variant="outlined"
                      source="featured"
                      validate={[required(), number(), minValue(0), isNotExceeded(1), checkPackageNumber("featured")]}
                    />
                    <NumberInput
                      variant="outlined"
                      source="boosted"
                      validate={[required(), number(), minValue(0), isNotExceeded(1), checkPackageNumber("boosted")]}
                    />
                    <NumberInput variant="outlined" source="days" validate={[required(), number(), minValue(1)]} />
                  </Spacing>

                  <Box pt={2}>
                    <Typography variant="h6">Package Name</Typography>
                    <Box className={styles.row}>{localizedInputs}</Box>
                  </Box>
                </CustomCard>
                <CustomToolbar
                  {...formProps}
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  formName={FormGroupNames.packages}
                />
              </form>
            );
          }}
        />
      </FormGroupContextProvider>
    </FormWrapper>
  );
};

const PackageCreate = (props) => {
  return (
    <Create
      {...props}
      transform={(values) => {
        values.deleted = false;
        return values;
      }}
    >
      <CustomForm variant="outlined" {...props} />
    </Create>
  );
};

export default PackageCreate;

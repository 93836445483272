import BathtubIcon from "@material-ui/icons/Bathtub";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import HotelIcon from "@material-ui/icons/Hotel";
import { Box, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useEffect, useState } from "react";
import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";

import Loader from "../../components/Loader";
import { ChatType, ResourcesKeys } from "../../enums";
import { areEqual } from "../../utils/general";
import realEstate from "../../assets/realEstate.svg";
import useGetEnumsAsValue from "../../hooks/useGetEnumsAsValue";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",

    borderBottom: "1px solid #efefef",
    padding: "8px 12px",
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: 12,
  },
  linkText: { fontSize: 14 },
}));

const LinkToItem = ({ to, text }) => {
  const classes = useStyles({});

  return (
    <RouterLink to={to} className={classes.link}>
      <Box display="flex" alignItems="center">
        <Typography className={classes.linkText}>{text}</Typography>
        <ChevronRightIcon fontSize="small" />
      </Box>
    </RouterLink>
  );
};

const ChatHeader = ({ title, property, reply, propertyError, type, loading }) => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({});
  const category = useGetEnumsAsValue("propertiesCategories");
  const propertyType = useGetEnumsAsValue("propertiesTypes");
  const purchasingOption = useGetEnumsAsValue("propertiesPurchasingOptions");

  const [loadingProperty, setLoadingProperty] = useState(true);
  const [propertyData, setPropertyData] = useState(null);

  const fetchPropertyData = async () => {
    try {
      const snapshot = await property.get();
      setPropertyData(snapshot.data());
      setLoadingProperty(false);
    } catch (ex) {
      console.log(ex.message);
      setLoadingProperty(false);
    }
  };

  useEffect(() => {
    if (property) fetchPropertyData();
  }, []);

  return (
    <>
      {property ? (
        <>
          <Box className={classes.root} display="flex" justifyContent="space-between" height={25}>
            <Typography variant="subtitle1" color="textSecondary" style={{ minWidth: 175 }}>
              {title}
            </Typography>
            <Loader loading={loading} size={16}>
              {type === ChatType.reply && reply && (
                <LinkToItem to={`${ResourcesKeys.Reply}/${reply.id}`} text="Show Reply" />
              )}
              {type === ChatType.property && property && !propertyError && (
                <LinkToItem to={`${ResourcesKeys.CompanyProperties}/${property.id}`} text="Show Property" />
              )}
            </Loader>
          </Box>
          <Loader loading={loadingProperty} display={isMd ? "block" : "flex"} size={16}>
            {propertyData ? (
              <Box className={classes.root} flexWrap="wrap" display={!isMd ? "flex" : "block"}>
                <Typography className={classes.linkText} style={{ marginRight: 16 }}>
                  {category(propertyData.category)}, {propertyType(propertyData.type)},{" "}
                  {purchasingOption(propertyData.purchasingOption)}
                </Typography>
                <Box width={50} height={50} borderRadius="50%" style={{ marginRight: 16 }}>
                  <img
                    src={propertyData.images[0].src}
                    alt={property.id}
                    width="100%"
                    height="100%"
                    style={{
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.currentTarget.src = realEstate;
                    }}
                  />
                </Box>
                <Typography className={classes.linkText} style={{ marginRight: 16 }}>
                  {propertyData.addressDetails.en}, {propertyData.cityName.en}, {propertyData.areaName.en}
                </Typography>
                <Box
                  display={isMd ? "flex" : "block"}
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginRight: 16 }}
                >
                  <Box display={"flex"} alignItems="center">
                    <Box>
                      <span className={classes.counter}>{propertyData.bedrooms}</span>
                      <HotelIcon className={classes.icon} />
                      <span className={classes.dot}>.</span>
                    </Box>
                    <Box>
                      <span className={classes.counter}>{propertyData.bathrooms}</span>
                      <BathtubIcon className={classes.icon} />
                      <span className={classes.dot}>.</span>
                    </Box>
                    <Box>
                      <span className={classes.counter}>{propertyData.parking}</span>
                      <DirectionsCarIcon className={classes.icon} />
                    </Box>
                  </Box>
                  <Box className={classes.space}>{propertyData?.space} Sqm</Box>
                </Box>
                <Typography className={classes.linkText}>
                  {propertyData.price},{" "}
                  {propertyData.purchasingOption !== "buy" ? `/ ${propertyData.rentalFrequency}` : ""}
                </Typography>
              </Box>
            ) : null}
          </Loader>{" "}
        </>
      ) : null}
    </>
  );
};

export default memo(ChatHeader, areEqual);

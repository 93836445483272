import { useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  CreateButton,
  Datagrid,
  EmailField,
  Filter,
  FilterButton,
  List,
  TextField,
  TextInput,
  useRefresh,
} from "react-admin";

import ListImage from "../../components/ListImage";
import TablePagination from "../../components/TablePagination";
import TimestampField from "../../components/TimestampField";
import useLocalizedRAFields from "../../hooks/useLocalizedRAFields";
import useSharedStyles from "../../hooks/useSharedStyles";
import CompanyBadges from "./components/CompanyBadges";
import DisablePropertyButton from "./components/DisablePropertyCompanyButton";
import EnablePropertyButton from "./components/EnablePropertyCompanyButton";
import CustomField from "./components/CustomField";
import { getInterval } from "./utils";
import ClearSortButton from "./components/ClearSort";
import { useLocation } from "react-router-dom";

const PropertiesCompaniesBulkActionButtons = (props) => (
  <>
    <EnablePropertyButton label="Enable" {...props} />
    <DisablePropertyButton label="Disable" {...props} />
  </>
);

// <FilterForm
//         filters={[
//           <TextInput label="Company Name (en)" source="name.en" variant="outlined" />,
//           <TextInput label="Company Name (en)" source="name.en" variant="outlined" />,
//           <TextInput label="Company Email" source="email" variant="outlined" />,
//         ]}
//       />//           <TextInput label="Company Email" source="email" variant="outlined" />,
//         ]}
//       />

//       <FilterButton
//         filters={[

const Test = (props) => (
  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <ClearSortButton />
      <PropertiesCompaniesFilters />
    </div>

    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <FilterButton
        filters={[
          <TextInput label="Company Name (en)" source="name.en" variant="outlined" />,
          <TextInput label="Company Email" source="email" variant="outlined" />,
        ]}
      />

      <CreateButton />
    </div>
  </div>
);

const PropertiesCompaniesFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Company Name (en)" source="name.en" variant="outlined" />
    <TextInput label="Company Email" source="email" variant="outlined" />
  </Filter>
);

const PropertiesCompaniesList = (props) => {
  const { styles } = useSharedStyles();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const refresh = useRefresh();
  const localizedFields = useLocalizedRAFields([
    {
      key: "name",
      label: "Name",
      cellClassName: styles.truncate3,
      sortable: false,
    },
  ]);

  const [timeToChange, setTimeToChange] = useState(0);

  const location = useLocation();

  function showResetBtn() {
    const queryParams = new URLSearchParams(location.search);
    const order = queryParams.get("order");
    const sort = queryParams.get("sort");

    const elem = document.getElementById("resetBtn");
    if (elem) {
      if (!order || !sort || (order.toLowerCase() == "desc" && sort.toLowerCase() == "createdat")) {
        elem.style.display = "none";
      } else {
        elem.style.display = "block";
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      refresh();
    }, 4000);

    // showResetBtn()

    getInterval().then((t) => setTimeToChange(t));
  }, []);

  return (
    <>
      <List
        {...props}
        bulkActionButtons={<PropertiesCompaniesBulkActionButtons />}
        sort={{ field: "createdAt", order: "DESC" }}
        pagination={<TablePagination />}
        // filters={ <PropertiesCompaniesFilters /> }
        // filters={  }
        exporter={false}
        actions={<Test />}
      >
        <Datagrid style={{ tableLayout: isSm ? "" : "fixed" }} rowClick="edit">
          {showResetBtn()}
          <ListImage source="logo.src" sortable={false} label="Logo" />
          {localizedFields}
          <EmailField source="email" cellClassName={styles.truncate3} sortable={false} />
          <TextField source="phoneNumber" cellClassName={styles.truncate3} sortable={false} />
          <CompanyBadges label="Badges" />
          <TimestampField source="createdAt" sortable={true} label="Created At" cellClassName={styles.truncate3} />
          <TextField source="package.name.en" />
          <TextField source="id" cellClassName={styles.truncate3} sortable={false} />
          {/* <BooleanField source="disabled" sortable={false} /> */}
          {/* <SortableCustomField label="Last seen"  time={timeToChange} /> */}
          <CustomField
            label="Last seen"
            source="lastSeen"
            cellClassName={styles.truncate3}
            time={timeToChange}
            sortable={true}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default PropertiesCompaniesList;

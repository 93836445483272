import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { minValue, NumberInput, required, SimpleForm, useNotify, BooleanInput } from "react-admin";

import { firestore } from "../../init/firebase";
import { applyTimestamps } from "../../utils/funcs";
import usePreventInputNumberScroll from "../../hooks/usePreventInputNumberScroll";
import { int } from "../../utils/validators";
import CustomCard from "../../components/CustomCard";
import CustomToolbar from "../../components/CustomToolbar";
import useSharedStyles from "../../hooks/useSharedStyles";

export default function SettingsEdit() {
  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState();
  const { styles } = useSharedStyles();
  const notify = useNotify();
  const { ref } = usePreventInputNumberScroll();

  useEffect(() => {
    setLoading(true);
    firestore
      .collection("settings")
      .doc("properties")
      .get()
      .then((doc) => {
        setSettings({
          ...doc.data(),
        });
        setLoading(false);
      })
      .catch(() => {
        notify("Error fetching settings");
        setLoading(false);
      });
  }, []);

  return loading || !settings ? null : (
    <SimpleForm
      toolbar={<CustomToolbar />}
      record={settings}
      save={async (data) => {
        try {
          setSubmitLoading(true);
          await firestore
            .collection("settings")
            .doc("properties")
            .update(
              applyTimestamps({
                data,
                isUpdate: true,
              })
            );
          notify("Settings updated", "success");
          setSubmitLoading(false);
        } catch (ex) {
          console.log(ex.message);
          notify("Operation failed", "error");
          setSubmitLoading(false);
        }
      }}
      saving={submitLoading}
    >
      <CustomCard>
        <Typography variant="h6">Buy Price</Typography>
        <Box className={styles.row}>
          <Box className={styles.rowElement}>
            <NumberInput
              inputRef={ref}
              variant="outlined"
              source="maxBuyPrice"
              label="Max Buy Price"
              validate={[required(), minValue(0), int()]}
            />
          </Box>
          <Box className={styles.rowElement}>
            <NumberInput
              inputRef={ref}
              variant="outlined"
              source="minBuyPrice"
              label="Min Buy Price"
              validate={[required(), minValue(0), int()]}
            />
          </Box>
        </Box>
      </CustomCard>
      <CustomCard>
        <Typography variant="h6">Rent Price</Typography>
        <Box className={styles.row}>
          <Box className={styles.rowElement}>
            <NumberInput
              inputRef={ref}
              variant="outlined"
              source="maxRentPrice"
              label="Max Rent Price"
              validate={[required(), minValue(0)]}
            />
          </Box>
          <Box className={styles.rowElement}>
            <NumberInput
              inputRef={ref}
              variant="outlined"
              source="minRentPrice"
              label="Min Rent Price"
              validate={[required(), minValue(0), int()]}
            />
          </Box>
        </Box>
      </CustomCard>
      <CustomCard>
        <Typography variant="h6">Space</Typography>
        <Box className={styles.row}>
          <Box className={styles.rowElement}>
            <NumberInput
              inputRef={ref}
              variant="outlined"
              source="maxSpace"
              label="Max Space"
              validate={[required(), minValue(0), int()]}
            />
          </Box>
          <Box className={styles.rowElement}>
            <NumberInput
              inputRef={ref}
              variant="outlined"
              source="minSpace"
              label="Min Space"
              validate={[required(), minValue(0), int()]}
            />
          </Box>
        </Box>
      </CustomCard>
      <CustomCard>
        <Typography variant="h6">Matching Percentage</Typography>
        <Box className={styles.row}>
          <Box className={styles.rowElement}>
            <NumberInput
              inputRef={ref}
              variant="outlined"
              source="minMatchingPercentage"
              label="Min Matching Percentage"
              validate={[required(), minValue(0), int()]}
            />
          </Box>
          <Box className={styles.rowElement}>
            <BooleanInput
              inputRef={ref}
              variant="outlined"
              source="automatedReplies"
              defaultValue={settings?.automatedReplies ?? false}
              label="Automated Replies"
              validate={[required()]}
            />



          </Box>
        </Box>

        
      </CustomCard>

      <CustomCard>
        {/* <Typography variant="h6">Matching Percentage</Typography> */}
        <Box className={styles.row}>
      
          <Box className={styles.rowElement}>
            <BooleanInput
              inputRef={ref}
              variant="outlined"
              source="companyActivityStatus"
              defaultValue={settings?.companyActivityStatus ?? false}
              label="Company Activity Status"
              validate={[required()]}
            />



          </Box>
        </Box>

        
      </CustomCard>
    </SimpleForm>
  );
}

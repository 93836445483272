import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { maxLength, minLength, required } from "react-admin";
import { Locals, UserRoles } from "../../../enums";
import useLocalizedRAInputs from "../../../hooks/useLocalizedRAInputs";

import { firestore } from "../../../init/firebase";
import { getDataFromFirebaseCollection } from "../../../utils/funcs";
import { companyDocRef, propertiesXmlSubRef } from "../../../utils/refrences.utils";
import { stringHasContent, validateRequired } from "../../../utils/validators";

export const isBoosted = () => {
  return (value, record) => {
    return record.boosted && value ? "Select either featured or boosted" : undefined;
  };
};

export const isFeatured = () => {
  return (value, record) => {
    return record.featured && value ? "Select either featured or boosted" : undefined;
  };
};

export const isPublished = (originalValue) => {
  return (value, _) => {
    return originalValue && value ? "Property is published, unpublish it to update" : undefined;
  };
};

export const useGetRequiredPropertiesEnums = () => {
  const [formData, setFormData] = useState({});
  const [isFormDataLoading, setIsFormDataLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      firestore.doc("enums/amenities").get(),
      firestore.doc("enums/companiesBadges").get(),
      firestore.doc("enums/propertiesRentalFrequencies").get(),
      firestore.doc("enums/propertiesPurchasingOptions").get(),
      firestore.doc("enums/propertiesFurnishings").get(),
      firestore.doc("enums/propertiesCategories").get(),
      firestore.doc("enums/propertiesTypes").get(),
      firestore.collection("cities").get(),
    ])
      .then((data) => {
        const res = getDataFromFirebaseCollection(
          data.map((item, index) => (index === data.length - 1 ? undefined : item))
        );
        let formatted = {};
        res.forEach((doc) => {
          const { id, ...rest } = doc;
          formatted[id] = rest;
        });
        formatted.cities = getDataFromFirebaseCollection(data[data.length - 1].docs);
        setFormData(formatted);
        setIsFormDataLoading(false);
      })
      .catch((e) => {
        setIsFormDataLoading(false);
      });
  }, []);

  return { formData, isFormDataLoading };
};

export const useGetPropertyCity = (chosenCity) => {
  const [isAreaLoading, setIsAreaLoading] = useState(false);
  const [areas, setAreas] = useState();

  useEffect(() => {
    if (chosenCity && !isAreaLoading) {
      setIsAreaLoading(true);
      firestore
        .collection("areas")
        .where("cityId", "==", chosenCity)
        .get()
        .then((snapshot) => {
          setIsAreaLoading(false);
          setAreas(getDataFromFirebaseCollection(snapshot.docs));
        });
    }
  }, [chosenCity]);

  return { areas };
};

export const usePropertyLocalization = (props) => {
  return useLocalizedRAInputs([
    {
      key: "description",
      label: "Description",
      multiline: true,
      counter: 2500,
      validate: {
        [Locals[0].key]: [required(), stringHasContent(), minLength(3), maxLength(2500)],
        [Locals[1].key]: [required(), stringHasContent(), minLength(3), maxLength(2500)],
      },
      ...props,
    },
    {
      key: "addressDetails",
      label: "Address Details",
      multiline: true,
      counter: 250,
      validate: {
        [Locals[0].key]: [
          validateRequired("Address Details Required"),
          stringHasContent(),
          minLength(3),
          maxLength(250),
        ],
        [Locals[1].key]: [
          validateRequired("Address Details Required"),
          stringHasContent(),
          minLength(3),
          maxLength(250),
        ],
      },
      ...props,
    },
  ]);
};

export const useSharedActionsStyles = () => {
  const useStyles = makeStyles((theme) => ({
    icon: {
      height: 20,
      fill: theme.palette.grey[500],
    },
    active: {
      fill: "#1B2C24",
    },
    inActive: {
      fill: "#a1a1a1",
    },
  }));

  const styles = useStyles({});

  return {
    styles,
  };
};

export const fetchCompanyData = async (user) => {
  let snapshot;
  if (user.permissions.role === UserRoles.propertyCompany) snapshot = await companyDocRef(user.id).get();
  else snapshot = await companyDocRef(user.permissions.companyId).get();
  return snapshot.data();
};

import { createMuiTheme } from "@material-ui/core/styles";
import { red, blue, green, grey, deepOrange, yellow } from "@material-ui/core/colors";

export const sharedTheme = {
  error: {
    main: red[300],
  },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1B2C24",
    },
    secondary: {
      main: "#f8e71c",
    },
    error: {
      main: red[300],
    },
    action: {
      main: blue[400],
    },
  },
  sidebar: {
    width: 200,
  },
  props: {
    // MuiTextField: { size: "small", variant: "outlined", fullWidth: true },
    MuiFormControl: { marginTop: "0px", marginRight: "0px" },
  },
  overrides: {
    RaDatagrid: {
      headerCell: {
        backgroundColor: grey[200],
        "&:not(::first-of-child)": {
          minWidth: 125,
        },
      },
    },
    RaList: {
      root: {
        marginTop: 35,
      },
    },
    MuiFormControl: {
      marginDense: {
        width: "100%",
        marginRight: 16,
      },
    },
    MuiFilledInput: {
      root: {
        width: "100%",
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: 8,
      },
    },
    RaNotification: {
      success: {
        backgroundColor: green[200],
        color: grey[50],
      },
      info: {
        backgroundColor: yellow[200],
        color: grey[50],
      },
      warning: {
        backgroundColor: deepOrange[200],
        color: grey[50],
      },
      error: {
        backgroundColor: red[300],
        color: grey[50],
      },
    },
    RaListToolbar: {
      toolbar: {
        alignItems: "center",
      },
      actions: {
        alignItems: "center",
      },
    },
    RaFilterForm: {
      form: {
        marginTop: 0,
        display: "flex",
        alignItems: "center",

        "& > div": {
          "& > button": {
            padding: 0.8,
            marginRight: 8,
            color: grey[100],
            backgroundColor: grey[400],

            "&:hover": {
              backgroundColor: red[300],
            },
          },
        },

        "& div[class^='RaFilterFormInput-spacer']": {
          width: 20,
        },
      },
    },
    RaFilterFormInput: {
      body: {
        display: "flex",
        alignItems: "center",
        height: 30,
      },
    },
  },
});

export default theme;

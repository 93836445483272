import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";

import { clientDocRef } from "../../../utils/refrences.utils";

const ClientName = (props) => {
  const [clientName, setClientName] = useState(null);

  useEffect(() => {
    (async function () {
      const snapshot = await clientDocRef(props.record.clientId).get();
      setClientName(snapshot.data().displayName);
    })();
  }, []);

  return clientName ? <Typography variant="body2">{clientName}</Typography> : null;
};

export default ClientName;

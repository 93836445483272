import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";

const propertiesCompaniesResource = {
  [ResourcesKeys.PropertiesCompanies]: {
    [RequestKeys.GET_LIST]: async (queryData) => {
      const { name, ...remainingFilters } = queryData.filter;
      console.log("queryData", queryData);
      queryData.filter = {
        ...remainingFilters,
        "name.en": name && name.en,
      };
      return getFinalList(firestore.collection(ResourcesKeys.PropertiesCompanies), {
        ...queryData,
        limit: queryData.pagination.perPage,
        filters: queryData.filter,
      });
    },
  },
};

export default propertiesCompaniesResource;

import { deleteObject } from "firebase/storage";

export const deleteFiles = ({ fileRefs }) => {
  const deletedFiles = Object.values(fileRefs).map((ref) => {
    console.log(ref.fullPath);
    deleteObject(ref);
  });
  return Promise.all(deletedFiles)
    .then(() => {
      console.log("Unwanted files have been deleted");
    })
    .catch((ex) => {
      console.log(ex.message);
    });
};

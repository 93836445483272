import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";

import ChatHeader from "./ChatHeader";
import ChatFeed from "./ChatFeed";
import chatActionCreators from "../../redux/actions/chat";
import ChatInput from "./ChatInput";
import { setIsSending } from "../../redux/reducers/chat";
import useCurrentUser from "../../hooks/useCurrentUser";
import { areEqual } from "../../utils/general";
import useCurrentRole from "../../hooks/useCurrentRole";
import useGetCompanyId from "../../hooks/useGetCompanyId";
import { ChatType } from "../../enums";
import { blockedCompaniesSubRef } from "../../utils/refrences.utils";

const Chatting = ({ currentRoom, currentRoomId, type }) => {
  const dispatch = useDispatch();
  const { user } = useCurrentUser();
  const { role } = useCurrentRole();
  const { companyId } = useGetCompanyId();
  const isSending = useSelector((state) => state.chat[type].isSending);

  const [propertyError, setPropertyError] = useState(null);
  const [propertyLoading, setPropertyLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const chatFeedRef = useRef();

  const scrollAfterSending = () => {
    const ref = chatFeedRef.current;
    ref.scrollTo(0, ref.scrollHeight);
  };

  const checkChatProperty = async () => {
    if (currentRoom && type !== ChatType.company) {
      setPropertyError(null);
      try {
        setPropertyLoading(true);
        const snapshot = await currentRoom.property.get();
        if (snapshot.data().deleted) setPropertyError("Property might be deleted");
      } catch (ex) {
        console.log(ex.message);
        setPropertyError("Property might be deleted");
      } finally {
        setPropertyLoading(false);
      }
    }
  };

  const checkBlockedChat = useCallback(
    (clientId) => {
      return blockedCompaniesSubRef(clientId)
        .where("companyId", "==", user.companyId)
        .onSnapshot({
          next(snapshots) {
            setIsBlocked(!!snapshots.size);
          },
        });
    },
    [user.companyId]
  );

  useEffect(() => {
    let unsub;

    if (currentRoomId) {
      unsub = checkBlockedChat(currentRoomId.split("_")[0]);
    }

    return () => {
      unsub?.();
    };
  }, [checkBlockedChat, currentRoomId]);

  useEffect(() => {
    dispatch(chatActionCreators.getRoomMessages(currentRoomId, type));

    (async function () {
      await checkChatProperty();
    })();
  }, [currentRoomId]);

  useEffect(() => {
    dispatch(chatActionCreators.markRoomAsSeen(currentRoomId, user.companyId, user.id, role, type));
  }, [currentRoomId, currentRoom?.lastMessage?.text, role]);

  return currentRoom ? (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <ChatHeader
        title={currentRoom.client.displayName}
        property={currentRoom.property}
        reply={currentRoom.reply}
        propertyError={propertyError}
        type={type}
        loading={propertyLoading}
      />
      <ChatFeed
        isPropertyChat={!!currentRoom.property}
        onScrollEnd={() => {
          if (currentRoom.messages && currentRoom.messages.length > 0)
            dispatch(
              chatActionCreators.getRoomMessages(
                currentRoomId,
                type,
                currentRoom.messages[currentRoom.messages.length - 1]
              )
            );
        }}
        messages={currentRoom.messages || []}
        chatFeedRef={chatFeedRef}
      />
      {isBlocked ? (
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 16,
          }}
        >
          You no longer can send a message to this client
        </Typography>
      ) : (
        <ChatInput
          loading={isSending}
          propertyLoading={propertyLoading}
          propertyError={propertyError}
          disabled={!currentRoom}
          onSend={(messageText) => {
            dispatch(setIsSending({ type, sending: true }));
            dispatch(
              chatActionCreators.sendMessage({
                roomId: currentRoomId,
                fromId: companyId,
                toId: currentRoom.client.id,
                text: messageText,
                type,
              })
            );
            scrollAfterSending();
          }}
          onLoading={() => dispatch(setIsSending({ type, sending: true }))}
          onAttach={(image) => {
            dispatch(
              chatActionCreators.sendMessage({
                roomId: currentRoomId,
                fromId: companyId,
                toId: currentRoom.client.id,
                attachment: image,
                type,
              })
            );
          }}
        />
      )}
    </Box>
  ) : null;
};

export default memo(Chatting, areEqual);

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { email, useRedirect, userLogin, Notification, usePermissions } from "react-admin";

import { ReactComponent as Shape } from "../../assets/shape.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as LoginIcon } from "../../assets/login-icon.svg";
import { useGetWebViewFcmToken } from "../../init/FCM/fcm.utils";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#1B2C24",
  },
  logo: {
    width: "100%",
    objectFit: "cover",
  },
  card: {
    minHeight: 250,
    borderRadius: 30,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
    backgroundColor: "#315444",
    overflow: "initial",
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  title: {
    color: "#f8e71c",
    fontSize: "18px",
  },
  textField: {
    backgroundColor: "#fff",
    color: "#1B2C24",
    borderRadius: 10,
    "& .MuiInputBase-input, & fieldset": {
      borderRadius: 10,
      borderWidth: 0,
    },
    "& .MuiInputBase-input": {
      fontSize: 12,
      height: 12,
    },
  },
  button: {
    backgroundColor: "#f8e71c",
    color: "#1B2C24",
    height: 24,
    width: 100,
    fontSize: 12,

    "&.Mui-disabled": {
      backgroundColor: "#315444",
      color: "rgb(82 123 105)",
    },
  },
}));

const isValid = (emailValue, password) => {
  if (!emailValue || !password) return;
  if (email()(emailValue)) return;
  return true;
};

const LoginPage = () => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [emailValue, setEmailValue] = useState();
  const [password, setPassword] = useState();
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const redirect = useRedirect();

  useGetWebViewFcmToken();

  useEffect(() => {
    if (permissions.loaded && permissions.permissions) redirect("/");
  }, [permissions]);

  return (
    <Box display={"flex"} minHeight="100vh" alignItems="center" className={classes.main}>
      <Box width="100%">
        <Box display="flex" justifyContent="center">
          <Box width={225}>
            <Logo />
          </Box>
        </Box>

        <Box width={isSm ? "75%" : "50%"} mx="auto" minWidth={isSm ? "" : 600} maxWidth={700}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={isSm ? 4 : 8}>
            <Card className={classes.card} style={{ width: isSm ? "100%" : 200 }}>
              <form
                onSubmit={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  dispatch(
                    userLogin(
                      {
                        username: emailValue,
                        password: password,
                      },
                      "/"
                    )
                  );
                }}
              >
                <CardContent className={classes.cardContent}>
                  <Box display="flex" flexDirection="column">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={-5}
                      mb={3}
                    >
                      <Box width={40}>
                        <LoginIcon />
                      </Box>
                      <Typography className={classes.title}>Login</Typography>
                    </Box>
                    <Box>
                      <TextField
                        id="email"
                        placeholder="Email"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        className={classes.textField}
                        value={emailValue}
                        onChange={(e) => setEmailValue(e.target.value)}
                      />
                    </Box>
                    <Box>
                      <TextField
                        id="password"
                        placeholder="Password"
                        variant="outlined"
                        type="password"
                        fullWidth
                        margin="dense"
                        className={classes.textField}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Box>
                  </Box>
                </CardContent>
                <CardActions>
                  <Box width="100%" mt={2} display="flex" justifyContent="center">
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      disabled={!isValid(emailValue, password)}
                      className={classes.button}
                    >
                      Sign in
                    </Button>
                  </Box>
                </CardActions>
              </form>
            </Card>
            {!isSm && (
              <Box width={400}>
                <Shape />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Notification />
    </Box>
  );
};

export default LoginPage;

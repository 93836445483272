import { ref } from "firebase/storage";
import { path } from "ramda";
import { GeoPoint } from "firebase/firestore";

const parseDataRecursively = (fieldPath, value, uploads) => {
  // check primitive value
  if (!value || typeof value !== "object" || value instanceof GeoPoint) return value;

  // check timestamp value
  if (value.toDate && typeof value.toDate === "function") return value.toDate();

  // check input file value
  // if (typeof value === "object" && Object.prototype.hasOwnProperty.call(value, "rawFile")) {
  if (value instanceof File) {
    uploads.push({
      fieldPath,
      fileData: {
        path: value.name,
        scr: value.name,
        rawFile: value,
      },
    });
    return;
  }

  // parse data again if the value is an array
  if (Array.isArray(value)) {
    value.forEach((entry, index) => parseDataRecursively(`${fieldPath}.${index}`, entry, uploads));
    return;
  }

  // case of the value is a normal object
  Object.entries(value).forEach(([nestedFieldPath, nestedValue]) => {
    return parseDataRecursively(`${fieldPath}.${nestedFieldPath}`, nestedValue, uploads);
  });
};

export const prepareDataUploads = (data) => {
  const uploads = [];
  for (const [key, value] of Object.entries(data)) {
    parseDataRecursively(key, value, uploads);
  }

  return uploads;
};

export const getPreviousFileRefs = ({ uploadedRefs, storage, previousData }) => {
  return Object.keys(uploadedRefs).reduce((acc, key) => {
    return {
      ...acc,
      [key]: ref(storage, path(key.split("."), previousData).path),
    };
  }, {});
};

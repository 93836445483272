import { useEffect, useRef } from "react";
import { Box, List, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ChatChannel from "./ChatChannel";
import { useScrollToEnd } from "./useScrollToEnd";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  scroll: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function ChatMenu({ channels, onChannelChange, onScrollEnd, currentRoomId, loading }) {
  const classes = useStyles({});
  const channelsRef = useRef();
  const { isAtEnd, setIsAtEnd } = useScrollToEnd(channelsRef);

  useEffect(() => {
    if (isAtEnd) {
      console.log("getting more rooms...");
      onScrollEnd();
    }

    return () => {
      setIsAtEnd(false);
    };
  }, [isAtEnd, setIsAtEnd]);

  return channels ? (
    <Box id="scrollableMenu" className={classes.container} ref={channelsRef}>
      {channels.length === 0 ? (
        <Box mt={4} textAlign="center">
          No channels to display
        </Box>
      ) : (
        <>
          <List component="nav" style={{ padding: 0 }}>
            {channels.map((channel) => (
              <ChatChannel
                key={channel.id}
                {...channel}
                onClick={() => onChannelChange(channel.id)}
                currentRoomId={currentRoomId}
              />
            ))}
          </List>
          <Box display="flex" justifyContent="center">
            {loading && (
              <Typography variant="body2" color="primary">
                Loading...
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  ) : null;
}

import { Box, Typography, makeStyles, useMediaQuery } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  type: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(0.5),
  },
}));

const PropertyPrice = ({ record }) => {
  const styles = useStyle({});
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box display={isMd ? "block" : "flex"} alignItems="center">
      <Typography>{record?.price}</Typography>
      <Typography variant="subtitle2" className={styles.type}>
        {record?.purchasingOption !== "buy" ? `/ ${record?.rentalFrequency}` : ""}
      </Typography>
    </Box>
  );
};

export default PropertyPrice;

import { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { minLength, PasswordInput, required, SimpleForm, useNotify } from "react-admin";

import { matches, stringHasContent } from "../../utils/validators";
import firebase from "../../init/firebase";
import CustomToolbar from "../../components/CustomToolbar";
import CustomCard from "../../components/CustomCard";
import useSharedStyles from "../../hooks/useSharedStyles";

export default function AdminProfile() {
  const [loading, setLoading] = useState();
  const notify = useNotify();
  const { styles } = useSharedStyles();

  return (
    <SimpleForm
      save={(data) => {
        setLoading(true);
        const user = firebase.auth().currentUser;

        if (user) {
          user
            .updatePassword(data.password)
            .then(() => {
              setLoading(false);
              notify("Password updated");
            })
            .catch((e) => {
              setLoading(false);
              notify(e.message);
            });
        } else {
          setLoading(false);
          notify("Operation failed");
        }
      }}
      saving={loading}
      toolbar={<CustomToolbar />}
    >
      <CustomCard>
        <Typography variant="h6">Profile</Typography>
        <Box className={styles.row}>
          <Box className={styles.rowElement}>
            <PasswordInput
              variant="outlined"
              source="password"
              validate={[required(), stringHasContent(), minLength(8)]}
            />
          </Box>
          <Box className={styles.rowElement}>
            <PasswordInput
              variant="outlined"
              source="passwordConfirm"
              validate={[required(), stringHasContent(), minLength(8), matches("password")]}
              label="Confirm Password"
            />
          </Box>
        </Box>
      </CustomCard>
    </SimpleForm>
  );
}

import { useCallback, useEffect, useState } from "react";
import { Box, Button, TextField, useMediaQuery } from "@material-ui/core";

import useCurrentUser from "../../hooks/useCurrentUser";
import Chart from "./Chart";
import { constructBigDataQueryUrl } from "./utils";
import StatsExport from "./StatsExport";

const TYPES = {
  views: "views",
  shares: "shares",
  whats: "whats",
  chat: "chat",
  calls: "calls",
};

const EVENTS = {
  [TYPES.views]: { company: "companyView", property: "propertyView" },
  [TYPES.shares]: { company: "companyShare", property: "propertyShare" },
  [TYPES.whats]: { company: "companyWhatsapp", property: "propertyWhatsapp" },
  [TYPES.chat]: { company: "companyChat", property: "propertyChat" },
  [TYPES.calls]: { company: "companyCalls", property: "propertyCalls" },
};

const Charts = () => {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { user } = useCurrentUser();

  const [loading, setLoading] = useState({
    [TYPES.views]: true,
    [TYPES.shares]: true,
    [TYPES.whats]: true,
    [TYPES.chat]: true,
    [TYPES.calls]: true,
  });
  const [error, setError] = useState({
    [TYPES.views]: null,
    [TYPES.shares]: null,
    [TYPES.whats]: null,
    [TYPES.chat]: null,
    [TYPES.calls]: null,
  });
  const [dataCompany, setDataCompany] = useState(null);
  const [dataProperty, setDataProperty] = useState(null);

  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [isValidFilter, setIsValidFilter] = useState(false);

  const handleChangeDate = (e, type) => {
    setDate((prevState) => {
      return { ...prevState, [type]: e.target.value.replaceAll("-", "") };
    });
  };

  // event name > companyView, propertyView and so on...
  const fetchByEventName = useCallback(async (eventName) => {
    try {
      const url = constructBigDataQueryUrl({
        idValue: user.companyId,
        idKey: "companyId",
        eventName,
        date,
      });

      const responseCompany = await fetch(url);
      return await responseCompany.json();
    } catch (ex) {
      throw new Error(ex);
    }
  }, []);

  // type > views, shares, whats, chat, calls
  const fetchStatisticsByType = useCallback(async (type) => {
    try {
      const companyEventName = EVENTS[type].company;
      const propertyEventName = EVENTS[type].property;

      // order, [company, property]
      const result = await Promise.all([fetchByEventName(companyEventName), fetchByEventName(propertyEventName)]);

      setDataCompany((prevState) => {
        return {
          ...prevState,
          [type]: result[0],
        };
      });

      setDataProperty((prevState) => {
        return {
          ...prevState,
          [type]: result[1],
        };
      });

      setLoading((prevState) => {
        return { ...prevState, [type]: false };
      });
    } catch (ex) {
      console.log(ex.message);
      setError((prevState) => {
        return { ...prevState, [type]: `Fetching error, ${type.toUpperCase()}` };
      });
      setLoading((prevState) => {
        return { ...prevState, [type]: false };
      });
    }
  }, []);

  const fetchAllStatistics = useCallback(() => {
    setLoading({
      [TYPES.views]: true,
      [TYPES.shares]: true,
      [TYPES.whats]: true,
      [TYPES.chat]: true,
      [TYPES.calls]: true,
    });

    fetchStatisticsByType(TYPES.views);
    fetchStatisticsByType(TYPES.calls);
    fetchStatisticsByType(TYPES.shares);
    fetchStatisticsByType(TYPES.chat);
    fetchStatisticsByType(TYPES.whats);
  }, [fetchStatisticsByType]);

  useEffect(() => {
    fetchAllStatistics();
  }, [fetchAllStatistics]);

  useEffect(() => {
    setIsValidFilter(!!date.startDate && !!date.endDate && date.startDate < date.endDate);
  }, [date]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: isXs ? "column" : "row",
          alignItems: isXs ? "start" : "center",
          margin: isXs ? "32px 16px 0px 16px" : "32px -8px 0px -8px",
        }}
      >
        <TextField
          type="date"
          variant="outlined"
          size="small"
          label="Start date"
          fullWidth={isXs}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleChangeDate(e, "startDate")}
          style={{ margin: isXs ? "0px 0px 8px 0px" : "0 8px 0px 8px" }}
        />
        <TextField
          type="date"
          variant="outlined"
          size="small"
          label="End date"
          fullWidth={isXs}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleChangeDate(e, "endDate")}
          style={{ margin: isXs ? "0px 0px 8px 0px" : "0 8px 0px 8px" }}
        />
        <Button variant="contained" color="primary" onClick={fetchAllStatistics} disabled={!isValidFilter}>
          Filter
        </Button>

        {dataCompany &&
          dataCompany[TYPES.views] &&
          dataCompany[TYPES.whats] &&
          dataCompany[TYPES.chat] &&
          dataCompany[TYPES.shares] &&
          dataCompany[TYPES.calls] &&
          dataProperty &&
          dataProperty[TYPES.views] &&
          dataProperty[TYPES.whats] &&
          dataProperty[TYPES.chat] &&
          dataProperty[TYPES.shares] &&
          dataProperty[TYPES.calls] && (
            <Box my={3} mx={2}>
              <StatsExport
                data={{
                  views: [dataCompany[TYPES.views], dataProperty[TYPES.views]],
                  whats: [dataCompany[TYPES.whats], dataProperty[TYPES.whats]],
                  chat: [dataCompany[TYPES.chat], dataProperty[TYPES.chat]],
                  shares: [dataCompany[TYPES.shares], dataProperty[TYPES.shares]],
                  calls: [dataCompany[TYPES.calls], dataProperty[TYPES.calls]],
                }}
              />
            </Box>
          )}
      </Box>

      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          margin: isXs ? 16 : "16px 0px 0px 0px",
          // gap: 16,
        }}
      >
        <Chart
          type={TYPES.views}
          title="Views"
          data={[dataCompany, dataProperty]}
          loading={loading[TYPES.views]}
          error={error[TYPES.views]}
        />

        <Chart
          type={TYPES.calls}
          title="Calls"
          data={[dataCompany, dataProperty]}
          loading={loading[TYPES.calls]}
          error={error[TYPES.calls]}
        />

        <Chart
          type={TYPES.whats}
          title="Whatsapp"
          data={[dataCompany, dataProperty]}
          loading={loading[TYPES.whats]}
          error={error[TYPES.whats]}
        />

        <Chart
          type={TYPES.shares}
          title="Shares"
          data={[dataCompany, dataProperty]}
          loading={loading[TYPES.shares]}
          error={error[TYPES.shares]}
        />

        <Chart
          type={TYPES.chat}
          title="Chat"
          data={[dataCompany, dataProperty]}
          loading={loading[TYPES.chat]}
          error={error[TYPES.chat]}
        />
      </Box>
    </>
  );
};

export default Charts;

import { createSlice } from "@reduxjs/toolkit";
import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";

import { ChatType } from "../../enums";

const initialState = {
  [ChatType.company]: {
    rooms: [],
    current: null,
    hasMore: false,
    isSending: false,
    roomsLoaded: false,
    loading: false,
  },
  [ChatType.reply]: {
    rooms: [],
    current: null,
    hasMore: false,
    isSending: false,
    roomsLoaded: false,
    loading: false,
  },
  [ChatType.property]: {
    rooms: [],
    current: null,
    hasMore: false,
    isSending: false,
    roomsLoaded: false,
    loading: false,
  },
};

const chatReducer = createSlice({
  name: "@@@Chat",
  initialState,
  reducers: {
    clearCurrentRoom(state, action) {
      const { type } = action.payload;
      state[type].current = null;
    },
    setRooms(state, action) {
      const { rooms, chatType } = action.payload;
      if (!state[chatType].roomsLoaded) {
        state[chatType].rooms = rooms;
        state[chatType].roomsLoaded = true;
      }
    },
    syncRooms(state, action) {
      const { rooms, chatType } = action.payload;
      state[chatType].rooms = _uniqBy([...state[chatType].rooms, ...rooms], "id");
    },
    updateRooms(state, action) {
      const { preparedRooms, type } = action.payload;
      if (state[type] && preparedRooms.length > 0) {
        const updatedRoom = preparedRooms[0];

        let rooms = [...state[type].rooms];
        const roomToUpdate = rooms.find((room) => room.id === updatedRoom.id);

        if (roomToUpdate) {
          roomToUpdate.lastMessage = updatedRoom.lastMessage;
        } else if (updatedRoom) {
          rooms = _uniqBy(rooms.concat(updatedRoom), "id");
        }

        state[type].rooms = _orderBy(rooms, ["lastMessage.createdAt"], ["desc"]);
      }
    },
    setRoomMessages(state, action) {
      const { messages, type, roomId } = action.payload;
      const rooms = [...state[type].rooms];

      if (messages.length === 0) {
        state[type].rooms = rooms.map((room) => {
          return room.id === roomId ? { ...room, hasMore: false } : room;
        });
      } else {
        const roomToAddTo = rooms.find((room) => room.id === roomId);
        if (roomToAddTo) {
          const existingMessages = roomToAddTo.messages || [];
          const concatenatedMessages = existingMessages.concat(messages);
          const sortedMessages = _orderBy(concatenatedMessages, "createdAt.seconds", ["desc"]);
          roomToAddTo.messages = _uniqBy(sortedMessages, "id");
        }
        state[type].rooms = rooms;
      }
    },
    setCurrentRoom(state, action) {
      const { type, roomId } = action.payload;
      state[type].current = roomId;
    },
    setIsSending(state, action) {
      const { type, sending } = action.payload;
      state[type].isSending = sending;
    },
    isLoading(state, action) {
      const { type, loading } = action.payload;
      state[type].isLoading = loading;
    },
    updateRoomSeen(state, action) {
      const { type, roomId } = action.payload;
      const roomIndex = state[type].rooms.findIndex((r) => r.id === roomId);
      let seen = state[type].rooms[roomIndex].lastMessage.seen;
      if (!seen) {
        state[type].rooms[roomIndex].lastMessage.seen = true;
      }
    },
  },
});

export const {
  clearCurrentRoom,
  setCurrentRoom,
  updateRooms,
  setIsSending,
  setRooms,
  syncRooms,
  setRoomMessages,
  updateRoomSeen,
  isLoading,
} = chatReducer.actions;
export default chatReducer.reducer;

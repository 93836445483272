import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRefresh, useResourceContext } from "ra-core";

import { resetResourceRefreshRequirement } from "../redux/reducers/list";

export default function useRealTimeRefresh() {
  const resourceKey = useResourceContext();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const requiresRefresh = useSelector((state) => state.lists[resourceKey] && state.lists[resourceKey].requiresRefresh);

  useEffect(() => {
    if (requiresRefresh) {
      dispatch(resetResourceRefreshRequirement({ resourceKey }));
      refresh();
    }
  }, [requiresRefresh]);
}

import { Box } from "@material-ui/core";

const Terms = () => {
  return (
    <Box p={5}>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>شروط وأحكام إستخدام التطبيق</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>
            هام: عن طريق تحميل التطبيق ، فإنك توافق على الالتزام بالشروط التالية:
          </span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <ul style={{ listStyleType: "undefined", marginLeft: "-0.5in" }}>
        <li>
          <span style={{ fontSize: "16px" }}>اتفاقية ترخيص التطبيق</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>الشروط والأحكام التكميلية للخصم والائتمان المصرح بها مسبقًا</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>الإخطارات عن طريق التطبيق&nbsp;</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>سياسة الخصوصية</span>
        </li>
      </ul>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>&nbsp; اتفاقية ترخيص التطبيق - ترخيص الاستخدام الفردي</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          يرجى قراءة اتفاقية ترخيص التطبيق هذه (المشار إليها لاحقًا باسم "الترخيص") بعناية قبل استخدام التطبيق الخاصة
          بالممتلكات العقارية أو تنزيل تحديث التطبيق المصاحب لهذا الترخيص. من خلال استخدام التطبيق أو تنزيل التحديث، كما
          هو معمول به ، فإنك توافق على الالتزام بشروط هذا الترخيص. إذا كنت لا توافق على شروط هذا الترخيص ، فلا تستخدم
          التطبيق أو تقوم بتنزيل تحديث التطبيق.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>1. عامة.</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (أ) &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;هذا جزء من تطبيق بروبرتي فيلتر الذي تملكه وتشغله شركة MORE REATIVE. من
          أجل استخدام هذا التطبيق ("التطبيق") وبرنامج التقديم ("التطبيق") ، يجب عليك أولاً الموافقة على البنود والشروط
          التالية ("الشروط والأحكام"). يرجى مراجعة الشروط والأحكام بعناية.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>(ب) &nbsp; &nbsp; &nbsp;&nbsp;قد يتيح&nbsp;</span>
        <span style={{ fontSize: "16px" }}>التطبيق</span>
        <span style={{ fontSize: "16px" }}>
          &nbsp;، وفقًا لتقديره ، تحديثات مستقبلية. قد لا تتضمن تحديثات التطبيق ، إن وجدت ، بالضرورة جميع ميزات التطبيق
          الحالية أو الميزات الجديدة التي تصدرها بروبرتي فيلترللإصدارات الأحدث. ستحكم شروط هذا الترخيص أي تحديثات مقدمة
          من بروبرتي فيلتروالتي تحل محل و / أو تكمل منتج التطبيق الأصلي ما لم يكن تحديث التطبيق هذا مصحوبًا بترخيص منفصل
          وفي هذه الحالة تسود شروط ذلك الترخيص.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>2. استخدامات الترخيص والقيود المسموح بها.</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (أ) &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;وفقًا لشروط وأحكام هذا الترخيص ، يتم منحك ترخيصًا محدودًا غير حصري
          لاستخدام التطبيق في ملف تعريف واحد مسجل في بروبرتي فيلترلا يجوز لك ، وأنت توافق على عدم قيامك بنسخ أو فك ترجمة
          أو هندسة عكسية أو تفكيك أو محاولة اشتقاق كود المصدر أو فك تشفير أو تعديل أو إنشاء أعمال مشتقة من التطبيق أو أي
          خدمات يقدمها التطبيق، أو أي جزء منها.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (ب) &nbsp; &nbsp; &nbsp;&nbsp;ستحتفظ بروبرتي فيلتربجميع الحقوق والملكية في التطبيق في جميع أنحاء العالم ، بما
          في ذلك ، على سبيل المثال لا الحصر ، ملكية جميع حقوق النشر والعلامات التجارية وبراءات الاختراع والشكل والمظهر
          وحقوق الأسرار التجارية وأي ملكية فكرية أخرى وحقوق الملكية المعترف بها بموجب أي قانون معمول به. تحتفظ بروبرتي
          فيلتربالحق في إجراء مثل هذه التعديلات على التطبيق من وقت لآخر كما تراه مناسباً من أجل تحسين وظائف التطبيق أو
          مظهره حسب تقديرها.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (ج) &nbsp; &nbsp; &nbsp;&nbsp;توافق على استخدام التطبيق والخدمات (على النحو المحدد في القسم 5 أدناه) بما
          يتوافق مع جميع القوانين المعمول بها ، بما في ذلك القوانين المحلية للبلد أو المنطقة التي تقيم فيها أو التي تقوم
          بتنزيل أو استخدام التطبيق والخدمات فيها . قد لا تتوفر ميزات التطبيق والخدمات بجميع اللغات أو المناطق ، وقد
          تختلف بعض الميزات حسب المنطقة ، وقد يكون بعضها مقيدًا أو غير متوفر من مزود الخدمة الخاص بك.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (د) &nbsp; &nbsp; &nbsp; &nbsp;يتطلب استخدام التطبيق تركيبة فريدة لاسم المستخدم وكلمة المرور ، تُعرف باسم
          معرّف بروبرتي فيلترID. مطلوب أيضًا معرّف التطبيق للوصول إلى تحديثات التطبيق وميزات معينة من البرامج والخدمات.
          بالإضافة إلى ذلك ، تقر بأن العديد من الميزات والتطبيقات المضمنة وخدمات التطبيق تنقل البيانات ويمكن أن تؤثر على
          الرسوم على خطة البيانات الخاصة بك وأنك مسؤول عن أي رسوم من هذا القبيل.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (هـ) &nbsp; &nbsp; &nbsp;&nbsp;إذا اخترت السماح بالتحديثات التلقائية للتطبيق ، فسيقوم جهازك بشكل دوري بالبحث
          باستخدام بروبرتي فيلترللحصول على تحديثات للتطبيق على جهازك ، وإذا كان أحدها متاحًا ، فسيتم تحميل التحديث
          وتثبيته تلقائيًا على جهازك. يمكنك إيقاف تشغيل تحديثات التطبيق التلقائية تمامًا في أي وقت بالانتقال إلى
          الإعدادات ، وضمن التحميل التلقائي ، قم بإيقاف تشغيل التحديثات.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (و) &nbsp; &nbsp; &nbsp; &nbsp;إذا كنت قد حصلت على حق الوصول إلى التطبيق من خلال عرض تجريبي مجاني أو عرض
          ترويجي ، فأنت توافق على أن بروبرتي فيلترتحتفظ بالحق في إلغاء أو تمديد أو تقصير الفترة التجريبية وفقًا لتقديرها
          الخاص دون الحاجة إلى إخطار المستخدمين قبل التغيير. تحتفظ بروبرتي فيلتربالحق ، وفقًا لتقديرها المطلق ، في تحديد
          أهليتك لإجراء تجربة لبعض الخدمات ؛ سنطلب منك تقديم تفاصيل الدفع لبدء الفترة التجريبية. في نهاية هذه التجارب ،
          قد نبدأ تلقائيًا في فرض رسوم عليك مقابل الاشتراك المدفوع الساري في اليوم الأول بعد نهاية التجربة ، على أساس
          شهري متكرر. من خلال تقديم تفاصيل الدفع الخاصة بك جنبًا إلى جنب مع التجربة ، فإنك توافق على هذه الرسوم باستخدام
          تفاصيل الدفع هذه. إذا كنت لا تريد هذه الرسوم ، فيجب عليك إلغاء الاشتراك المدفوع المطبق من خلال معرّف مستخدم
          التطبيق الخاص بك.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          3. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>النقل</strong>. لا يجوز لك تأجير التطبيق أو إعارته أو بيعه أو
          إعادة توزيعه أو ترخيصه من الباطن.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          4. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>الموافقة على استخدام البيانات عند استخدام التطبيق</strong> . يتم
          إرسال معرفات فريدة معينة لملف التعريف الخاص بك إلى بروبرتي فيلترمن أجل تسهيل ميزات الاتصال المختلفة للتطبيق.
          قد يحتفظ بروبرتي فيلتربمعلوماتك لفترة محدودة من الوقت. في جميع الأوقات ، سيتم التعامل مع معلوماتك وفقًا لسياسة
          خصوصية بروبرتي فيلتر، والتي يمكن الاطلاع عليها أدناه.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>5. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;خدمات ومواد الطرف الثالث.</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (أ) &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;أنت توافق على استخدام الخدمات على مسؤوليتك وحدك وأن بروبرتي فيلترأو
          الشركات التابعة لها أو وكلائها أو مديريها أو المرخصين لها لا يتحملون أي مسؤولية تجاهك. أنت توافق على الدفاع عن
          بروبرتي فيلتروموظفيها ومقاوليها ومسؤوليها ووكلائها ومديريها وتعويضهم وحمايتهم من جميع الالتزامات والمطالبات
          والنفقات ، بما في ذلك أتعاب المحاماة التي تنشأ عن استخدامك للتطبيق. تحتفظ بروبرتي فيلتربالحق ، على نفقتها
          الخاصة ، في تولي الدفاع الحصري والتحكم في أي مسألة تكون ملزمًا بتعويضها عن التطبيق. إذا اختارت بروبرتي فيلترأن
          تتولى الدفاع عن أي مسألة تخضع للتعويض من جانبك ، فسوف تتعاون مع بروبرتي فيلتر، على نفقتها ، وفي أي مجال تطلبه
          بروبرتي فيلتربشكل معقول.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (ب) &nbsp; &nbsp; &nbsp;&nbsp;قد تعرض خدمات معينة أو تتضمن أو تتيح محتوى أو بيانات أو معلومات أو تطبيقات أو
          مواد من أطراف ثالثة ("مواد الطرف الثالث") أو توفر روابط لمواقع ويب معينة لأطراف أخرى. باستخدام الخدمات ، فإنك
          تقر وتوافق على أن بروبرتي فيلترليست مسؤولة عن فحص أو تقييم المحتوى ، أو الدقة ، أو الاكتمال ، أو التوقيت ، أو
          الصلاحية ، أو الامتثال لحقوق الطبع والنشر ، أو الشرعية، أو الجودة أو أي جانب آخر من مواد الطرف الثالث أو
          الويب. المواقع الإلكترونية، لا تضمن شركة بروبرتي فيلترأو مسؤوليها أو الشركات التابعة لها، ولا تتحمل ولن تتحمل
          أي التزام أو مسؤولية تجاهك أو تجاه أي شخص آخر عن أي خدمات طرف ثالث أو مواد أو مواقع إلكترونية خاصة بطرف ثالث
          أو لصالح أي مواد أو منتجات أو خدمات أخرى لأطراف ثالثة. يتم توفير مواد الطرف الثالث وروابط إلى مواقع إلكترونية
          أخرى فقط للتسهيل عليك.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (ج) &nbsp; &nbsp; &nbsp;&nbsp;إلى الحد الذي تقوم فيه بتحميل أي محتوى من خلال استخدام الخدمات ، فإنك تقر بأنك
          تمتلك جميع الحقوق في ، أو لديك تصريح أو مسموح لك قانونًا بتحميل هذا المحتوى وأن هذا المحتوى لا ينتهك أي شروط
          خدمة تنطبق على الخدمات. أنت توافق على أن الخدمات تحتوي على محتوى خاص ومعلومات ومواد مملوكة لشركة بروبرتي
          فيلترو / أو مالك الموقع و / أو المرخصين لهم ، ومحمية بموجب قوانين الملكية الفكرية المعمول بها والقوانين الأخرى
          ، بما في ذلك على سبيل المثال لا الحصر حقوق النشر. أنت توافق على أنك لن تستخدم مثل هذا المحتوى أو المعلومات أو
          المواد ذات الملكية الخاصة بخلاف الاستخدام المسموح به للخدمات أو بأي طريقة لا تتوافق مع شروط هذا الترخيص أو
          تنتهك أي حقوق ملكية فكرية لطرف ثالث أو المزيد من الممتلكات منقي. لا يجوز إعادة إنتاج أي جزء من الخدمات بأي شكل
          أو بأي وسيلة. أنت توافق على عدم تعديل أو تأجير أو إقراض أو بيع أو توزيع أو إنشاء أعمال مشتقة تستند إلى الخدمات
          بأي طريقة ، ولا يجوز لك استغلال الخدمات بأي طريقة غير مصرح بها على الإطلاق ، بما في ذلك على سبيل المثال لا
          الحصر ، استخدام خدمات لنقل أي فيروسات كمبيوتر أو فيروسات متنقلة أو أحصنة طروادة أو برامج ضارة أخرى ، أو عن
          طريق التعدي على سعة الشبكة أو إثقالها. أنت توافق أيضًا على عدم استخدام الخدمات بأي شكل من الأشكال للمضايقة أو
          الإساءة أو الملاحقة أو التهديد أو التشهير أو انتهاك أو حقوق أي طرف آخر ، وأن بروبرتي فيلترليست مسؤولة بأي شكل
          من الأشكال عن أي استخدام من هذا القبيل من قبلك ، ولا لأي رسائل أو عمليات نقل مزعجة أو تهديدية أو تشهيرية أو
          مسيئة أو مخالفة أو غير قانونية قد تتلقاها نتيجة لاستخدام أي من الخدمات.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>6. المحتوى المحظور.</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (أ) &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;أنت توافق على أنك لن تقوم بتحميل أو نشر أو توزيع أي نص أو اتصالات أو
          بيانات أو معلومات أخرى من خلال التطبيق ("المحتوى") (1) غير قانونية أو مهددة أو مسيئة أو مهينة أو تشهيرية
          &nbsp;أو مضايقة أو مؤذية أو عنصرية أو عرقية أو مرفوضة بأي شكل آخر ، (2) المحتوى الجنسي الصريح أو غير اللائق
          (بما في ذلك ، على سبيل المثال لا الحصر ، لغة جنسية مصورة أو غير لائقة ذات طبيعة تهديدية أو مضايقة موجهة إلى أي
          فرد أو مجموعة) ؛ (3) يشكل أو يشجع على سلوك من شأنه أن يؤدي إلى مسؤولية مدنية أو ينتهك القانون ؛ (4) ينتهك أو
          ينتحل حقوق الغير بما في ذلك ، على سبيل المثال لا الحصر ، حقوق الطبع والنشر والعلامات التجارية وحقوق الخصوصية
          أو الدعاية أو أي حق ملكية آخر ؛ (5) يحتوي على فيروس أو أي مكون ضار آخر مصمم لمقاطعة أو تدمير أو تقييد وظائف أي
          برنامج كمبيوتر أو جهاز أو معدات اتصالات ، أو مصمم للحصول على وصول غير مصرح به إلى أي معلومات ؛ (6) ليس لديك
          جميع الحقوق والتراخيص اللازمة للإرسال بموجب أي قانون أو بموجب أي علاقة تعاقدية أو ائتمانية (7) التي تشكل أو
          تحتوي على مؤشرات كاذبة أو مضللة عن المنشأ أو بيانات الوقائع. أنت توافق أيضًا على أنك لن تجمع معلومات حول أي من
          مستخدمي التطبيق أو تستخدم هذه المعلومات لغرض إرسال أو تسهيل نقل البريد الإلكتروني الجماعي غير المرغوب فيه أو
          الاتصالات الأخرى.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          (ب) &nbsp; &nbsp; &nbsp;&nbsp;لا تقوم بروبرتي فيلتربشكل عام ، ولن تكون ملزمة بأي شكل من الأشكال ، بفحص أو
          مراقبة أو تحرير أي محتوى ينشره مستخدمو التطبيق. ومع ذلك ، تحتفظ بروبرتي فيلتربالحق في إزالة أي محتوى ، وفقًا
          لتقديرها الخاص ، لا يتوافق مع هذه الشروط والأحكام أو يكون بطريقة أخرى ضارًا أو مرفوضًا أو غير دقيق. ومع ذلك ،
          فإن تطبيق بروبرتي فيلتر لن يكون بأي حال من الأحوال مسؤول عن أي فشل أو تأخير في إزالة أي محتوى من هذا القبيل.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>7.&nbsp;</span>
        </strong>
        <strong>
          <span style={{ fontSize: "16px" }}>صلاحية الترخيص</span>
        </strong>
        <span style={{ fontSize: "16px" }}>
          &nbsp;يسري هذا الترخيص حتى يتم إنهاؤه. ستنتهي حقوقك بموجب هذا الترخيص تلقائيًا أو تتوقف عن أن تكون سارية
          المفعول دون إشعار من بروبرتي فيلترإذا فشلت في الامتثال لأي شرط (شروط) من هذا الترخيص. عند إنهاء هذا الترخيص ،
          يجب عليك التوقف عن استخدام التطبيق بالكامل. تظل الأقسام 4 و 5 و 6 و 7 و 8 و 9 و 12 من هذا الترخيص سارية بعد أي
          إنهاء من هذا القبيل.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>8. إخلاء المسؤولية عن الضمانات.</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.1 أنت تقر وتوافق صراحةً ، إلى الحد الذي يسمح به القانون المعمول به ، على أن استخدام التطبيق وأي خدمات يقدمها
          التطبيق أو يتم الوصول إليها من خلاله تقع على مسؤوليتك وحدك وعلى مسؤوليتك الكاملة.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.2 إلى الحد الأقصى الذي يسمح به القانون المعمول به ، يتم توفير التطبيق والخدمات "كما هي" و "حسب توفرها"،
          وبدون أي ضمان من أي نوع، لأغراض القسمين 7 و 8 &nbsp;بموجب هذا يُخلي المسئولية عن جميع الضمانات والشروط
          المتعلقة بالتطبيق والخدمات ، سواء كانت صريحة أو ضمنية أو قانونية ، بما في ذلك ، على سبيل المثال لا الحصر ،
          الضمانات والشروط الضمنية أو القابلية للتسويق والجودة والملاءمة لغرض معين والدقة والتمتع الهادئ وعدم التعدي على
          حقوق الطرف الثالث.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.3 لا ضمان ضد التداخل مع إستخدامك التطبيق والخدمات ، أو أن الوظائف المضمنة في أو الخدمات المقدمة من التطبيق ،
          سوف تفي بمتطلباتك من التطبيق والخدمات ، خالية من الأخطاء ، أن أي خدمة ستستمر في التوفر ، وسيتم تصحيح العيوب
          الموجودة في التطبيق أو الخدمات ، أو أن التطبيق سيكون متوافقًا أو يعمل مع أي برنامج أو تطبيقات أو خدمات تابعة
          لجهة خارجية. قد يؤثر تثبيت هذا التطبيق على توفر وإمكانية استخدام برامج أو تطبيقات أو خدمات الطرف الثالث ،
          وكذلك المزيد من منتجات وخدمات التطبيق.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.4 أنت تقر أيضًا بأن التطبيق والخدمات ليست مقصودة أو مناسبة للاستخدام في المواقف أو البيئات التي يكون فيها
          الإخفاق أو التأخيرات الزمنية أو الأخطاء أو عدم الدقة في المحتوى أو البيانات أو المعلومات المقدمة من قبل الموقع
          أو خدمات الوفاة أو الإصابة الشخصية أو الأضرار الجسدية أو البيئية الشديدة ، بما في ذلك على سبيل المثال لا الحصر
          تشغيل المرافق النووية أو أنظمة الملاحة الجوية أو الاتصالات أو التحكم في النقل الجوي أو أنظمة دعم الحياة أو
          الأسلحة.
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          8.5 لا تشكل المعلومات الشفوية أو المكتوبة أو المشورة المقدمة من خلال التطبيق أو من الممثلين المعتمدين للتطبيق
          ضمانًا. إذا ثبت أن التطبيق أو الخدمات معيبة ، فأنت تتحمل التكلفة الكاملة لجميع الخدمات الضرورية أو الإصلاح أو
          التصحيح. لا تسمح بعض السلطات القضائية باستثناء الضمانات أو القيود الضمنية على الحقوق القانونية المعمول بها
          للمستهلك ، لذلك قد لا ينطبق عليك الاستثناء والقيود المذكورة أعلاه.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>9.</span>
        </strong>
        <span style={{ fontSize: "16px" }}>
          &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <strong>تحديد المسؤولية إلى الحد الذي لا يحظره القانون المعمول به.&nbsp;</strong>لن يكون بأي حال من الأحوال
          التطبيق أو الشركات التابعة لها أو الوكلاء أو أصحابها مسؤولين عن أي إصابة شخصية أو أي عرضية أو خاصة أو غير
          مباشرة أو تبعات ، الأضرار الناجمة عن فقدان الأرباح ، أو الفساد أو فقدان البيانات ، أو الإخفاق في نقل أو استلام
          أي بيانات، أو مقاطعة الأعمال أو أي عمليات تجارية أخرى أو عدم القدرة على استخدام التطبيق والخدمات أو أي برامج
          أو تطبيقات تابعة لجهة خارجية مرتبطة بالتطبيق أو الخدمات ، مهما كان السبب ، بصرف النظر عن نظرية المسؤولية
          (العقد أو الضرر أو غير ذلك) احتمالية حدوث مثل هذه الأضرار. لا تسمح بعض السلطات القضائية باستثناء أو تحديد
          المسؤولية عن الإصابة الشخصية أو الأضرار العرضية أو التبعية ، لذلك قد لا ينطبق عليك هذا التحديد. لا يجوز بأي
          حال من الأحوال أن تتجاوز المسؤولية الإجمالية للتطبيق تجاهك عن جميع الأضرار (بخلاف ما قد يقتضيه القانون المعمول
          به) مبلغ ( &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ).
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>10. &nbsp; &nbsp; &nbsp;&nbsp;الشهادات الرقمية.&nbsp;</span>
        </strong>
        <span style={{ fontSize: "16px" }}>
          يحتوي التطبيق على وظائف تسمح له بقبول الشهادات الرقمية سواء الصادرة من بروبرتي فيلترأو من جهات خارجية. أنت
          وحدك المسؤول عن تقرير ما إذا كنت ستعتمد أو لا تعتمد على شهادة سواء صادرة عن التطبيق أو طرف ثالث. يقع استخدامك
          للشهادات الرقمية على مسؤوليتك وحدك. إلى الحد الأقصى الذي يسمح به القانون المعمول به ، لا يقدم التطبيق أي
          ضمانات أو إقرارات ، صريحة أو ضمنية ، فيما يتعلق بقابلية التسويق أو الملاءمة لأي غرض معين ، أو الدقة ، أو
          الأمان ، أو عدم الالتزام بهذه الالتزامات.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          11. &nbsp; &nbsp; &nbsp;&nbsp;<strong>القانون المسيطر وقابلية الفصل.</strong> سيخضع هذا الترخيص لقوانين دولة
          قطر ويتم تفسيره وفقًا لها ، باستثناء تعارضها مع مبادئ القانون. إذا وجدت أي محكمة ذات اختصاص قضائي لأي سبب من
          الأسباب أن أي بند أو جزء منه غير قابل للتنفيذ ، فإن بقية هذا الترخيص تظل سارية المفعول والتأثير الكامل.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          12. &nbsp; &nbsp; &nbsp;&nbsp;<strong>الإتفاقية الكامل، اللغة المعتمدة</strong>. يشكل هذا الترخيص الاتفاقية
          الكاملة بينك وبين بروبرتي فيلترفيما يتعلق بالتطبيق ويبطل جميع المفاهيم السابقة أو الحالية فيما يتعلق بهذا
          الموضوع. لن يكون أي تعديل على هذا الترخيص ملزمًا إلا إذا كان مكتوبًا وموقعًا بواسطة&nbsp;
        </span>
        <span style={{ fontSize: "16px" }}>بروبرتي فيلتر</span>
        <span style={{ fontSize: "16px" }}>ي</span>
        <span style={{ fontSize: "16px" }}>
          تم ترجمة هذا الترخيص للمتطلبات المحلية وفي حالة وجود نزاع بين اللغة العربية وأي إصدارات غير العربية ، يجب أن
          تكون النسخة العربية من هذا الترخيص هي السائدة ، إلى الحد الذي لا يحظره القانون المحلي.
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>الشروط والأحكام التكميلية للخصم والائتمان المصرح بها مسبقًا</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          تكمل شروط وأحكام الخصم المباشر المصرح به مسبقًا ("الشروط التكميلية") اتفاقية ترخيص التطبيق (المشار إليها
          لاحقًا باسم "الترخيص") ؛ تحكم كل من شروط الترخيص وهذه الشروط التكميلية استخدامك لميزة بروبرتي فيلتر باي.
          المصطلحات المكتوبة بحروف كبيرة المستخدمة في هذه الشروط التكميلية لها المعاني المنصوص عليها في الترخيص.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>1- &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;نظرة عامة وقيود الاستخدام</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          يسمح لك بروبرتي فيلترباي بتخزين تمثيلات افتراضية لبطاقات الائتمان والخصم ، و / أو المعلومات المصرفية لتمكين
          المدفوعات اللاسلكية ، والتي تدعمها ميزة&nbsp;
        </span>
        <span style={{ fontSize: "16px" }}>بروبرتي فيلترباي</span>
        <span style={{ fontSize: "16px" }}>
          &nbsp;("المدفوعات المدعومة") واستخدام الشركاء المدعومين لإجراء مدفوعات بدون تلامس داخل التطبيق. قد تختلف
          الميزات حسب المنطقة والمُصدر والتاجر.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          من أجل تسهيل ميزة الدفع بدون تلامس داخل التطبيق ، يجب عليك تقديم اختيارك لطريقة الدفع الصالحة سواء عن طريق
          البطاقة أو الحساب المصرفي وتقديم إقرارًا وتفويضًا لمرة واحدة في النموذج التالي:
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>أنا (نحن) أفوض (نفوض) بموجب هذا&nbsp;</span>
        <span style={{ fontSize: "16px" }}>تطبيق بروبرتي فيلتر</span>
        <span style={{ fontSize: "16px" }}>
          و / أو شركائها في المعالجة لبدء إدخالات الخصم و / أو الائتمان على حسابي (
        </span>
        <span style={{ fontSize: "16px" }}>حساباتنا</span>
        <span style={{ fontSize: "16px" }}>
          ) ، في المؤسسة المالية المعتمدة المسماة فيما يلي باسم DEPOSITORY ، ولخصم و / أو اعتماد نفس هذا الحساب.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          أقوم (نقوم) بتضمين تفويضي (تفويضنا) لـ بروبرتي فيلتروالجهة المختصة المعالجة لعكس أو خصم أي إدخالات ائتمانية تم
          إجراؤها عن طريق الخطأ على حسابي (حساباتنا). أقر (نقر) بأن المعاملات المالية على حسابي (حساباتنا) يجب أن تمتثل
          لأحكام القانون القطري.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          تم تصميم بروبرتي فيلترباي لاستخدامك الشخصي والشركات ، ولا يجوز لك سوى تقديم معلوماتك الخاصة و / أو المعلومات
          المصرح بها قانونًا أعلاه فيما يتعلق بمعرف المستخدم الخاص بك. إذا كنت تقوم بتوفير بطاقة أو حسابات مدعومة للشركة
          ، أو إذا كنت مستخدمًا متصلًا بشركة تقوم بالتزويد نيابة عن عميلك ، فإنك تقر بأنك تفعل ذلك بتفويض من العميل أو
          زميلك حسب الاقتضاء ، وأنت مخول بإلزام العميل / زميلك بشروط الاستخدام هذه وجميع المعاملات التي تتم عن طريق
          استخدام هذه الميزة.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          أنت توافق على عدم استخدام بروبرتي فيلترباي لأغراض غير قانونية أو احتيالية ، أو أي أغراض أخرى محظورة بموجب
          الترخيص وهذه الشروط التكميلية. أنت توافق أيضًا على استخدام بروبرتي فيلترباي وفقًا للقانون واللوائح المعمول
          بها. أنت توافق على عدم التدخل أو تعطيل خدمة بروبرتي فيلترباي (بما في ذلك الوصول إلى الخدمة من خلال أي وسيلة
          آلية) ، أو أي خوادم أو شبكات متصلة بالخدمة ، أو أي سياسات أو متطلبات أو لوائح الشبكات المتصلة بالخدمة ( بما في
          ذلك أي وصول غير مصرح به إلى البيانات أو حركة المرور عليها أو استخدامها أو مراقبتها).
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>2- &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;التطبيق وصفته معك</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          أنت توافق على أن بروبرتي فيلترليست طرفًا في اتفاقيات حامل البطاقة أو البنك أو التاجر ، كما أنه ليس مسؤول عن
          محتوى أو دقة أو عدم توفر أي من بطاقات الدفع أو المعلومات المصرفية أو بطاقات القيمة المخزنة أو الأنشطة التجارية
          أو المعاملات ، أو عمليات الشراء أثناء استخدام وظيفة بروبرتي فيلترباي ، كما أنها لا تشارك بأي شكل من الأشكال في
          إصدار الائتمان أو تقييم الأهلية للحصول على الائتمان ، أو استحقاق أو استرداد المكافآت بموجب برنامج مكافآت
          التاجر. لجميع النزاعات أو الأسئلة حول بطاقات الدفع أو الحسابات المصرفية أو بطاقات القيمة المخزنة أو النشاط
          التجاري المرتبط ، يرجى الاتصال بالجهة المصدرة للبطاقة أو البنك أو التاجر المعني.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>3- &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;الخصوصية</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>تتطلب ميزة بروبرتي فيلتر باي</span>
        <span dir="LTR" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
        <span style={{ fontSize: "16px" }}>
          &nbsp;بعض المعلومات من جهازك لتقديم التجربة الكاملة. يمكنك العثور على مزيد من المعلومات حول البيانات التي تم
          جمعها أو استخدامها أو مشاركتها كجزء من استخدامك لـ بروبرتي فيلترباي من خلال قراءة سياسة خصوصية بروبرتي فيلتر
          أدناه.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>
            4- &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;الأمن. الأجهزة المفقودة أو المعطلة.
          </span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>بروبرتي فيلتر باي</span>
        <span style={{ fontSize: "16px" }}>
          &nbsp;، تخزن معاملات الدفع والخصم المصرح به مسبقًا تمثيلات افتراضية للدفع المدعوم ، و / أو معلومات الحساب
          المصرفي التي قد تكون قابلة للتطبيق ويجب حمايتها لأنك ستحمي بطاقات الائتمان والخصم المادية الخاصة بك قد يؤدي
          تقديم رمز المرور الخاص بجهازك إلى جهة خارجية أو السماح لطرف ثالث بإضافة بصمات أصابعه لاستخدام Touch ID إلى
          قدرتها على إجراء مدفوعات باستخدام بروبرتي فيلترباي على جهازك. أنت وحدك المسؤول عن الحفاظ على أمان جهازك ورمز
          المرور الخاص بك. أنت توافق على أن بروبرتي فيلترلا تتحمل أي مسؤولية في حالة فقد أو مشاركة الوصول إلى جهازك. أنت
          توافق على أن بروبرتي فيلتر لا تتحمل أي مسؤولية إذا قمت بإجراء تعديلات غير مصرح بها على (على سبيل المثال عن
          طريق "كسر الحماية").
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          إذا أبلغت أو اشتبهت بروبرتي فيلترفي نشاط احتيالي أو مسيء ، فإنك توافق على التعاون مع بروبرتي فيلترفي أي تحقيق
          واستخدام أي إجراءات لمنع الاحتيال نوصي بها.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>5- &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;تحديد المسؤولية</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          بالإضافة إلى إخلاء المسؤولية عن الضمانات وتحديد المسؤولية المنصوص عليها في الترخيص ، لا يتحمل&nbsp;
        </span>
        <span style={{ fontSize: "16px" }}>التطبيق</span>
        <span style={{ fontSize: "16px" }}>
          &nbsp;أي مسؤولية عن عمليات الشراء ، أو المدفوعات ، أو المعاملات ، أو أي نشاط تجاري آخر يتم بإستخدام&nbsp;
        </span>
        <span style={{ fontSize: "16px" }}>بروبرتي فيلترباي</span>
        <span style={{ fontSize: "16px" }}>&nbsp;</span>
        <span style={{ fontSize: "16px" }}>
          للاتفاقيات التي قد تبرمها مع البنك المصدر أو شبكة الدفع أو البائع لحل أي أسئلة أو نزاعات تتعلق بالبطاقات.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>الإخطارات / الإشعارات من التطبيق</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          1. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;إذا احتاجت بروبرتي فيلترإلى الاتصال بك بشأن منتجك أو حسابك ، فإنك توافق
          على تلقي الإخطارات عبر البريد الإلكتروني. أنت توافق على أن أي إشعارات نرسلها إليك إلكترونيًا ستلبي أي متطلبات
          اتصال قانونية.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          2. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;أنت تدرك وتوافق على الإعلانات المستهدفة مع أطراف ثالثة موثوق بها وفي
          محاولة لتقديم خدمة أفضل والتمتع بالتطبيق والوظائف ذات الصلة. أنت تدرك وتوافق على أن بروبرتي فيلترقد ترسل إليك
          إشعارات على التطبيق من وقت لآخر بما يتماشى مع تطوير الأعمال والمنتجات وفقًا لتقديرها الخاص.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          إذا كانت لديك أي أسئلة بخصوص هذه الشروط والأحكام أو التطبيق ، يمكنك الوصول إلى بروبرتي فيلترعلى ( &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ).
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>سياسة الخصوصية&nbsp;</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          في بروبرتي فيلتر، حماية معلوماتك الشخصية دائمًا أولوية قصوى. تحترم ميزة بروبرتي فيلترخصوصيتك كمستخدم. تتمثل
          سياستنا في أن جميع المعلومات التي تقدمها ، بصفتك مستخدمًا مسجلًا ، سيتم الاحتفاظ بها بشكل آمن ولغرض واحد هو
          تزويدك بأفضل تجربة تصفح في تطبيقنا. لحماية خصوصيتك بشكل أفضل ، نقدم هذا الإشعار لشرح ممارسات المعلومات عبر
          الإنترنت والخيارات التي يمكنك القيام بها حول طريقة جمع معلوماتك واستخدامها. سيكون من السهل العثور على هذا
          الإشعار في الصفحة الرئيسية وأي صفحات نطلب فيها معلومات لاستخدام التطبيق.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>1. المساءلة</span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>بروبرتي فيلترهي المسؤولة عن المعلومات الشخصية التي تقع تحت سيطرتها.</span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          يتحمل جميع موظفي بروبرتي فيلتروالمتعاقدين الخارجيين مسؤولية الامتثال اليومي.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          قد يحتوي تطبيقنا على روابط لمواقع أخرى. نحن لسنا مسؤولين عن ممارسات الخصوصية لمواقع أخرى. نشجع مستخدمينا على
          أن يكونوا على دراية عندما يغادرون تطبيقنا لقراءة بيانات الخصوصية لكل موقع إلكتروني يجمع معلومات التعريف
          الشخصية. تنطبق سياسة الخصوصية هذه فقط على المعلومات التي تم جمعها بواسطة بروبرتي فيلتر. يرجى مراجعة الشروط
          والأحكام لمزيد من المعلومات.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>2. تحديد الأغراض</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          ما لم يكن الغرض واضحًا بسبب طبيعة المعاملة المعنية ، فإن بروبرتي فيلترستحدد الأغراض التي من أجلها يتم جمع
          المعلومات الشخصية في أو قبل وقت جمع المعلومات. سيتم وصف الأغراض المقترحة بطريقة مفهومة بشكل معقول.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>3. الموافقة</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          سنجمع معلوماتك الشخصية أو نستخدمها أو نكشف عنها فقط بمعرفتك وموافقتك ، باستثناء ما يقتضيه القانون أو يسمح به.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          لن تجعل ميزة بروبرتي فيلتر موافقتك مطلبًا لتزويد منتج أو خدمة بخلاف ما هو مطلوب لتتمكن من توفير المنتج أو
          الخدمة.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          يمكن أن تكون الموافقة صريحة أو ، في بعض الظروف ، ضمنية ومُعطاة كتابيًا ، باستخدام أو عدم استخدام مربع الاختيار
          ، إلكترونيًا أو شفهيًا (شخصيًا أو عبر الهاتف) ، أو عن طريق سلوكك ، مثل استخدام المنتج أو الخدمة.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          عند تحديد نوع الموافقة التي يجب الحصول عليها ، ستأخذ بروبرتي فيلترفي الاعتبار جميع العوامل ذات الصلة ، بما في
          ذلك حساسية المعلومات.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          يمكنك سحب موافقتك في أي وقت ، بموجب إشعار مقبول ، مع مراعاة القيود القانونية أو التعاقدية.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>4. الحدود المسموحة في جمع المعلومات&nbsp;</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          سيقتصر جمع المعلومات الشخصية بواسطة بروبرتي فيلترعلى ما هو ضروري للأغراض التي تحددها. سنجمع المعلومات الشخصية
          بوسائل عادلة وقانونية.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>5. قيود الإستخدام الاحتفاظ بالمعلومات والكشف عنها</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          لن يتم استخدام المعلومات الشخصية أو الكشف عنها لأغراض أخرى غير تلك التي تم جمعها من أجلها ، إلا بموافقتك أو
          وفقًا لما يقتضيه القانون أو يسمح به. سيتم الاحتفاظ بها فقط طالما كان ذلك ضروريًا لهذه الأغراض أو وفقًا لما
          يقتضيه القانون.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          قد نكشف عن معلوماتك الشخصية إلى: (1) أطراف في سياق بيع أو تمويل أو إعادة تنظيم محتمل لأعمالنا ، شريطة أن تتم
          الموافقة على الالتزام بهذه السياسة فيما يتعلق بمعلوماتك الشخصية ، (2) الأطراف التي تم الإتفاق على تقديم بيانات
          مجمعة ، مع العلم أننا سنزيل المعرفات الشخصية قبل القيام بذلك (3) الأطراف التي تعاقدنا معها لمعالجة البيانات ،
          بما في ذلك الأطراف الموجودة في البلدان أو نطاقات غير تلك التي تقيم فيها (عند حدوث ذلك ، تخضع معلوماتك الشخصية
          للقوانين القضائية لتلك البلدان).
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>6. الدقة</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          سنحافظ على معلوماتك الشخصية دقيقة وكاملة ومحدثة حسب الضرورة للأغراض التي سيتم استخدامها من أجلها.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>7. الضمانات</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          ستعمل ميزة بروبرتي فيلترعلى حماية المعلومات الشخصية عن طريق الضمانات الأمنية المناسبة لحساسية المعلومات ، بما
          في ذلك من خلال استخدام التدابير التالية: المادية (على سبيل المثال ، خزائن الملفات، والوصول المحدود ، والتخلص
          من المعلومات الشخصية بالطريقة المناسبة والصحيحة) ، التنظيمية (على سبيل المثال ، التصاريح الأمنية ، الوصول فقط
          على أساس "الحاجة إلى المعرفة") ، التكنولوجي (مثل كلمات المرور والتشفير) وتدريب الموظفين.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>8. الشفافية</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          سيتم توفير المعلومات المتعلقة بسياساتنا وممارساتنا المتعلقة بإدارة المعلومات الشخصية للأفراد بسهولة.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>9. الوصول الفردي</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          عند الطلب ، ستعلمك بروبرتي فيلتربوجود واستخدام والكشف عن المعلومات الشخصية المتعلقة بك ، وتمنحك الوصول إلى تلك
          المعلومات. لديك الحق في الاعتراض على دقة واكتمال معلوماتك وتعديلها حسب الاقتضاء.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          ومع ذلك ، في ظروف معينة يسمح بها القانون ، لن يتم الكشف عن هذه المعلومات لك. بعض الأمثلة على هذه الظروف هي
          المعلومات التي تحتوي على إشارات إلى أفراد آخرين ، والتي لا يمكن الكشف عنها لأسباب قانونية أو أمنية أو تجارية ،
          أو تخضع لامتياز التقاضي.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>10. الاتصال بنا و / أو الإعتراض على الإمتثال</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          لأي شيء يتعلق بهذه السياسة ، بما في ذلك الأسئلة أو التعليقات ، أو للطعن في امتثالنا لهذه السياسة ، يرجى
          الاتصال بنا على النحو التالي: ...
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          ستقوم بروبرتي فيلتربإبلاغ الأفراد الذين يقدمون استفسارات أو يقدمون شكاوى حول إجراءات التحقيق أو الشكوى ، حسب
          الاقتضاء. إذا تم العثور على ما يبرر الشكوى ، فسنتخذ الإجراءات المناسبة ، بما في ذلك ، إذا لزم الأمر ، تعديل
          سياساتنا وممارساتنا.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          بالطبع ، تخضع هذه السياسة لأي قوانين خصوصية أخرى معمول بها ويحق لنا تغييرها في أي وقت.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>المعلومات التي يتم جمعها:</span>
        </strong>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          ينطبق هذا الإشعار على جميع المعلومات التي تم جمعها أو تقديمها في تطبيق بروبرتي فيلترنقوم بجمع معلومات التعريف
          الشخصية والمعلومات مجهولة المصدر من المستخدمين المسجلين. تشمل أنواع المعلومات التي يتم جمعها ما يلي:
        </span>
      </p>
      <ul style={{ listStyleType: "disc", marginLeft: "-0.5in" }}>
        <li>
          <span style={{ fontSize: "16px" }}>الاسم&nbsp;</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>العنوان&nbsp;</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>عنوان البريد الإلكتروني&nbsp;</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>رقم الهاتف&nbsp;</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>هوية المستخدم&nbsp;</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>كلمة المرور&nbsp;</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>الجنس&nbsp;</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>تاريخ الميلاد&nbsp;</span>
        </li>
        <li>
          <span style={{ fontSize: "16px" }}>صور</span>
        </li>
      </ul>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          قد نستخدم ملفات تعريف الارتباط لتزويد المستخدمين المسجلين بخدمات مخصصة. ملفات تعريف الارتباط هي كميات صغيرة من
          البيانات التي يتم إرسالها إلى متصفح المستخدم المسجل من خادم الموقع الإلكتروني وتخزينها على محرك الأقراص
          الثابتة للمستخدم المسجل. هذه المعلومات لا تحدد هوية المستخدم على وجه التحديد ولكنها تحدد جهاز الكمبيوتر الخاص
          بالمستخدم. لن تجمع ملفات تعريف الارتباط المستخدمة أي معلومات شخصية مثل الاسم أو العنوان أو رقم الهاتف.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>طريقة استخدامنا للمعلومات</span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          نستخدم معلومات المستخدمين المسجلين لأغراض التنميط والتحليل. تسمح لنا هذه العناصر بتقديم منتجات وخدمات أفضل
          ومحسّنة ، ومنتجات وخدمات جديدة قد تهم المستخدمين المسجلين.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          لا يتم بيع المعلومات الشخصية للمستخدم المسجل أو تأجيرها لأطراف ثالثة دون إذن صريح من المستخدم المسجل الفردي.
          نحن نحتفظ بالحق في مشاركة المعلومات الديموغرافية و / أو الإحصائية المجمعة مع الشركاء والمعلنين والجهات
          الراعية.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          نحن نستخدم معلومات مجمعة وغير محددة للهوية لتحسين تجربتك على الموقع ومشاركتها مع المعلنين أو الأطراف المهتمة
          الأخرى. على سبيل المثال ، قد نشارك مع معلن أن X عددًا من الأشخاص زاروا منطقة معينة من التطبيق.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          لن نشارك معلوماتك مع أي شركة أو مؤسسة أخرى ، إلا في شكل مجمع غير محدد للهوية.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>كيف يمكنك الوصول إلى معلوماتك أو تصحيحها ؟</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          يمكنك الوصول إلى جميع معلومات التعريف الشخصية الخاصة بك التي نجمعها عبر الإنترنت. لحماية خصوصيتك وأمنك ، سنتخذ
          أيضًا خطوات معقولة للتحقق من هويتك قبل منح الوصول أو إجراء التصحيحات. يمكنك تصحيح الأخطاء الواقعية في معلومات
          التعريف الشخصية الخاصة بك عن طريق إرسال طلب إلينا يوضح الخطأ بشكل موثوق. لمزيد من المعلومات حول الإجراءات
          راسلنا على:
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>التزامنا بخصوصية الأطفال</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          حماية خصوصية الصغار أمر مهم بشكل خاص. لهذا السبب ، لا نقوم أبدًا بجمع أو الاحتفاظ بالمعلومات في تطبيقنا من
          أولئك الذين نعرف أنهم أقل من 13 عامًا ، ولا يوجد أي جزء من تطبيقنا منظم لجذب أي شخص أقل من 13 عامًا.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>التزامنا بأمن البيانات</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          لمنع الوصول غير المصرح به ، والحفاظ على دقة البيانات ، وضمان الاستخدام الصحيح للمعلومات ، قمنا بوضع إجراءات
          مادية وإلكترونية وإدارية مناسبة لحماية وتأمين المعلومات التي نجمعها عبر الإنترنت.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <strong>
          <span style={{ fontSize: "16px" }}>قيود على مسؤوليتنا</span>
        </strong>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          على الرغم من أننا ملتزمون باتخاذ جميع الخطوات المعقولة لتنفيذ سياسة الخصوصية الخاصة بنا بشكل صحيح ، فإن
          مسؤوليتنا عن انتهاكات الأمان المرتبطة بأي معلومات يتم إرسالها عبر هذا الموقع محدودة. للحصول على بيان بهذه
          القيود ، يرجى الرجوع إلى الفقرة 2 من شروط وأحكام المستخدم الخاصة بنا. بالإضافة إلى ذلك ، نحن لسنا مسؤولين عن
          أمان أي معلومات قد تختار نقلها من خلال هذا التطبيق إلى أي معلن أو إلى أي موقع مرتبط. لا تغطي سياسة الخصوصية
          الخاصة بنا أي معلومات يتم إرسالها على هذا النحو. أخيرًا ، نحتفظ بالحق في الكشف عن أي معلومات مقدمة إلى هذا
          التطبيق إلى الحد الذي يُطلب منا القيام بذلك بموجب القانون أو إذا كنا نعتقد بحسن نية أن الكشف عن هذه المعلومات
          مستحسن (1) لتحديد الهوية أو الاتصال أو تقديم المساعدة القانونية اتخاذ إجراء ضد أي طرف ينتهك أو يهدد بانتهاك
          شروط وأحكام المستخدم الخاصة بنا أو يضر أو يهدد بإلحاق الضرر بحقوق أو ممتلكات أي طرف ثالث ، أو (2) لحماية
          السلامة الجسدية لأي طرف.
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span dir="RTL" style={{ fontSize: "16px" }}>
          &nbsp;
        </span>
      </p>
      <p
        dir="RTL"
        style={{
          margin: "0in",
          marginBottom: ".0001pt",
          fontSize: "15px",
          fontFamily: '"Times New Roman","serif"',
          textAlign: "justify",
        }}
      >
        <span style={{ fontSize: "16px" }}>
          بالنقر فوق هذا المربع ، فإنك توافق على أنك قد قرأت وقبلت البنود والشروط المذكورة أعلاه. لديك السلطة لمنح
          بروبرتي فيلتروالشركات التابعة لها الإذن بمعالجة المدفوعات من حسابك.
        </span>
      </p>
    </Box>
  );
};

export default Terms;

import { IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useRedirect } from "react-admin";

import TooltipPopover from "../../../components/TooltipPopover";
import { ResourcesKeys } from "../../../enums";

const ViewStats = (props) => {
  const redirect = useRedirect();
  const { record } = props;

  const redirectViewStats = (e) => {
    e.stopPropagation();
    redirect(`/${ResourcesKeys.CompanyProperties}/${record.id}/statistics`);
  };

  return (
    <TooltipPopover message="View Property Statistics">
      <IconButton onClick={redirectViewStats}>
        <VisibilityIcon color="primary" />
      </IconButton>
    </TooltipPopover>
  );
};

export default ViewStats;

import messageAudioFile from "../../../assets/message.mp3";

const usePlayNotificationSound = () => {
  const notificationSound = new Audio(messageAudioFile);

  return () => {
    notificationSound.play().catch((ex) => {
      console.log(ex.message);
    });
  };
};

export default usePlayNotificationSound;

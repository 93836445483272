import { adminSaga } from "react-admin";
import { all, fork } from "redux-saga/effects";

import { authProvider, dataProvider } from "../../init/firebase";
import syncUser from "./syncUser";
import { watchGetRoomMessages } from "./chats/getRoomMessages";
import { watchSendMessage } from "./chats/sendMessage";
import { watchAddMessage } from "./chats/addMessage";
import { watchMarkRoomAsSeen } from "./chats/markRoomAsSeen";
import { watchSyncPropertyRooms } from "./chats/syncPropertyRooms";
import { watchSyncCompanyRooms } from "./chats/syncCompanyRooms";
import { watchSyncReplyRooms } from "./chats/syncReplyRooms";
import { watchSyncPropertyMessages } from "./chats/syncPropertyMessages";
import { watchSyncCompanyMessages } from "./chats/syncCompanyMessages";
import { watchSyncReplyMessages } from "./chats/syncReplyMessages";
import { watchUpdateSyncedRooms } from "./chats/updateSyncedRooms";
import { watchGetCompanyRooms } from "./chats/getCompanyRooms";
import { watchGetPropertyRooms } from "./chats/getPropertyRooms";
import { watchGetReplyRooms } from "./chats/getReplyRooms";
import { watchGetRoles } from "./getRoles";
import { watchGetCities } from "./getCities";
import { watchGetAreas } from "./getAreas";
import { watchGetSettings } from "./getSettings";
import watchGetEnums from "./getEnums";
import watchGetNotificationsList from "../../pages/NotificationsListInitialization/sagas/getNotifications";

const saga = function* rootSaga() {
  yield all(
    [
      adminSaga(dataProvider, authProvider),
      syncUser,
      watchSyncPropertyRooms,
      watchSyncCompanyRooms,
      watchSyncReplyRooms,
      watchSyncPropertyMessages,
      watchSyncCompanyMessages,
      watchSyncReplyMessages,
      watchGetRoomMessages,
      watchSendMessage,
      watchAddMessage,
      watchMarkRoomAsSeen,
      watchUpdateSyncedRooms,
      watchGetCompanyRooms,
      watchGetPropertyRooms,
      watchGetReplyRooms,
      watchGetRoles,
      watchGetCities,
      watchGetAreas,
      watchGetSettings,
      watchGetNotificationsList,
      watchGetEnums,
    ].map(fork)
  );
};

export default saga;

const UserRoles = {
  admin: "admin",
  propertyCompany: "propertyCompany",
  propertyAdmin: "propertyAdmin",
  propertySupervisor: "propertySupervisor",
  propertySales: "propertySales",
  unknown: "unknown",
};

export default UserRoles;

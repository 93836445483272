import { useEffect, useRef } from "react";

const usePreventInputNumberScroll = () => {
  const ref = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    ref && ref.current && ref.current.addEventListener("wheel", handleWheel);
    return () => {
      ref && ref.current && ref.current.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return { ref };
};

export default usePreventInputNumberScroll;

import NotificationsList from "../../Notifications/NotificationList";
import { getNotificationData, prepareNotificationsList, redirectOnClick } from "./utils/notificationRequest";

const DropdownNotificationList = () => {
  return (
    <NotificationsList
      type="dropdown"
      getNotificationData={getNotificationData}
      getNotificationRequest={prepareNotificationsList}
      onRedirect={redirectOnClick}
    />
  );
};

export default DropdownNotificationList;

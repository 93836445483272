import { Datagrid, List, TextField } from "react-admin";

import TablePagination from "../../components/TablePagination";
import TimestampField from "../../components/TimestampField";
import useLocalizedRAFields from "../../hooks/useLocalizedRAFields";
import useSharedStyles from "../../hooks/useSharedStyles";
import PackagesActions from "./Actions";

const PackagesList = (props) => {
  const { styles } = useSharedStyles();
  const localizedFields = useLocalizedRAFields([
    {
      key: "name",
      label: "Name",
      cellClassName: styles.truncate3,
      sortable: false,
    },
  ]);

  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "desc" }}
      pagination={<TablePagination />}
      bulkActionButtons={<PackagesActions />}
      exporter={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" cellClassName={styles.imageCell} sortable={false} />
        {localizedFields}
        <TextField source="published" cellClassName={styles.imageCell} sortable={false} />
        <TextField source="total" cellClassName={styles.imageCell} sortable={false} />
        <TextField source="featured" cellClassName={styles.imageCell} sortable={false} />
        <TextField source="boosted" cellClassName={styles.imageCell} sortable={false} />
        <TextField source="days" cellClassName={styles.imageCell} sortable={false} />
        <TimestampField source="createdAt" label="Created At" />
      </Datagrid>
    </List>
  );
};
export default PackagesList;

import { Box, Typography } from "@material-ui/core";
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  ImageInput,
  SelectInput,
  ImageField,
  SaveButton,
  FormGroupContextProvider,
  FormWithRedirect,
  NumberInput,
  required,
} from "react-admin";

import React from "react";
import FormWrapper from "../../components/FormWrapper";
import { useState } from "react";
import { ResourcesKeys } from "../../enums";
import useSharedStyles from "../../hooks/useSharedStyles";
import CustomToolbar from "../../components/CustomToolbar";
import { useEffect } from "react";
import { applyTimestamps } from "../../utils/funcs";
import ImageUploadInput from "../../components/ImageUploadInput";
import CustomCard from "../../components/CustomCard";
import WarningIcon from "@material-ui/icons/Warning";

const Custom = (props) => {
  const { styles } = useSharedStyles();
  // const [type, setType] = useState("Content ID");

  const [date, setDate] = useState(null);

  useEffect(() => {
    setDate(new Date());
  }, []);

  // const handleTypeChange = (e) => {
  //   if (e.target.value == "url") {
  //     setType(e.target.value);
  //   } else {
  //     setType(`${e.target.value} ID`);
  //   }
  // };

  const validateDate = (value) => {
    const expiryDate = new Date(value);

    if (expiryDate && expiryDate < new Date()) {
      return "Date must be in the future";
    }
  };

  return (
    <FormWrapper>
      <FormGroupContextProvider name="AdCreation">
        <FormWithRedirect
          {...props}
          // save={ handleSave }
          render={(formProps) => {
            return (
              <form>
                <CustomCard>
                  <Box className={styles.row} alignItems="center">
                    {/* <Box style={{ width: "25%", marginRight: "20px" }}>
                      <SelectInput
                        onChange={handleTypeChange}
                        source="type"
                        variant="outlined"
                        choices={[
                          { id: "company", name: "Company" },
                          { id: "property", name: "Property" },
                          { id: "url", name: "URL", selected: true },
                        ]}
                      />
                    </Box> */}

                    <Box className={styles.rowElement}>
                      <TextInput source="contentId" label="Url" variant="outlined" />
                    </Box>
                  </Box>
                </CustomCard>

                <CustomCard>
                  <Box className={styles.row}>
                    <NumberInput variant="outlined" label="Period in seconds" source="period" validate={[required()]} />

                    <DateInput
                      variant="outlined"
                      label="Expiration date"
                      source="expiryDate"
                      defaultValue={date}
                      validate={[required(), validateDate]}
                    />
                  </Box>
                </CustomCard>

                {/* <ImageUploadInput source="image" label="Image" validate={[required()]} /> */}
                <CustomCard>
                  <Typography variant="h6">AD Image</Typography>

                  <Box style={{ border: "3px dashed gray", padding: "10px", borderRadius: "10px" }}>
                    <Typography style={{ fontSize: "0.875rem", display: "flex", alignItems: "center" }} variant="p">
                      {" "}
                      <WarningIcon style={{ color: "orange", marginRight: "10px" }} /> Note: Maximum recommended size is
                      500 kb.
                    </Typography>

                    <ImageInput accept="image/*" label="image" source="image" validate={[required()]}>
                      <ImageField label="image" source="path" />
                    </ImageInput>
                  </Box>
                </CustomCard>

                <CustomToolbar
                  {...formProps}
                  saving={formProps.saving}
                  // handleSubmit={ formProps.handleSubmit }
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  formName="AdCreation"
                  errors={{}}
                />
              </form>
            );
          }}
        />
      </FormGroupContextProvider>
    </FormWrapper>
  );
};

export default function CreateAD(props) {
  const handleTansform = (data) => {
    const expiryDate = new Date(data.expiryDate);

    data.expiryDate = expiryDate;

    // const timestamp = Math.floor(expiryDate.getTime() / 1000);
    // data.expiryDate = timestamp;

    // const imageSendToBackend = {
    //     src:   data.image.rawFile.path,
    //     path:   data.image.undefined
    // }

    // data.image = imageSendToBackend;

    // if (data.type == "url") {
    //   data.type = null;
    // }
    data.type = null;

    if (!data.contentId) {
      data.contentId = null;
    }

    const objectCreated = applyTimestamps({
      data,
      isCreate: true,
    });

    const x = {
      expired: false,
      ...objectCreated,
    };

    console.log("check x", x);

    return x;
  };

  return (
    <>
      <h2>Creating New AD</h2>

      <Create {...props} transform={handleTansform}>
        <Custom {...props} />
      </Create>
    </>
  );
}

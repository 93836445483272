import { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";

import Loader from "../../../components/Loader";
import { propertyDocRef } from "../../../utils/refrences.utils";

const PropertyIdentifier = (props) => {
  const [identifierNumb, setIdentifier] = useState("");
  const [loading, setLoading] = useState(false);

  const identifier = props.record.identifier;
  const serviceRef = propertyDocRef(props.record.id);

  useEffect(() => {
    let unsub;
    if (identifier) {
      setIdentifier(identifier);
    } else {
      setLoading(true);
      unsub = serviceRef.onSnapshot(
        (snapshot) => {
          if (snapshot.exists && snapshot.data().identifier) {
            setIdentifier(snapshot.data().identifier);
            setLoading(false);
            unsub?.();
          }
        },
        (ex) => {
          console.log(ex);
          setLoading(false);
        }
      );
    }

    return () => {
      unsub?.();
    };
  }, [identifier, serviceRef]);

  return (
    <Loader loading={loading}>
      <Typography variant="body2">{identifierNumb}</Typography>
    </Loader>
  );
};

export default PropertyIdentifier;

import { maxLength, minLength, required } from "react-admin";

import useLocalizedRAInputs from "../../../hooks/useLocalizedRAInputs";
import { Locals } from "../../../enums";
import { stringHasContent } from "../../../utils/validators";

export const usePackagesLocalizations = (props) => {
  return useLocalizedRAInputs([
    {
      key: "name",
      label: "Name",
      validate: {
        [Locals[0].key]: [required(), stringHasContent(), minLength(3), maxLength(30)],
        [Locals[1].key]: [required(), stringHasContent(), minLength(3), maxLength(30)],
      },
      ...props,
    },
  ]);
};

import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";

const tenantsResource = {
  [ResourcesKeys.Tenants]: {
    [RequestKeys.GET_LIST]: async (queryData) =>
      getFinalList(firestore.collectionGroup(ResourcesKeys.Landlords), {
        ...queryData,
        limit: queryData.pagination.perPage,
        filters: {
          ...queryData.filter,
        },
      }),
  },
};

export default tenantsResource;

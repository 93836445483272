import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList, prepareQueryFilters } from "../../../utils/funcs";

const companyPropertiesResource = {
  [ResourcesKeys.CompanyProperties]: {
    [RequestKeys.GET_LIST]: async (queryData) => {
      queryData.filter.operators = {
        ...(queryData.filter.priceFrom ? { priceFrom: ">=" } : {}),
        ...(queryData.filter.priceTo ? { priceTo: "<=" } : {}),
        ...(queryData.filter.filters?.assignees ? { assignees: "array-contains" } : {}),
        // add more here, if needed
      };

      queryData.fieldOverrides = {
        ...(queryData.filter.priceFrom ? { priceFrom: "price" } : {}),
        ...(queryData.filter.priceTo ? { priceTo: "price" } : {}),
        // add more here, if needed
      };

      queryData.sort.field = queryData.filter.priceFrom || queryData.filter.priceTo ? "price" : "createdAt";

      return getFinalList(firestore.collection(ResourcesKeys.Properties), {
        ...queryData,
        limit: queryData.pagination.perPage,
        ...prepareQueryFilters(queryData.filter),
      });
    },
  },
};

export default companyPropertiesResource;

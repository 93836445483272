import { Typography } from "@material-ui/core";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useRef, useState } from "react";

dayjs.extend(relativeTime);

const TimeCounter = ({ endDate, interval = 60 * 1000, clear = false }) => {
  const [counter, setCounter] = useState(dayjs(endDate).from(dayjs()));
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCounter(dayjs(endDate).from(dayjs()));
    }, interval);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (clear && intervalRef.current) clearInterval(intervalRef.current);
  }, [clear]);

  return (
    <Typography variant="caption" color="textSecondary">
      {counter}
    </Typography>
  );
};

export default TimeCounter;

import { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";

const Bullet = (props) => {
  const record = useRecordContext(props);
  const [seen, setSeen] = useState(false);
  const requests = props.requests;

  useEffect(() => {
    setSeen(requests.includes(record.id));
  }, [requests]);

  return (
    <>
      {!seen ? (
        <div
          style={{
            width: 10,
            height: 10,
            backgroundColor: "#ff9494",
            borderRadius: "50%",
          }}
        />
      ) : null}
    </>
  );
};

export default Bullet;

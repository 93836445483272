import NotificationsList from "../../Notifications/NotificationList";
import { getNotificationData, prepareNotificationsList, redirectOnClick } from "./utils/notificationRequest";

const FullNotificationList = () => {
  return (
    <NotificationsList
      type="full"
      getNotificationData={getNotificationData}
      getNotificationRequest={prepareNotificationsList}
      onRedirect={redirectOnClick}
    />
  );
};

export default FullNotificationList;

import { Box, Typography } from "@material-ui/core";
import { forwardRef } from "react";
import { useRedirect } from "react-admin";
import { useSelector } from "react-redux";

import { ResourcesKeys } from "../../../enums";
import NotificationItems from "../NotificationsItems";

const DropDownMenuNotificationsList = forwardRef((props, ref) => {
  const notifications = useSelector((state) => state.notifications);

  // console.log( "notificationsss", notifications );

  const redirect = useRedirect();

  return (
    <div {...props} {...{ ref }}>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography>Notifications</Typography>
        <Typography
          variant="body2"
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          onClick={() => {
            redirect(`/${ResourcesKeys.notifications}`);
            props.handleClose();
          }}
        >
          See All
        </Typography>
      </Box>

      <Box width={320} overflow="hidden">
        <NotificationItems notifications={notifications} limit={5} handleClose={props.handleClose} />
      </Box>
    </div>
  );
});

export default DropDownMenuNotificationsList;

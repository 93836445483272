import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Card, CircularProgress, Toolbar, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { DataGrid } from "@material-ui/data-grid";
import { from, of } from "rxjs";
import { catchError, filter, map, pluck, switchMap, tap, toArray, concatMap } from "rxjs/operators";
import { Title, useNotify, useRedirect } from "react-admin";
import { isEmpty } from "lodash/fp";

import ComparisonModal from "./ComparisonModal";
import { firestore } from "../../init/firebase";
import { applyTimestamps } from "../../utils/funcs";
import { ResourcesKeys } from "../../enums";
import { getMatchPercent, getTableCols } from "./utils";
import { areEqual } from "../../utils/general";
import useCurrentUser from "../../hooks/useCurrentUser";
import useIsSales from "../../hooks/useIsSales";
import useIsSupervisor from "../../hooks/useIsSupervisor";
import { companyDocRef, employeesDocRef, repliesSubRef, requestDocRef } from "../../utils/refrences.utils";
import useIsCompany from "../../hooks/useIsCompany";
import Loader from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
  sectionStyleResponsive: {
    minHeight: "90vh",
    overflowY: "hidden",
    overflowX: "auto",
    marginTop: "28px",
  },
  root: {
    "& .MuiDataGrid-row": {
      display: "flex",
      width: "fit-content",
      cursor: "pointer",

      "&:hover": {
        backgroundColor: theme.palette.action.hover,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#eee",
      [theme.breakpoints.down("sm")]: {
        position: "static",
      },
    },
  },
  hideCheckBox: {
    "&.MuiDataGrid-row": {
      "& .MuiDataGrid-checkboxInput": {
        opacity: 0.5,
        pointerEvents: "none",
      },
    },
  },
}));

const RequestMatches = () => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const redirect = useRedirect();
  const { requestId } = useParams();
  const notify = useNotify();
  const classes = useStyles();
  const { user } = useCurrentUser();
  const { isSales } = useIsSales();
  const { isCompany } = useIsCompany();
  const { isSupervisor } = useIsSupervisor();
  const settings = useSelector((state) => state.settings.properties);
  const storeCities = useSelector((state) => state.cities);
  const storeAres = useSelector((state) => state.areas);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sendReplyLoading, setSendReplyLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(null);
  const [request, setRequest] = useState(null);
  const [areas, setAreas] = useState([]);
  const [city, setCity] = useState(null);
  const [property, setProperty] = useState(null);
  const [showComparison, setShowComparison] = useState(false);

  const userId = user.id;
  const companyId = user.companyId;
  const isEmployee = () => isSales || isSupervisor;
  const handleCloseModel = () => setShowComparison(false);

  const getRequest$ = () =>
    from(requestDocRef(requestId).get()).pipe(
      map((snapshot) => snapshot.data()),
      tap((req) => {
        console.log("getRequest", req);
        setRequest(req);
      }),
      catchError((ex) => {
        console.log(ex.message);
      })
    );

  const getAllReplies$ = (userId, isEmp) => {
    let query = repliesSubRef(requestId);
    console.log("getRequest isEmp", isEmp);

    if (isEmp) {
      query = query.where("property.assignees", "array-contains", userId);
    } else {
      query = query.where("property.companyId", "==", companyId);
    }

    return from(query.limit(20).get()).pipe(
      map((snapshot) => snapshot.docs),
      switchMap((docs) => from(docs)),
      map((docSnapshot) => {
        console.log("getRequest de replies", docSnapshot.data());
        return { ...docSnapshot.data(), id: docSnapshot.id };
      }),
      toArray(),
      catchError((ex) => {
        console.log(ex.message);
        return of([]);
      })
    );
  };

  const getProperties$ = ({ req, replies, emp }) => {
    let query = firestore
      .collection(ResourcesKeys.Properties)
      .where("deleted", "==", false)
      .where("category", "==", req.category)
      .where("type", "==", req.type)
      .where("cityId", "==", req.cityId)
      .where("purchasingOption", "==", req.purchasingOption)
      .where("areaId", "in", req.areas.slice(0, 10));

    if (emp) {
      query = query.where("assignees", "array-contains", userId);
    } else {
      query = query.where("companyId", "==", companyId);
    }

    return from(query.limit(20).get()).pipe(
      pluck("docs"),
      switchMap((docs) => from(docs)),
      map((snapshot) => ({
        id: snapshot.id,
        ...snapshot.data(),
      })),
      map((property, i) => {
        const reply = replies.find((r) => r.id === `${requestId}_${property.id}`);
        return {
          ...property,
          city: storeCities[property.cityId].name.en,
          area: storeAres[property.areaId].name.en,
          sent: !!reply,
          sentBy: reply ? reply.sentBy : null,
          replyType: reply ? reply.type : null,
        };
      }),
      concatMap((property) => {
        if (!property.sentBy) return of(property);
        return from(property.sentBy.get()).pipe(
          map((snapshot) => ({
            ...property,
            sentBy: snapshot.data().email,
          })),
          catchError((ex) => {
            console.log(ex.message);
          })
        );
      }),
      map((property) => getMatchPercent(property, req)),
      filter((property) => property.matching > settings.minMatchingPercentage),
      toArray(),
      catchError((ex) => {
        console.log(ex.message);
      })
    );
  };

  useEffect(() => {
    let sub;
    if (!isEmpty(storeCities) && !isEmpty(storeAres)) {
      setLoading(true);
      sub = getRequest$()
        .pipe(
          tap((req) => {
            setCity(storeCities[req.cityId]);
            setAreas(req.areas.map((areaId) => storeAres[areaId]));
          }),
          switchMap((req) => {
            return getAllReplies$(userId, isEmployee()).pipe(
              map((replies) => {
                return {
                  replies,
                  req,
                };
              })
            );
          }),
          switchMap(({ replies, req }) => {
            return getProperties$({
              req,
              replies,
              emp: isEmployee(),
            });
          })
        )
        .subscribe({
          next(values) {
            console.log("wareny el values", values);
            setRows(values);
            setLoading(false);
            setError(null);
          },
          error(ex) {
            setLoading(false);
            setError(ex.message);
          },
        });
    }

    return () => {
      sub && sub.unsubscribe();
    };
  }, [storeAres, storeCities]);

  const sendReplies = async () => {
    try {
      setSendReplyLoading(true);
      const batch = firestore.batch();
      selected.forEach((propertyId) => {
        batch.set(
          firestore
            .collection(ResourcesKeys.PropertiesRequests)
            .doc(requestId)
            .collection(ResourcesKeys.Reply)
            .doc(`${requestId}_${propertyId}`),
          applyTimestamps({
            data: {
              property: rows.find((doc) => doc.id === propertyId),
              disabled: false,
              companyId,
              sentBy: isCompany ? companyDocRef(companyId) : employeesDocRef(companyId, userId),
              type: "MANUALREPLY",
            },
            isCreate: true,
          })
        );
      });
      await batch.commit();
      // update rows after send requests
      const newRows = rows.map((row) => {
        if (selected.includes(row.id)) {
          row.sent = true;
        }
        return row;
      });
      setRows(newRows);
      setSelected([]);
      setSendReplyLoading(false);
      notify("Request(s) sent", "success");
    } catch (ex) {
      console.log(ex.message);
      setSendReplyLoading(false);
      notify("Failed to sent request(s)", "error");
    }
  };

  return (
    <Loader loading={loading}>
      <>
        {error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <section className={isSm ? classes.sectionStyleResponsive : ""}>
            <Title title="Properties Matches" />
            <Toolbar style={{ display: "flex", justifyContent: "flex-end" }}>
              {selected.length > 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={sendReplyLoading}
                  onClick={sendReplies}
                  startIcon={sendReplyLoading ? <CircularProgress size={16} /> : null}
                >
                  Send Replies
                </Button>
              ) : null}
            </Toolbar>
            <Card>
              {rows.length ? (
                <DataGrid
                  rows={rows}
                  columns={getTableCols()}
                  className={classes.root}
                  pageSize={10}
                  checkboxSelection
                  autoHeight
                  onRowClick={({ row }) => {
                    setProperty(row);
                    setShowComparison(true);
                  }}
                  rowsPerPageOptions={[5, 10, 20]}
                  loading={loading}
                  sortingOrder={["desc", "asc"]}
                  sortModel={[
                    {
                      field: "matching",
                      sort: "desc",
                    },
                  ]}
                  disableSelectionOnClick
                  onSelectionModelChange={(changeParams) => {
                    // todo - revisit this as `selectionModel` not working in production mode
                    setSelected(Array.isArray(changeParams) ? changeParams : changeParams.selectionModel);
                  }}
                  selectionModel={selected}
                  error={error}
                  getRowClassName={(params) => (params.getValue(params.id, "sent") ? classes.hideCheckBox : "")}
                />
              ) : (
                <Box py={2} textAlign="center">
                  <Typography variant="body1" style={{ marginBottom: 3 }}>
                    No Rows
                  </Typography>
                  <Box textAlign="right" mr={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => redirect(`/companyProperties/create?requestId=${requestId}`)}
                    >
                      {/* <Link to={`/companyProperties/create?requestId=${requestId}`}>Create Property</Link> */}
                      Create Property
                    </Button>
                  </Box>
                </Box>
              )}
            </Card>
            <ComparisonModal
              isOpen={showComparison}
              handleCancel={handleCloseModel}
              property={property}
              request={request}
              areas={areas}
              city={city}
            />
          </section>
        )}
      </>
    </Loader>
  );
};

export default memo(RequestMatches, areEqual);

import { firestore } from "../init/firebase";
import { ResourcesKeys } from "../enums";
import {
  blockedCompaniesCollPath,
  clientDocPath,
  companyDocPath,
  employeeDocPath,
  employeesCollPath,
  messagesCollPath,
  messagesDocPath,
  packageDocPath,
  propertyDocPath,
  repliesCollPath,
  repliesDocPath,
  requestDocPath,
  requestsSeenByCollPath,
  requestsSeenByDocPath,
  reviewCollPath,
  reviewsDocPath,
  roomDocPath,
  statisticsDocPath,
  xmlsCollPath,
} from "./paths.utils";

// helpers
export const getDocRef = (path) => firestore.doc(path);
export const getRef = (name) => firestore.collection(name);
export const getCGRef = (name) => firestore.collectionGroup(name);

/**
 * Collections
 * */
// root collection references
export const propertiesRequestsRef = getRef(ResourcesKeys.PropertiesRequests);
export const settingsRef = getRef(ResourcesKeys.Settings);
export const clientRef = getRef(ResourcesKeys.Clients);
export const companiesRef = getRef(ResourcesKeys.PropertiesCompanies);
export const roomsRef = getRef(ResourcesKeys.Rooms);
export const propertiesRef = getRef(ResourcesKeys.CompanyProperties);
export const landlordsRef = getRef(ResourcesKeys.Landlords);
export const packagesRef = getRef(ResourcesKeys.Packages);
export const notificationsCollectionRef = getRef(ResourcesKeys.notifications);

// seb collection references
export const reviewsSubRef = (companyId) => getRef(reviewCollPath(companyId));
export const propertiesXmlSubRef = (companyId) => getRef(xmlsCollPath(companyId));
export const employeesSubRef = (companyId) => getRef(employeesCollPath(companyId));
export const requestsSeenBySubRef = (requestId) => getRef(requestsSeenByCollPath(requestId));
export const repliesSubRef = (requestId) => getRef(repliesCollPath(requestId));
export const messagesSubRef = (roomId) => getRef(messagesCollPath(roomId));
export const blockedCompaniesSubRef = (clientId) => getRef(blockedCompaniesCollPath(clientId));

// collection group references
export const landlordsCGRef = getCGRef(ResourcesKeys.Landlords);
export const seenByCGRef = getCGRef(ResourcesKeys.SeenBy);
export const reviewsCGRef = getCGRef(ResourcesKeys.Reviews);

/**
 * Documents
 * */
// root collection document references
export const clientDocRef = (clientId) => getDocRef(clientDocPath(clientId));
export const companyDocRef = (companyId) => getDocRef(companyDocPath(companyId));
export const propertyDocRef = (propertyId) => getDocRef(propertyDocPath(propertyId));
export const statisticsDocRef = (companyId) => getDocRef(statisticsDocPath(companyId, companyId));
export const roomDocRef = (roomId) => getDocRef(roomDocPath(roomId));
export const requestDocRef = (requestId) => getDocRef(requestDocPath(requestId));
export const packageDocRef = (packageId) => getDocRef(packageDocPath(packageId));

// sub collection document references
export const employeesDocRef = (companyId, docId) => getDocRef(employeeDocPath(companyId, docId));
export const requestsSeenByDocRef = (requestId, docId) => getDocRef(requestsSeenByDocPath(requestId, docId));
export const repliesDocRef = (requestId, docId) => getDocRef(repliesDocPath(requestId, docId));
export const messagesDocRef = (roomId, docId) => getDocRef(messagesDocPath(roomId, docId));
export const reviewsDocRef = (companyId, docId) => getDocRef(reviewsDocPath(companyId, docId));

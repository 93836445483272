import { Box, makeStyles, Popover, Typography } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const TooltipPopover = ({ message, children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const id = !!anchorEl ? "property-status" : undefined;

  return (
    <>
      {message ? (
        <>
          <Box
            aria-describedby={id}
            color="primary"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handlePopoverClose}
          >
            {children}
          </Box>

          <Popover
            id={id}
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography variant="body2">{message}</Typography>
          </Popover>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default TooltipPopover;

import { useEffect, useState } from "react";

export function useScrollToEnd(ref, reverse = false) {
  const element = ref.current;
  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const value =
        Math.ceil(element.clientHeight + (reverse ? -element.scrollTop : element.scrollTop)) === element.scrollHeight;
      if (value) console.log({ end: value });
      setIsAtEnd(value);
    }

    if (element) element.addEventListener("scroll", handleScroll);

    return () => {
      if (element) element.removeEventListener("scroll", handleScroll);
    };
  }, [element, reverse]);

  return { isAtEnd, setIsAtEnd };
}

import { Box, Typography } from "@material-ui/core";
import {
  Create,
  FormGroupContextProvider,
  FormWithRedirect,
  maxValue,
  minValue,
  NumberInput,
  required,
} from "react-admin";

import ImageUploadInput from "../../components/ImageUploadInput";
import { int } from "../../utils/validators";
import CustomToolbar from "../../components/CustomToolbar";
import useDisabledEvents from "../../hooks/useDisabledEvents";
import { useUserGuideLocalizations } from "./utils";
import { FormGroupNames, ResourcesKeys } from "../../enums";
import FormWrapper from "../../components/FormWrapper";
import CustomCard from "../../components/CustomCard";
import useSharedStyles from "../../hooks/useSharedStyles";

const CustomForm = (props) => {
  const { styles } = useSharedStyles();
  const { disabledEvents } = useDisabledEvents();
  const localizedInputs = useUserGuideLocalizations(props);

  return (
    <FormGroupContextProvider name={FormGroupNames.userGuide}>
      <FormWithRedirect
        {...props}
        style={disabledEvents}
        redirect={`/${ResourcesKeys.UserGuide}`}
        render={(formProps) => {
          return (
            <form style={disabledEvents}>
              <FormWrapper>
                <CustomCard>
                  <Typography variant="h6">Details</Typography>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>{localizedInputs[0]}</Box>
                    <Box className={styles.rowElement}>{localizedInputs[1]}</Box>
                  </Box>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>{localizedInputs[2]}</Box>
                    <Box className={styles.rowElement}>{localizedInputs[3]}</Box>
                  </Box>
                  <Box className={styles.row}>
                    <Box className={styles.rowElement}>
                      <NumberInput
                        variant="outlined"
                        source="sortIndex"
                        validate={[required(), int(), minValue(0), maxValue(20)]}
                      />
                    </Box>
                  </Box>
                </CustomCard>

                <CustomCard>
                  <Typography variant="h6">Media</Typography>
                  <ImageUploadInput source="image" validate={[required()]} />
                </CustomCard>
              </FormWrapper>

              <CustomToolbar
                {...formProps}
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                formName={FormGroupNames.userGuide}
              />
            </form>
          );
        }}
      />
    </FormGroupContextProvider>
  );
};

const UserGuideCreate = (props) => {
  return (
    <Create {...props} component="div">
      <CustomForm {...props} variant="outlined" />
    </Create>
  );
};

export default UserGuideCreate;

import { useListContext } from "react-admin";
import useDisabledEvents from "./useDisabledEvents";

const useIsEmptyList = (props) => {
  const listContext = useListContext(props);
  const { disabledEvents } = useDisabledEvents(true);

  const isEmpty = listContext.ids.length === 0;

  return {
    isEmpty,
    disabledStyles: isEmpty ? disabledEvents : {},
  };
};

export default useIsEmptyList;

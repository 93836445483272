import { RequestKeys, ResourcesKeys } from "../../../enums";
import { firestore } from "../../firebase";
import { getFinalList } from "../../../utils/funcs";

const propertiesResource = {
  [ResourcesKeys.Properties]: {
    [RequestKeys.GET_LIST]: async (queryData) =>
      getFinalList(firestore.collection(ResourcesKeys.Properties), {
        ...queryData,
        sort: {
          field: "createdAt",
          order: "desc",
        },
        limit: queryData.pagination.perPage,
        filters: {
          ...queryData.filter,
          deleted: false,
        },
      }),
  },
};

export default propertiesResource;

import { useRef, useState } from "react";
import { Create, FormGroupContextProvider, FormWithRedirect, required, TextInput } from "react-admin";

import CustomCard from "../../components/CustomCard";
import CustomSelect from "../../components/CustomSelect";
import CustomToolbar from "../../components/CustomToolbar";
import FormWrapper from "../../components/FormWrapper";
import { FormGroupNames, ResourcesKeys } from "../../enums";
import useDisabledEvents from "../../hooks/useDisabledEvents";

const CustomForm = (props) => {
  const { typeRef } = props;
  const { disabledEvents } = useDisabledEvents();
  const typeEnums = [
    { id: "clients", name: "Clients" },
    { id: "propertiesCompanies", name: "Properties Companies" },
  ];

  const [selectedType, setSelectedType] = useState([]);

  return (
    <FormWrapper>
      <FormGroupContextProvider name={FormGroupNames.announcementsCreate}>
        <FormWithRedirect
          {...props}
          redirect={`/${ResourcesKeys.announcements}`}
          render={(formProps) => {
            return (
              <form style={disabledEvents}>
                <CustomCard>
                  <TextInput source="title" variant="outlined" fullWidth validate={[required()]} />
                  <TextInput
                    source="message"
                    variant="outlined"
                    fullWidth
                    validate={[required()]}
                    multiline
                    maxRows={4}
                    minRows={4}
                  />
                  <CustomSelect
                    multiple={true}
                    handleSelectItem={(_selectedType) => {
                      setSelectedType(_selectedType);
                      typeRef.current = _selectedType;
                    }}
                    selectedItem={selectedType}
                    labelTitle="Type *"
                    listItems={typeEnums}
                    width={"100%"}
                  />
                </CustomCard>

                <CustomToolbar
                  {...formProps}
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  formName={FormGroupNames.announcementsCreate}
                  errors={{
                    type: !selectedType.length ? "Type is required" : "",
                  }}
                />
              </form>
            );
          }}
        />
      </FormGroupContextProvider>
    </FormWrapper>
  );
};

const AnnouncementsCreate = (props) => {
  const typeRef = useRef();

  return (
    <Create
      {...props}
      component="section"
      transform={(values) => {
        values.types = typeRef.current;
        return values;
      }}
    >
      <CustomForm typeRef={typeRef} />
    </Create>
  );
};

export default AnnouncementsCreate;

import { Card, makeStyles, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.grey[100],
    boxShadow: `0 3px 4px ${theme.palette.grey[300]}`,
  },
}));

const CustomCard = ({ children, width = "auto" }) => {
  const classes = useStyles({});
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Card
      style={{
        width: isSm ? "auto" : width,
      }}
      className={classes.card}
    >
      {children}
    </Card>
  );
};

export default CustomCard;

import React from "react";
import PackageDelete from "./PackageDelete";

const PackagesActions = (props) => {
  return (
    <>
      <PackageDelete {...props}></PackageDelete>
    </>
  );
};

export default PackagesActions;

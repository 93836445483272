import { cancel, fork, take } from "redux-saga/effects";

import { firestore, rsf } from "../init/firebase";
import chatActionCreators from "../redux/actions/chat";
import { getDataFromFirebaseCollection } from "./funcs";
import { getChatRoomsQuery } from "../queries/chatRooms";
import { companyDocRef } from "./refrences.utils";

function* syncMessages(action) {
  const { companyId, type } = action.payload;

  while (true) {
    let task = yield fork(
      rsf.firestore.syncCollection,
      firestore
        .collectionGroup("messages")
        .where("to", "==", companyDocRef(companyId))
        .where("seen", "==", false)
        .orderBy("createdAt", "desc")
        .limit(1),
      {
        successActionCreator: (snapshot) => {
          if (snapshot.docs.length === 0) return { type: "" };
          let isAdded = false;
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              isAdded = true;
            }
          });
          if (isAdded)
            return chatActionCreators.addMessage(
              snapshot.docs[0].ref.parent.parent.id,
              getDataFromFirebaseCollection(snapshot.docs)[0],
              type
            );
          return { type: "" };
        },
        failureActionCreator: (error) => console.log(`subscribeToChat handler error: ${error}`),
      }
    );

    // Wait for the pause action, then stop sync
    yield take("PAUSE_SYNC");
    yield cancel(task);

    // Wait for the resume action
    yield take("RESUME_SYNC");
  }
}

function* syncChatRooms(action) {
  const { companyId, userId, role, type } = action.payload;

  while (true) {
    let task = yield fork(rsf.firestore.syncCollection, getChatRoomsQuery(type, companyId, userId, role).limit(1), {
      successActionCreator: (snapshot) => {
        const changes = snapshot.docChanges();
        if (changes.length > 0) {
          return chatActionCreators.updateSyncedRooms({
            rooms: snapshot.docs,
            type,
            userId,
          });
        }
        return { type: "" };
      },
      failureActionCreator: (error) => console.log(`subscribeToChatRooms handler error: ${error}`),
    });

    // Wait for the pause action, then stop sync
    yield take("PAUSE_SYNC");
    yield cancel(task);

    // Wait for the resume action
    yield take("RESUME_SYNC");
  }
}

const sagasUtils = { syncMessages, syncChatRooms };
export default sagasUtils;

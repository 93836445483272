import { useEffect } from "react";
import { useDispatch } from "react-redux";

import chatActionCreators from "../redux/actions/chat";
import useCurrentUser from "./useCurrentUser";
import useGetUserRole from "./useGetUserRole";
import useIsCompany from "./useIsCompany";
import useIsCompanyAdmin from "./useIsCompanyAdmin";

const useChatListener = (shouldListen = false) => {
  const dispatch = useDispatch();
  const { role } = useGetUserRole();
  const { isCompany } = useIsCompany();
  const { isCompanyAdmin } = useIsCompanyAdmin();
  const { user } = useCurrentUser();
  const companyId = user && user.companyId;
  const userId = user && user.id;

  useEffect(() => {
    if (shouldListen) {
      if (isCompany || isCompanyAdmin) {
        dispatch(chatActionCreators.syncCompanyRooms(companyId, userId, role));
        dispatch(chatActionCreators.syncCompanyMessages(companyId));
      }

      dispatch(chatActionCreators.syncPropertyRooms(companyId, userId, role));
      dispatch(chatActionCreators.syncReplyRooms(companyId, userId, role));

      dispatch(chatActionCreators.syncPropertyMessages(companyId));
      dispatch(chatActionCreators.syncReplyMessages(companyId));
    }
  }, [shouldListen, userId, companyId, role, isCompany, isCompanyAdmin, dispatch]);
};

export default useChatListener;
